import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
	selector: 'app-payment-note',
	templateUrl: './payment-note.component.html',
	styleUrls: ['./payment-note.component.scss'],
})
export class PaymentNoteComponent implements OnInit {

	note: any;
	method: any;
	methodForm: UntypedFormGroup;

	constructor(
		public navParams: NavParams,
		public auth: AuthService,
		public formBuilder: UntypedFormBuilder,
		public modalController: ModalController
	) {
		this.method = this.navParams.data.method;

		if (this.method == 'check') {
			this.methodForm = this.formBuilder.group({
				bank: ['', [Validators.required]],
				check_number: ['', [Validators.required]],
				owner: ['', [Validators.required]],
				account_number: [''],
				reserved: [false],
				reservation_number: [''],
			});
		}else if(this.method == 'transfer'){
			this.methodForm = this.formBuilder.group({
				bank: ['', [Validators.required]],
				voucher_number: ['', [Validators.required]],
				owner: ['', [Validators.required]],
				account_number: [''],
			});
		}else if(this.method == 'link'){
			this.methodForm = this.formBuilder.group({
				bank: ['', [Validators.required]],
				operation_number: ['', [Validators.required]],
			});
		}else if(this.method == 'other'){
			this.methodForm = this.formBuilder.group({
				method: ['', [Validators.required]],
				note: ['', [Validators.required]],
			});
		}
	}

	ngOnInit() { }

	recordPayment() {
		let data = {
			method: this.method,
			method_data: this.methodForm.value
		}

		this.modalController.dismiss(data);
	}
}
