import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-add-discount',
	templateUrl: './add-discount.component.html',
	styleUrls: ['./add-discount.component.scss'],
})
export class AddDiscountComponent implements OnInit {

	discountForm: UntypedFormGroup;
	type: any = 'custom';
	order: any;
	manager: any = false;
	discounts: any = [];
	selected_discount: any;
	loading: any = true;

	constructor(
		public popoverController: PopoverController,
		public modalController: ModalController,
		public formBuilder: UntypedFormBuilder,
		public navParams: NavParams,
		public auth: AuthService,
		public components: ComponentsService,
		public api: ApiService,
		public pos: PosService
	) {
		this.order = this.navParams.get('order');

		this.discountForm = this.formBuilder.group({
			type: [this.type, [Validators.required]],
			amount: ['', [Validators.required]],
			note: ['', [Validators.required]]
		});

		this.api.getRef(`accounts/${this.auth.account}/discounts`).ref
			.where('branches', 'array-contains', this.auth.branch)
			.where('available', '==', true)
			.get()
			.then(sanpshots => {
				sanpshots.forEach(element => {
					let discount = element.data();
					discount.$key = element.id;
					this.discounts.push(discount);
				});
				this.discounts.sort(function (a, b) {
					var orderA = a.amount, orderB = b.amount;
					return orderA - orderB;
				});
				this.loading = false;
			})
	}

	ngOnInit() {
	}

	changeType(ev) {
		this.type = ev.detail.value;
	}

	selectDiscount(ev) {
		this.selected_discount = ev.detail.value;
		this
	}

	addDiscount(type) {
		let discount;
		let valid = true;
		let have_discount = false;

		if (this.order) {
			if (this.order.discounts.length > 0) {
				have_discount = true;
			}
		}

		if (this.pos.discounts.length > 0) {
			have_discount = true;
		}

		if (!have_discount) {
			if (type == 'custom') {
				discount = this.discountForm.value;
				discount.amount = Number(discount.amount);
				if (discount.amount <= 0) {
					valid = false;
					this.components.showToast('El valor de descuento debe ser mayor a 0.', 'error');
				}
			} else {
				if (this.selected_discount == undefined) {
					valid = false;
					this.components.showToast('Debes de seleccionar un descuento.', 'error');
				} else {
					discount = this.selected_discount;
				}
			}
			if (valid) {
				discount.$key = this.api.db.createId();
				this.pos.addDiscount(discount);
				this.components.showToast('Descuento agregado correctamente');
				this.modalController.dismiss();
			} else {
				this.components.dismissLoader();
			}
		} else {
			this.components.showAlert('Error', 'Tu cuenta ya posee con un descuento activo', ['Aceptar']);
			this.modalController.dismiss()
		}
	}
}
