import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFirestore } from 'angularfire2/firestore';
import { BehaviorSubject, from } from 'rxjs';
import { CountdownTimerComponent } from 'src/app/components/shared/countdown-timer/countdown-timer.component';
import { ComponentsService } from "../components/components.service";
import { PosService } from '../pos/pos.service';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { Network } from '@capacitor/network';


@Injectable({
	providedIn: 'root'
})

export class AuthService {

	public isLicenseAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isAccountAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

	isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	token: any = '';
	peripheral: any = false;
	peripheral_device: any = false;
	account: any;
	branch: any;
	device: any;
	device_name: any;
	device_code: any;
	timer: any;
	license: any = false;
	countdownPresent: any = false;
	account_data: any;
	device_number: any;
	branch_data: any;
	uid: any;
	kds_devices: any = [];
	device_data: any = [];
	report: any;

	pos_roles: any = [
		{
			type: 'manager',
			roles: ['all']
		},
		{
			type: 'employee',
			roles: ['pos']
		},
		{
			type: 'cashier',
			roles: ['pos', 'clients', 'printers', 'transactions', 'my-orders', 'closings']
		}
	]

	constructor(
		public database: AngularFireDatabase,
		private router: Router,
		public db: AngularFirestore,
		private angularAuth: AngularFireAuth,
		public modalController: ModalController,
		public components: ComponentsService,
		private ble: BLE,
		public pos: PosService,
	) {

		this.init();
	}

	init() {
		Network.getStatus().then(data => {
			if (data.connected) {
				this.angularAuth.authState.subscribe((firebaseUser) => {
					if (firebaseUser !== null) {
						this.uid = firebaseUser.uid
						this.loadToken();
					} else {
						this.isAuthenticated.next(false);
					}
				})
			} else {
				this.init();
			}
		})
	}

	/**
	 * It checks if the user has an account, branch, device, and device name saved in the local storage.
	 * If they do, it sets the account, branch, device, and device name to the values saved in the local
	 * storage. It also sets the isAuthenticated to true and calls the checkDeviceStatus() and
	 * checkMembership() functions. If the user doesn't have an account, branch, device, or device name
	 * saved in the local storage, it sets the isAuthenticated to false
	 */
	async loadToken() {
		const account = await Preferences.get({ key: 'account' });
		const branch = await Preferences.get({ key: 'branch' });
		const device = await Preferences.get({ key: 'device' });
		const device_name = await Preferences.get({ key: 'device_name' });
		const device_code = await Preferences.get({ key: 'device_code' });
		const device_number = await Preferences.get({ key: 'device_number' });

		if (account && account.value && branch && branch.value && device && device.value) {
			this.account = account.value;
			this.branch = branch.value;
			this.device = device.value;
			this.device_name = device_name.value;
			this.device_number = device_number.value;
			this.device_code = device_code.value;
			this.isAuthenticated.next(true);
			this.checkBranch();
			this.checkDevice();
			this.checkMembership();
			this.checkAccount();
		} else {
			this.isAuthenticated.next(false);
		}
	}

	/**
	 * It checks if the device code is available and if it is, it sets the device as unavailable and
	 * stores the device's account, branch and device keys in the device's storage
	 * @param code - The code of the device to be registered.
	 * @returns A promise
	 */
	registerDevice(code) {
		return new Promise((resolve, reject) => {

			firebase.auth().signInAnonymously()
				.then((data) => {

					let uid = data.user.uid;

					this.db.collection('devices').ref
						.where('code', '==', code)
						.where('type', '==', 'POS')
						.get()
						.then(snapshots => {
							console.log(snapshots);

							if (snapshots.empty) {
								const user = this.angularAuth.auth.currentUser;

								if (user && user.isAnonymous) {
									user.delete()
										.then(() => {
											this.angularAuth.auth.signOut().then(() => {
												reject(false);
											}, error => {
												console.log('Error al hacer logout:', error);
											})
										})
										.catch((error) => {
											reject(false);
										});
								}
							} else {
								snapshots.forEach(async element => {
									let device = element.data();
									device.$key = element.id;

									if (device.available) {
										from(Preferences.set({ key: 'account', value: device.account_key }));
										from(Preferences.set({ key: 'branch', value: device.branch_key }));
										from(Preferences.set({ key: 'device', value: device.$key }));

										const device_id = await Device.getId();

										let batch = this.db.firestore.batch();

										batch.update(this.db.firestore.collection(`accounts`).doc(device.account_key), {
											logged_device: true
										});

										batch.update(this.db.firestore.collection(`devices`).doc(device.$key), {
											available: false,
											device_id: device_id.identifier,
											updated_at: firebase.firestore.FieldValue.serverTimestamp(),
											activeUID: uid
										});

										this.db.collection(`accounts/${device.account_key}/devices/`).ref
											.doc(device.$key)
											.get()
											.then((snapshot) => {
												let _device = snapshot.data();
												_device.$key = snapshot.id;

												from(Preferences.set({ key: 'device_name', value: _device.name }));
												from(Preferences.set({ key: 'device_code', value: _device.code }));
												from(Preferences.set({ key: 'device_number', value: String(_device.number) }));

												batch.update(this.db.firestore.collection(`accounts/${device.account_key}/devices/`).doc(device.$key), {
													available: false,
													device_id: device_id.identifier,
													updated_at: firebase.firestore.FieldValue.serverTimestamp()
												});

												batch.commit().then(data => {
													this.isAuthenticated.next(true);
													this.loadToken();
													resolve(true);
												}, err => {
													console.log(err);
													reject();
												});

											}).catch((error: any) => {
												reject(error);
											});
									} else {
										const user = this.angularAuth.auth.currentUser;

										if (user && user.isAnonymous) {
											user.delete()
												.then(() => {
													this.angularAuth.auth.signOut().then(() => {
														reject(false);
													}, error => {
														console.log('Error al hacer logout:', error);
													})
												})
												.catch((error) => {
													reject(false);
												});
										}
									}
								});
							}
						}, err => {
							console.log(err);
							const user = this.angularAuth.auth.currentUser;

							if (user && user.isAnonymous) {
								user.delete()
									.then(() => {
										this.angularAuth.auth.signOut().then(() => {
											reject(false);
										}, error => {
											console.log('Error al hacer logout:', error);
										})
									})
									.catch((error) => {
										reject(false);
									});
							}
						})

				}, err => {
					console.log(err);
				})

		})
	}

	checkDevice() {
		this.db.collection(`accounts/${this.account}/devices`).ref
			.doc(this.device)
			.get()
			.then(snapshot => {
				let device_details = snapshot.data();
				this.device_data = device_details;
				this.device_data.$key = snapshot.id;
			}, err => {
				console.log(err);
			});
	}

	/**
	 * It listens for changes to the membership document in the details collection of the account document
	 */
	async checkMembership() {
		this.db.collection(`licenses`).ref
			.where('account_key', '==', this.account)
			.onSnapshot(snapshots => {
				snapshots.forEach(element => {
					let license = element.data();
					license.$key = element.id;
					this.license = license;
					this.license.creation_date = this.license.creation_date.toDate();

					if (this.license.free_trial) {
						this.license.expiration_trial = this.license.creation_date.setDate(this.license.creation_date.getDate() + 7);
						this.license.expiration_trial = new Date(this.license.expiration_trial);
					}
				});
				this.isLicenseAvailable.next(true);
			}, err => {
				console.log(err);
			});
	}

	checkBranch() {
		this.db.collection(`accounts/${this.account}/branches`).ref
			.doc(this.branch)
			.get()
			.then(snapshot => {
				let branch_details = snapshot.data();
				this.branch_data = branch_details;
				this.branch_data.$key = snapshot.id;
			}, err => {
				console.log(err);
			});
	}

	/**
	 * It checks the account details of the user and stores it in the account_data variable
	 */
	async checkAccount() {

		this.db.collection(`accounts`).ref
			.doc(this.account)
			.onSnapshot(snapshots => {
				this.account_data = snapshots.data();

				if (this.account_data.dte_counter == undefined) {
					this.account_data.dte_counter = 0;
				}

				this.isAccountAvailable.subscribe((value) => {
					if (!value) {

						this.db.collection(`accounts/${this.account}/reports`).ref
							.where('branch_key', '==', this.branch)
							.where('name', '==', this.components.dateToString(new Date()))
							.onSnapshot(snapshots => {
								if (snapshots.empty) {
									this.report = {
										name: this.components.dateToString(new Date()),
										branch_key: this.branch,
										date: new Date(),
										total_taxes: 0,
										total_orders: 0,
										total_discounts: 0,
										total_sales: 0,
										total_tip: 0,
										total_credit: 0,
										sales_data: [],
										sales_heatmap: [],
										clients_data: [{
											name: 'Hombres',
											quantity: 0,
											key: 'mens',
											sales_heatmap: [],
										}, {
											name: 'Mujeres',
											quantity: 0,
											key: 'womens',
											sales_heatmap: [],
										}, {
											name: 'Niños',
											quantity: 0,
											key: 'childrens',
											sales_heatmap: [],
										}],
										types_data: [],
										employees_data: [],
										total_tables: 0,
										total_clients: 0,
										payment_methods: [],
										products: [],
										total_refunds: 0,
										deliveries_data: [],
										total_deliveries: 0,
										total_deliveries_orders: 0,
										deliveries_sales: [],
										deliveries_heatmap: [],
										tables_data: [],
										mens: 0,
										womens: 0,
										childrens: 0,
										giftcards: 0,
										total_giftcards: 0,
									};
								} else {
									snapshots.forEach(element => {
										this.report = element.data();
										this.report.$key = element.id;
									});
								}
							})

						this.db.collection(`accounts/${this.account}/users`).ref
							.where('user_key', '==', this.token)
							.get()
							.then(snapshots => {
								snapshots.forEach(element => {
									this.account_data.user = element.data();
									this.account_data.user.$key = element.id;
									this.getUserType();
								})
							})

						Preferences.get({ key: 'peripheral' }).then(peripheral => {
							this.ble.isConnected(peripheral.value).then(data => {
								this.peripheral = peripheral.value;
							}, err => {
								this.peripheral = false;
								Preferences.remove({ key: 'peripheral' });
							})
						})

						Preferences.get({ key: 'peripheral_device' }).then(peripheral_device => {
							this.ble.isConnected(peripheral_device.value).then(data => {
								this.peripheral_device = peripheral_device.value;
							}, err => {
								this.peripheral_device = false;
								Preferences.remove({ key: 'peripheral_device' });
							})
						})

						this.isAccountAvailable.next(true);
					}
				})
			}, err => {
				console.log(err);
			});

	}
	/**
	 * It returns a promise that resolves to the membership of the account
	 * @returns A promise that resolves to the membership of the account.
	 */
	async getMembership() {
		return new Promise((resolve, reject) => {
			this.db.collection(`licenses`).ref
				.where('account_key', '==', this.account)
				.onSnapshot(snapshots => {
					snapshots.forEach(element => {
						let membership = element.data().membership;
						resolve(membership);
					});
				}, err => {
					console.log(err);
				});
		})
	}

	/**
	 * It returns a promise that resolves to the data of the branch document in the database
	 * @returns The branch object
	 */
	async getBranch() {
		return new Promise((resolve, reject) => {
			this.db.collection(`accounts/${this.account}/branches`).ref
				.doc(this.branch)
				.onSnapshot(snapshots => {
					let branch = snapshots.data();
					return (branch);
				}, err => {
					console.log(err);
					reject(err)
				});
		})
	}

	/**
	 * It checks if the code entered by the user is valid and if it is, it returns the branch data
	 * @param code - The code that the user entered
	 * @returns A promise that resolves to a boolean value.
	 */
	checkCode(code) {
		return new Promise((resolve, reject) => {
			this.components.showLoader('Ingresando a Quanto POS...', 'dark').then(() => {
				let real_code = code.slice(0, 4);
				this.db.collection(`accounts/${this.account}/users`).ref
					.where('code', '==', real_code)
					.where('pos', '==', true)
					.where('branches', 'array-contains', this.branch)
					.get()
					.then(snapshots => {

						if (snapshots.empty) {
							reject(false);
						} else {
							snapshots.forEach(element => {
								this.account_data.user = element.data();
								this.account_data.user.$key = element.id;
								this.token = element.id;

								this.db.collection(`accounts/${this.account}/branches`).ref
									.doc(this.branch)
									.get()
									.then(snapshot => {
										let branch = snapshot.data();
										this.startCoutdown();
										this.getUserType();
										resolve(branch);
									}, err => {
										console.log(err);
									});
							});
						}
					}, err => {
						console.log(err);
						reject();
					});
			})
		});
	}

	/**
	 * It loops through the pos_roles array and compares the user's pos_roles to the pos_roles in the
	 * array. If they match, it sets the user's type to the type of the pos_roles in the array
	 */
	getUserType() {
		this.pos_roles.forEach(element => {
			let rol = element;

			if (JSON.stringify(rol.roles) == JSON.stringify(this.account_data.user.pos_roles)) {
				this.account_data.user.type = rol.type;
			}
		});
	}

	/**
	 * It starts a countdown timer that locks the user out of the app after 2 minutes of inactivity
	 */
	startCoutdown() {
		let countdown = this.account_data.timer;

		if (countdown) {
			this.timer = setInterval(() => {
				if (this.token) {
					if (countdown > 0) {
						countdown -= 1;
						if (countdown == 30) {
							this.showTimer();
						} else if (countdown == 0) {
							clearInterval(this.timer);
							this.lockUser();
						}
					}
				}
			}, 1000);
		}

		addEventListener('mousemove', e => {
			countdown = this.account_data.timer;
			if (this.countdownPresent) {
				this.modalController.dismiss();
			}
		});

		addEventListener('keydown', e => {
			countdown = this.account_data.timer;
			if (this.countdownPresent) {
				this.modalController.dismiss();
			}
		})
	}

	/**
	 * If the countdown modal is not already present, create a new modal, present it, and set the
	 * countdownPresent variable to true
	 */
	async showTimer() {
		if (!this.countdownPresent) {
			this.countdownPresent = true;
			const modal = await this.modalController.create({
				component: CountdownTimerComponent,
				cssClass: 'coutdown-modal',
				animated: false,
				backdropDismiss: false
			});
			await modal.present();
			await modal.onDidDismiss().then(() => (this.countdownPresent = false));
		}
	}

	/**
	 * The function removes the token from the storage and navigates to the code page
	 */
	async lockUser() {
		this.pos.clearOrder(undefined);

		/* Esto cierra cualquier ventana que esta abierta */
		const modals = Array.from(document.getElementsByTagName("ion-modal"));
		const popovers = Array.from(document.getElementsByTagName("ion-popover"));
		const alerts = Array.from(document.getElementsByTagName("ion-alert"));

		for (const modal of modals) await modal.dismiss();
		for (const popover of popovers) await popover.dismiss();
		for (const alert of alerts) await alert.dismiss();

		Preferences.remove({ key: 'token' }).then(data => {
			this.token = '';
			this.router.navigate(['/code'], { replaceUrl: true, clearHistory: true, } as NavigationExtras);
		})
	}

	/**
	 * It removes the token from storage, unsubscribes from the device, and sets the device to available
	 * @returns A promise
	 */
	logOut() {
		return new Promise((resolve, reject) => {
			Preferences.remove({ key: 'token' });
			Preferences.remove({ key: 'account' });
			Preferences.remove({ key: 'branch' });
			Preferences.remove({ key: 'device' });
			Preferences.remove({ key: 'device_name' });
			Preferences.remove({ key: 'device_code' });
			Preferences.remove({ key: 'peripheral' });
			Preferences.remove({ key: 'peripheral_device' });
			this.isAuthenticated.next(false);
			clearInterval(this.timer);

			let batch = this.db.firestore.batch();
			batch.update(this.db.firestore.collection(`devices`).doc(this.device), {
				available: true,
				device_id: '',
				activeUID: firebase.firestore.FieldValue.delete()

			});
			batch.update(this.db.firestore.collection(`accounts/${this.account}/devices`).doc(this.device), {
				available: true
			});
			batch.commit().then(data => {

				const user = this.angularAuth.auth.currentUser;

				if (user && user.isAnonymous) {
					user.delete()
						.then(() => {
							this.angularAuth.auth.signOut().then(() => {
								this.router.navigate(['/register'], { replaceUrl: true, clearHistory: true } as NavigationExtras);
								resolve(true);
							}, error => {
								console.log('Error al hacer logout:', error);
							})
						})
						.catch((error) => {
							console.log('Error al eliminar usuario anónimo:', error);
						});
				}

			}, err => {
				reject();
			});

		})
	}

	/**
	 * It removes the token from the storage
	 */
	cleanToken() {
		Preferences.remove({ key: 'token' });
	}

}
