import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';

@Component({
	selector: 'app-remote-payment',
	templateUrl: './remote-payment.component.html',
	styleUrls: ['./remote-payment.component.scss'],
})
export class RemotePaymentComponent implements OnInit {

	card_type: any = 'unknown';
	card_number: any;
	paymentForm: UntypedFormGroup;

	constructor(
		public formBuilder: UntypedFormBuilder,
		public modalController: ModalController,
		private nav: IonNav,
		public components: ComponentsService
	) {
		this.paymentForm = this.formBuilder.group({
			card_number: ['', [Validators.required]],
			name: ['', [Validators.required]],
			date: ['', [Validators.required]],
			cvv: ['', [Validators.required]],
			email: [''],
			client: ['']
		});
	}

	ngOnInit() { }

	getCreditCardType() {
		//first check for MasterCard
		if (/^5[1-5]/.test(this.card_number)) {
			this.card_type = "mastercard";
		}
		//then check for Visa
		else if (/^4/.test(this.card_number)) {
			this.card_type = "visa";
		}
		else if (/^3[47]/.test(this.card_number)) {
			// this.components.showToast('', 'Solo aceptamos tarjetas VISA y Mastercard');
			this.card_type = "amex";
		}
		else {
			// this.components.showToast('', 'Solo aceptamos tarjetas VISA y Mastercard');
			this.card_type = "unknown";
		}
	}

	makePayment() {
		this.nav.push(PaymentSuccessComponent, { level: 1 });
	}
}
