import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-giftcard',
	templateUrl: './giftcard.component.html',
	styleUrls: ['./giftcard.component.scss'],
})
export class GiftcardComponent implements OnInit {

	code: any;
	giftcard: any = false;

	constructor(
		public modalController: ModalController,
		public components: ComponentsService,
		public api: ApiService,
		public auth: AuthService
	) {

	}

	ngOnInit() {

	}

	redeemCode() {

		this.api.getRef(`accounts/${this.auth.account}/giftcards`).ref
			.where('code', '==', String(this.code))
			.get()
			.then(snapshots => {
				if (snapshots.empty) {
					this.components.showToast('El codigo ingresado no es valido.', 'error');
				} else {
					snapshots.forEach(element => {
						let today = new Date();

						if (today > element.data().expiration_date.toDate()) {
							this.components.showToast('Giftcard expirada.', 'error');
						} else {
							this.giftcard = element.data();
							this.giftcard.$key = element.id;
						}
					});
				}
			})
	}

	useGiftCard() {
		this.modalController.dismiss(this.giftcard);
	}

}
