import { Component, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
  selector: 'app-update-app',
  templateUrl: './update-app.component.html',
  styleUrls: ['./update-app.component.scss'],
})
export class UpdateAppComponent implements OnInit {
  device: any;
  constructor(
    public components: ComponentsService
  ) { }

  ngOnInit() {
    Device.getInfo().then(data => {
      this.device = data;
    })
  }
}
