import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PaymentNoteComponent } from './payment-note/payment-note.component';

@Component({
	selector: 'app-more-methods',
	templateUrl: './more-methods.component.html',
	styleUrls: ['./more-methods.component.scss'],
})
export class MoreMethodsComponent implements OnInit {

	total: any;
	tip: any;
	ticket: any = '';

	constructor(
		public navParams: NavParams,
		private nav: IonNav,
		public modalController: ModalController,
		public auth: AuthService
	) {
	}

	ngOnInit() { }

	openPaymentNote(method) {
		this.nav.push(PaymentNoteComponent, {
			level: 1,
			method: method
		});
	}
}
