import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { SeparateItemsComponent } from '../../payments/separate-items/separate-items.component';
import { TicketSelectionComponent } from '../../payments/ticket-selection/ticket-selection.component';
import { TransferTicketsComponent } from '../../tables/transfer-tickets/transfer-tickets.component';
import { AddDiscountComponent } from '../add-discount/add-discount.component';
import { AddNoteOrderComponent } from '../add-note-order/add-note-order.component';
import { CancelNotesComponent } from '../cancel-notes/cancel-notes.component';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-ticket-options',
	templateUrl: './ticket-options.component.html',
	styleUrls: ['./ticket-options.component.scss'],
})
export class TicketOptionsComponent implements OnInit {

	order: any;
	payments: any;
	params: any;

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		private nav: IonNav,
		public api: ApiService,
		public alertController: AlertController,
		public auth: AuthService,
		public pos: PosService,
		public router: Router,
		public components: ComponentsService
	) {
		this.params = this.navParams.data;

		if (Object.keys(this.params).length > 0) {
			this.order = this.params.order;
			this.payments = this.params.payments;
		}
	}

	ngOnInit() {

	}

	/**
	 * The function is called when the user clicks the "Add Discount" button. It pushes the
	 * AddDiscountComponent onto the navigation stack, and passes the order object to the
	 * AddDiscountComponent
	 */
	async goToDiscountPage() {
		this.nav.push(AddDiscountComponent, { order: this.order });
	}

	/* Pushing the AddNoteOrderComponent onto the navigation stack, and passing the order object to the
	AddNoteOrderComponent */
	openAddNote() {
		this.nav.push(AddNoteOrderComponent, { order: this.order });
	};

	/**
	 * It creates a modal that contains the TransferTicketsComponent, and passes the order to the
	 * component
	 */
	async goToChangeTablePage() {
		this.modalController.dismiss();
		const modal = await this.modalController.create({
			component: TransferTicketsComponent,
			cssClass: 'full-modal',
			canDismiss: true,
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				orders: [this.order]
			}
		});

		await modal.present();
	}

	/**
	 * If the order has payments, show an alert, otherwise, navigate to the cancel notes page
	 */
	async openCancelOrder() {
		if (this.payments.length > 0) {
			const alert = await this.alertController.create({
				header: 'Tienes pagos realizados',
				message: 'No puedes cancelar esta orden ya que tienes pagos realizados',
				buttons: [
					{
						text: 'Aceptar',
						role: 'role'
					}
				]
			});

			await alert.present();
		} else {
			this.nav.push(CancelNotesComponent, {
				level: 1,
				order: this.order,
				type: 'order'
			});

		}
	}

	/**
	 * It creates a modal that displays the ticket selection component, and when the modal is dismissed,
	 * it sets the ticket_key to the data returned from the modal
	 */
	async selectTicket() {
		const modal = await this.modalController.create({
			component: TicketSelectionComponent,
			cssClass: 'tickets-modal',
			canDismiss: true,
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				table: this.order.table
			}
		});
		await modal.present();

		modal.onDidDismiss().then(data => {
			if (data.data) {
				this.router.navigateByUrl(`/pos/${data.data}`).then(response => {
					console.log('aqui ?');

					this.pos.updateOrderTicket(data.data);
					this.modalController.dismiss();
				})
			}
		});


	}

	async OpenSelectItems() {
		let data = {
			order_key: this.params.order.$key,
			order: this.params.order,
			total: this.params.total,
		}

		const modal = await this.modalController.create({
			component: SeparateItemsComponent,
			cssClass: 'full-modal',
			showBackdrop: false,
			// animated: false,
			backdropDismiss: true,
			componentProps: data
		});

		await modal.present().then(() => {
		});

		await modal.onDidDismiss().then(data => {
			if (data.data) {
				this.pos.updateOrderTicket(data.data);
				this.modalController.dismiss();
				// this.router.navigateByUrl(`/pos/${data.data}`).then(response => {
				// 	// this.pos.updateOrderTicket(data.data)
				// 	// this.modalController.dismiss();
				// })
			}
		})
	}

}
