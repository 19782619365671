import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-split-amount',
	templateUrl: './split-amount.component.html',
	styleUrls: ['./split-amount.component.scss'],
})
export class SplitAmountComponent implements OnInit {

	number: any;
	wrong: any = false;
	table: any;
	ticket: any;
	total: any;
	tip: any;

	constructor(
		public router: Router,
		public auth: AuthService,
		public components: ComponentsService,
		public navParams: NavParams,
		public modalController: ModalController
	) {
		this.table = navParams.get('table');
		this.ticket = this.navParams.data.ticket;
		this.total = this.navParams.data.total;
		this.tip = this.navParams.data.tip;
	}

	ngOnInit() { }

	setNumber(number) {
		if (this.number == '0' || !this.number) {
			this.number = String(number);
		} else {
			this.number += number;
		}
	}

	removeNumber() {
		let number = this.number.substring(0, this.number.length - 1);
		if (number == '') {
			this.number = '0';
		} else {
			this.number = number;
		}
	}

	logOut() {
		this.auth.logOut();
	}

	goToTable() {
		if (this.number == '0') {
			this.components.dismissLoader();
			this.wrong = true;
			setTimeout(() => {
				this.wrong = false;
				this.number = '0';
			}, 500);
		} else {
			this.modalController.dismiss(this.number);
		}
	}

	itAmount() {

	}
}
