import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ColorPickerModule } from 'ngx-color-picker';

import { IonicModule } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Globalization } from '@ionic-native/globalization/ngx';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './services/language/languages.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { firebaseConfig } from './../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService } from './services/auth/auth.service';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { SortablejsModule } from 'ngx-sortablejs';
import { IonicGestureConfig } from '../app/utils/ionic-gesture-config.service';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

var config = {
    statusbarPadding: true
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        ReactiveFormsModule,
        ColorPickerModule,
        IonicModule.forRoot({
            backButtonText: '',
            mode: 'ios',
            backButtonIcon: 'arrow-back-outline',
            // animated: isPlatform('mobileweb'),
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        SortablejsModule.forRoot({
            animation: 400,
        })
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
            // provide: RouteReuseStrategy,
            // useClass: IonicRouteStrategy,
        },
        TranslateConfigService,
        Globalization,
        AuthService,
        BarcodeScanner,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: IonicGestureConfig
        },
        BluetoothSerial,
        BLE,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
