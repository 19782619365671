import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-return-order',
	templateUrl: './return-order.component.html',
	styleUrls: ['./return-order.component.scss'],
})
export class ReturnOrderComponent implements OnInit {

	order: any;
	note: any = '';
	message: any = '';
	return_stock: any = false;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService,
		public modalController: ModalController,
		public alertController: AlertController

	) {
		this.order = JSON.parse(JSON.stringify(navParams.data.order));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));
	}

	ngOnInit() {

	}

	async annulOrder() {
		const alert = await this.alertController.create({
			header: 'Anular orden',
			message: '¿Seguro que deseas anular esta orden?. Esta acción no se puede revertir.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Anular orden',
					role: 'destructive',
					id: 'confirm-button',
					handler: () => {
						this.components.showLoader('Anulando orden...').then(() => {
							this.order.note = this.note;
							this.order.message = this.message;
							this.api.annulOrder(this.order, this.return_stock).then(data => {
								this.components.dismissLoader();
								this.modalController.dismiss('returned');
							})
						});
					}
				}
			]
		});

		await alert.present();
	}

}
