import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-add-cancel-note',
	templateUrl: './add-cancel-note.component.html',
	styleUrls: ['./add-cancel-note.component.scss'],
})
export class AddCancelNoteComponent implements OnInit {

	item: any;
	quantity: any = 1;
	note: any = '';
	return_stock: any = false;
	other: any = false;
	message: any = '';

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService
	) {
		this.item = JSON.parse(JSON.stringify(navParams.data.pre_item));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));

		if (this.note == 'Otros') {
			this.note = '';
		}
	}

	ngOnInit() {
	}

	changeQuantity(type) {
		if (type == 'add') {
			if (this.quantity + 1 > this.item.quantity) {
				this.components.showToast('Limite de items para anular');
			} else {
				this.quantity++;
			}

		}
		else if (type == 'minus') {
			if (this.quantity - 1 < 1) {
				this.components.showToast('Para anular debes seleccionar al menos uno');
			} else {
				this.quantity--;
			}

		}
	};

	returnItem() {
		if (this.note == '') {
			this.components.showToast('Debes agregar una nota a tu debolución', 'danger');
		} else {
			let return_data = {
				note: this.note,
				quantity: this.quantity,
				return_stock: this.return_stock,
				message: this.message
			}
			this.api.returnItem(this.item, return_data).then(data => {
				this.modalController.dismiss();
			}, err => {
				console.log(err);
			})
		}
	}


}
