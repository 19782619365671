import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
  selector: 'app-add-delivery',
  templateUrl: './add-delivery.component.html',
  styleUrls: ['./add-delivery.component.scss'],
})
export class AddDeliveryComponent implements OnInit {

  deliveryForm: FormGroup;

  constructor(
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    public api: ApiService,
    public auth: AuthService,
    public components: ComponentsService
  ) {
    this.deliveryForm = this.formBuilder.group({
      name: [''],
      email: ['', [Validators.email]],
      phone: [''],
      commission: [''],
    });
  }

  ngOnInit() { }

  addDelivery(ev) {
    this.components.buttonLoad(ev);

    let delivery = this.deliveryForm.value;
    this.api.getRef(`accounts/${this.auth.account}/deliveries`).ref
      .where('email', '==', delivery.email)
      .get()
      .then((snapshots) => {
        if (snapshots.empty) {
          delivery.creation_date = new Date();
          delivery.commission = Number(delivery.commission);
          this.api.addDocument(`accounts/${this.auth.account}/deliveries`, delivery).then((data) => {
            this.modalController.dismiss(delivery);
            this.components.dismissButtonLoad(ev);
            this.components.showToast('Delivery agregado correctamente.');
          });
        } else {
          this.components.dismissButtonLoad(ev);
          this.components.showToast('El delivery ya se encuentra registrado en el sistema.', 'error');
        }
      });
  }
}
