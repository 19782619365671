import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { BlankPage } from 'src/app/blank/blank.page';

@Component({
	selector: 'app-modal-base',
	templateUrl: './modal-base.component.html',
	styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent implements OnInit {
	rootPage: any = BlankPage;
	params: any;

	constructor() { }

	ngOnInit() {
	}
}