import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import CryptoJS from 'crypto-js';
import * as firebase from 'firebase';
import 'firebase/storage';
import { isEqual } from 'lodash';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ComponentsService } from '../components/components.service';
import { PosService } from '../pos/pos.service';
import { PrintService } from '../print/print.service';
import { DteService } from '../dte/dte.service';

@Injectable({
	providedIn: 'root'
})

export class ApiService {

	constructor(
		public db: AngularFirestore,
		private http: HttpClient,
		public auth: AuthService,
		public components: ComponentsService,
		public router: Router,
		public pos: PosService,
		public print: PrintService,
		public platform: Platform,
		public dte: DteService
	) {
	}

	getAllDocuments(collection: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db.collection(collection)
				.get()
				.toPromise()
				.then((querySnapshot) => {
					let arr = [];
					querySnapshot.forEach(function (doc) {
						var obj = JSON.parse(JSON.stringify(doc.data()));
						obj.$key = doc.id
						arr.push(obj);
					});

					if (arr.length > 0) {
						resolve(arr);
					} else {
						resolve(null);
					}


				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	getDocument(collection, documentId) {
		return new Promise((resolve, reject) => {
			this.db.collection(collection).doc(documentId)
				.get()
				.toPromise()
				.then((snapshot) => {
					let doc = snapshot.data();
					doc.$key = snapshot.id;
					resolve(doc);
					/**
					 * It deletes a document from a collection
					 * @param {string} collectionName - The name of the collection you want to delete the document from.
					 * @param {string} docID - The ID of the document you want to delete.
					 * @returns A promise that resolves to the object that was deleted.
					 */
				}).catch((error: any) => {
					reject(error);
				});
		})
	}

	deleteDocument(collectionName: string, docID: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db
				.collection(collectionName)
				.doc(docID)
				.delete()
				.then((obj: any) => {
					resolve(obj);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	addDocument(collectionName: string, dataObj: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db.collection(collectionName).add(dataObj)
				.then((obj: any) => {
					resolve(obj);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	updateDocument(collectionName: string, docID: string, dataObj: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.db
				.collection(collectionName)
				.doc(docID)
				.update(dataObj)
				.then((obj: any) => {
					resolve(obj);
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	setDocument(collectionName: string, docID: string, dataObj: any) {
		return new Promise((resolve, reject) => {
			this.db
				.collection(collectionName)
				.doc(docID)
				.set(dataObj)
				.then((obj: any) => {
					resolve(obj)
				})
				.catch((error: any) => {
					reject(error);
				})
		})
	}

	getRef(collection) {
		return this.db.collection(collection);
	}

	uploadToCloudinary(file) {
		return new Promise((resolve, reject) => {

			const timestamp = Math.round((new Date).getTime() / 1000);

			let public_id = this.makeid(20);

			const formData = new FormData();
			formData.append("file", file);
			formData.append("api_key", environment.cloudinary.api_key);
			formData.append("public_id", public_id);
			formData.append("return_delete_token", "1");
			formData.append("timestamp", String(timestamp));
			var signature = CryptoJS.SHA1(`public_id=${public_id}&return_delete_token=1&timestamp=${String(timestamp)}${environment.cloudinary.api_secret}`);
			formData.append("signature", String(signature));
			this.http.post(environment.cloudinary.upload.url, formData)
				.subscribe(response => {
					resolve(response);
				}, error => {
					reject(error);
				});

		})
	}

	deleteToCloudinary(image) {
		return new Promise((resolve, reject) => {
			const timestamp = Math.round((new Date).getTime() / 1000);

			const formData = new FormData();
			formData.append("api_key", environment.cloudinary.api_key);
			formData.append("public_id", image.public_id);
			formData.append("timestamp", String(timestamp));
			var signature = CryptoJS.SHA1(`public_id=${image.public_id}&timestamp=${String(timestamp)}${environment.cloudinary.api_secret}`);
			formData.append("signature", String(signature));
			this.http.post(environment.cloudinary.delete.url, formData)
				.subscribe(response => {
					resolve(response);
				}, error => {
					reject(error);
				});

		})
	}

	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() *
				charactersLength));
		}
		return result;
	}

	addToFavorites(items, is_category) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();
			this.getRef(`accounts/${this.auth.account}/favorites`).ref
				.get()
				.then(snapshots => {
					let count = snapshots.size;

					items.forEach(element => {

						count++;
						let item_key = this.db.createId();
						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/favorites`).doc(item_key), {
							branch_key: this.auth.branch,
							item_key: element.$key,
							creation_date: new Date,
							order: count,
							is_category: is_category
						});

					});

					batch.commit().then(data => {
						resolve(true);
					}, err => {
						reject();
					})

				})
		});
	}

	updateFavoritesOrder(items) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();
			let order = 0;
			items.forEach(element => {
				order++;
				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/favorites`).doc(element.favorite_key), {
					order: order,
				});
			});

			batch.commit().then(data => {
				resolve(true);
			}, err => {
				reject();
			})

		})
	}

	addOrder() {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			this.getRef(`accounts/${this.auth.account}/branches`).ref
				.doc(this.auth.branch)
				.get()
				.then(branch_snapshot => {
					let order_number = 1;

					if (branch_snapshot.data().current_order_number) {
						order_number = branch_snapshot.data().current_order_number + 1;
					}

					batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
						current_order_number: order_number
					});

					let order_key = this.db.createId();

					let order = {
						order_number: order_number,
						type: this.pos.type,
						notes: this.pos.note,
						user_key: this.auth.token,
						branch_key: this.auth.branch,
						branch: this.auth.branch_data,
						creation_date: new Date(),
						device_name: this.auth.device_name,
						device_key: this.auth.device,
					}

					if (this.pos.type == 'tables') {
						order['number_persons'] = this.pos.mens + this.pos.womens + this.pos.childrens;
						order['mens'] = this.pos.mens;
						order['womens'] = this.pos.womens;
						order['childrens'] = this.pos.childrens;
						order['table'] = this.pos.table;
						order['table_area'] = this.pos.table_area;
					}

					if (this.pos.client) {
						order['client'] = this.pos.client;
						this.pos.client = false;
					}

					if (this.pos.delivery) {
						order['delivery'] = this.pos.delivery;
						this.pos.delivery = false;
					}

					if (this.pos.splitted) {
						order['splitted'] = this.pos.splitted;
						order['splitted_payments'] = this.pos.splitted_payments + 1;
					}

					if (this.auth.account_data.fiscal) {
						if (this.auth.account_data.fiscal.type == 'dte') {
							order['tax_receipt'] = { type: 'invoice' };
						} else {
							order['tax_receipt'] = this.pos.tax_receipt;
						}
					}

					order['user'] = this.auth.account_data.user;

					batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(order_key), order);

					let counter = 0;
					let kds_items = [];
					let items = [];

					this.pos.discounts.forEach(discount => {
						delete discount.$key;
						discount.order_key = order_key;
						discount.creation_date = new Date()
						discount.user_key = this.auth.token;
						discount.branch_key = this.auth.branch;
						discount.user = this.auth.account_data.user;
						let discount_key = this.db.createId();

						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_discounts`).doc(discount_key), discount);
					});

					this.pos.discounts = [];

					this.pos.items.forEach(item => {
						let item_key = this.db.createId();
						item.order_key = order_key;
						item.ticket_key = false;

						delete item.creation_date;

						if (!item.is_promotion) {
							item.is_promotion = false;
						}

						if (item.have_promotion) {
							this.usagePromotion(item);
						}

						item.user = {
							user_key: this.auth.account_data.user.$key,
							name: this.auth.account_data.user.name,
							lastname: this.auth.account_data.user.lastname
						};

						item.ticket = {
							type: this.pos.type
						};

						if (this.pos.type == 'tables') {
							item.ticket.mens = this.pos.mens;
							item.ticket.womens = this.pos.womens;
							item.ticket.childrens = this.pos.childrens;
							item.ticket.table = this.pos.table;
							item.ticket.table_area = this.pos.table_area;
						}

						if (item.quantity) {
							item.quantity = Number(item.quantity);
						}

						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item_key), item);

						if (!item.is_promotion) {
							let item_element = JSON.parse(JSON.stringify(item));

							let kds_devices = [];

							this.auth.kds_devices.forEach(device => {
								if (!item.miscellaneous) {
									if (item.is_variant) {
										const found = device.items.findIndex(_element => _element == item.parent.$key);
										if (found > -1) {
											kds_devices.push(device);
										}
									} else {
										const found = device.items.findIndex(_element => _element == item.item_key);
										if (found > -1) {
											kds_devices.push(device);
										}
									}
								} else {
									item.kds_devices.forEach(element => {
										if (device.$key == element.kds_key) {
											kds_devices.push(device);
										}
									});
								}
							});

							kds_devices.forEach(kds_element => {
								const found = kds_items.findIndex(_element => _element.kds_key == kds_element.$key);

								if (found < 0) {
									kds_items.push({
										kds_key: kds_element.$key,
										account_key: this.auth.account,
										branch_key: this.auth.branch,
										order_key: order_key,
										order: order,
										creation_date: new Date(),
										type: 'order',
										items: [{
											item_key: item_key,
											quantity: item.quantity,
											kds_type: item.kds_type,
											item: item
										}]
									})
								} else {
									kds_items[found].items.push({
										item_key: item_key,
										quantity: item.quantity,
										kds_type: item.kds_type,
										item: item
									})
								}
							});
							items.push(item_element);
							counter++;
						} else {
							let prom_counter = 0;

							let promo_items = item.x_items.concat(item.y_items);

							promo_items.forEach(prom_item => {
								let item_element = JSON.parse(JSON.stringify(prom_item));
								const found = items.findIndex(element => element.item_key == item_element.item_key);
								if (found < 0) {
									items.push(item_element);
								} else {
									items[found].quantity += item_element.quantity;
								}

								let kds_devices = [];

								this.auth.kds_devices.forEach(device => {
									if (item.is_variant) {
										const found = device.items.findIndex(_element => _element == prom_item.parent.$key);
										if (found > -1) {
											kds_devices.push(device);
										}
									} else {
										const found = device.items.findIndex(_element => _element == prom_item.item_key);
										if (found > -1) {
											kds_devices.push(device);
										}
									}
								});

								kds_devices.forEach(kds_element => {
									if (kds_element.branch_key == this.auth.branch) {
										const found = kds_items.findIndex(_element => _element.kds_key == kds_element.$key);

										if (found < 0) {
											kds_items.push({
												kds_key: kds_element.$key,
												account_key: this.auth.account,
												branch_key: this.auth.branch,
												order_key: order_key,
												order: order,
												creation_date: firebase.firestore.FieldValue.serverTimestamp(),
												type: 'order',
												items: [{
													item_key: item_key,
													quantity: prom_item.quantity,
													kds_type: prom_item.kds_type ? prom_item.kds_type : '',
													item: prom_item
												}]
											})
										} else {
											kds_items[found].items.push({
												item_key: item_key,
												quantity: prom_item.quantity,
												kds_type: prom_item.kds_type ? prom_item.kds_type : '',
												item: prom_item
											})
										}

									}
								});

								prom_counter++;

								if (prom_counter == promo_items.length) {
									counter++;
								}
							});

							this.usagePromotion(item);
						}

						if (counter == this.pos.items.length) {
							counter = 0;

							this.print.printItems(order, items);

							kds_items.forEach(element => {
								element.user = this.auth.account_data.user;
								element.status = 'preparing';
								let kds_item_key = this.db.createId();
								batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/kds_items`).doc(kds_item_key), element);
							});

							let inventory_items = [];

							items.forEach(filter_item => {
								const found = inventory_items.findIndex(_element => _element.item_key == filter_item.item_key);
								if (found > -1) {
									inventory_items[found].quantity += filter_item.quantity;
								} else {
									inventory_items.push(filter_item);
								}
							})

							inventory_items.forEach(filter_item => {
								if (filter_item.miscellaneous) {
									counter++;
									if (inventory_items.length == counter) {
										batch.commit().then(() => {
											let response_order = order;
											response_order['$key'] = order_key;
											resolve(response_order);
										}, err => {
											reject();
										});
									}

								} else {

									if (filter_item.inventory_management) {
										this.getRef(`accounts/${this.auth.account}/items_branches`).ref
											.where('item_key', '==', filter_item.item_key)
											.where('branch_key', '==', this.auth.branch)
											.get()
											.then(snapshots => {
												snapshots.forEach(element => {
													let item_quantity = element.data().quantity;
													item_quantity = item_quantity - filter_item.quantity;

													batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches/`).doc(element.id), {
														quantity: item_quantity
													});

													let inventory_record_key = this.db.createId();

													batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
														branch_key: this.auth.branch,
														branch: this.auth.branch_data,
														creation_date: firebase.firestore.FieldValue.serverTimestamp(),
														item_key: filter_item.item_key,
														item: filter_item,
														new_quantity: item_quantity,
														old_quantity: element.data().quantity,
														reason: 'sales',
														user: this.auth.account_data.user
													});

													counter++;

													if (inventory_items.length == counter) {
														batch.commit().then(data => {
															let response_order = order;
															response_order['$key'] = order_key;
															resolve(response_order);
														}, err => {
															reject();
														});
													}
												});
											});
									} else {
										counter++;

										if (inventory_items.length == counter) {
											batch.commit().then(data => {
												let response_order = order;
												response_order['$key'] = order_key;
												resolve(response_order);
											}, err => {
												reject();
											});
										}
									}

								}
							});
						}
					});
				})
		})
	}

	addItemsToOrder(order) {

		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			let counter = 0;
			let kds_items = [];
			let items = [];

			this.pos.discounts.forEach(discount => {
				delete discount.$key;
				discount.order_key = order.$key;
				discount.creation_date = new Date();
				discount.user_key = this.auth.token;
				discount.branch_key = this.auth.branch;
				discount.user = this.auth.account_data.user;
				let discount_key = this.db.createId();
				batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_discounts`).doc(discount_key), discount);
			});

			this.pos.discounts = [];

			if (this.pos.items.length == 0) {
				batch.commit().then(() => {
					resolve(true);
				}, err => {
					console.log(err);
					reject();
				});
			}

			let user_data = this.auth.account_data.user;

			this.pos.items.forEach(item => {
				const found = order.items.findIndex(element => element.item_key == item.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers));
				item.order_key = order.$key;
				item.creation_date = new Date();
				item.ticket_key = false;

				item.user = {
					user_key: this.auth.account_data.user.$key,
					name: this.auth.account_data.user.name,
					lastname: this.auth.account_data.user.lastname
				};

				item.ticket = {
					type: order.type
				};

				if (order.type == 'tables') {
					item.ticket.mens = order.mens;
					item.ticket.womens = order.womens;
					item.ticket.childrens = order.childrens;
					item.ticket.table = order.table;
					item.ticket.table_area = order.table_area;
				}


				let item_key;

				let kds_devices = [];

				if (item.have_promotion) {
					this.usagePromotion(item)
				}

				if (item.quantity) {
					item.quantity = Number(item.quantity);
				}

				if (!item.is_promotion) {

					if (found < 0) {
						let ticket_item_key = this.db.createId();
						item_key = ticket_item_key;
						item.is_promotion = false;
						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(ticket_item_key), item);
					} else {
						item_key = order.items[found].$key;
						order.items[found].quantity += item.quantity;
						batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(order.items[found].$key), {
							quantity: order.items[found].quantity
						});
					}

					let item_element = JSON.parse(JSON.stringify(item));

					this.auth.kds_devices.forEach(device => {
						if (!item.miscellaneous) {
							if (item.is_variant) {
								const found = device.items.findIndex(_element => _element == item.parent.$key);
								if (found > -1) {
									kds_devices.push(device);
								}
							} else {
								const found = device.items.findIndex(_element => _element == item.item_key);
								if (found > -1) {
									kds_devices.push(device);
								}
							}
						} else {
							item.kds_devices.forEach(element => {
								if (device.$key == element.kds_key) {
									kds_devices.push(device);
								}
							});
						}
					});

					kds_devices.forEach(kds_element => {
						const found = kds_items.findIndex(_element => _element.kds_key == kds_element.$key);

						if (found < 0) {

							kds_items.push({
								kds_key: kds_element.$key,
								account_key: this.auth.account,
								branch_key: this.auth.branch,
								order_key: order.$key,
								order: order,
								creation_date: firebase.firestore.FieldValue.serverTimestamp(),
								type: 'order',
								items: [{
									item_key: item_key,
									quantity: item.quantity,
									kds_type: item.kds_type,
									item: item
								}]
							})
						} else {
							kds_items[found].items.push({
								item_key: item_key,
								quantity: item.quantity,
								kds_type: item.kds_type,
								item: item
							})
						}
					});

					items.push(item_element);
					counter++;
				} else {
					let ticket_item_key = this.db.createId();
					let prom_counter = 0;

					if (item.quantity) {
						item.quantity = Number(item.quantity);
					}

					batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(ticket_item_key), item);

					let promo_items = item.x_items.concat(item.y_items);

					promo_items.forEach(prom_item => {
						let item_element = JSON.parse(JSON.stringify(prom_item));
						const found = items.findIndex(element => element.item_key == item_element.item_key);
						if (found < 0) {
							items.push(item_element);
						} else {
							items[found].quantity += item_element.quantity;
						}

						let kds_devices = [];

						this.auth.kds_devices.forEach(device => {
							if (item.is_variant) {
								const found = device.items.findIndex(_element => _element == prom_item.parent.$key);
								if (found > -1) {
									kds_devices.push(device);
								}
							} else {
								const found = device.items.findIndex(_element => _element == prom_item.item_key);
								if (found > -1) {
									kds_devices.push(device);
								}
							}
						});

						kds_devices.forEach(kds_element => {
							if (kds_element.branch_key == this.auth.branch) {
								const found = kds_items.findIndex(_element => _element.kds_key == kds_element.$key);

								if (found < 0) {
									kds_items.push({
										kds_key: kds_element.$key,
										account_key: this.auth.account,
										branch_key: this.auth.branch,
										order_key: order.$key,
										order: order,
										creation_date: firebase.firestore.FieldValue.serverTimestamp(),
										type: 'order',
										items: [{
											item_key: prom_item.item_key,
											quantity: prom_item.quantity,
											kds_type: prom_item.kds_type ? prom_item.kds_type : '',
											item: prom_item
										}]
									})
								} else {
									kds_items[found].items.push({
										item_key: prom_item.item_key,
										quantity: prom_item.quantity,
										kds_type: prom_item.kds_type ? prom_item.kds_type : '',
										item: prom_item
									})
								}

							}
						});

						prom_counter++;

						if (prom_counter == promo_items.length) {
							counter++;
						}
					});

					this.usagePromotion(item);
				}


				if (counter == this.pos.items.length) {
					counter = 0;
					this.print.printItems(order, this.pos.items);

					kds_items.forEach(element => {
						element.user = user_data;
						element.status = 'preparing';
						let kds_item_key = this.db.createId();
						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/kds_items`).doc(kds_item_key), element);
					});

					let inventory_items = [];

					items.forEach(filter_item => {
						const found = inventory_items.findIndex(_element => _element.item_key == filter_item.item_key);
						if (found > -1) {
							inventory_items[found].quantity += filter_item.quantity;
						} else {
							inventory_items.push(filter_item);
						}
					})

					inventory_items.forEach(filter_item => {
						if (filter_item.miscellaneous) {
							counter++;
							if (this.pos.items.length == counter) {
								let backup_items = this.pos.items;
								this.pos.items = [];
								batch.commit().then(data => {
									resolve(true);
								}, err => {
									console.log(err);
									this.pos.items = backup_items;
									reject();
								});
							}

						} else {
							if (filter_item.inventory_management) {
								this.getRef(`accounts/${this.auth.account}/items_branches`).ref
									.where('item_key', '==', filter_item.item_key)
									.where('branch_key', '==', this.auth.branch)
									.get()
									.then(snapshots => {

										snapshots.forEach(element => {
											let item_quantity = element.data().quantity;
											item_quantity = item_quantity - filter_item.quantity;
											counter++;

											batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches/`).doc(element.id), {
												quantity: item_quantity
											});

											let inventory_record_key = this.db.createId();

											batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
												branch_key: this.auth.branch,
												branch: this.auth.branch_data,
												creation_date: new Date(),
												item_key: filter_item.item_key,
												item: filter_item,
												new_quantity: item_quantity,
												old_quantity: element.data().quantity,
												reason: 'sales',
												user: this.auth.account_data.user
											});

											if (inventory_items.length == counter) {
												let backup_items = this.pos.items;
												this.pos.items = [];
												batch.commit().then(data => {
													resolve(true);
												}, err => {
													console.log(err);
													this.pos.items = backup_items;
													reject();
												});
											}
										});
									}, err => {
										console.log(err);
									});
							} else {

								counter++;

								if (inventory_items.length == counter) {

									let backup_items = this.pos.items;
									this.pos.items = [];
									batch.commit().then(data => {
										resolve(true);
									}, err => {
										console.log(err);
										this.pos.items = backup_items;
										reject();
									});
								}
							}
						}
					});

				}

			});

		})
	}

	addDiscountsToOrder(order) {
		return new Promise((resolve, reject) => {

			let batch = this.db.firestore.batch();

			this.pos.discounts.forEach(discount => {
				delete discount.$key;
				discount.order_key = order.$key;
				discount.creation_date = new Date();
				discount.user_key = this.auth.token;
				discount.branch_key = this.auth.branch;
				let discount_key = this.db.createId();

				batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_discounts`).doc(discount_key), discount);
			});

			this.pos.discounts = [];
			batch.commit().then(() => {
				resolve(true);
			}, err => {
				reject();
			});
		})
	}

	usagePromotion(item, annul = false) {
		let batch = this.db.firestore.batch();
		if (item.promotion.usage_limits) {

			this.getDocument(`accounts/${this.auth.account}/promotions`, item.promotion.$key).then(data => {
				let promotion = JSON.parse(JSON.stringify(data));
				let usage = 0;
				if (annul) {
					usage = promotion.usage - item.quantity;
				} else {
					usage = promotion.usage + item.quantity;
				}
				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/promotions/`).doc(promotion.$key), {
					usage: usage
				});

				batch.commit();
			})
		}
	}

	sendEmail(ticket_key, email: any = false) {
		return new Promise((resolve, reject) => {
			let postData = {
				id: ticket_key
			}

			if (email) {
				postData['email'] = email;
			}

			this.http.post(`${environment.emails_url}/ticket`, postData).subscribe(response => {
				resolve(response);
			}, err => {
				reject(err);
			});
		})
	}

	createUser(user) {

		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			let user_key = this.db.createId();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/users`).doc(user_key), {
				email: user.email,
				creation_date: new Date(),
				name: user.name,
				lastname: user.lastname,
			});

			let account_user_key = this.db.createId();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/users`).doc(account_user_key), {
				roles: user.roles,
				creation_date: new Date(),
				user_key: user_key,
				autogenerated: true
			});

			batch.commit().then(data => {
				resolve(true);
			}, err => {
				reject();
			});


		});

	}

	returnItem(item, data) {
		return new Promise((resolve, reject) => {

			let batch = this.db.firestore.batch();
			let items = [];

			if (item.quantity) {
				item.quantity = Number(item.quantity);
				data.quantity = Number(data.quantity);
			}

			if (item.miscellaneous) {
				if (item.quantity - data.quantity > 0) {
					batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
						quantity: item.quantity - data.quantity
					});
				} else {
					batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key));
				}

				let return_key = this.db.createId();

				batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), {
					creation_date: new Date(),
					autogenerated: true,
					ticket_key: item.ticket_key,
					user_key: this.auth.token,
					item: item,
					quantity: data.quantity,
					note: data.note,
					message: data.message,
					type: 'miscellaneous'
				});

				batch.commit().then(data => {
					resolve(true);
				}, err => {
					reject();
				});
			} else if (!item.is_promotion) {
				if (data.return_stock && item.inventory_management) {

					this.getRef(`accounts/${this.auth.account}/items_branches`).ref
						.doc(item.item_branch.$key)
						.get()
						.then(snapshot => {
							let item_branch = snapshot.data();
							item_branch.$key = snapshot.id;
							let new_quantity = item_branch.quantity + data.quantity;

							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(item_branch.$key), {
								quantity: new_quantity
							});

							if (item.quantity - data.quantity > 0) {
								batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
									quantity: item.quantity - data.quantity
								});
							} else {
								batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key));
							}

							let inventory_record_key = this.db.createId();

							batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
								branch_key: this.auth.branch,
								branch: this.auth.branch_data,
								creation_date: new Date(),
								item_key: item.$key,
								item: item,
								new_quantity: new_quantity,
								old_quantity: item_branch.quantity,
								reason: 'return_from_ticket',
								user: this.auth.account_data.user
							});

							let return_key = this.db.createId();

							batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), {
								creation_date: new Date(),
								branch_key: this.auth.branch,
								branch: this.auth.branch_data,
								order_key: item.order_key,
								item_key: item.$key,
								user_key: this.auth.token,
								item: item,
								message: data.message,
								quantity: data.quantity,
								note: data.note,
								type: 'item'
							});

							batch.commit().then(data => {
								resolve(true);
							}, err => {
								console.log(err);
								reject();
							});

						})

				} else {
					if (item.quantity - data.quantity > 0) {
						batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
							quantity: item.quantity - data.quantity
						});
					} else {
						batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key));
					}

					let return_key = this.db.createId();

					batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), {
						creation_date: new Date(),
						autogenerated: true,
						order_key: item.order_key,
						item_key: item.item_key,
						user_key: this.auth.token,
						item: item,
						quantity: data.quantity,
						note: data.note,
						type: 'item',
						message: data.message,
					});

					batch.commit().then(data => {
						resolve(true);
					}, err => {
						reject();
					});
				}
			} else if (item.is_promotion) {
				if (data.return_stock) {
					let prom_counter = 0;
					batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key));

					item.x_items.forEach(x_item => {
						let item_element = JSON.parse(JSON.stringify(x_item));
						const found = items.findIndex(element => element.item_key == item_element.item_key);

						if (found < 0) {
							items.push(item_element);
						} else {
							items[found].quantity += item_element.quantity;
						}
						prom_counter++;

						if (prom_counter == item.x_items.length) {
							prom_counter = 0;

							item.y_items.forEach(y_item => {
								let item_element = JSON.parse(JSON.stringify(y_item));
								const found = items.findIndex(element => element.item_key == item_element.item_key);
								if (found < 0) {
									items.push(item_element);
								} else {
									items[found].quantity += item_element.quantity;
								}
								prom_counter++;

								if (prom_counter == item.y_items.length) {
									let counter = 0;
									items.forEach(item_element => {
										if (item_element.inventory_management) {
											this.getRef(`accounts/${this.auth.account}/items_branches`).ref
												.where('item_key', '==', item_element.item_key)
												.where('branch_key', '==', item_element.item_branch.branch_key)
												.get()
												.then(snapshots => {
													counter++;
													snapshots.forEach(element => {
														batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(element.id), {
															quantity: element.data().quantity + item_element.quantity
														});

														let inventory_record_key = this.db.createId();

														batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
															branch_key: this.auth.branch,
															branch: this.auth.branch_data,
															creation_date: new Date(),
															item_key: item_element.item_key,
															item: item,
															new_quantity: element.data().quantity + item_element.quantity,
															old_quantity: element.data().quantity,
															reason: 'return_from_promotion',
															user: this.auth.account_data.user
														});
													});
													if (counter == items.length) {
														let return_key = this.db.createId();

														batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), {
															creation_date: new Date(),
															branch_key: this.auth.branch,
															branch: this.auth.branch_data,
															order_key: item.order_key,
															item_key: item.$key,
															user_key: this.auth.token,
															item: item,
															quantity: data.quantity,
															note: data.note,
															message: data.message,
															type: 'promotion'
														});

														batch.commit().then(data => {
															resolve(true);
														}, err => {
															console.log(err);
															reject();
														});
													}
												})
										} else {
											counter++;

											let return_key = this.db.createId();

											batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), {
												creation_date: new Date(),
												branch_key: this.auth.branch,
												branch: this.auth.branch_data,
												order_key: item.order_key,
												item_key: item.$key,
												user_key: this.auth.token,
												item: item,
												quantity: data.quantity,
												note: data.note,
												message: data.message,
												type: 'promotion'
											});

											batch.commit().then(data => {
												resolve(true);
											}, err => {
												console.log(err);
												reject();
											});
										}
									});
								}
							});
						}
					});
				} else {
					batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key));

					let return_key = this.db.createId();

					batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), {
						creation_date: new Date(),
						branch_key: this.auth.branch,
						branch: this.auth.branch_data,
						order_key: item.order_key,
						item_key: item.$key,
						user_key: this.auth.token,
						item: item,
						quantity: data.quantity,
						note: data.note,
						type: 'promotion'
					});

					batch.commit().then(data => {
						resolve(true);
					}, err => {
						reject();
					});
				}
				this.usagePromotion(item, true);
			}
		})
	}

	addClosingX() {
		let todayDate = new Date();
		let batch = this.db.firestore.batch();

		return new Promise((resolve, reject) => {
			this.getRef(`accounts/${this.auth.account}/closings`).ref
				.where('branch_key', '==', this.auth.branch)
				.orderBy('creation_date')
				.limitToLast(1)
				.get()
				.then(closingSnapshot => {

					if (closingSnapshot.empty) {
						// Si aun no tiene un corte lo hace desde el dia que abrio la cuenta
						this.getRef('accounts').ref
							.doc(this.auth.account)
							.get()
							.then(account_snapshot => {
								let account = account_snapshot.data();
								account.creation_date = account.creation_date.toDate();

								this.completeClosingX(resolve, reject, batch, account.creation_date, todayDate, closingSnapshot.empty);
							}, err => {
								console.log(err);
								reject();
							});
					} else {
						closingSnapshot.forEach(element => {
							let date = element.data().to.toDate();
							this.completeClosingX(resolve, reject, batch, date, todayDate, closingSnapshot.empty);
						});
					}

				}, err => {
					console.log(err);
					reject();
				})

		});
	}

	completeClosingX(resolve, reject, batch, start_date, end_date, empty) {
		let closing = {
			creation_date: end_date,
			from: start_date,
			to: end_date,
			branch_key: this.auth.branch,
			user_key: this.auth.token,
			branch: this.auth.branch_data,
			user: this.auth.account_data.user,
			type: 'x',
			total: 0,
			sales: 0,
			invoices_sales: 0,
			ccfs_sales: 0,
			tickets_sales: 0,
			tip: 0,
			tickets_tip: 0,
			ccfs_tip: 0,
			invoices_tip: 0,
			total_card: 0,
			total_cash: 0,
			total_link: 0,
			total_check: 0,
			total_transfer: 0,
			total_other: 0,
			total_giftcard: 0,
			giftcards: 0,
			total_tickets: 0,
			total_invoices: 0,
			total_ccfs: 0,
			tickets_refunds: 0,
			ccfs_refunds: 0,
			invoices_refunds: 0,
			refunds: 0,
			cancellations: [],
			discounts: 0,
			exempt: 0,
			invoices_exempt: 0,
			ccfs_exempt: 0,
			tickets_exempt: 0,
			credit: 0,
			deposits: 0,
			gateways: [],
			tickets: [],
			invoices: [],
			ccfs: []
		}

		if (this.auth.account_data.fiscal.type == 'dte') {
			closing['is_dte'] = true;
		}



		this.getRef(`accounts/${this.auth.account}/tickets`).ref
			.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
			.where('branch_key', '==', this.auth.branch)
			.where('completed_date', '>=', start_date)
			.where('completed_date', '<=', end_date)
			.orderBy('completed_date')
			.get()
			.then(ticket_snapshots => {

				if (ticket_snapshots.empty) {
					this.components.showAlert('No puedes realizar corte x', 'No se han encontrado ventas para poder generar un cierre X, revisa tus cuentas abiertas o genera una venta para continuar.', [
						{
							text: 'Continuar',
							role: 'cancel'
						},
					]);
					batch.commit().then(data => {
						resolve(true);
					}, err => {
						console.log(err);
						reject();
					});
				} else {

					ticket_snapshots.forEach(element => {
						let ticket = element.data();

						if (ticket.exempt > 0 && ticket.exempt !== undefined) {
							closing.exempt += Number(ticket.subtotal);
						} else {
							closing.sales += Number(ticket.subtotal + ticket.hidden_taxes + ticket.taxes);
						}

						closing.total += Number(ticket.total);
						closing.tip += Number(ticket.tip);

						if (ticket.was_credit) {
							closing.credit += ticket.total;
						}

						if (ticket.fiscal) {
							if (ticket.fiscal_type == 'dte') {
								if (ticket.fiscal.type == 'invoice') {
									closing['invoices'].push(ticket.fiscal.dte.json.identificacion.numeroControl);

									if (ticket.exempt > 0 && ticket.exempt !== undefined) {
										closing.invoices_exempt += ticket.subtotal;
									} else {
										closing.invoices_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
									}
									closing.invoices_tip += ticket.tip;
								} else if (ticket.fiscal.type == 'ccf') {
									closing['ccfs'].push(ticket.fiscal.dte.json.identificacion.numeroControl);

									if (ticket.exempt > 0 && ticket.exempt !== undefined) {
										closing.ccfs_exempt += ticket.subtotal;
									} else {
										closing.ccfs_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
									}
									closing.ccfs_tip += ticket.tip;
								}
							} else {
								if (ticket.fiscal.type == 'ticket') {
									closing['tickets'].push(ticket.fiscal.ticket.current_ticket_number);

									if (ticket.exempt > 0 && ticket.exempt !== undefined) {
										closing.tickets_exempt += ticket.subtotal;
									} else {
										closing.tickets_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
									}
									closing.tickets_tip += ticket.tip;
								} else if (ticket.fiscal.type == 'ccf') {
									closing['ccfs'].push(Number(ticket.fiscal.ccf.number));

									if (ticket.exempt > 0 && ticket.exempt !== undefined) {
										closing.ccfs_exempt += ticket.subtotal;
									} else {
										closing.ccfs_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
									}
									closing.ccfs_tip += ticket.tip;
								} else if (ticket.fiscal.type == 'invoice') {
									closing['invoices'].push(Number(ticket.fiscal.invoice.number));

									if (ticket.exempt > 0 && ticket.exempt !== undefined) {
										closing.invoices_exempt += ticket.subtotal;
									} else {
										closing.invoices_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
									}

									closing.invoices_tip += ticket.tip;
								}
							}
						}
					});

					if (empty) {
						closing.from = ticket_snapshots.docs[0].data().completed_date.toDate();
					}

					this.getRef(`accounts/${this.auth.account}/ticket_refunds`).ref
						.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
						.where('branch_key', '==', this.auth.branch)
						.where('creation_date', '>=', start_date)
						.where('creation_date', '<=', end_date)
						.get()
						.then(refunds_snapshots => {
							refunds_snapshots.forEach(element => {
								let refund = element.data();

								closing.refunds += Number(refund.total);
								if (refund.fiscal) {
									closing['tickets'].push(refund.fiscal.ticket.current_ticket_number);
									if (refund.ticket.fiscal.type == 'ticket') {
										closing.tickets_refunds += Number(refund.total);
									} else if (refund.ticket.fiscal.type == 'ccf') {
										closing.ccfs_refunds += Number(refund.total);
									} else if (refund.ticket.fiscal.type == 'invoice') {
										closing.invoices_refunds += Number(refund.total);
									}
								}
							});

							this.getRef(`accounts/${this.auth.account}/items_refunds`).ref
								.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
								.where('branch_key', '==', this.auth.branch)
								.where('creation_date', '>=', start_date)
								.where('creation_date', '<=', end_date)
								.get()
								.then(items_refunds_snapshots => {
									items_refunds_snapshots.forEach(element => {
										let refund = element.data();
										closing.refunds += Number(refund.price * refund.quantity);

										if (refund.fiscal) {
											closing['tickets'].push(refund.fiscal.ticket.current_ticket_number);
											if (refund.ticket.fiscal.type == 'ticket') {
												closing.tickets_refunds += Number(refund.price * refund.quantity);
											} else if (refund.ticket.fiscal.type == 'ccf') {
												closing.ccfs_refunds += Number(refund.price * refund.quantity);
											} else if (refund.ticket.fiscal.type == 'invoice') {
												closing.invoices_refunds += Number(refund.price * refund.quantity);
											}
										}

									});

									this.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
										.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
										.where('branch_key', '==', this.auth.branch)
										.where('creation_date', '>=', start_date)
										.where('creation_date', '<=', end_date)
										.get()
										.then(discounts_snapshots => {
											discounts_snapshots.forEach(element => {
												let discount = element.data();

												if (discount.iva_discount !== undefined) {
													closing.discounts += discount.iva_discount;
												}

												closing.discounts += Number(discount.total_discount);
											});

											this.getRef(`accounts/${this.auth.account}/order_returns`).ref
												.where('branch_key', '==', this.auth.branch)
												.where('creation_date', '>=', start_date)
												.where('creation_date', '<=', end_date)
												.get()
												.then(discounts_snapshots => {

													discounts_snapshots.forEach(element => {
														closing.cancellations.push(element.data().order_number)
													});

													this.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
														.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
														.where('branch_key', '==', this.auth.branch)
														.where('ticket_credit_payment', '==', false)
														.where('ticket_creation_date', '>=', start_date)
														.where('ticket_creation_date', '<=', end_date)
														.get()
														.then(payments_snapshots => {
															payments_snapshots.forEach(element => {
																let payment = element.data();

																if (payment.gateway) {
																	let found = closing.gateways.findIndex(e => e.gateway == payment.gateway);

																	if (found > -1) {
																		closing.gateways[found].total += payment.total;
																		closing.gateways[found].quantity += 1;
																	} else {
																		closing.gateways.push({
																			gateway: payment.gateway,
																			quantity: 1,
																			total: payment.total
																		})
																	}
																}

																if (closing[`total_${payment.method}`] == undefined) {
																	closing[`total_${payment.method}`] = 0;
																}

																closing[`total_${payment.method}`] += Number(payment.total);
															});

															this.getRef(`accounts/${this.auth.account}/giftcard_payments`).ref
																.where('branch_key', '==', this.auth.branch)
																.where('creation_date', '>=', start_date)
																.where('creation_date', '<=', end_date)
																.get()
																.then(payments_giftcards_snapshots => {
																	payments_giftcards_snapshots.forEach(element => {
																		let payment = element.data();
																		closing.giftcards += Number(payment.total);

																		if (payment.gateway) {
																			let found = closing.gateways.findIndex(e => e.gateway == payment.gateway);

																			if (found > -1) {
																				closing.gateways[found].total += payment.total;
																				closing.gateways[found].quantity += 1;
																			} else {
																				closing.gateways.push({
																					gateway: payment.gateway,
																					quantity: 1,
																					total: payment.total
																				})
																			}
																		}

																		if (closing[`total_${payment.method}`] == undefined) {
																			closing[`total_${payment.method}`] = 0;
																		}

																		closing[`total_${payment.method}`] += Number(payment.total);
																	});

																	let closing_key = this.db.createId();

																	closing.total_tickets = closing.tickets.length;
																	closing.total_ccfs = closing.ccfs.length;
																	closing.total_invoices = closing.invoices.length;

																	closing['tickets'].sort(function (a, b) {
																		var orderA = a, orderB = b;
																		return orderA - orderB;
																	});

																	closing['invoices'].sort(function (a, b) {
																		var orderA = a, orderB = b;
																		return orderA - orderB;
																	});

																	closing['ccfs'].sort(function (a, b) {
																		var orderA = a, orderB = b;
																		return orderA - orderB;
																	});

																	batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/closings`).doc(closing_key), closing);

																	batch.commit().then(data => {

																		this.components.showToast('El corte ha sido generado con éxito');

																		resolve(true);
																	}, err => {
																		console.log(err);
																		reject();
																	});

																});

														}, err => {
															console.log(err);
															reject();
														})

												}, err => {
													console.log(err);
													reject();
												})

										}, err => {
											console.log(err);
											reject();
										})


								}, err => {
									console.log(err);
									reject();
								})

						}, err => {
							console.log(err);
							reject();
						})
				}



			}, err => {
				console.log(err);
				reject();
			})
	}


	addClosingZ() {

		let todayDate = new Date();
		let batch = this.db.firestore.batch();

		return new Promise((resolve, reject) => {

			this.getRef(`accounts/${this.auth.account}/closings`).ref
				.where('branch_key', '==', this.auth.branch)
				.where('type', '==', 'z')
				.where('big', '==', false)
				.orderBy('creation_date')
				.limitToLast(1)
				.get()
				.then(closingSnapshot => {

					if (closingSnapshot.empty) {
						this.getRef('accounts').ref
							.doc(this.auth.account)
							.get()
							.then(account_snapshot => {
								let account = account_snapshot.data();
								account.creation_date = account.creation_date.toDate();
								this.completeClosingZ(resolve, reject, batch, account.creation_date, todayDate, closingSnapshot.empty);
							}, err => {
								console.log(err);
								reject();
							});
					} else {
						closingSnapshot.forEach(element => {
							let date = element.data().to.toDate();
							this.completeClosingZ(resolve, reject, batch, date, todayDate, closingSnapshot.empty);

						});
					}
				}, err => {
					console.log(err);
					reject();
				})

		});
	}

	completeClosingZ(resolve, reject, batch, start_date, end_date, empty) {

		let closing = {
			big: false,
			creation_date: end_date,
			from: start_date,
			to: end_date,
			branch_key: this.auth.branch,
			branch: this.auth.branch_data,
			user_key: this.auth.token,
			user: this.auth.account_data.user,
			type: 'z',
			total: 0,
			sales: 0,
			invoices_sales: 0,
			ccfs_sales: 0,
			tickets_sales: 0,
			tip: 0,
			tickets_tip: 0,
			ccfs_tip: 0,
			invoices_tip: 0,
			total_card: 0,
			total_cash: 0,
			total_giftcard: 0,
			total_link: 0,
			total_check: 0,
			total_transfer: 0,
			total_other: 0,
			giftcards: 0,
			total_tickets: 0,
			total_invoices: 0,
			total_ccfs: 0,
			tickets_refunds: 0,
			ccfs_refunds: 0,
			invoices_refunds: 0,
			refunds: 0,
			cancellations: [],
			discounts: 0,
			exempt: 0,
			invoices_exempt: 0,
			ccfs_exempt: 0,
			tickets_exempt: 0,
			credit: 0,
			deposits: 0,
			gateways: [],
			tickets: [],
			invoices: [],
			ccfs: []
		}

		if (this.auth.account_data.fiscal.type == 'dte') {
			closing['is_dte'] = true;
		}

		let valid = true;

		this.getRef(`accounts/${this.auth.account}/closings`).ref
			.where('branch_key', '==', this.auth.branch)
			.where('type', '==', 'x')
			.where('creation_date', '>=', start_date)
			.where('creation_date', '<=', end_date)
			.orderBy('creation_date')
			.get()
			.then(closingXSnapshots => {

				if (closingXSnapshots.empty) {
					this.components.showAlert('No puedes realizar corte z', 'No se han encontrado ventas para poder generar un cierre z, revisa tus cuentas abiertas o genera una venta para continuar.', [
						{
							text: 'Continuar',
							role: 'cancel'
						},
					]);
					valid = false;
					reject('nox');
				}
				let x_closings = [];

				closingXSnapshots.forEach(element => {
					let closingx = element.data();
					closingx.creation_date = closingx.creation_date.toDate();
					x_closings.push(closingx);
				});

				if (empty) {
					closing.from = closingXSnapshots.docs[0].data().from.toDate();
				}

				if (valid) {
					this.getRef(`accounts/${this.auth.account}/tickets`).ref
						.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
						.where('branch_key', '==', this.auth.branch)
						.where('completed_date', '>=', start_date)
						.where('completed_date', '<=', end_date)
						.orderBy('completed_date')
						.get()
						.then(ticket_snapshots => {

							ticket_snapshots.forEach(element => {
								let ticket = element.data();

								ticket.completed_date = ticket.completed_date.toDate();
								if (ticket.completed_date > x_closings[x_closings.length - 1].creation_date) {
									valid = false;
								}

								if (ticket.exempt > 0 && ticket.exempt !== undefined) {
									closing.exempt += Number(ticket.subtotal);
								} else {
									closing.sales += Number(ticket.subtotal + ticket.hidden_taxes + ticket.taxes - ticket.exempt);
								}

								closing.total += Number(ticket.total);
								closing.tip += Number(ticket.tip);

								if (ticket.was_credit) {
									closing.credit += ticket.total;
								}

								if (ticket.fiscal) {
									if (ticket.fiscal_type == 'dte') {
										if (ticket.fiscal.type == 'invoice') {
											closing['invoices'].push(ticket.fiscal.dte.json.identificacion.numeroControl);

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.invoices_exempt += ticket.subtotal;
											} else {
												closing.invoices_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.invoices_tip += ticket.tip;
										} else if (ticket.fiscal.type == 'ccf') {
											closing['ccfs'].push(ticket.fiscal.dte.json.identificacion.numeroControl);

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.ccfs_exempt += ticket.subtotal;
											} else {
												closing.ccfs_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.ccfs_tip += ticket.tip;
										}
									} else {
										if (ticket.fiscal.type == 'ticket') {
											closing['tickets'].push(ticket.fiscal.ticket.current_ticket_number);

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.tickets_exempt += ticket.subtotal;
											} else {
												closing.tickets_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.tickets_tip += ticket.tip;
										} else if (ticket.fiscal.type == 'ccf') {
											closing['ccfs'].push(Number(ticket.fiscal.ccf.number));

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.ccfs_exempt += ticket.subtotal;
											} else {
												closing.ccfs_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.ccfs_tip += ticket.tip;
										} else if (ticket.fiscal.type == 'invoice') {
											closing['invoices'].push(Number(ticket.fiscal.invoice.number));

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.invoices_exempt += ticket.subtotal;
											} else {
												closing.invoices_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}

											closing.invoices_tip += ticket.tip;
										}
									}
								}
							});

							if (valid) {

								this.getRef(`accounts/${this.auth.account}/ticket_refunds`).ref
									.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
									.where('branch_key', '==', this.auth.branch)
									.where('creation_date', '>=', start_date)
									.where('creation_date', '<=', end_date)
									.get()
									.then(refunds_snapshots => {
										refunds_snapshots.forEach(element => {

											let refund = element.data();

											closing.refunds += Number(refund.total);
											if (refund.fiscal) {
												closing['tickets'].push(refund.fiscal.ticket.current_ticket_number);
												if (refund.ticket.fiscal.type == 'ticket') {
													closing.tickets_refunds += Number(refund.total);
												} else if (refund.ticket.fiscal.type == 'ccf') {
													closing.ccfs_refunds += Number(refund.total);
												} else if (refund.ticket.fiscal.type == 'invoice') {
													closing.invoices_refunds += Number(refund.total);
												}
											}
										});

										this.getRef(`accounts/${this.auth.account}/items_refunds`).ref
											.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
											.where('branch_key', '==', this.auth.branch)
											.where('creation_date', '>=', start_date)
											.where('creation_date', '<=', end_date)
											.get()
											.then(items_refunds_snapshots => {
												items_refunds_snapshots.forEach(element => {

													let refund = element.data();
													closing.refunds += Number(refund.price * refund.quantity);

													if (refund.fiscal) {
														closing['tickets'].push(refund.fiscal.ticket.current_ticket_number);
														if (refund.ticket.fiscal.type == 'ticket') {
															closing.tickets_refunds += Number(refund.price * refund.quantity);
														} else if (refund.ticket.fiscal.type == 'ccf') {
															closing.ccfs_refunds += Number(refund.price * refund.quantity);
														} else if (refund.ticket.fiscal.type == 'invoice') {
															closing.invoices_refunds += Number(refund.price * refund.quantity);
														}
													}

												});

												this.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
													.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
													.where('branch_key', '==', this.auth.branch)
													.where('creation_date', '>=', start_date)
													.where('creation_date', '<=', end_date)
													.get()
													.then(discounts_snapshots => {

														discounts_snapshots.forEach(element => {
															let discount = element.data();

															if (discount.iva_discount !== undefined) {
																closing.discounts += discount.iva_discount;
															}

															closing.discounts += Number(discount.total_discount);
														});


														this.getRef(`accounts/${this.auth.account}/order_returns`).ref
															.where('branch_key', '==', this.auth.branch)
															.where('creation_date', '>=', start_date)
															.where('creation_date', '<=', end_date)
															.get()
															.then(discounts_snapshots => {
																discounts_snapshots.forEach(element => {
																	closing.cancellations.push(element.data().order_number)
																});

																this.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
																	.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
																	.where('branch_key', '==', this.auth.branch)
																	.where('ticket_credit_payment', '==', false)
																	.where('ticket_creation_date', '>=', start_date)
																	.where('ticket_creation_date', '<=', end_date)
																	.get()
																	.then(payments_snapshots => {

																		payments_snapshots.forEach(element => {
																			let payment = element.data();

																			if (payment.gateway) {
																				let found = closing.gateways.findIndex(e => e.gateway == payment.gateway);

																				if (found > -1) {
																					closing.gateways[found].total += payment.total;
																					closing.gateways[found].quantity += 1;
																				} else {
																					closing.gateways.push({
																						gateway: payment.gateway,
																						quantity: 1,
																						total: payment.total
																					})
																				}
																			}

																			if (closing[`total_${payment.method}`] == undefined) {
																				closing[`total_${payment.method}`] = 0;
																			}

																			closing[`total_${payment.method}`] += Number(payment.total);

																		});

																		this.getRef(`accounts/${this.auth.account}/giftcard_payments`).ref
																			.where('branch_key', '==', this.auth.branch)
																			.where('creation_date', '>=', start_date)
																			.where('creation_date', '<=', end_date)
																			.get()
																			.then(payments_giftcards_snapshots => {
																				payments_giftcards_snapshots.forEach(element => {
																					let payment = element.data();
																					closing.giftcards += Number(payment.total);

																					if (payment.gateway) {
																						let found = closing.gateways.findIndex(e => e.gateway == payment.gateway);

																						if (found > -1) {
																							closing.gateways[found].total += payment.total;
																							closing.gateways[found].quantity += 1;
																						} else {
																							closing.gateways.push({
																								gateway: payment.gateway,
																								quantity: 1,
																								total: payment.total
																							})
																						}
																					}

																					if (closing[`total_${payment.method}`] == undefined) {
																						closing[`total_${payment.method}`] = 0;
																					}

																					closing[`total_${payment.method}`] += Number(payment.total);

																				});

																				let closing_key = this.db.createId();

																				closing.total_tickets = closing.tickets.length;
																				closing.total_ccfs = closing.ccfs.length;
																				closing.total_invoices = closing.invoices.length;

																				closing['tickets'].sort(function (a, b) {
																					var orderA = a, orderB = b;
																					return orderA - orderB;
																				});

																				closing['invoices'].sort(function (a, b) {
																					var orderA = a, orderB = b;
																					return orderA - orderB;
																				});

																				closing['ccfs'].sort(function (a, b) {
																					var orderA = a, orderB = b;
																					return orderA - orderB;
																				});

																				batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/closings`).doc(closing_key), closing);

																				batch.commit().then(data => {
																					this.components.showToast('El corte ha sido generado con éxito');
																					resolve(true);
																				}, err => {
																					console.log(err);
																					reject();
																				});

																			})

																	}, err => {
																		console.log(err);
																		reject();
																	})

															}, err => {
																console.log(err);
																reject();
															})

													}, err => {
														console.log(err);
														reject();
													})


											}, err => {
												console.log(err);
												reject();
											})

									}, err => {
										console.log(err);
										reject();
									})
							} else {
								reject('nox')
							}
						}, err => {
							console.log(err);
							reject();
						})

				}
			})

	}

	addClosingBigZ() {

		let todayDate = new Date();
		let batch = this.db.firestore.batch();

		return new Promise((resolve, reject) => {

			this.getRef(`accounts/${this.auth.account}/closings`).ref
				.where('branch_key', '==', this.auth.branch)
				.where('type', '==', 'z')
				.where('big', '==', true)
				.orderBy('creation_date')
				.limitToLast(1)
				.get()
				.then(closingSnapshot => {

					if (closingSnapshot.empty) {
						this.getRef('accounts').ref
							.doc(this.auth.account)
							.get()
							.then(account_snapshot => {
								let account = account_snapshot.data();
								account.creation_date = account.creation_date.toDate();
								this.completeClosingBigZ(resolve, reject, batch, account.creation_date, todayDate, closingSnapshot.empty);
							}, err => {
								console.log(err);
								reject();
							});
					} else {
						closingSnapshot.forEach(element => {
							let date = element.data().to.toDate();
							this.completeClosingBigZ(resolve, reject, batch, date, todayDate, closingSnapshot.empty);

						});
					}
				}, err => {
					console.log(err);
					reject();
				})

		});
	}

	completeClosingBigZ(resolve, reject, batch, start_date, end_date, empty) {

		let closing = {
			big: true,
			creation_date: end_date,
			from: start_date,
			to: end_date,
			branch_key: this.auth.branch,
			branch: this.auth.branch_data,
			user_key: this.auth.token,
			user: this.auth.account_data.user,
			type: 'z',
			total: 0,
			sales: 0,
			invoices_sales: 0,
			ccfs_sales: 0,
			tickets_sales: 0,
			tip: 0,
			tickets_tip: 0,
			ccfs_tip: 0,
			invoices_tip: 0,
			total_card: 0,
			total_cash: 0,
			total_giftcard: 0,
			total_link: 0,
			total_check: 0,
			total_transfer: 0,
			total_other: 0,
			giftcards: 0,
			total_tickets: 0,
			total_invoices: 0,
			total_ccfs: 0,
			tickets_refunds: 0,
			ccfs_refunds: 0,
			invoices_refunds: 0,
			refunds: 0,
			cancellations: [],
			discounts: 0,
			exempt: 0,
			invoices_exempt: 0,
			credit: 0,
			deposits: 0,
			ccfs_exempt: 0,
			tickets_exempt: 0,
			gateways: [],
			tickets: [],
			invoices: [],
			ccfs: []
		}

		if (this.auth.account_data.fiscal.type == 'dte') {
			closing['is_dte'] = true;
		}

		let valid = true;

		this.getRef(`accounts/${this.auth.account}/closings`).ref
			.where('branch_key', '==', this.auth.branch)
			.where('type', '==', 'x')
			.where('creation_date', '>=', start_date)
			.where('creation_date', '<=', end_date)
			.orderBy('creation_date')
			.get()
			.then(closingXSnapshots => {

				if (closingXSnapshots.empty) {
					this.components.showAlert('No puedes realizar corte gran z', 'No se han encontrado ventas para poder generar un cierre z, revisa tus cuentas abiertas o genera una venta para continuar.', [
						{
							text: 'Continuar',
							role: 'cancel'
						},
					]);
					valid = false;
					reject('nox');
				}
				let x_closings = [];

				closingXSnapshots.forEach(element => {
					let closingx = element.data();
					closingx.creation_date = closingx.creation_date.toDate();
					x_closings.push(closingx);
				});

				if (empty) {
					closing.from = closingXSnapshots.docs[0].data().from.toDate();
				}

				if (valid) {
					this.getRef(`accounts/${this.auth.account}/tickets`).ref
						.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
						.where('branch_key', '==', this.auth.branch)
						.where('completed_date', '>=', start_date)
						.where('completed_date', '<=', end_date)
						.orderBy('completed_date')
						.get()
						.then(ticket_snapshots => {

							ticket_snapshots.forEach(element => {
								let ticket = element.data();
								ticket.completed_date = ticket.completed_date.toDate();

								if (ticket.completed_date > x_closings[x_closings.length - 1].creation_date) {
									valid = false;
								}

								if (ticket.exempt > 0 && ticket.exempt !== undefined) {
									closing.exempt += Number(ticket.subtotal);
								} else {
									closing.sales += Number(ticket.subtotal + ticket.hidden_taxes + ticket.taxes);
								}

								closing.total += Number(ticket.total);
								closing.tip += Number(ticket.tip);

								if (ticket.was_credit) {
									closing.credit += ticket.total;
								}


								if (ticket.fiscal) {
									if (ticket.fiscal_type == 'dte') {
										if (ticket.fiscal.type == 'invoice') {
											closing['invoices'].push(ticket.fiscal.dte.json.identificacion.numeroControl);

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.invoices_exempt += ticket.subtotal;
											} else {
												closing.invoices_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.invoices_tip += ticket.tip;
										} else if (ticket.fiscal.type == 'ccf') {
											closing['ccfs'].push(ticket.fiscal.dte.json.identificacion.numeroControl);

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.ccfs_exempt += ticket.subtotal;
											} else {
												closing.ccfs_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.ccfs_tip += ticket.tip;
										}
									} else {
										if (ticket.fiscal.type == 'ticket') {
											closing['tickets'].push(ticket.fiscal.ticket.current_ticket_number);

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.tickets_exempt += ticket.subtotal;
											} else {
												closing.tickets_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.tickets_tip += ticket.tip;
										} else if (ticket.fiscal.type == 'ccf') {
											closing['ccfs'].push(Number(ticket.fiscal.ccf.number));

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.ccfs_exempt += ticket.subtotal;
											} else {
												closing.ccfs_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}
											closing.ccfs_tip += ticket.tip;
										} else if (ticket.fiscal.type == 'invoice') {
											closing['invoices'].push(Number(ticket.fiscal.invoice.number));

											if (ticket.exempt > 0 && ticket.exempt !== undefined) {
												closing.invoices_exempt += ticket.subtotal;
											} else {
												closing.invoices_sales += (ticket.subtotal + ticket.taxes + ticket.hidden_taxes);
											}

											closing.invoices_tip += ticket.tip;
										}
									}
								}

							});

							if (valid) {

								this.getRef(`accounts/${this.auth.account}/ticket_refunds`).ref
									.where('branch_key', '==', this.auth.branch)
									.where('creation_date', '>=', start_date)
									.where('creation_date', '<=', end_date)
									.get()
									.then(refunds_snapshots => {
										refunds_snapshots.forEach(element => {

											let refund = element.data();

											closing.refunds += Number(refund.total);
											if (refund.fiscal) {
												closing['tickets'].push(refund.fiscal.ticket.current_ticket_number);
												if (refund.ticket.fiscal.type == 'ticket') {
													closing.tickets_refunds += Number(refund.total);
												} else if (refund.ticket.fiscal.type == 'ccf') {
													closing.ccfs_refunds += Number(refund.total);
												} else if (refund.ticket.fiscal.type == 'invoice') {
													closing.invoices_refunds += Number(refund.total);
												}
											}
										});

										this.getRef(`accounts/${this.auth.account}/items_refunds`).ref
											.where('branch_key', '==', this.auth.branch)
											.where('creation_date', '>=', start_date)
											.where('creation_date', '<=', end_date)
											.get()
											.then(items_refunds_snapshots => {
												items_refunds_snapshots.forEach(element => {

													let refund = element.data();
													closing.refunds += Number(refund.price * refund.quantity);

													if (refund.fiscal) {
														closing['tickets'].push(refund.fiscal.ticket.current_ticket_number);
														if (refund.ticket.fiscal.type == 'ticket') {
															closing.tickets_refunds += Number(refund.price * refund.quantity);
														} else if (refund.ticket.fiscal.type == 'ccf') {
															closing.ccfs_refunds += Number(refund.price * refund.quantity);
														} else if (refund.ticket.fiscal.type == 'invoice') {
															closing.invoices_refunds += Number(refund.price * refund.quantity);
														}
													}

												});

												this.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
													.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
													.where('branch_key', '==', this.auth.branch)
													.where('creation_date', '>=', start_date)
													.where('creation_date', '<=', end_date)
													.get()
													.then(discounts_snapshots => {
														discounts_snapshots.forEach(element => {
															let discount = element.data();

															if (discount.iva_discount !== undefined) {
																closing.discounts += discount.iva_discount;
															}

															closing.discounts += Number(discount.total_discount);
														});

														this.getRef(`accounts/${this.auth.account}/order_returns`).ref
															.where('branch_key', '==', this.auth.branch)
															.where('creation_date', '>=', start_date)
															.where('creation_date', '<=', end_date)
															.get()
															.then(discounts_snapshots => {
																discounts_snapshots.forEach(element => {
																	closing.cancellations.push(element.data().order_number)
																});


																this.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
																	.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
																	.where('branch_key', '==', this.auth.branch)
																	.where('ticket_credit_payment', '==', false)
																	.where('ticket_creation_date', '>=', start_date)
																	.where('ticket_creation_date', '<=', end_date)
																	.get()
																	.then(payments_snapshots => {

																		payments_snapshots.forEach(element => {
																			let payment = element.data();

																			if (payment.gateway) {
																				let found = closing.gateways.findIndex(e => e.gateway == payment.gateway);

																				if (found > -1) {
																					closing.gateways[found].total += payment.total;
																					closing.gateways[found].quantity += 1;
																				} else {
																					closing.gateways.push({
																						gateway: payment.gateway,
																						quantity: 1,
																						total: payment.total
																					})
																				}
																			}

																			if (closing[`total_${payment.method}`] == undefined) {
																				closing[`total_${payment.method}`] = 0;
																			}

																			closing[`total_${payment.method}`] += Number(payment.total);
																		});

																		this.getRef(`accounts/${this.auth.account}/giftcard_payments`).ref
																			.where('branch_key', '==', this.auth.branch)
																			.where('creation_date', '>=', start_date)
																			.where('creation_date', '<=', end_date)
																			.get()
																			.then(payments_giftcards_snapshots => {
																				payments_giftcards_snapshots.forEach(element => {
																					let payment = element.data();
																					closing.giftcards += Number(payment.total);

																					if (payment.gateway) {
																						let found = closing.gateways.findIndex(e => e.gateway == payment.gateway);

																						if (found > -1) {
																							closing.gateways[found].total += payment.total;
																							closing.gateways[found].quantity += 1;
																						} else {
																							closing.gateways.push({
																								gateway: payment.gateway,
																								quantity: 1,
																								total: payment.total
																							})
																						}
																					}

																					if (closing[`total_${payment.method}`] == undefined) {
																						closing[`total_${payment.method}`] = 0;
																					}

																					closing[`total_${payment.method}`] += Number(payment.total);

																				});

																				let closing_key = this.db.createId();

																				closing.total_tickets = closing.tickets.length;
																				closing.total_ccfs = closing.ccfs.length;
																				closing.total_invoices = closing.invoices.length;

																				closing['tickets'].sort(function (a, b) {
																					var orderA = a, orderB = b;
																					return orderA - orderB;
																				});

																				closing['invoices'].sort(function (a, b) {
																					var orderA = a, orderB = b;
																					return orderA - orderB;
																				});

																				closing['ccfs'].sort(function (a, b) {
																					var orderA = a, orderB = b;
																					return orderA - orderB;
																				});

																				batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/closings`).doc(closing_key), closing);

																				batch.commit().then(data => {
																					this.components.showToast('El corte ha sido generado con éxito');
																					resolve(true);
																				}, err => {
																					console.log(err);
																					reject();
																				});

																			})

																	}, err => {
																		console.log(err);
																		reject();
																	})

															}, err => {
																console.log(err);
																reject();
															})

													}, err => {
														console.log(err);
														reject();
													})


											}, err => {
												console.log(err);
												reject();
											})

									}, err => {
										console.log(err);
										reject();
									})
							} else {
								reject('nox')
							}
						}, err => {
							console.log(err);
							reject();
						})

				}
			})

	}

	getUserCode() {
		return new Promise((resolve, reject) => {
			let code = this.makeUserCode();
			this.getRef(`accounts/${this.auth.account}/users`).ref
				.where('code', '==', code)
				.get()
				.then(snapshots => {
					if (snapshots.empty) {
						resolve(code);
					} else {
						reject();
					}
				}, err => {
					reject();
				})
		});
	}

	makeUserCode() {
		var result = '';
		var characters = '0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < 4; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	updateInventory(item, new_quantity, old_quantity) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(item.branch_key), {
				quantity: new_quantity,
			});

			let record_key = this.db.createId();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(record_key), {
				old_quantity: old_quantity,
				new_quantity: new_quantity,
				creation_date: new Date(),
				reason: 'inventory_update',
				item_key: item.$key,
				item: item,
				branch_key: this.auth.branch,
				branch: this.auth.branch_data,
				user: this.auth.account_data.user,
			});

			batch.commit().then(data => {
				resolve(true);
			}, err => {
				reject();
			})
		});
	}

	changeOrdersTables(orders) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			orders.forEach(element => {
				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(element.$key), {
					table: this.pos.table,
					table_area: this.pos.table_area
				});
			});

			batch.commit().then(data => {
				resolve(true);
			}, err => {
				reject();
			})
		})
	}

	splitItems(parent_order, order_key, items, orders) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();
			let new_order_key, actual_key;

			this.getDocument(`accounts/${this.auth.account}/orders`, order_key).then(data => {
				let order = JSON.parse(JSON.stringify(data));
				delete order.$key;
				order.creation_date = data['creation_date'].toDate();

				if (order.last_payment) {
					delete order.last_payment;
				}

				if (order.splitted) {
					delete order.splitted;
				}

				if (order.splitted) {
					delete order.splitted;
				} if (order.splitted_payments) {
					delete order.splitted_payments;
				}

				this.getRef(`accounts/${this.auth.account}/branches`).ref
					.doc(this.auth.branch)
					.get()
					.then(branch_snapshot => {
						let order_number = 1;

						if (branch_snapshot.data().current_order_number) {
							order_number = branch_snapshot.data().current_order_number;
						}

						orders.forEach((element, index) => {
							new_order_key = this.db.createId();
							order_number = order_number + 1;
							order.order_number = order_number;

							if (index == 0) {
								actual_key = new_order_key;
							}

							batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(new_order_key), order);

							element.items.forEach(element => {

								if (element.new) {
									let item_order_key = this.db.createId();
									let item_order = JSON.parse(JSON.stringify(element));

									delete item_order.$key;
									delete item_order.new;
									delete item_order.items_to_paid;
									item_order.order_key = new_order_key;
									batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item_order_key), item_order);
								} else {
									batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(element.$key), {
										order_key: new_order_key,
									});
								}

							});

						});

						batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
							current_order_number: order_number
						});

						items.forEach(element => {
							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(element.$key), {
								quantity: element.quantity,
							});
						});

						batch.commit().then(data => {
							resolve(actual_key);
						}, err => {
							console.log(err);
							reject();
						})

					})


			}, err => {
				console.log(err);

				reject();
			});
		})
	}

	makeGiftCardPayment(gateway, email, client, method, amount, to) {
		return new Promise((resolve, reject) => {

			let batch = this.db.firestore.batch();

			let giftcard_key = this.db.createId();

			let payment = {
				branch_key: this.auth.branch,
				branch: this.auth.branch_data,
				amount: amount,
				total: amount,
				user_key: this.auth.token,
				user: this.auth.account_data.user,
				creation_date: new Date(),
				method: method,
				gateway: gateway,
				giftcard_key: giftcard_key
			}

			let payment_key = this.db.createId();
			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/giftcard_payments`).doc(payment_key), payment);

			this.getRef(`accounts`).ref
				.doc(this.auth.account)
				.get()
				.then(account_snapshot => {
					let account_data = account_snapshot.data();

					if (account_data.current_giftcard_number) {
						account_data.current_giftcard_number += 1;
					} else {
						account_data.current_giftcard_number = 1;
					}

					batch.update(this.db.firestore.collection(`accounts`).doc(this.auth.account), { current_giftcard_number: account_data.current_giftcard_number });

					let expiration_date = new Date();
					expiration_date.setFullYear(expiration_date.getFullYear() + 1);

					let giftcard = {
						branch_key: this.auth.branch,
						branch: this.auth.branch_data,
						available_amount: amount,
						total: amount,
						user_key: this.auth.token,
						user: this.auth.account_data.user,
						creation_date: new Date(),
						expiration_date: expiration_date,
						email: email,
						to: to,
						client: client,
						number: account_data.current_giftcard_number,
						code: `${new Date().getDate()}${new Date().getMonth()}${new Date().getFullYear()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}`,
						payment_method: method,
					}

					batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/giftcards`).doc(giftcard_key), giftcard);

					this.auth.report.total_giftcard += giftcard.total;
					this.auth.report.giftcards += 1;

					batch.commit().then(data => {
						resolve(giftcard_key);
					}, err => {
						reject();
					});
				})

		})
	}

	makePayment(payment_data) {

		return new Promise((resolve, reject) => {

			let completed = false;
			let batch = this.db.firestore.batch();

			let pending_payment = Number(((payment_data.pending.toFixed(2)) - (payment_data.deposit + payment_data.tip)).toFixed(2));

			let data_order = {
				last_payment: new Date(),
			};

			if (payment_data.method == 'giftcard') {
				let new_available = payment_data.giftcard.available_amount - Number((payment_data.deposit + payment_data.tip).toFixed(2));

				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/giftcards`).doc(payment_data.giftcard.$key), {
					available_amount: new_available
				});
			}

			if (payment_data.order.splitted) {
				if (pending_payment > 0) {
					data_order['pending_splitted'] = Number((pending_payment).toFixed(2));
					batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(payment_data.order.$key), data_order);
				} else {
					if (payment_data.order.splitted_payments == payment_data.order.splitted) {
						/* Converting an order to a ticket because the payment is complete. */
						completed = true;
					} else {
						data_order['pending_splitted'] = firebase.firestore.FieldValue.delete();
						data_order['splitted_payments'] = payment_data.order.splitted_payments + 1;
						batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(payment_data.order.$key), data_order);
					}
				}
			} else {
				if (pending_payment <= 0) {
					/* Converting an order to a ticket because the payment is complete. */
					completed = true;
				} else {
					batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(payment_data.order.$key), data_order);
				}
			}

			let payment_key = this.db.createId();

			if (completed) {

				let ticket = JSON.parse(JSON.stringify(payment_data.order));
				ticket.fiscal_type == 'standard';
				ticket.creation_date = payment_data.order.creation_date instanceof Date ? payment_data.order.creation_date : payment_data.order.creation_date.toDate();
				ticket.status = 'completed';
				ticket.tip = payment_data.gran_tip;
				ticket.subtotal = payment_data.subtotal;
				ticket.total = payment_data.gran_total;
				ticket.discount = payment_data.gran_discount;
				ticket.taxes = payment_data.taxes;
				ticket.hidden_taxes = payment_data.hidden_taxes;
				ticket.exempt = payment_data.exempt;
				ticket.completed_date = new Date();

				if (ticket.type !== 'tables') {
					ticket.mens = 0;
					ticket.womens = 0;
					ticket.childrens = 0;
				}

				batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(payment_data.order.$key));

				payment_data.items.forEach(item => {
					if (item.parent) {
						delete item.parent._highlightResult;
					}
					delete item._highlightResult;
					batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
						order_key: firebase.firestore.FieldValue.delete(),
						_highlightResult: firebase.firestore.FieldValue.delete(),
						creation_date: ticket.completed_date,
						available: firebase.firestore.FieldValue.delete(),
						active: firebase.firestore.FieldValue.delete(),
						favorite_key: firebase.firestore.FieldValue.delete(),
						favorite_order: firebase.firestore.FieldValue.delete(),
						last_payment: firebase.firestore.FieldValue.delete(),
						ticket_creation_date: ticket.completed_date,
						ticket_key: payment_data.order.$key,
					});
				});

				this.getRef(`accounts/${this.auth.account}/branches`).ref
					.doc(this.auth.branch)
					.get()
					.then(branch_snapshot => {
						let ticket_number = 1;
						let branch = branch_snapshot.data();
						ticket.address = branch.address;
						ticket.phone = branch.phone;

						if (branch.fiscal !== undefined && Object.keys(branch.fiscal).length > 0) {
							if (ticket.tax_receipt) {
								if (ticket.tax_receipt.type == 'ticket') {
									if (branch.fiscal.current_ticket_number) {
										ticket_number = branch.fiscal.current_ticket_number + 1;
									}

									ticket_number = branch.fiscal.current_ticket_number + 1;

									let fiscal = {
										ticket: branch.fiscal,
										type: 'ticket',
										nit: this.auth.account_data.fiscal.nit,
										nrc: this.auth.account_data.fiscal.nrc,
										business_name: this.auth.account_data.fiscal.business_name,
										branch: branch.name
									};

									fiscal.ticket.current_ticket_number = ticket_number;
									fiscal.ticket.device_number = this.auth.device_number;

									batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
										fiscal: fiscal.ticket
									});

									ticket.fiscal = fiscal;
								} else {
									if (branch.fiscal.current_invoice_number) {
										ticket_number = branch.fiscal.current_invoice_number + 1;
									}

									ticket_number = branch.fiscal.current_invoice_number + 1;

									ticket.fiscal = ticket.tax_receipt;
									ticket.fiscal.business_name = this.auth.account_data.fiscal.business_name;
									ticket.fiscal.nrc = this.auth.account_data.fiscal.nrc;
									ticket.fiscal.nit = this.auth.account_data.fiscal.nit;
									ticket.fiscal.branch = branch.name;

									if (this.auth.account_data.fiscal.type == 'dte') {
										if (ticket.tax_receipt.type == 'invoice') {
											ticket.fiscal.current_invoice_number = branch.fiscal.current_invoice_number + 1;
											let new_fiscal = branch.fiscal;
											new_fiscal.current_invoice_number = ticket.fiscal.current_invoice_number;
											batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
												fiscal: new_fiscal
											});
										} else if (ticket.tax_receipt.type == 'ccf') {
											ticket.fiscal.current_ccf_number = branch.fiscal.current_ccf_number + 1;
											let new_fiscal = branch.fiscal;
											new_fiscal.current_ccf_number = ticket.fiscal.current_ccf_number;
											batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
												fiscal: new_fiscal
											});
										}
									}
								}
								delete ticket.tax_receipt;
							}

						} else {
							ticket.fiscal = false;
						}

						ticket.last_payment = new Date();

						let new_payment = {
							branch_key: this.auth.branch,
							branch: this.auth.branch_data,
							amount: payment_data.deposit,
							tip: payment_data.tip,
							total: Number((payment_data.deposit + payment_data.tip).toFixed(2)),
							user_key: this.auth.token,
							user: this.auth.account_data.user,
							creation_date: new Date(),
							method: payment_data.method,
							order_key: payment_data.order.$key,
							ticket_key: payment_data.order.$key,
							gateway: payment_data.gateway,
							giftcard: false,
							ticket_credit_payment: false,
							ticket_creation_date: ticket.completed_date,
						}

						if (payment_data.giftcard) {
							new_payment['giftcard'] = payment_data.giftcard.$key;
						}

						if (payment_data.other) {
							new_payment['other_payment'] = payment_data.other_payment;
						}

						if (ticket.fiscal) {
							new_payment['fiscal'] = ticket.fiscal;
						}
						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_payments`).doc(payment_key), new_payment);

						payment_data.payments.forEach(payment => {
							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_payments`).doc(payment.$key), {
								ticket_key: payment_data.order.$key,
								ticket_creation_date: ticket.completed_date,
								ticket_credit_payment: false
							});
						});


						let total_discount = 0;
						let iva_discount = 0;
						if (payment_data.discounts) {
							payment_data.discounts.forEach(discount => {
								discount.order_key = firebase.firestore.FieldValue.delete();
								discount.ticket_key = payment_data.order.$key;
								discount.fiscal = ticket.fiscal;
								batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_discounts`).doc(discount.$key), discount)
								total_discount += discount.total_discount;
								iva_discount += discount.iva_discount;
							});
						}
						let all_payments = payment_data.payments;
						all_payments.push(new_payment)

						if (ticket.fiscal && (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte')) {


							this.dte.getJson(ticket, payment_data.items, payment_data.discounts, all_payments).then((json: any) => {
								this.dte.signDte(json).then(dteResponse => {

									ticket.fiscal_type = 'dte';
									ticket.fiscal.dte = {
										selloRecibido: dteResponse['selloRecibido'],
										codigoGeneracion: dteResponse['codigoGeneracion'],
										observaciones: dteResponse['observaciones'],
										json: json.dteJson
									}

									ticket.total = Number(ticket.total);
									ticket.have_dte = true;
									ticket.iva_discount = iva_discount;

									batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(payment_data.order.$key), ticket);
									batch.update(this.db.firestore.collection(`accounts`).doc(this.auth.account), { dte_counter: this.auth.account_data.dte_counter + 1 });

									this.updateReportForTicket(batch, ticket, all_payments, payment_data.items);

									batch.commit().then(data => {
										resolve({
											payment_key: payment_key,
											completed: completed,
											ticket_key: payment_data.order.$key,
											order: payment_data.order
										});
									});
								}, err => {
									reject({ code: 'x02', error: err });
								})
							}, err => {
								reject({ code: 'x03', error: err });
							})
						} else {

							ticket.fiscal_type = 'standard';
							ticket.iva_discount = iva_discount;

							// Aqui alimenta el JSON DE LA REPORTERIA 
							this.updateReportForTicket(batch, ticket, all_payments, payment_data.items);

							batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(payment_data.order.$key), ticket);
							batch.commit().then(data => {
								resolve({
									payment_key: payment_key,
									completed: completed,
									ticket_key: payment_data.order.$key,
									data_order: data_order
								});
							}, err => {
								reject({ code: 'x04', error: err });
							});
						}
					}, err => {
						console.log(err);

					})

			} else {

				this.getRef(`accounts/${this.auth.account}/branches`).ref
					.doc(this.auth.branch)
					.get()
					.then(branch_snapshot => {

						let branch = branch_snapshot.data();

						let payment = {
							branch_key: this.auth.branch,
							branch: this.auth.branch_data,
							amount: payment_data.deposit,
							tip: payment_data.tip,
							total: Number((payment_data.deposit + payment_data.tip).toFixed(2)),
							user_key: this.auth.token,
							creation_date: new Date(),
							method: payment_data.method,
							order_key: payment_data.order.$key,
							gateway: payment_data.gateway,
							giftcard: false,
							ticket_credit_payment: false
						}

						if (payment_data.giftcard) {
							payment['giftcard'] = payment_data.giftcard.$key;
						}

						if (branch.fiscal) {
							if (payment_data.order.tax_receipt.type == 'ticket') {

								let fiscal = {
									ticket: branch.fiscal,
									type: 'ticket',
									nit: this.auth.account_data.fiscal.nit,
									nrc: this.auth.account_data.fiscal.nrc,
									business_name: this.auth.account_data.fiscal.business_name,
									branch: branch.name,
									order_number: payment_data.order.order_number
								};

								fiscal.ticket.device_number = this.auth.device_number;

								payment['fiscal'] = fiscal;
							} else {
								payment['fiscal'] = payment_data.order.tax_receipt;
								payment['fiscal'].business_name = this.auth.account_data.fiscal.business_name;
								payment['fiscal'].nit = this.auth.account_data.fiscal.nit;
								payment['fiscal'].nrc = this.auth.account_data.fiscal.nrc;
								payment['fiscal'].branch = branch.name
							}
						} else {
							payment['fiscal'] = false;
						}

						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_payments`).doc(payment_key), payment);

						batch.commit().then(data => {
							resolve({
								payment_key: payment_key,
								completed: completed,
								data_order: data_order
							});
						}, err => {
							reject();
						});
					})
			}
		})
	}

	annulOrder(order, back_to_stock) {
		return new Promise((resolve, reject) => {

			let batch = this.db.firestore.batch()

			batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(order.$key));

			let return_order = order;

			return_order.return_date = new Date();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/order_returns`).doc(order.$key),
				return_order
			);

			order.items.forEach(element => {
				let item = element;
				batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(element.$key));

				if (item.have_promotion) {
					this.usagePromotion(item, true);
				}
			});

			if (back_to_stock) {
				let counter = 0;
				let items = [];

				order.items.forEach(item => {
					if (!item.is_promotion) {
						let item_element = JSON.parse(JSON.stringify(item));
						const found = items.findIndex(element => element.item_key == item_element.item_key);
						if (found < 0) {
							items.push(item_element);
						} else {
							items[found].quantity += item_element.quantity;
						}
						counter++;

					} else {
						let prom_counter = 0;

						item.x_items.concat(item.y_items).forEach(item_promo => {
							let item_element = JSON.parse(JSON.stringify(item_promo));
							const found = items.findIndex(element => element.item_key == item_element.item_key);
							if (found < 0) {
								items.push(item_element);
							} else {
								items[found].quantity += item_element.quantity;
							}

							prom_counter++;

							if (prom_counter == item.x_items.concat(item.y_items).length) {
								counter++;
							}
						});
						this.usagePromotion(item, false);
					}


					if (counter == order.items.length) {
						counter = 0;

						items.forEach(item_element => {
							if (item_element.inventory_management) {
								this.getRef(`accounts/${this.auth.account}/items_branches`).ref
									.where('item_key', '==', item_element.item_key)
									.where('branch_key', '==', item_element.item_branch.branch_key)
									.get()
									.then(snapshots => {
										counter++;
										snapshots.forEach(element => {

											batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(element.id), {
												quantity: element.data().quantity + item_element.quantity
											});

											let inventory_record_key = this.db.createId();

											batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
												branch_key: this.auth.branch,
												branch: this.auth.branch_data,
												creation_date: new Date(),
												item_key: item_element.item_key,
												item: item_element,
												new_quantity: element.data().quantity + item_element.quantity,
												old_quantity: element.data().quantity,
												reason: 'annul_order',
												user: this.auth.account_data.user,
											});
										});
										if (counter == items.length) {

											batch.commit().then(data => {
												resolve(true);
											}, err => {
												console.log(err);
												reject();
											});
										}
									})
							} else {
								counter++;

								if (counter == items.length) {

									batch.commit().then(data => {
										resolve(true);
									}, err => {
										console.log(err);
										reject();
									});
								}

							}
						});
					}
				})
			} else {
				batch.commit().then(data => {
					resolve(true);
				}, err => {
					console.log(err);
					reject();
				});
			}
		})
	}

	annulItem(item, quantity, return_stock, note, message, order) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch()

			if (item.quantity - quantity < 1) {
				batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key));
			} else {
				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
					quantity: item.quantity - quantity
				});
			}

			let return_key = this.db.createId();
			let item_return = {
				ticket_item_key: item.$key,
				order_key: item.order_key,
				order_number: order.order_number,
				creation_date: new Date(),
				return_date: new Date(),
				user: this.auth.account_data.user,
				note: note,
				quantity: quantity,
				price: item.price,
				return_stock: return_stock,
				item: item,
				branch_key: this.auth.branch,
				branch: this.auth.branch_data,
				message: message
			}

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_returns`).doc(return_key), item_return);

			if (return_stock) {
				if (!item.is_promotion) {
					if (item.inventory_management) {
						this.getRef(`accounts/${this.auth.account}/items_branches`).ref
							.where('item_key', '==', item.item_key)
							.where('branch_key', '==', item.item_branch.branch_key)
							.get()
							.then(snapshots => {

								snapshots.forEach(element => {
									batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(element.id), {
										quantity: element.data().quantity + quantity
									});

									let inventory_record_key = this.db.createId();

									batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
										branch_key: this.auth.branch,
										branch: this.auth.branch_data,
										creation_date: new Date(),
										item_key: item.item_key,
										item: item,
										new_quantity: element.data().quantity + quantity,
										old_quantity: element.data().quantity,
										reason: 'annul_item',
										user: this.auth.account_data.user
									});

								});

								batch.commit().then(data => {
									resolve(true);
								}, err => {
									console.log(err);
									reject();
								});
							})
					}
				} else {
					item.x_items.concat(item.y_items).forEach(item => {
						if (item.inventory_management) {
							this.getRef(`accounts/${this.auth.account}/items_branches`).ref
								.where('item_key', '==', item.item_key)
								.where('branch_key', '==', item.item_branch.branch_key)
								.get()
								.then(snapshots => {

									snapshots.forEach(element => {
										batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(element.id), {
											quantity: element.data().quantity + quantity
										});

										let inventory_record_key = this.db.createId();

										batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
											branch_key: this.auth.branch,
											branch: this.auth.branch_data,
											creation_date: new Date(),
											item_key: item.item_key,
											item: item,
											new_quantity: element.data().quantity + item.quantity,
											old_quantity: element.data().quantity,
											reason: 'annul_item',
											user: this.auth.account_data.user
										});

									});

									batch.commit().then(data => {
										resolve(true);
									}, err => {
										console.log(err);
										reject();
									});
								})
						}
					});
				}
			} else {
				batch.commit().then(data => {
					resolve(true);
				}, err => {
					console.log(err);
					reject();
				});
			}
		})
	}

	covertOrderToCreditTicket(payment_data) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			let ticket = JSON.parse(JSON.stringify(payment_data.order));

			ticket.creation_date = payment_data.order.creation_date.toDate();
			ticket.status = 'credit';
			ticket.tip = payment_data.gran_tip;
			ticket.subtotal = payment_data.subtotal;
			ticket.total = payment_data.gran_total;
			ticket.discount = payment_data.gran_discount;
			ticket.taxes = payment_data.taxes;
			ticket.hidden_taxes = payment_data.hidden_taxes;
			ticket.exempt = payment_data.exempt;
			ticket.completed_date = new Date();
			ticket.was_credit = true;

			batch.delete(this.db.firestore.collection(`accounts/${this.auth.account}/orders`).doc(payment_data.order.$key));

			payment_data.items.forEach(item => {
				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
					ticket_key: payment_data.order.$key,
					order_key: firebase.firestore.FieldValue.delete(),
					creation_date: new Date(),
					available: firebase.firestore.FieldValue.delete(),
					active: firebase.firestore.FieldValue.delete(),
					favorite_key: firebase.firestore.FieldValue.delete(),
					favorite_order: firebase.firestore.FieldValue.delete(),
					last_payment: firebase.firestore.FieldValue.delete(),
					ticket_creation_date: ticket.completed_date
				});
			});

			this.getRef(`accounts/${this.auth.account}/branches`).ref
				.doc(this.auth.branch)
				.get()
				.then(branch_snapshot => {
					let ticket_number = 1;
					let branch = branch_snapshot.data();
					ticket.address = branch.address;
					ticket.phone = branch.phone;

					if (branch_snapshot.data().fiscal !== undefined && Object.keys(branch.fiscal).length > 0) {

						if (ticket.tax_receipt.type == 'ticket') {

							if (branch.fiscal.current_ticket_number) {
								ticket_number = branch.fiscal.current_ticket_number + 1;
							}

							ticket_number = branch.fiscal.current_ticket_number + 1;

							let fiscal = {
								ticket: branch.fiscal,
								type: 'ticket',
								nit: this.auth.account_data.fiscal.nit,
								nrc: this.auth.account_data.fiscal.nrc,
								business_name: this.auth.account_data.fiscal.business_name,
								branch: branch.name
							};

							fiscal.ticket.current_ticket_number = ticket_number;
							fiscal.ticket.device_number = this.auth.device_number;

							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
								fiscal: fiscal.ticket
							});

							ticket.fiscal = fiscal;
						} else {
							if (branch.fiscal.current_invoice_number) {
								ticket_number = branch.fiscal.current_invoice_number + 1;
							}

							ticket_number = branch.fiscal.current_invoice_number + 1;

							ticket.fiscal = ticket.tax_receipt;
							ticket.fiscal.business_name = this.auth.account_data.fiscal.business_name;
							ticket.fiscal.nrc = this.auth.account_data.fiscal.nrc;
							ticket.fiscal.nit = this.auth.account_data.fiscal.nit;
							ticket.fiscal.branch = branch.name;

							if (this.auth.account_data.fiscal.type == 'dte') {
								if (ticket.tax_receipt.type == 'invoice') {
									ticket.fiscal.current_invoice_number = branch.fiscal.current_invoice_number + 1;
									let new_fiscal = branch.fiscal;
									new_fiscal.current_invoice_number = ticket.fiscal.current_invoice_number;
									batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
										fiscal: new_fiscal
									});
								} else if (ticket.tax_receipt.type == 'ccf') {
									ticket.fiscal.current_ccf_number = branch.fiscal.current_ccf_number + 1;
									let new_fiscal = branch.fiscal;
									new_fiscal.current_ccf_number = ticket.fiscal.current_ccf_number;
									batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
										fiscal: new_fiscal
									});
								}
							}
						}
						delete ticket.tax_receipt;
					} else {
						ticket.fiscal = false;
					}

					let total_discount = 0;
					let iva_discount = 0;
					if (payment_data.discounts) {
						payment_data.discounts.forEach(discount => {
							discount.order_key = firebase.firestore.FieldValue.delete();
							discount.ticket_key = payment_data.order.$key;
							discount.fiscal = ticket.fiscal;

							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_discounts`).doc(discount.$key), discount)
							total_discount += discount.total_discount;
							iva_discount += discount.iva_discount;
						});
					}

					if (ticket.fiscal && this.auth.account_data.fiscal.type == 'dte') {


						this.dte.getJson(ticket, payment_data.items, payment_data.discounts, []).then((json: any) => {
							this.dte.signDte(json).then(dteResponse => {

								ticket.fiscal_type = 'dte';
								ticket.fiscal.dte = {
									selloRecibido: dteResponse['selloRecibido'],
									codigoGeneracion: dteResponse['codigoGeneracion'],
									observaciones: dteResponse['observaciones'],
									json: json.dteJson
								}

								ticket.total = Number(ticket.total);
								ticket.have_dte = true;
								ticket.iva_discount = iva_discount;

								batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(payment_data.order.$key), ticket);

								batch.update(this.db.firestore.collection(`accounts`).doc(this.auth.account), { dte_counter: this.auth.account_data.dte_counter + 1 });

								this.updateReportForTicket(batch, ticket, [], payment_data.item);

								batch.commit().then(data => {
									resolve({ completed: true, ticket_key: payment_data.order.$key });
								}, err => {
									console.log(err);
									reject(false);
								});
							}, err => {
								console.log(err);
							})
						})
					} else {
						ticket.fiscal_type = 'standard';
						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(payment_data.order.$key), ticket);
						this.updateReportForTicket(batch, ticket, [], payment_data.items);

						batch.commit().then(data => {
							resolve({ completed: true, ticket_key: payment_data.order.$key });
						}, err => {
							console.log(err);
							reject();
						});
					}

				})


		})
	}

	makePaymentToCredit(ticket, pending, deposit, tip, method, gateway) {
		return new Promise((resolve, reject) => {

			let batch = this.db.firestore.batch();

			let completed = false;

			let pending_payment = Number(((pending.toFixed(2)) - Number(deposit + tip)).toFixed(2));

			if (pending_payment <= 0) {
				completed = true;
			}

			let payment_key = this.db.createId();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_payments`).doc(payment_key), {
				branch_key: this.auth.branch,
				branch: this.auth.branch_data,
				amount: deposit,
				tip: tip,
				total: Number((deposit + tip).toFixed(2)),
				user_key: this.auth.token,
				user: this.auth.account_data.user,
				creation_date: new Date(),
				method: method,
				gateway: gateway,
				ticket_key: ticket.$key,
				ticket_credit_payment: true,
				ticket_creation_date: ticket.completed_date,
			});

			if (completed) {
				batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(ticket.$key), {
					status: 'completed',
				});
			}

			batch.commit().then(data => {
				resolve({
					completed: completed
				});
			}, err => {
				reject();
			});


		})
	}

	isObjectEmpty(objectName) {
		for (let prop in objectName) {
			if (objectName.hasOwnProperty(prop)) {
				return false;
			}
		}
		return true;
	};

	refundTicket(ticket, note, message, return_stock, dte: any = false) {

		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();
			let items = [];

			this.getRef(`accounts/${this.auth.account}/branches`).ref
				.doc(this.auth.branch)
				.get()
				.then(branch_snapshot => {

					let refund_key = this.db.createId();

					let refund = {
						ticket_key: ticket.$key,
						creation_date: new Date(),
						user: this.auth.account_data.user,
						total: ticket.total,
						note: note,
						return_stock: return_stock,
						branch_key: this.auth.branch,
						branch: this.auth.branch_data,
						ticket: ticket,
						message: message,
					};

					if (!this.isObjectEmpty(branch_snapshot.data().fiscal) && branch_snapshot.data().fiscal) {

						if (!dte) {
							let ticket_number = 1;

							ticket_number = branch_snapshot.data().fiscal.current_ticket_number + 1;

							let fiscal = {
								business_name: this.auth.account_data.fiscal.business_name,
								ticket: branch_snapshot.data().fiscal,
								type: ticket.fiscal.type
							};

							fiscal.ticket.current_ticket_number = ticket_number;
							fiscal.ticket.device_number = this.auth.device_number;

							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
								fiscal: fiscal.ticket
							});

							refund['fiscal'] = fiscal;
						} else {
							refund['fiscal'] = {
								business_name: this.auth.account_data.fiscal.business_name,
								is_dte: true,
								dte: dte,
								ticket: branch_snapshot.data().fiscal,
								type: ticket.fiscal.type
							};
						}
					}

					this.auth.report.total_refunds += refund.total;
					let find_employee = this.auth.report.employees_data.findIndex(element => element.user_key == refund.ticket.user.$key)

					if (find_employee > -1) {
						this.auth.report.employees_data[find_employee].total_refunds += Number(refund.total);
						this.auth.report.employees_data[find_employee].refunds_tip += Number(refund.ticket.tip);
						this.auth.report.employees_data[find_employee].refunds++;
					} else {
						this.auth.report.employees_data.push({
							$key: refund.ticket.user.$key,
							user_key: refund.ticket.user.$key,
							user: refund.ticket.user,
							total_refunds: refund.total,
							refunds: 1,
							refunds_tip: refund.ticket.tip,
							name: `${refund.ticket.user.name + ' ' + refund.ticket.user.lastname}`
						});
					}

					if (this.auth.report.$key == undefined) {
						let report_key = this.db.createId();
						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/reports`).doc(report_key), this.auth.report);
					} else {
						batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/reports`).doc(this.auth.report.$key), this.auth.report);
					}


					batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_refunds`).doc(refund_key), refund);

					batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(ticket.$key), {
						status: 'refunded',
					});

					if (return_stock) {
						let counter = 0;

						ticket.items.forEach(item => {
							if (!item.is_promotion) {
								let item_element = JSON.parse(JSON.stringify(item));
								const found = items.findIndex(element => element.item_key == item_element.item_key);
								if (found < 0) {
									items.push(item_element);
								} else {
									items[found].quantity += item_element.quantity;
								}
								counter++;
							} else {
								let prom_counter = 0;
								item.x_items.concat(item.y_items).forEach(item_promo => {
									let item_element = JSON.parse(JSON.stringify(item_promo));
									const found = items.findIndex(element => element.item_key == item_element.item_key);
									if (found < 0) {
										items.push(item_element);
									} else {
										items[found].quantity += item_element.quantity;
									}
									prom_counter++;

									if (prom_counter == item.x_items.concat(item.y_items).length) {
										counter++;
									}
								})
							}

							if (counter == ticket.items.length) {
								counter = 0;
								items.forEach(item_element => {
									if (item_element.inventory_management) {
										this.getRef(`accounts/${this.auth.account}/items_branches`).ref
											.where('item_key', '==', item_element.item_key)
											.where('branch_key', '==', item_element.item_branch.branch_key)
											.get()
											.then(snapshots => {
												counter++;
												snapshots.forEach(item_branch => {
													let new_quantity = item_branch.data().quantity + item_element.quantity;
													batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(item_branch.id), {
														quantity: new_quantity
													});

													let inventory_record_key = this.db.createId();

													batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
														branch_key: this.auth.branch,
														branch: this.auth.branch_data,
														creation_date: new Date(),
														item_key: item_element.item_key,
														item: item_element,
														new_quantity: new_quantity,
														old_quantity: item_branch.data().quantity,
														reason: 'refund_ticket',
														user: this.auth.account_data.user
													});
												});
												if (counter == items.length) {
													batch.commit().then(data => {
														resolve(true);
													}, err => {
														console.log(err);
														reject();
													});
												}
											})
									} else {
										counter++;

										if (counter == items.length) {

											batch.commit().then(data => {
												resolve(true);
											}, err => {
												console.log(err);
												reject();
											});
										}

									}
								});
							}
						})
					} else {
						batch.commit().then(data => {
							resolve(true);
						}, err => {
							console.log(err);
							reject();
						});
					}


				})

		})
	}

	refundItem(ticket, item, note, message, quantity, return_stock) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			this.getRef(`accounts/${this.auth.account}/branches`).ref
				.doc(this.auth.branch)
				.get()
				.then(branch_snapshot => {

					let to_zero = ticket.total - ticket.total_refunds;
					to_zero = to_zero - (item.price * quantity);
					this.getDocument(`accounts/${this.auth.account}/users`, this.auth.token).then(user => {

						let refund_key = this.db.createId();

						let item_refund = {
							ticket_item_key: item.$key,
							ticket_key: item.ticket_key,
							creation_date: new Date(),
							user: user,
							note: note,
							quantity: quantity,
							price: item.price,
							return_stock: return_stock,
							branch_key: this.auth.branch,
							branch: this.auth.branch_data,
							ticket: ticket,
							item: item,
							message: message
						}

						if (branch_snapshot.data().fiscal) {
							let ticket_number = 1;

							ticket_number = branch_snapshot.data().fiscal.current_ticket_number + 1;

							let fiscal = {
								business_name: this.auth.account_data.fiscal.business_name,
								ticket: branch_snapshot.data().fiscal,
								type: 'ticket'
							};

							fiscal.ticket.current_ticket_number = ticket_number;
							fiscal.ticket.device_number = this.auth.device_number;

							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/branches`).doc(this.auth.branch), {
								fiscal: fiscal.ticket
							});

							item_refund['fiscal'] = fiscal;

						}

						batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/items_refunds`).doc(refund_key), item_refund);

						batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/ticket_items`).doc(item.$key), {
							have_refund: true,
							quantity: item.quantity - quantity
						});

						if (to_zero > 0) {
							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(item.ticket_key), {
								have_items_refunds: true,
							});
						} else {
							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/tickets`).doc(item.ticket_key), {
								have_items_refunds: true,
								status: 'refunded',
							});
						}

						this.auth.report.total_refunds += item_refund.quantity * item_refund.price;
						let find_employee = this.auth.report.employees_data.findIndex(element => element.user_key == item_refund.ticket.user.$key)

						if (find_employee > -1) {
							this.auth.report.employees_data[find_employee].total_refunds += Number(item_refund.quantity * item_refund.price);
							this.auth.report.employees_data[find_employee].refunds++;
						} else {
							this.auth.report.employees_data.push({
								$key: item_refund.ticket.user.$key,
								user_key: item_refund.ticket.user.$key,
								user: item_refund.ticket.user,
								total_refunds: item_refund.quantity * item_refund.price,
								refunds: 1,
								refunds_tip: 0,
								name: `${item_refund.ticket.user.name + ' ' + item_refund.ticket.user.lastname}`
							});
						}

						if (this.auth.report.$key == undefined) {
							let report_key = this.db.createId();
							batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/reports`).doc(report_key), this.auth.report);
						} else {
							batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/reports`).doc(this.auth.report.$key), this.auth.report);
						}

						if (return_stock) {
							if (!item.is_promotion) {

								if (item.inventory_management) {
									this.getRef(`accounts/${this.auth.account}/items_branches`).ref
										.doc(item.item_branch.$key)
										.get()
										.then(snapshot => {
											if (snapshot.exists) {
												let item_branch = snapshot.data();
												item_branch.$key = snapshot.id;
												let new_quantity = item_branch.quantity + quantity;

												batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(item_branch.$key), {
													quantity: new_quantity
												});

												let inventory_record_key = this.db.createId();

												batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
													branch_key: this.auth.branch,
													branch: this.auth.branch_data,
													creation_date: new Date(),
													item_key: item.$key,
													item: item,
													new_quantity: new_quantity,
													old_quantity: item_branch.quantity,
													reason: 'refund_from_ticket',
													user: this.auth.account_data.user,
												});

												batch.commit().then(data => {
													item_refund['$key'] = refund_key;
													resolve(item_refund);
												}, err => {
													console.log(err);
													reject();
												});
											} else {
												this.components.showAlert('Error al regresar al inventario', 'Al parecer este item ya no existe en tus productos por lo cual no puedes realizar la devolución del inventario', ['Aceptar'])
												reject();
											}
										})
								} else {
									batch.commit().then(data => {
										item_refund['$key'] = refund_key;
										resolve(item_refund);
									}, err => {
										console.log(err);
										reject();
									});
								}
							} else {
								let prom_counter = 0;
								let items = [];

								item.x_items.concat(item.y_items).forEach(item_promo => {
									let item_element = JSON.parse(JSON.stringify(item_promo));
									const found = items.findIndex(element => element.item_key == item_element.item_key);

									if (found < 0) {
										items.push(item_element);
									} else {
										items[found].quantity += item_element.quantity;
									}

									prom_counter++;

									if (prom_counter == item.x_items.concat(item.y_items).length) {
										let counter = 0;
										items.forEach(item_element => {
											if (item_element.inventory_management) {
												this.getRef(`accounts/${this.auth.account}/items_branches`).ref
													.doc(item_element.item_branch.$key)
													.get()
													.then(snapshot => {
														let item_branch = snapshot.data();
														item_branch.$key = snapshot.id;
														let new_quantity = item_branch.quantity + item_element.quantity;
														counter++;

														batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(item_branch.$key), {
															quantity: new_quantity
														});

														let inventory_record_key = this.db.createId();

														batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(inventory_record_key), {
															branch_key: this.auth.branch,
															branch: this.auth.branch_data,
															creation_date: new Date(),
															item_key: item_element.item_key,
															item: item_element,
															new_quantity: new_quantity,
															old_quantity: item_branch.quantity,
															reason: 'refund_from_ticket',
															user: this.auth.account_data.user
														});

														batch.commit().then(data => {
															item_refund['$key'] = refund_key;
															resolve(item_refund);
														}, err => {
															console.log(err);
															reject();
														});

													})
											}
										});
									}
								})
							}
						} else {
							batch.commit().then(data => {
								item_refund['$key'] = refund_key;
								resolve(item_refund);
							}, err => {
								reject();
							});
						}
					})

				});
		});
	}

	returnToStock(reason) {
	}

	deletePayment(order, payment) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

		})
	}

	makeTransfer(item, from_branch, to_branch) {
		return new Promise((resolve, reject) => {
			let batch = this.db.firestore.batch();

			batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(from_branch.$key), {
				quantity: from_branch.new_quantity
			});

			let invertory_key1 = this.db.createId();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(invertory_key1), {
				old_quantity: from_branch.quantity,
				new_quantity: from_branch.new_quantity,
				creation_date: new Date(),
				reason: 'transfer',
				item_key: from_branch.item_key,
				item: item,
				branch_key: this.auth.branch_data.$key,
				branch: this.auth.branch_data,
				user: this.auth.account_data.user
			});

			batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/items_branches`).doc(to_branch.$key), {
				quantity: to_branch.new_quantity
			});

			let invertory_key2 = this.db.createId();

			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/inventory_record`).doc(invertory_key2), {
				old_quantity: to_branch.quantity,
				new_quantity: to_branch.new_quantity,
				creation_date: new Date(),
				reason: 'transfer',
				item_key: to_branch.item_key,
				item: item,
				branch_key: to_branch.branch.$key,
				branch: to_branch.branch,
				user: this.auth.account_data.user
			});

			batch.commit().then(data => {
				resolve(true);
			}, err => {
				reject(err);
			})

		})
	}

	updateReportForTicket(batch, ticket, all_payments, items) {
		this.auth.report.total_orders++;
		this.auth.report.total_taxes += Number(ticket.taxes + ticket.hidden_taxes);
		this.auth.report.total_discounts += Number(ticket.discount);
		if (ticket.iva_discount !== undefined) {
			this.auth.report.total_discounts += ticket.iva_discount;
		}
		this.auth.report.total_sales += Number(ticket.total);
		this.auth.report.total_tip += Number(ticket.tip);

		if (ticket.was_credit) {
			this.auth.report.total_credit += Number(ticket.total);
		}

		let date_string = this.components.dateToString(ticket.completed_date);
		let find = this.auth.report.sales_data.findIndex(element => element.name == date_string)

		if (find > -1) {
			this.auth.report.sales_data[find].value += Number(ticket.total) - Number(ticket.tip);
			this.auth.report.sales_data[find].mens += Number(ticket.mens);
			this.auth.report.sales_data[find].womens += Number(ticket.womens);
			this.auth.report.sales_data[find].childrens += Number(ticket.childrens);
		} else {
			this.auth.report.sales_data.push({
				name: date_string,
				value: Number(ticket.total) - Number(ticket.tip),
				mens: Number(ticket.mens),
				womens: Number(ticket.womens),
				childrens: Number(ticket.childrens),
			})
		}

		let date = ticket.completed_date;
		let hour = this.auth.report.sales_heatmap.find(element => element.name == date.getHours() + ':00');

		if (hour === undefined) {
			let data_data = []

			let day_conv;
			if (date.getDay() == 0) {
				day_conv = 6;
			} else {
				day_conv = date.getDay() - 1;
			}

			data_data.push({
				day: day_conv,
				total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
			})

			this.auth.report.sales_heatmap.push({
				name: date.getHours() + ':00',
				hours: date.getHours(),
				time_data: data_data
			})

		} else {

			let day_conv;
			if (date.getDay() == 0) {
				day_conv = 6;
			} else {
				day_conv = date.getDay() - 1;
			}

			let day = hour.time_data.find(element => element.day == day_conv);

			if (day === undefined) {
				hour.time_data.push({
					day: day_conv,
					total: Number(ticket.total.toFixed(2) - Number(ticket.tip))
				})
			} else {
				day.total += ticket.total - Number(ticket.tip);
			}
		}

		let find_type = this.auth.report.types_data.findIndex(element => element.type == ticket.type)

		if (find_type > -1) {
			this.auth.report.types_data[find_type].total += Number(ticket.total) - Number(ticket.tip);
			this.auth.report.types_data[find_type].quantity += 1;
		} else {
			this.auth.report.types_data.push({
				type: ticket.type,
				total: Number(ticket.total) - Number(ticket.tip),
				quantity: 1,
				name: `${ticket.type}`
			});
		}

		let find_employee = this.auth.report.employees_data.findIndex(element => element.user_key == ticket.user.$key)

		let endDate: any = ticket.completed_date;
		let startDate: any = ticket.creation_date;

		let hours =
			(Math.abs(endDate.getTime() - startDate.getTime()) /
				(1000 * 60 * 60)) %
			24;
		let minutes =
			(Math.abs(endDate.getTime() - startDate.getTime()) /
				(1000 * 60)) %
			60;
		let seconds =
			(Math.abs(endDate.getTime() - startDate.getTime()) / 1000) % 60;

		if (!ticket.discount) {
			ticket.discount = 0;
		}

		if (find_employee >= 0) {
			this.auth.report.employees_data[find_employee].total += Number(ticket.total) - Number(ticket.tip);
			this.auth.report.employees_data[find_employee].tip += Number(ticket.tip);
			this.auth.report.employees_data[find_employee].quantity += 1;
			this.auth.report.employees_data[find_employee].discount += ticket.discount;
			this.auth.report.employees_data[find_employee].tickets += 1;
			this.auth.report.employees_data[find_employee].times.push(`${parseInt(hours.toString())}:${parseInt(minutes.toString())}:${parseInt(seconds.toString())}`);

			if (ticket.type == 'tables') {
				this.auth.report.employees_data[find_employee].tables += 1;
			}

			let find = this.auth.report.employees_data[find_employee].sales_data.findIndex(element => element.name == date_string)

			if (find > -1) {
				this.auth.report.employees_data[find_employee].sales_data[find].value += Number(ticket.total) - Number(ticket.tip);
			} else {
				this.auth.report.employees_data[find_employee].sales_data.push({
					name: date_string,
					value: Number(ticket.total) - Number(ticket.tip)
				})
			}

			let date_employee = ticket.completed_date;
			let hour_employee;

			if (Array.isArray(this.auth.report.employees_data[find_employee].sales_heatmap)) {
				hour_employee = this.auth.report.employees_data[find_employee].sales_heatmap.find(element => element.name == date_employee.getHours() + ':00');
			}

			if (hour_employee === undefined) {
				let data_data = []

				let day_conv;
				if (date_employee.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date_employee.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
				})

				this.auth.report.employees_data[find_employee].sales_heatmap.push({
					name: date_employee.getHours() + ':00',
					hours: date_employee.getHours(),
					time_data: data_data
				})

			} else {

				let day_conv;
				if (date_employee.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date_employee.getDay() - 1;
				}

				let day = hour_employee.time_data.find(element => element.day == day_conv);

				if (day === undefined) {
					hour_employee.time_data.push({
						day: day_conv,
						total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
					})
				} else {
					day.total += ticket.total - Number(ticket.tip);
				}
			}

		} else {
			let user = ticket.user;

			delete user.admin_roles;
			delete user.branches;
			delete user.branches;
			delete user.extra;
			delete user.kds;
			delete user.occupied;
			delete user.pos;
			delete user.pos_roles;
			delete user.pos_roles;

			let date_employee = ticket.completed_date;

			let data_data = []

			let day_conv;
			if (date_employee.getDay() == 0) {
				day_conv = 6;
			} else {
				day_conv = date_employee.getDay() - 1;
			}

			data_data.push({
				day: day_conv,
				total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
			})

			this.auth.report.employees_data.push({
				$key: ticket.user.$key,
				user_key: ticket.user.$key,
				user: user,
				discount: ticket.discount,
				tickets: 1,
				tables: 0,
				refunds: 0,
				total_refunds: 0,
				refunds_tip: 0,
				times: [`${parseInt(hours.toString())}:${parseInt(minutes.toString())}:${parseInt(seconds.toString())}`],
				total: Number(ticket.total) - Number(ticket.tip),
				tip: Number(ticket.tip),
				quantity: 1,
				name: `${ticket.user.name + ' ' + ticket.user.lastname}`,
				sales_data: [{
					name: date_string,
					value: Number(ticket.total) - Number(ticket.tip)
				}],
				sales_heatmap: [{
					name: date.getHours() + ':00',
					hours: date.getHours(),
					time_data: data_data
				}]
			});

		}

		if (ticket.type == 'delivery') {
			let date_delivery = ticket.completed_date;

			this.auth.report.total_deliveries += ticket.total;
			this.auth.report.total_deliveries_orders += 1;

			let date_string = this.components.dateToString(ticket.completed_date);

			let find_delivery = this.auth.report.deliveries_data.findIndex(element => element.name == ticket.delivery.name);

			if (find_delivery > -1) {
				this.auth.report.deliveries_data[find_delivery].total += Number(ticket.total);
				this.auth.report.deliveries_data[find_delivery].tickets += 1;

				let find_date = this.auth.report.deliveries_data[find_delivery].sales_data.findIndex(element => element.name == date_string);

				if (find_date > -1) {
					this.auth.report.deliveries_data[find_delivery].sales_data[find_date].value += Number(ticket.total);
				} else {
					this.auth.report.deliveries_data[find_delivery].sales_data.push({
						name: date_string,
						value: Number(ticket.total)
					})
				}

				let hour_delivery;

				if (Array.isArray(this.auth.report.deliveries_data[find_delivery].sales_heatmap)) {
					hour_delivery = this.auth.report.deliveries_data[find_delivery].sales_heatmap.find(element => element.name == date_delivery.getHours() + ':00');
				}

				if (hour_delivery === undefined) {
					let data_data = []

					let day_conv;
					if (date_delivery.getDay() == 0) {
						day_conv = 6;
					} else {
						day_conv = date_delivery.getDay() - 1;
					}

					data_data.push({
						day: day_conv,
						total: Number(ticket.total.toFixed(2))
					})

					this.auth.report.deliveries_data[find_delivery].sales_heatmap.push({
						name: date_delivery.getHours() + ':00',
						hours: date_delivery.getHours(),
						time_data: data_data
					})

				} else {

					let day_conv;
					if (date_delivery.getDay() == 0) {
						day_conv = 6;
					} else {
						day_conv = date_delivery.getDay() - 1;
					}

					let day = hour_delivery.time_data.find(element => element.day == day_conv);

					if (day === undefined) {
						hour_delivery.time_data.push({
							day: day_conv,
							total: Number(ticket.total.toFixed(2))
						})
					} else {
						day.total += ticket.total;
					}
				}

			} else {

				let data_data = []

				let day_conv;
				if (date_delivery.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date_delivery.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.total.toFixed(2))
				})

				this.auth.report.deliveries_data.push({
					name: ticket.delivery.name,
					total: Number(ticket.total),
					tickets: 1,
					sales_data: [{
						name: date_string,
						value: Number(ticket.total)
					}],
					sales_heatmap: [{
						name: date_delivery.getHours() + ':00',
						hours: date_delivery.getHours(),
						time_data: data_data
					}]
				})
			}

			let find_date = this.auth.report.deliveries_sales.findIndex(element => element.name == date_string);

			if (find_date > -1) {
				this.auth.report.deliveries_sales[find_date].value += Number(ticket.total);
			} else {
				this.auth.report.deliveries_sales.push({
					name: date_string,
					value: Number(ticket.total)
				})
			}

			let hour_delivery;

			if (Array.isArray(this.auth.report.deliveries_heatmap)) {
				hour_delivery = this.auth.report.deliveries_heatmap.find(element => element.name == date_delivery.getHours() + ':00');
			}

			if (hour_delivery === undefined) {
				let data_data = []

				let day_conv;
				if (date_delivery.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date_delivery.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.total.toFixed(2))
				})

				this.auth.report.deliveries_heatmap.push({
					name: date_delivery.getHours() + ':00',
					hours: date_delivery.getHours(),
					time_data: data_data
				})

			} else {

				let day_conv;
				if (date_delivery.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date_delivery.getDay() - 1;
				}

				let day = hour_delivery.time_data.find(element => element.day == day_conv);

				if (day === undefined) {
					hour_delivery.time_data.push({
						day: day_conv,
						total: Number(ticket.total.toFixed(2))
					})
				} else {
					day.total += ticket.total;
				}
			}

		}

		if (ticket.type == 'tables') {

			// HOMBRES
			let found_mens = this.auth.report.clients_data.findIndex((element) => element.key == 'mens');
			this.auth.report.clients_data[found_mens].quantity += ticket.mens;

			let hour_mens;

			if (Array.isArray(this.auth.report.clients_data[found_mens].sales_heatmap)) {
				hour_mens = this.auth.report.clients_data[found_mens].sales_heatmap.find(element => element.name == date.getHours() + ':00');
			}

			if (hour_mens === undefined) {
				let data_data = []

				let day_conv;
				if (date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.mens) - Number(ticket.tip)
				})

				this.auth.report.clients_data[found_mens].sales_heatmap.push({
					name: date.getHours() + ':00',
					hours: date.getHours(),
					time_data: data_data
				})

			} else {

				let day_conv;
				if (date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date.getDay() - 1;
				}

				let day = hour_mens.time_data.find(element => element.day == day_conv);

				if (day === undefined) {
					hour_mens.time_data.push({
						day: day_conv,
						total: Number(ticket.mens)
					})
				} else {
					day.total += ticket.mens;
				}
			}

			// WOMENS
			let found_womens = this.auth.report.clients_data.findIndex((element) => element.key == 'womens');
			this.auth.report.clients_data[found_womens].quantity += ticket.womens;

			let hour_womens;

			if (Array.isArray(this.auth.report.clients_data[found_womens].sales_heatmap)) {
				hour_womens = this.auth.report.clients_data[found_womens].sales_heatmap.find(element => element.name == date.getHours() + ':00');
			}

			if (hour_womens === undefined) {
				let data_data = []

				let day_conv;
				if (date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.womens)
				})

				this.auth.report.clients_data[found_womens].sales_heatmap.push({
					name: date.getHours() + ':00',
					hours: date.getHours(),
					time_data: data_data
				})

			} else {

				let day_conv;
				if (date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date.getDay() - 1;
				}

				let day = hour_womens.time_data.find(element => element.day == day_conv);

				if (day === undefined) {
					hour_womens.time_data.push({
						day: day_conv,
						total: Number(ticket.womens)
					})
				} else {
					day.total += ticket.womens;
				}
			}

			// CHILDRENS

			let found_childrens = this.auth.report.clients_data.findIndex((element) => element.key == 'childrens');
			this.auth.report.clients_data[found_childrens].quantity += ticket.childrens;

			let hour_childrens;

			if (Array.isArray(this.auth.report.clients_data[found_childrens].sales_heatmap)) {
				hour_childrens = this.auth.report.clients_data[found_childrens].sales_heatmap.find(element => element.name == date.getHours() + ':00');
			}

			if (hour_childrens === undefined) {
				let data_data = []

				let day_conv;
				if (date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.childrens)
				})

				this.auth.report.clients_data[found_childrens].sales_heatmap.push({
					name: date.getHours() + ':00',
					hours: date.getHours(),
					time_data: data_data
				})

			} else {

				let day_conv;
				if (date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date.getDay() - 1;
				}

				let day = hour_childrens.time_data.find(element => element.day == day_conv);

				if (day === undefined) {
					hour_childrens.time_data.push({
						day: day_conv,
						total: Number(ticket.childrens)
					})
				} else {
					day.total += ticket.childrens;
				}
			}

			this.auth.report.total_tables += ticket.total - Number(ticket.tip);
			this.auth.report.total_clients += Number(ticket.number_persons);
			this.auth.report.mens += Number(ticket.mens);
			this.auth.report.womens += Number(ticket.womens);
			this.auth.report.childrens += Number(ticket.childrens);

			let date_table = ticket.completed_date;


			let table_found = this.auth.report.tables_data.findIndex((element) => element.name == ticket.table);


			if (table_found >= 0) {
				this.auth.report.tables_data[table_found].total += Number(ticket.total) - Number(ticket.tip);
				this.auth.report.tables_data[table_found].tickets += 1;
				this.auth.report.tables_data[table_found].persons += Number(ticket.number_persons);
				this.auth.report.tables_data[table_found].mens += Number(ticket.mens);
				this.auth.report.tables_data[table_found].childrens += Number(ticket.childrens);
				this.auth.report.tables_data[table_found].womens += Number(ticket.womens);
				this.auth.report.tables_data[table_found].tips += Number(ticket.tip);
				this.auth.report.tables_data[table_found].times.push(`${parseInt(hours.toString())}:${parseInt(minutes.toString())}:${parseInt(seconds.toString())}`);

				let find = this.auth.report.tables_data[table_found].sales_data.findIndex(element => element.name == date_string)

				if (find > -1) {
					this.auth.report.tables_data[table_found].sales_data[find].value += Number(ticket.total) - Number(ticket.tip);
				} else {
					this.auth.report.tables_data[table_found].sales_data.push({
						name: date_string,
						value: Number(ticket.total) - Number(ticket.tip)
					})
				}

				let hour_table;

				if (Array.isArray(this.auth.report.tables_data[table_found].sales_heatmap)) {
					hour_table = this.auth.report.tables_data[table_found].sales_heatmap.find(element => element.name == date.getHours() + ':00');
				}

				if (hour_table === undefined) {
					let data_data = []

					let day_conv;
					if (date.getDay() == 0) {
						day_conv = 6;
					} else {
						day_conv = date.getDay() - 1;
					}

					data_data.push({
						day: day_conv,
						total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
					})

					this.auth.report.tables_data[table_found].sales_heatmap.push({
						name: date.getHours() + ':00',
						hours: date.getHours(),
						time_data: data_data
					})

				} else {

					let day_conv;
					if (date.getDay() == 0) {
						day_conv = 6;
					} else {
						day_conv = date.getDay() - 1;
					}

					let day = hour_table.time_data.find(element => element.day == day_conv);

					if (day === undefined) {
						hour_table.time_data.push({
							day: day_conv,
							total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
						})
					} else {
						day.total += ticket.total - Number(ticket.tip);
					}
				}

			} else {

				let data_data = []

				let day_conv;
				if (date_table.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = date_table.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(ticket.total.toFixed(2)) - Number(ticket.tip)
				})

				this.auth.report.tables_data.push({
					name: ticket.table,
					area: ticket.table_area,
					total: Number(ticket.total) - Number(ticket.tip),
					tickets: 1,
					persons: Number(ticket.number_persons),
					mens: Number(ticket.mens),
					womens: Number(ticket.womens),
					childrens: Number(ticket.childrens),
					tips: ticket.tip,
					times: [`${parseInt(hours.toString())}:${parseInt(minutes.toString())}:${parseInt(seconds.toString())}`],
					sales_data: [{
						name: date_string,
						value: Number(ticket.total) - Number(ticket.tip)
					}],
					sales_heatmap: [{
						name: date_table.getHours() + ':00',
						hours: date_table.getHours(),
						time_data: data_data
					}],
				});
			}

		}

		all_payments.forEach(payment => {
			let date_string = this.components.dateToString(payment.creation_date);;

			let find = this.auth.report.payment_methods.findIndex(element => element.name == payment.method);

			if (find >= 0) {
				this.auth.report.payment_methods[find].total += Number(payment.total);
				this.auth.report.payment_methods[find].quantity += 1;

				if (payment.gateway) {
					if (this.auth.report.payment_methods[find].gateways == undefined) {
						this.auth.report.payment_methods[find].gateways = [{
							quantity: 1,
							name: payment.gateway,
							total: payment.total
						}];
					} else {
						let find_gateway = this.auth.report.payment_methods[find].gateways.findIndex(element => element.name == payment.gateway);

						if (find_gateway >= 0) {
							this.auth.report.payment_methods[find].gateways[find_gateway].total += payment.total;
							this.auth.report.payment_methods[find].gateways[find_gateway].quantity++;
						} else {
							this.auth.report.payment_methods[find].gateways.push({
								quantity: 1,
								name: payment.gateway,
								total: payment.total
							});
						}
					}
				}

				let find_date = this.auth.report.payment_methods[find].sales_data.findIndex(element => element.name == date_string);

				if (find_date >= 0) {
					this.auth.report.payment_methods[find].sales_data[find_date].total += payment.total;
				} else {
					this.auth.report.payment_methods[find].sales_data.push({
						name: date_string,
						total: payment.total
					})
				}

				let hour_table;

				if (Array.isArray(this.auth.report.payment_methods[find].sales_heatmap)) {
					hour_table = this.auth.report.payment_methods[find].sales_heatmap.find(element => element.name == payment.creation_date.getHours() + ':00');

					if (hour_table === undefined) {
						let data_data = []

						let day_conv;
						if (payment.creation_date.getDay() == 0) {
							day_conv = 6;
						} else {
							day_conv = payment.creation_date.getDay() - 1;
						}

						data_data.push({
							day: day_conv,
							total: Number(payment.total.toFixed(2))
						})

						this.auth.report.payment_methods[find].sales_heatmap.push({
							name: payment.creation_date.getHours() + ':00',
							hours: payment.creation_date.getHours(),
							time_data: data_data
						})

					} else {

						let day_conv;
						if (payment.creation_date.getDay() == 0) {
							day_conv = 6;
						} else {
							day_conv = payment.creation_date.getDay() - 1;
						}

						let day = hour_table.time_data.find(element => element.day == day_conv);

						if (day === undefined) {
							hour_table.time_data.push({
								day: day_conv,
								total: Number(payment.total.toFixed(2))
							})
						} else {
							day.total += payment.total;
						}
					}

				}

			} else {

				let data_data = []

				let day_conv;
				if (payment.creation_date.getDay() == 0) {
					day_conv = 6;
				} else {
					day_conv = payment.creation_date.getDay() - 1;
				}

				data_data.push({
					day: day_conv,
					total: Number(payment.total.toFixed(2))
				})

				let report_data = {
					name: payment.method,
					quantity: 1,
					total: Number(payment.total),
					sales_data: [{
						name: date_string,
						total: payment.total
					}],
					sales_heatmap: [{
						name: payment.creation_date.getHours() + ':00',
						hours: payment.creation_date.getHours(),
						time_data: data_data
					}],
				}
				if (payment.gateway) {
					report_data['gateways'] = [{
						name: payment.gateway,
						total: payment.total,
						quantity: 1
					}]
				}
				this.auth.report.payment_methods.push(report_data)
			}

		});

		items.forEach(product => {
			product.creation_date = ticket.completed_date;

			if (product.is_promotion) {
				product.x_items.forEach(element => {
					let product_x = element;
					product_x.creation_date = product.creation_date;
					this.components.getProductReports(this.auth.report, product_x);
				})
				product.y_items.forEach(element => {
					let product_y = element;
					product_y.creation_date = product.creation_date;
					this.components.getProductReports(this.auth.report, product_y);
				})
			} else {
				if (!product.miscellaneous) {
					product.current_quantity = product.item_branch.quantity;
				}
				this.components.getProductReports(this.auth.report, product);
			}
		})

		this.auth.report.products.sort(function (a, b) {
			var orderA = a.total, orderB = b.total;
			return orderB - orderA;
		});

		if (this.auth.report.$key == undefined) {
			let report_key = this.db.createId();
			batch.set(this.db.firestore.collection(`accounts/${this.auth.account}/reports`).doc(report_key), this.auth.report);
		} else {
			batch.update(this.db.firestore.collection(`accounts/${this.auth.account}/reports`).doc(this.auth.report.$key), this.auth.report);
		}

	}
}

