// This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const environment = {
	production: false,
	api_url: "",
	payment_url: "",
	cloudinary: {
		api_key: '321842221971572',
		cloud_name: 'ddqvtwvdk',
		api_secret: 'ICDezxj5Oqm0cHRrZ8jeKT6IAUk',
		upload: {
			url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/upload',
		},
		delete: {
			url: 'https://api.cloudinary.com/v1_1/ddqvtwvdk/image/destroy',
		}
	},
	algolia: {
		appId: 'TPGQ58F06T',
		apiKey: 'c6f1c3ae667b761bd025f08625659e03'
	},
	emails_url: 'https://us-central1-quanto-develop.cloudfunctions.net/emails-emails',
	api: 'https://sandbox.quantopos.com',
	mh: {
		environment: '00',
		url: 'https://apitest.dtes.mh.gob.sv'
	}
};


export const firebaseConfig = {
	apiKey: "AIzaSyBmreAMoptKaFVLWQb_oOl4agWhFR-KVA4",
	authDomain: "quanto-sandbox.firebaseapp.com",
	projectId: "quanto-sandbox",
	storageBucket: "quanto-sandbox.appspot.com",
	messagingSenderId: "451046662365",
	appId: "1:451046662365:web:9ca520c09b78cb719a3b34"
}


