
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globalization } from '@ionic-native/globalization/ngx';

@Injectable({
    providedIn: 'root'
})
export class TranslateConfigService {

    constructor(
        private translate: TranslateService,
        private globalization: Globalization
    ) { }

    getDeviceLanguage() {
        return new Promise((resolve, reject) => {
            this.globalization.getPreferredLanguage().then(res => {
                let lang = res.value.substr(0, res.value.indexOf('-'))
                resolve(lang);
            }).catch(e => {
                reject(e);
            });
        })

    }

    getDefaultLanguage() {
        let language = this.translate.getBrowserLang();
        this.translate.setDefaultLang(language);
        return language;
    }

    setLanguage(setLang) {
        this.translate.use(setLang);
    }

}
