import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-link-payment',
	templateUrl: './link-payment.component.html',
	styleUrls: ['./link-payment.component.scss'],
})
export class LinkPaymentComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public components: ComponentsService
	) { }

	ngOnInit() { }

	copyLink() {
		this.components.showToast('Enlace copiado', 'Enlace copiado correctamente');
	}
}
