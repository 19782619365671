import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-countdown-timer',
	templateUrl: './countdown-timer.component.html',
	styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit {
	countdown: any = 30;
	constructor(
		public components: ComponentsService,
		public modalController: ModalController
	) { }

	ngOnInit() {

		setInterval(() => {
			if (this.countdown > 0) {
				this.countdown -= 1;
			}
		}, 1000);
	}

}
