import { AmountBoardComponent } from './../amount-board/amount-board.component';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';

@Component({
	selector: 'app-select-payment-method',
	templateUrl: './select-payment-method.component.html',
	styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		public components: ComponentsService
	) {

	}

	ngOnInit() {
	}

	async openAmountBoard(payment_type) {
		const modal = await this.modalController.create({
			component: AmountBoardComponent,
			cssClass: 'pay-board-modal',
			componentProps: {
				type: payment_type,
				client: this.navParams.get('client')
			}
		});


		modal.onDidDismiss().then((data) => {
			if (data.data) {
				this.modalController.dismiss();
				this.showGiftcard();
			}
		});

		await modal.present();
	}

	showGiftcard() {
		console.log('aqui aparece la giftcard');
	}
}
