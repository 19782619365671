import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { AutoLoginGuard } from './guards/autoLogin/auto-login.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { AutoCodeGuard } from 'src/app/guards/autoCode/auto-code.guard';


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
	},
	{
		path: 'main',
		loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
		canLoad: [AuthGuard]
	},
	{
		path: 'code',
		loadChildren: () => import('./pages/auth/code/code.module').then(m => m.CodePageModule),
		canLoad: [AuthGuard]
	},
	{
		path: 'register',
		loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule),
		canLoad: [AutoLoginGuard]
	},
	{
		path: 'register/:code',
		loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule),
		canLoad: [AutoLoginGuard]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
		}),
		TranslateModule
	],
	exports: [
		RouterModule,
	]
})
export class AppRoutingModule { }
