import { EventEmitter, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import { isEqual } from 'lodash';
import { ComponentsService } from '../components/components.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class PosService {

	public AddItemEvent: EventEmitter<number> = new EventEmitter();
	public UpdateItemEvent: EventEmitter<number> = new EventEmitter();
	public RemoveItemEvent: EventEmitter<number> = new EventEmitter();
	public cancelOrderEvent: EventEmitter<number> = new EventEmitter();
	public removeItems: EventEmitter<number> = new EventEmitter();
	public addedOrder: EventEmitter<number> = new EventEmitter();
	public updateOrder: EventEmitter<number> = new EventEmitter();
	public changeTypeEvent: EventEmitter<number> = new EventEmitter();
	public changeFiscalType: EventEmitter<number> = new EventEmitter();

	items: any = [];
	discounts: any = [];
	promotions: any = [];
	client: any = false;
	delivery: any = false;
	note: any = false;
	splitted = 0;
	splitted_payments = 0;
	items_count: any = 0;
	ticket_open: any = false;
	mens: any = 0;
	womens: any = 0;
	childrens: any = 0;
	type: any = '';
	table: any = '';
	table_area: any = '';
	tax_receipt: any = { type: 'ticket' };
	exempt: any = false;
	order: any;
	scanner_open: any = false;

	constructor(
		public db: AngularFirestore,
		public components: ComponentsService,
		public alertController: AlertController,
		public router: Router
	) {
	}

	/**
	 * If the item is not found in the array, push it. If it is found, check if the notes are the same. If
	 * they are, add the quantity. If they are not, push it
	 * @param item_element - The item that was clicked on the menu.
	 * @param item - The item that is being added to the cart.
	 */
	addItem(item) {
		item = this.checkPromotion(item);
		if (item.is_variant) {
			item.category = item.parent.category;
		}

		item.is_promotion = false;
		item.kds_type = '';

		const found = this.items.findIndex(element => element.item_key == item.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers));

		if (found > -1) {
			this.items[found].quantity += item.quantity;
		} else {
			this.items.push(item);
		}

		this.items_count += item.quantity;

		this.AddItemEvent.emit(item);
	}

	updateItem(item, new_note = false) {
		const found = this.items.findIndex(element => item.item_key == element.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers));
		if (new_note) item.notes = new_note;
		this.items[found] = item;
		console.log(item);

		this.UpdateItemEvent.emit();
	}

	updateItemPrice(item) {
		const found = this.items.findIndex(element => (element.item_key == item.item_key));
		this.items[found] = item;
		this.UpdateItemEvent.emit();
	}

	/**
	 * The addMiscellaneous function takes an item as a parameter, pushes it to the items array, and emits
	 * the AddItemEvent
	 * @param item - The item to be added to the list.
	 */
	addMiscellaneous(item) {
		this.items.push(item);
		this.AddItemEvent.emit();
	}

	/**
	 * The addMiscellaneous function takes an item as a parameter, pushes it to the items array, and emits
	 * the AddItemEvent
	 * @param item - The item to be added to the list.
	 */
	removeItem(item) {
		const found = this.items.findIndex(element => element.item_key == item.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion);
		this.items_count -= this.items[found].quantity;
		this.items.splice(found, 1);
		this.components.showToast('Producto eliminado correctamente.');
		if (item.have_promotion && item.promotion.usage_limits) {
			const found_promo = this.promotions.findIndex(_element => _element.$key == item.promotion.$key);
			this.promotions[found_promo].usage -= item.quantity;
		}
		this.removeItems.emit(item);
	}

	/**
	 * It clears the type, table, persons, and items variables, and then emits the cancelOrderEvent
	 */
	cancelOrder(order) {
		return new Promise(async (resolve, reject) => {
			if (this.items.length > 0 || this.client) {
				const alert = await this.alertController.create({
					header: 'Tienes datos sin guardar',
					message: `¿Seguro que deseas salir sin guardar los items?. Esta acción no se puede revertir.`,
					buttons: [
						{
							text: 'Cancelar',
							role: 'cancel'
						},
						{
							text: 'Eliminar',
							role: 'destructive',
							handler: () => {
								console.log(order);

								if (order == undefined) {
									this.items.forEach(element => {
										if (element.have_promotion && element.promotion.usage_limits) {
											const found_promo = this.promotions.findIndex(_element => _element.$key == element.promotion.$key);
											this.promotions[found_promo].usage -= element.quantity;
										}

										this.removeItems.emit(element);
									});
									this.cancelOrderEvent.emit();
									this.resetAll();
								} else {
									this.items.forEach(element => {
										if (element.have_promotion && element.promotion.usage_limits) {
											const found_promo = this.promotions.findIndex(_element => _element.$key == element.promotion.$key);
											this.promotions[found_promo].usage -= element.quantity;
										}
										this.removeItems.emit(element);
									});

									this.resetAll();
								}
								resolve(true);
							}
						}
					],
				});
				await alert.present();
			} else {
				this.resetAll();
				this.cancelOrderEvent.emit();
				this.router.navigate(['pos']);
				resolve(true);
			}
		})
	}

	clearOrder(order) {
		if (order == undefined) {
			this.type = '';
			this.note = '';
			this.table = '';
			this.mens = '';
			this.womens = '';
			this.childrens = '';
			this.items = [];
			this.items_count = 0;
			this.discounts = [];
			this.client = false;
			this.tax_receipt = { type: 'ticket' };
			this.exempt = false;
			this.ticket_open = false;
			this.cancelOrderEvent.emit();
		} else {
			this.note = '';
			this.items = [];
			this.items_count = 0;
			this.client = false;
			this.discounts = [];
			this.tax_receipt = { type: 'ticket' };
			this.exempt = false;
			this.ticket_open = false;
			this.removeItems.emit();
		}
	}

	/**
	 * When the order is created, the event is emitted
	 */
	orderCreated() {
		this.addedOrder.emit();
	}

	changeFiscal(fiscal) {
		this.changeFiscalType.emit(fiscal);
	}

	/**
	 * The function takes an order as an argument, and emits an event with the order as the payload
	 * @param order - The order object that we want to update.
	 */
	updateOrderTicket(order) {
		this.updateOrder.emit(order);
	}

	/**
	 * The addPromotion function takes an item as a parameter, pushes it to the items array, and emits the
	 * AddItemEvent event
	 * @param item - The item to be added to the cart.
	 */
	addPromotion(item) {
		this.items.push(item);
		this.AddItemEvent.emit();
	}

	/**
	 * It loops through all the promotions, checks if the promotion is disabled, if not, it checks if the
	 * promotion applies to all items or just a few, and if it applies to all items, it checks if the
	 * promotion is a percentage or a fixed amount, and then it applies the promotion to the item
	 * @param item - The item to check for promotions
	 * @returns The item is being returned.
	 */
	checkPromotion(item) {
		this.promotions.forEach((promotion, index) => {
			let valid = true;

			if (!item.have_promotion) item.real_price = item.price;

			//Validamos la promoción y vemos si aplica

			if (promotion.clients.length > 0) {
				if (this.client) {
					const found_client = promotion.clients.findIndex(_element => _element == this.client.$key);
					if (found_client < 0) valid = false;
				} else {
					valid = false
				}
			}

			if (new Date() < promotion.start_date || new Date() > promotion.end_date) {

				valid = false;
			}

			if (promotion.usage_limits) {
				if ((promotion.usage_limits - promotion.usage) == 0) {
					valid = false
				} else {
					this.promotions[index].usage += item.quantity;
				}
			}

			if (promotion.usage_order_limits) {
				let usage_order_limits = 1;
				this.items.forEach(element => {
					let item = element;
					if (item.have_promotion && (item.promotion.$key === promotion.$key)) {
						usage_order_limits += (item.quantity);
					}
				});

				if (this.order) {
					this.order.items.forEach(element => {
						let item = element;
						if (item.have_promotion && (item.promotion.$key === promotion.$key)) {
							usage_order_limits += (item.quantity);
						}
					});
				}

				if (usage_order_limits > promotion.usage_order_limits) {
					valid = false;
				}
			}

			if (valid) {
				if (!item.have_promotion) {
					if (promotion.apply_all) {
						item.have_promotion = true;
						item.promotion = promotion;
						if (promotion.percentage) {
							let price = item.price * (promotion.percentage / 100);
							item.price = item.price - price;
						} else if (promotion.amount) {
							let price = item.price - promotion.amount;
							item.price = price;
							if (item.price < 0) item.price = 0
						}
					} else {
						let found_item;
						if (item.is_variant) {
							found_item = promotion.items.findIndex(element => element.key == (element.type == 'category' ? item.parent.category.$key : (item.is_variant ? item.parent_key : item.item_key)));
						} else {
							found_item = promotion.items.findIndex(element => element.key == (element.type == 'category' ? item.category.$key : (item.is_variant ? item.parent_key : item.item_key)));
						}



						if (found_item > -1) {
							item.have_promotion = true;
							item.promotion = promotion;
							if (promotion.amount) {
								item.price = item.price - promotion.amount;
								if (item.price < 0) item.price = 0;
							} else {
								let price = item.price * (promotion.percentage / 100);
								item.price = item.price - price;
							}
						} else {

						}
					}
				}

			} else {
				delete item.promotion;
				item.have_promotion = false;
				item.price = item.real_price;
				delete item.real_price;
			}
		});

		return item;
	}

	/**
	 * The function takes a client object as a parameter, sets the client property of the class to the
	 * client object, and then loops through the items array and calls the checkPromotion function on each
	 * item
	 * @param client - The client object that you want to add to the cart.
	 */
	addClient(client) {
		this.client = client;
		this.items.forEach(item => {
			if (!item.have_promotion && !item.is_promotion) {
				this.checkPromotion(item);
			}
		});
		this.UpdateItemEvent.emit();
	}

	addDelivery(delivery) {
		this.delivery = delivery;
	}

	/**
	 * It removes the client property from the current instance of the class, and then loops through the
	 * items array and calls the checkPromotion function on each element
	 */
	removeClient() {
		this.client = false;
		this.items.forEach(item => {
			if (item.have_promotion) {
				this.checkPromotion(item);
			}
		});
		this.UpdateItemEvent.emit();
	}

	addDiscount(discount) {
		this.discounts.push(discount);
		this.AddItemEvent.emit();
	}

	updateDiscount(discount) {
		const found = this.discounts.findIndex(element => element.$key == discount.$key);
		this.discounts[found] = discount;
		this.UpdateItemEvent.emit();
	}

	removeDiscount(discount) {
		const found = this.discounts.findIndex(element => element.$key == discount.$key);
		this.discounts.splice(found, 1);
		this.components.showToast('Descuento eliminado correctamente.');
		this.RemoveItemEvent.emit();
	}

	resetAll() {
		this.items = [];
		this.discounts = [];
		this.client = false;
		this.note = false;
		this.splitted = 0;
		this.splitted_payments = 0;
		this.items_count = 0;
		this.ticket_open = false;
		this.mens = 0;
		this.womens = 0;
		this.childrens = 0;
		this.type = '';
		this.table = '';
		this.table_area = '';
		this.tax_receipt = { type: 'ticket' };
		this.exempt = false;
		this.order;
	}

	/**
	 * It changes the type of the component and emits an event.
	 * @param type - The type of the current view.
	 */
	changeType(type) {
		this.type = type;
		if (this.type != 'tables') {
			this.table = '';
		}
		this.changeTypeEvent.emit();
	}


}
