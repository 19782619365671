import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, Platform } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PrintService } from 'src/app/services/print/print.service';
import { CreditBoardComponent } from '../../payments/credit-board/credit-board.component';
import { ModalBaseComponent } from '../../shared/modal-base/modal-base.component';
import { AddCancelNoteComponent } from '../add-cancel-note/add-cancel-note.component';
import { CancelNotesComponent } from '../cancel-notes/cancel-notes.component';
import { MoreMethodsComponent } from '../../payments/more-methods/more-methods.component';
import { GiftcardComponent } from '../../payments/giftcard/giftcard.component';

@Component({
	selector: 'app-ticket-details',
	templateUrl: './ticket-details.component.html',
	styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit {

	ticket: any;
	email: any;
	pending: any = 0;
	unsubscribe: any;

	item_refunds: any = [];
	discounts: any = [];
	taxes: any = 0;
	total_discount: any = 0;
	iva_discount: any = 0;

	constructor(
		public navParams: NavParams,
		private api: ApiService,
		public components: ComponentsService,
		public modalController: ModalController,
		public alertController: AlertController,
		public print: PrintService,
		public auth: AuthService,
		public platform: Platform
	) {
		this.ticket = this.navParams.get('ticket');
		this.taxes = this.ticket.taxes + this.ticket.hidden_taxes - this.ticket.exempt;
		if (this.ticket.iva_rete == undefined) {
			this.ticket.iva_rete = 0;
		}

		if (this.ticket.discount > 0 && this.ticket.exempt == 0) {
			let totaliva = this.ticket.total / (1 + 0.13)
			this.taxes = this.components.fixed(this.ticket.total - this.components.fixed(totaliva));
		}
	}

	ngOnInit() {
		if (this.ticket.discount > 0) {
			this.api.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
				.where(`ticket_key`, '==', this.ticket.$key)
				.get()
				.then(snapshots => {
					snapshots.forEach(element => {
						let discount = element.data();
						discount.$key = element.id;
						discount.creation_date = discount.creation_date.toDate();
						if (discount.user == undefined) {
							this.api.getDocument(`accounts/${this.auth.account}/users`, discount.user_key).then(data => {
								discount.user = data;
							})
						}

						if (this.ticket.exempt == 0) {
							this.total_discount = discount.total_discount + discount.iva_discount;
							this.iva_discount += discount.iva_discount;
						} else {
							this.total_discount = discount.total_discount;
						}

						this.discounts.push(discount);
					});
				})
		}

		this.ticket.payments = [];
		if (this.ticket.status != 'returned') {
			this.ticket.items = [];

			if (this.ticket.status == 'credit') {
				this.pending = this.ticket.total;
				this.getPending();
			} else {
				this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
					.where('ticket_key', '==', this.ticket.$key)
					.get()
					.then(snapshots => {
						snapshots.forEach(element => {
							let payment = element.data();
							payment.$key = element.id;
							payment.creation_date = payment.creation_date.toDate();
							this.ticket.payments.push(payment);

						});
					}, err => {
						console.log(err);
					})
			}

			this.api.getRef(`accounts/${this.auth.account}/ticket_items`).ref
				.where('ticket_key', '==', this.ticket.$key)
				.get()
				.then(snapshots => {
					snapshots.forEach(element => {
						let item = element.data();
						item.$key = element.id;

						if (this.ticket.exempt > 0) {
							item.price = item.price - (item.hidden_taxes / item.quantity);
						}

						if (item.have_refund) {
							this.api.getRef(`accounts/${this.auth.account}/items_refunds`).ref
								.where('ticket_item_key', '==', item.$key)
								.get()
								.then(snapshots => {
									snapshots.forEach(element => {
										let refund = element.data();
										refund.creation_date = refund.creation_date.toDate()
										refund.$key = element.id;
										refund['item'] = item;

										this.item_refunds.push(refund);
									});
								})
						}
						this.ticket.items.push(item);
					});
				}, err => {
					console.log(err);
				});

		}
	}

	getPending() {
		this.ticket.payments = [];

		this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
			.where('ticket_key', '==', this.ticket.$key)
			.get()
			.then(snapshots => {
				snapshots.forEach(element => {
					let payment = element.data();
					payment.$key = element.id;
					payment.creation_date = payment.creation_date.toDate();
					this.ticket.payments.push(payment);
				});

				let pending = Number(this.ticket.total);
				this.ticket.payments.forEach(element => {
					pending -= element.amount;
				});

				this.pending = pending;
				if (this.pending <= 0) {
					this.ticket.status = 'completed';
				}

				console.log(this.pending);


			}, err => {
				console.log(err);
			})
	}

	sendEmail() {
		if (this.ticket.client) {
			this.components.showLoader('Enviando correo electrónico...').then(() => {
				this.api.sendEmail(this.ticket.$key).then(data => {
					this.components.showToast('Ticket enviado con exito');
					this.components.dismissLoader();
				}, err => {
					this.components.dismissLoader();
				})
			});
		} else {
			if (this.email) {
				this.components.showLoader('Enviando correo electrónico...').then(() => {
					this.api.sendEmail(this.ticket.$key, this.email).then(data => {
						this.components.showToast('Ticket enviado con exito');
						this.components.dismissLoader();
					}, err => {
						this.components.dismissLoader();
					})
				});
			} else {
				this.components.dismissLoader();
				this.components.showToast('Ingresa un correo electronico al cual deseas enviar el ticket', 'error')
			}
		}
	}

	async openEditTicketModal(item) {
		const modal = await this.modalController.create({
			component: AddCancelNoteComponent,
			cssClass: 'payment-modal',
			componentProps: {
				pre_item: item
			}
		});
		await modal.present();
	}

	printTicket() {
		if (this.ticket?.status != 'refunded') {
			if (!this.auth.license.membership.features.tickets.print) {
				this.components.showAlert('Actualiza tu licencia', 'Al actualizar tu licencia podrás imprimir ordenes.', ['Aceptar']);
			} else {
				let ticket = JSON.parse(JSON.stringify(this.ticket));

				ticket.items.forEach(item => {
					if (ticket.exempt > 0) {
						item.price = item.price + item.hidden_taxes + item.total_taxes
						item.price = (item.price) / item.quantity;
					}
				});
				ticket.completed_date = this.ticket.completed_date;
				ticket.creation_date = this.ticket.creation_date;
				ticket.fiscal = this.ticket.fiscal
				if (ticket.iva_discount == undefined) {
					ticket.iva_discount = 0;
				}

				if (this.platform.is('cordova')) {
					this.print.printTicket(ticket);
				} else {

					this.print.pdfTicket(ticket);
				}
			}
		} else {
			this.printTicketRefund();
		}
	}

	async openBoardPayment(payment_type, giftcard = false, other = false) {

		let data = {
			pending: this.pending,
			ticket: this.ticket,
			discounts: this.discounts,
			payment_type: payment_type,
			other: false
		}

		if (payment_type == 'giftcard') {
			data['giftcard'] = giftcard;
		}

		if (other) {
			data.other = true;
			data['other_payment'] = other;
		}

		const modal = await this.modalController.create({
			component: CreditBoardComponent,
			cssClass: 'pay-board-modal',
			componentProps: data
		});

		await modal.present();
		await modal.onDidDismiss().then((data) => {
			if (data.data) {
				if (data.data.completed) {
					this.getPending();
					this.ticket.status = 'completed';
					this.components.showToast('Pago realizado con exito');
				} else if (data.data.paid) {
					this.getPending();
					this.components.showToast('Pago realizado con exito');
				}

			}
		})
	}

	async openMoreMethods() {
		const modal = await this.modalController.create({
			component: ModalBaseComponent,
			cssClass: 'edit-item-modal',
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				rootPage: MoreMethodsComponent,
				params: {
				}
			}
		});

		modal.present();

		await modal.onDidDismiss().then((data) => {
			if (data.data) {
				this.openBoardPayment(data.data.method, false, data.data.method_data)
			}
		});
	}

	async giftCardPayment() {
		const modal = await this.modalController.create({
			component: GiftcardComponent,
			showBackdrop: true,
		});

		await modal.present();

		await modal.onDidDismiss().then((data) => {
			if (data.data) {
				this.openBoardPayment('giftcard', data.data);
			}
		});
	}

	async returnTicket() {
		if (this.auth.license.membership.features.refunds) {

			if (this.ticket.fiscal_type == 'dte') {

				const difference = new Date().getTime() - this.ticket.completed_date.getTime();
				const horas = difference / (1000 * 60 * 60);
				// if (horas < 24) {
				const alert = await this.alertController.create({
					header: 'Devolución de ticket',
					message: '¿Seguro que deseas hacer una devolución de el ticket?. Esta acción no se puede revertir.',
					buttons: [
						{
							text: 'Cancelar',
							role: 'cancel'
						},
						{
							text: 'Devolver ticket',
							role: 'destructive',
							id: 'confirm-button',
							handler: async () => {

								if (this.item_refunds.length > 0) {
									const alert = await this.alertController.create({
										header: 'No puedes realizar devolución de ticket',
										message: 'No puedes realizar esta devolución ya que tienes items que contienen devolución',
										buttons: ['Aceptar']

									});
									await alert.present();
								} else {
									const modal = await this.modalController.create({
										component: ModalBaseComponent,
										cssClass: 'ticket-options-modal',
										showBackdrop: true,
										backdropDismiss: true,
										componentProps: {
											rootPage: CancelNotesComponent,
											params: {
												ticket: this.ticket,
												type: 'refund_ticket'
											}
										}
									});
									await modal.present().then()

									await modal.onDidDismiss().then(data => {
										if (data.data) {
											if (data.data.ticket_refunded) {

												this.api.getRef(`accounts/${this.auth.account}/ticket_refunds`).ref
													.where('ticket_key', '==', this.ticket.$key)
													.get()
													.then(snapshots => {
														snapshots.forEach(element => {
															this.ticket.status = 'refunded';
															this.ticket.refund = element.data();
															this.ticket.refund.creation_date = this.ticket.refund.creation_date.toDate();
														});
													})
											}
										}

									})
								}
							}
						}
					]
				});

				await alert.present();

				// } else {
				// 	const alert = await this.alertController.create({
				// 		header: 'No puedes realizar devolución',
				// 		message: 'No puedes realizar la devolución del ticket debido a que ya pasaron más de 2 hras',
				// 	})
				// 	await alert.present();
				// }

			} else {
				const alert = await this.alertController.create({
					header: 'Devolución de ticket',
					message: '¿Seguro que deseas hacer una devolución de el ticket?. Esta acción no se puede revertir.',
					buttons: [
						{
							text: 'Cancelar',
							role: 'cancel'
						},
						{
							text: 'Devolver ticket',
							role: 'destructive',
							id: 'confirm-button',
							handler: async () => {

								if (this.item_refunds.length > 0) {
									const alert = await this.alertController.create({
										header: 'No puedes realizar devolución de ticket',
										message: 'No puedes realizar esta devolución ya que tienes items que contienen devolución',
										buttons: ['Aceptar']

									});
									await alert.present();
								} else {
									const modal = await this.modalController.create({
										component: ModalBaseComponent,
										cssClass: 'ticket-options-modal',
										showBackdrop: true,
										backdropDismiss: true,
										componentProps: {
											rootPage: CancelNotesComponent,
											params: {
												ticket: this.ticket,
												type: 'refund_ticket'
											}
										}
									});
									await modal.present().then()

									await modal.onDidDismiss().then(data => {
										if (data.data) {
											if (data.data.ticket_refunded) {

												this.api.getRef(`accounts/${this.auth.account}/ticket_refunds`).ref
													.where('ticket_key', '==', this.ticket.$key)
													.get()
													.then(snapshots => {
														snapshots.forEach(element => {
															this.ticket.status = 'refunded';
															this.ticket.refund = element.data();
															this.ticket.refund.creation_date = this.ticket.refund.creation_date.toDate();
														});
													})
											}
										}

									})
								}
							}
						}
					]
				});

				await alert.present();
			}
		} else {
			this.components.showToast('Debes mejorar tu plan para realizar devoluciones.');
		}
	}

	async refundItem(item) {
		const alert = await this.alertController.create({
			header: 'Devolución de item',
			message: '¿Seguro que deseas hacer una devolución de el item?. Esta acción no se puede revertir.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Devolver producto',
					role: 'destructive',
					id: 'confirm-button',
					handler: async () => {

						const modal = await this.modalController.create({
							component: ModalBaseComponent,
							cssClass: 'edit-item-modal',
							showBackdrop: true,
							backdropDismiss: true,
							componentProps: {
								rootPage: CancelNotesComponent,
								params: {
									item: item,
									ticket: this.ticket,
									type: 'refund_item'
								}
							}
						});

						await modal.present().then()

						await modal.onDidDismiss().then(data => {
							if (data.data) {
								if (data.data.item_refunded) {
									item.quantity = item.quantity - data.data.refund.quantity;
									item.have_refund = true;
									let refund = data.data.refund;
									refund['item'] = item;
									data.data.refund.creation_date = new Date();

									// if(this.ticket.total_refunds == 0){
									// 	this.ticket.total_refunds += data.data.refund.quantity * data.data.refund.price;
									// }
									this.item_refunds.push(data.data.refund);
								}
							}

						})

					}
				}
			]
		});

		await alert.present();
	}

	printRefundItem(_return) {
		console.log(_return);

		if (!this.auth.license.membership.features.tickets.print) {
			this.components.showAlert('Actualiza tu licencia', 'Al actualizar tu licencia podrás imprimir ordenes.', ['Aceptar']);
		} else {

			if (this.platform.is('cordova')) {
				this.print.printRefundItem(_return);
			} else {
				this.print.pdfRefundItem(_return);
			}
		}
	}

	printTicketRefund() {
		if (!this.auth.license.membership.features.tickets.print) {
			this.components.showAlert('Actualiza tu licencia', 'Al actualizar tu licencia podrás imprimir ordenes.', ['Aceptar']);
		} else {
			if (this.platform.is('cordova')) {
				this.print.printRefundTicket(this.ticket);
			} else {
				this.print.pdfRefundTicket(this.ticket);
			}

		}
	}

	printReturn(order) {
		let total = 0;
		let total_payments = 0;
		let gran_discount = 0;
		let hidden_taxes = 0;
		let taxes = 0;
		let tip = 0;

		let client: any = false;


		if (order.items) {
			order.items.forEach(element => {

				element.price = Number(element.price);

				if (element.is_promotion) {
					let promo = element;
					promo.quantity = 1;
					promo.total_taxes = 0;
					promo.hidden_taxes = 0;

					element.x_items.forEach(element_x => {

						if (element_x.taxes_item) {
							element_x.total_taxes = 0;
							element_x.hidden_taxes = 0;
							element_x.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
									element_x.total_taxes += (element_x.price) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_x.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_x.price * element_x.quantity) - _tax;
									_tax = Number(_tax);
									hidden_taxes += _tax;
									promo.hidden_taxes += _tax;
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					});

					element.y_items.forEach(element_y => {
						if (element_y.taxes_item) {
							element_y.total_taxes = 0;
							element_y.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
									element_y.total_taxes += (element_y.price) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_y.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_y.price * element_y.quantity) - _tax;
									_tax = Number(_tax);
									hidden_taxes += _tax;
									promo.hidden_taxes += _tax;
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					});

				} else {
					if (element.is_variant) {
						if (element.parent.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
							element.parent.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += _tax;
									hidden_taxes += _tax;
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					} else {
						if (element.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;

							element.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price) * (Number(tax.rate) / 100);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += _tax;
									hidden_taxes += _tax;
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					}
				}

				total += element.price * element.quantity;
			});
		} else {

		}


		if (order.client) {
			client = order.client;
		}

		total = Number(Number(total).toFixed(2));


		if (order.type == 'tables') {
			if (this.auth.branch_data.active_tip && !order.tip_removed) {
				if (this.auth.branch_data.include_taxes_tip) {
					tip = ((total * Number(this.auth.branch_data.tip)) / 100);
				} else {
					tip = (((total - (taxes + hidden_taxes)) * Number(this.auth.branch_data.tip)) / 100);
				}
				tip = Math.round(tip * 100) / 100
			} else {
				tip = 0;
			}
		} else {
			tip = 0;
		}

		if (order.discounts) {

			order.discounts.forEach(element => {
				if (element.type == 'percentage') {

					let discount = Number(Number(total * (Number(element.amount) / 100)).toFixed(2));
					element.total_discount = discount;
					total = total - discount;
					gran_discount += discount;
				} else if (element.type == 'fixed') {
					element.total_discount = element.amount;
					total = total - element.amount;
					gran_discount += element.amount;
				}

				if (total < 0) {
					total = 0;
				}
			});
		}

		console.log(order);

		if (this.platform.is('cordova')) {
			this.print.printOrder(order, order.amount, total, tip, gran_discount, true);
		} else {
			this.print.pdfOrder(order, order.amount, total, tip, gran_discount, true);
		}
	}

	async deletePayment(payment) {
		const alert = await this.alertController.create({
			header: `¿Estas seguro que deseas eliminar el pago de $${payment.total}?`,
			message: 'Si realizas esta accion no podras revertir.',
			buttons: [
				{
					text: 'Cancelar',
					role: 'role',
				},
				{
					text: 'Eliminar pago',
					role: 'destructive',
					id: 'confirm-button',
					handler: () => {
						this.components.showLoader('Eliminando pago...').then(() => {
							this.api.deleteDocument(`accounts/${this.auth.account}/ticket_payments`, payment.$key)
								.then((data) => {
									this.getPending();
									this.components.dismissLoader();
								});
						});
					},
				},
			],
		});
		await alert.present();
	}
}
