import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as currency from 'currency.js';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-credit-board',
	templateUrl: './credit-board.component.html',
	styleUrls: ['./credit-board.component.scss'],
})
export class CreditBoardComponent implements OnInit {

	slideOptions = { slidesPerView: 'auto', zoom: false, autoplay: false, loop: false };
	loading: any = false;
	ticket: any;
	amount: any = '';
	total: any;
	cash_options: any = [];
	wrong: any = false;
	deposit: any = 0;
	deposit_tip: any = 0;
	branch: any;
	step: any = 0;
	authorization: any = '';
	params: any;
	currency_amount: any = 0;
	payment_gateways: any = [];
	gateway: any = false;
	paying: any = false;

	constructor(
		public modalController: ModalController,
		public api: ApiService,
		public navParams: NavParams,
		public components: ComponentsService,
		public auth: AuthService
	) {
		this.params = this.navParams.data;

		this.ticket = this.navParams.data.ticket;
		this.total = this.navParams.data.pending;

		this.api.getDocument(`accounts/${this.auth.account}/branches`, this.ticket.branch_key).then(data => {
			this.branch = data;
		})
	}

	ngOnInit() {
		if (this.params.payment_type == 'card') {
			this.api.getAllDocuments(`accounts/${this.auth.account}/payment_gateways`)
				.then((data) => {
					if (data !== null) {
						this.payment_gateways = data;
					}
				});
		}
	}

	makePayment() {
		this.paying = true;
		let valid = true;
		let gateway = false;

		if (this.gateway) {
			gateway = this.gateway.name;
		}

		if (this.params.payment_type == 'card') {
			let pending = Math.round(this.params.pending * 100) / 100;
			if (this.currency_amount > pending) {
				valid = false;
				this.components.showToast('El monto no puede ser mayor al pendiente de pago.', 'error');
			}
		}

		if (valid) {
			this.components.showLoader('Guardando cobro').then(() => {
				let deposit = 0;

				deposit = Number(this.deposit.toFixed(2));

				let tip = 0;
				tip = Number(this.deposit_tip.toFixed(2));

				this.api.makePaymentToCredit(this.ticket, this.params.pending, deposit, tip, this.params.payment_type, gateway).then(response => {
					if (response['completed']) {
						this.components.dismissLoader();
						this.modalController.dismiss({ completed: true });
					} else {
						this.components.dismissLoader();
						this.modalController.dismiss({ paid: true });
					}
				}, err => {
					console.log(err);
				});

			})
		}
	}

	activeGateway(event) {
		this.gateway = event.detail.value;
	}


	setNumber(number) {
		if (this.amount == Number(this.params.pending.toString())) {
			this.amount = '';
		}
		this.amount += number;
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
		this.changeTip();
	}

	clearAmount() {
		this.amount = '';
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
		this.changeTip();
	}

	setOption(value) {
		let amount = Number(this.amount);
		amount += Number(value);
		this.amount = amount.toString();

		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
		this.changeTip();
	}

	removeNumber() {
		this.amount = this.amount.substring(0, this.amount.length - 1);
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
		this.changeTip();
	}

	changeTip() {
		let amount = this.currency_amount;

		if (this.currency_amount > this.total) {
			amount = this.total;
		}

		if (this.branch.active_tip && this.ticket.type == 'tables') {

			if (this.ticket.tip_removed) {
				this.deposit = Number(amount);
				this.deposit_tip = 0;
			} else {

				if (this.ticket.discount > 0 || this.ticket.exempt > 0) {
					this.deposit_tip = amount * (this.branch.tip / 100);
					this.deposit = Number(amount) - Number(Number(this.deposit_tip).toFixed(2));

				} else {
					this.deposit = amount / (this.branch.tip / 100 + 1);
					this.deposit_tip = Number(amount) - Number(Number(this.deposit).toFixed(2));
				}

			}
		} else {
			this.deposit = Number(amount);
		}
	}

	setTotal() {
		this.amount = this.params.pending.toString();
		this.currency_amount = currency(this.amount);
		this.changeTip();
	}
}
