import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import * as currency from 'currency.js';
import { ApiService } from './../../../services/api/api.service';
import { AuthService } from './../../../services/auth/auth.service';
import { ComponentsService } from './../../../services/components/components.service';
import { PosService } from './../../../services/pos/pos.service';

@Component({
	selector: 'app-amount-board',
	templateUrl: './amount-board.component.html',
	styleUrls: ['./amount-board.component.scss'],
})
export class AmountBoardComponent implements OnInit {

	loading: any = false;
	order: any;
	amount: any = '';
	cash_options: any = [];
	payment_type: any;
	currency_amount: any = 0;
	client: any;
	step: any = 0;
	payment_gateways: any;
	email: any;
	gateway: any = false;
	to: any = '';

	constructor(
		public modalController: ModalController,
		public api: ApiService,
		public navParams: NavParams,
		public components: ComponentsService,
		public auth: AuthService,
		public pos: PosService
	) {
		this.payment_type = this.navParams.get('type');
		this.client = this.navParams.get('client');
		this.email = this.client.email;
	}

	ngOnInit() {
	}

	setNumber(number) {
		this.amount += number;
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
	}

	clearAmount() {
		this.amount = '';
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
	}

	setOption(value) {
		let amount = Number(this.amount);
		amount += Number(value);
		this.amount = amount.toString();
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
	}

	removeNumber() {
		this.amount = this.amount.substring(0, this.amount.length - 1);
		this.currency_amount = currency(this.amount, { fromCents: true, precision: 2 });
	}

	makePayment() {
		this.components.showLoader('Guardando cobro...').then(() => {
			if (this.gateway) {
				this.gateway = this.gateway.name;
			}
			if (this.to !== '') {
				this.api.makeGiftCardPayment(this.gateway, this.email, this.client, this.payment_type, this.currency_amount.value, this.to).then(data => {
					this.components.showToast('La giftcard fue creada y enviada con exito.');
					this.components.dismissLoader();
					this.modalController.dismiss(true);
				}, err => {
					this.components.dismissLoader();
				})
			} else {
				this.components.dismissLoader();
				this.components.showToast('Debes de ingresar de parte de quien es la Gift card', 'error');
			}
		});
	}

	canMakePayment() {
		this.loading = true;

		if (this.payment_type == 'card') {
			this.api.getAllDocuments(`accounts/${this.auth.account}/payment_gateways`).then(data => {
				this.payment_gateways = data;
				this.step = 1;
				this.loading = false;
			})
		} else {
			this.step = 1;
			this.loading = false;
		}
	}

	activeGateway(event) {
		this.gateway = event.detail.value;
	}
}
