import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AuthService } from './../../../services/auth/auth.service';

@Component({
	selector: 'app-return-item',
	templateUrl: './return-item.component.html',
	styleUrls: ['./return-item.component.scss'],
})
export class ReturnItemComponent implements OnInit {

	item: any;
	quantity: any = 1;
	note: any = '';
	message: any = '';
	return_stock: any = false;
	other: any = false;
	ticket: any;
	order: any;

	constructor(
		public navParams: NavParams,
		public components: ComponentsService,
		private api: ApiService,
		public modalController: ModalController,
		public auth: AuthService
	) {
		this.item = JSON.parse(JSON.stringify(navParams.data.item));
		this.note = JSON.parse(JSON.stringify(navParams.data.reason));
		this.order = JSON.parse(JSON.stringify(navParams.data.order));
	}

	ngOnInit() { }

	changeQuantity(type) {
		if (type == 'add') {
			if (this.quantity + 1 > this.item.quantity) {
				this.components.showToast('Limite de items para anular');
			} else {
				this.quantity++;
			}

		}
		else if (type == 'minus') {
			if (this.quantity - 1 < 1) {
				this.components.showToast('Para anular debes seleccionar al menos uno');
			} else {
				this.quantity--;
			}

		}
	};

	returnItem() {
		this.components.showLoader('Realizando devolución...').then(() => {
			this.api.annulItem(this.item, this.quantity, this.return_stock, this.note, this.message, this.order).then(response => {
				this.components.dismissLoader();
				this.modalController.dismiss({ item_refunded: true, refund: response });
			});
		});
	}

}
