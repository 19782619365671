import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { ComponentsService } from '../../../services/components/components.service';

@Component({
	selector: 'app-modify-price',
	templateUrl: './modify-price.component.html',
	styleUrls: ['./modify-price.component.scss'],
})
export class ModifyPriceComponent implements OnInit {

	item: any;
	apply_to: any = 'all';
	note: any;
	new_price: any;

	constructor(
		public modalController: ModalController,
		public components: ComponentsService,
		public navParams: NavParams,
		public api: ApiService,
		public auth: AuthService,
		public pos: PosService,
		public nav: IonNav
	) {
		this.item = this.navParams.get('item');
		this.note = this.item.notes;
		if (this.item.notes == undefined) {
			this.item.notes = '';
		}
		console.log(this.item.notes);


	}

	ngOnInit() { }

	editPrice() {

		if (this.note) {
			if (!this.item.order_key) {

				if (this.apply_to == 'all' || (this.item.quantity == 1 && this.apply_to == 1)) {
					this.item.price = Number(this.new_price);
					this.item.notes = this.note;
					this.pos.updateItemPrice(this.item);
					this.components.showToast('Precio actualizado correctamente.', 'success');
					this.modalController.dismiss();
				} else {
					const found = this.pos.items.findIndex(element => element.item_key == this.item.item_key);
					this.pos.items[found].quantity = this.item.quantity - this.apply_to;
					this.item.price = Number(this.new_price);
					this.item.quantity = Number(this.apply_to);
					this.item.notes = this.note;
					this.pos.addItem(this.item);
					this.components.showToast('Precio actualizado correctamente.', 'success');
					this.modalController.dismiss();
				}
			} else {
				if (this.apply_to == 'all' || (this.item.quantity == 1 && this.apply_to == 1)) {
					this.item.price = Number(this.new_price);
					this.item.notes = this.note;
					this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, this.item).then(() => {
						this.components.showToast('Precio actualizado correctamente.', 'success');
						this.modalController.dismiss();
					}, err => {
						console.log(err);
					})
				} else {
					this.item.quantity = this.item.quantity - this.apply_to;
					this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, this.item).then(() => {
						this.item.quantity = this.apply_to;
						this.item.price = Number(this.new_price);
						this.item.notes = this.note;
						this.api.addDocument(`accounts/${this.auth.account}/ticket_items`, this.item).then(() => {
							this.components.showToast('Precio actualizado correctamente.', 'success');
							this.modalController.dismiss();
						}, err => {
							console.log(err);
						})
					}, err => {
						console.log(err);

					})
				}
			}
		} else {
			this.components.showToast('Debes de ingresar una nota para poder cambiar el precio.', 'error');
		}

		// this.item.note = this.note;

		// if (this.apply_to == 'all' || (this.item.quantity == 1 && this.apply_to == 1)) {
		// 	this.api.updateDocument(this.where, this.item.$key, this.item).then(() => {
		// 		this.components.showToast('Precio actualizado correctamente.', 'success');
		// 		this.modalController.dismiss();
		// 	},err=>{
		// 		console.log(err);

		// 	})
		// } else {
		// 	this.item.quantity = this.item.quantity - this.apply_to;
		// 	this.api.updateDocument(this.where, this.item.$key, this.item).then(() => {
		// 		this.item.quantity = this.apply_to;
		// 		this.api.addDocument(`accounts/${this.auth.account}/pre_tickets`, this.item).then(() => {
		// 			this.components.showToast('Precio actualizado correctamente.', 'success');
		// 			this.modalController.dismiss();
		// 		},err=>{
		// 			console.log(err);

		// 		})
		// 	},err=>{
		// 		console.log(err);

		// 	})
		// }
	}
}
