import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { DeliveryDataComponent } from '../delivery-data/delivery-data.component';
import algoliasearch from 'algoliasearch';
import { environment } from '../../../../environments/environment';
import { AddDeliveryComponent } from '../../deliveries/add-delivery/add-delivery.component';
const client = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);

@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss'],
})
export class DeliveriesComponent implements OnInit {

  deliveries: any = [];
  index: any = client.initIndex(`deliveries`);
  loading: any = false;
  last_data: any;

  constructor(
    private api: ApiService,
    public auth: AuthService,
    public modalController: ModalController,
    public navParams: NavParams,
    public components: ComponentsService
  ) {
  }

  ngOnInit() {
    this.api.getRef(`accounts/${this.auth.account}/deliveries`).ref
      .orderBy('creation_date', 'desc')
      .limit(20)
      .get()
      .then(snapshots => {
        this.loadData(snapshots);
        if (snapshots.size < 10) {
          this.last_data = 'finished';
        }
      });
  }

  async addCustomer() {
    if (!this.components.isModalPresent) {
      this.components.isModalPresent = true;
      const modal = await this.modalController.create({
        component: AddDeliveryComponent,
        cssClass: 'auto-height',
        animated: true,
        backdropDismiss: true
      });

      await modal.present().then(data => {
        this.components.isModalPresent = false;
      })

      modal.onDidDismiss().then(data => {
        if (data.data) {
          this.selectDelivery(data.data);
        }
      })
    }
  }

  async selectDelivery(delivery) {
    const modal = await this.modalController.create({
      component: DeliveryDataComponent,
      cssClass: 'auto-height',
      animated: true,
      backdropDismiss: true,
      componentProps: {
        delivery
      }
    });

    await modal.present();

    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.modalController.dismiss(data.data);
      }
    })
  }

  loadData(snapshots) {
    this.last_data = snapshots.docs[snapshots.docs.length - 1];

    snapshots.forEach(element => {
      let delivery = element.data();
      delivery.$key = element.id;
      this.deliveries.push(delivery);
    });
    this.loading = false;
  }

  hits: any = [];

  search(e) {
    this.last_data = false;
    this.loading = true;
    let query = e.detail.value;
    this.deliveries = [];

    if (query !== '') {
      this.index.search(query, {
        filters: `(account_key:${this.auth.account}) AND type:delivery`
      }).then(({ hits }) => {
        this.hits = hits;

        hits.forEach(element => {
          let delivery_search = element;
          delivery_search.$key = element.objectID;
          console.log(delivery_search);

          this.api.getRef(`accounts/${this.auth.account}/deliveries`).ref
            .doc(delivery_search.$key)
            .onSnapshot(snapshot => {
              let delivery = snapshot.data();
              delivery.$key = snapshot.id;
              this.deliveries.push(delivery);
            })
        });

        this.loading = false;
      });
    } else {
      this.deliveries = [];
      this.ngOnInit();
    }
  }
}
