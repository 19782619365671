import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonItemSliding, ModalController, PopoverController, Platform } from '@ionic/angular';
import * as firebase from 'firebase';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { PrintService } from 'src/app/services/print/print.service';
import { PaymentMethodComponent } from '../../payments/payment-method/payment-method.component';
import { SuccessPaymentComponent } from '../../payments/success-payment/success-payment.component';
import { ModalBaseComponent } from '../../shared/modal-base/modal-base.component';
import { NumberComponent } from '../../tables/number/number.component';
import { ClientsComponent } from '../clients/clients.component';
import { EditItemComponent } from '../edit-item/edit-item.component';
import { TicketOptionsComponent } from '../ticket-options/ticket-options.component';
import { TransferTicketsComponent } from './../../tables/transfer-tickets/transfer-tickets.component';
import { DeliveriesComponent } from '../deliveries/deliveries.component';
import { EditClientComponent } from '../../clients/edit-client/edit-client.component';

@Component({
	selector: 'app-ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit, OnChanges {
	@ViewChild(IonItemSliding) item_slides: IonItemSliding;
	@Input() order_pos: any;
	branch: any;
	order: any;
	total: any = 0;
	total_payments: any = 0;
	payments: any = [];
	client: any = false;
	loading: any = true;
	tip: any = 0;
	taxes: any = 0;
	iva: any = 0;
	gran_discount: any;
	hidden_taxes: any = 0;
	open_payment_modal = false;
	iva_discount: any = 0;
	iva_rete: any = 0;

	constructor(
		public popoverController: PopoverController,
		public modalController: ModalController,
		public api: ApiService,
		public auth: AuthService,
		public components: ComponentsService,
		public router: Router,
		public actvRoute: ActivatedRoute,
		public alertController: AlertController,
		public pos: PosService,
		public printer: PrintService,
		public platform: Platform
	) {
		/* This is a subscription to an event that is triggered when an item is added to the ticket. */
		this.pos.AddItemEvent.subscribe((x) => {
			this.getTotal();
		});

		/* This is a subscription to an event that is triggered when an item is updated to the ticket. */
		this.pos.UpdateItemEvent.subscribe((x) => {
			this.getTotal();
		});

		/* This is a subscription to an event that is triggered when an item is deleted to the ticket. */
		this.pos.RemoveItemEvent.subscribe((x) => {
			this.getTotal();
		});

		/* This is a subscription to an event that is triggered when an item is removed from the ticket. */
		this.pos.removeItems.subscribe((x) => {
			this.getTotal();
		});

		/* This is a subscription to an event that is triggered when an item is removed from the ticket. */
		this.pos.addedOrder.subscribe((x) => {
			// this.ngOnInit();
		});

		/* This is a subscription to an event that is triggered when an item is removed from the ticket. */
		this.pos.changeFiscalType.subscribe((fiscal_type) => {
			if (fiscal_type) {
				this.order.tax_receipt = fiscal_type;
			}
		});

		this.pos.changeFiscalType.subscribe((x) => {
			this.getTotal();
		});
	}

	/**
	 * We're getting the branch information from the database, then we're subscribing to the route
	 * parameters. If there's no order key, we're setting the order to false. If there is an order key,
	 * we're getting the order items and payments from the database
	 */
	ngOnInit() {
		this.loading = true;
		this.branch = this.auth.branch_data;

		if (!this.order_pos) {
			this.order = false;
			this.pos.client = false;
			this.pos.exempt = false;
			this.loading = false;
			this.getTotal();
		} else {
			this.order = this.order_pos;
			this.order.items = [];
			this.order.discounts = [];
			this.pos.delivery = this.order.delivery;
			this.pos.client = this.order.client;
			if (this.order.client && this.order.client.exempt) {
				this.pos.exempt = true;
			}

			this.api.getRef(`accounts/${this.auth.account}/ticket_items`).ref
				.where('order_key', '==', this.order.$key)
				.onSnapshot(snapshots => {
					snapshots.docChanges().forEach(element => {
						if (element.type == 'added') {
							let item = element.doc.data();
							item.$key = element.doc.id;
							if (item.is_promotion) {
								item.quantity = 1;
							}
							if (this.order.items) {
								const found = this.order.items.findIndex(element => element.$key == item.$key);
								if (found < 0) {
									this.order.items.push(item);
									this.pos.items_count += item.quantity;
								}
							}
							if (this.order.items) {
								this.order.items.sort(function (a, b) {
									var orderA = a.creation_date, orderB = b.creation_date;
									return orderA - orderB;
								});
							}
						} else if (element.type == 'modified') {
							let item = element.doc.data();
							item.$key = element.doc.id;
							const found = this.order.items.findIndex(element => element.$key == item.$key);
							this.order.items[found] = item;
							this.getTotal();
						} else if (element.type == 'removed') {
							let item = element.doc.data();
							item.$key = element.doc.id;
							const found = this.order.items.findIndex(element => element.$key == item.$key);
							this.order.items.splice(found, 1);
							this.getTotal();
						}
					});

					this.getTotal();
					this.pos.order = this.order;
					this.order.discounts = [];
					this.loading = false;

					this.api.getRef(`accounts/${this.auth.account}/ticket_discounts`).ref
						.where('order_key', '==', this.order.$key)
						.onSnapshot(snapshots => {
							snapshots.docChanges().forEach(element => {
								if (element.type == 'added') {
									let discount = element.doc.data();
									discount.$key = element.doc.id;
									this.order.discounts.push(discount);
									this.getTotal();
								} else if (element.type == 'modified') {
									let discount = element.doc.data();
									discount.$key = element.doc.id;
									this.order.discounts[element.oldIndex] = discount;
									this.getTotal();
								} else if (element.type == 'removed') {
									this.order.discounts.splice(element.oldIndex, 1);
									this.getTotal();
								}
							});

							this.api.getRef(`accounts/${this.auth.account}/ticket_payments`).ref
								.where('order_key', '==', this.order.$key)
								.orderBy('creation_date')
								.onSnapshot(snapshots => {
									snapshots.docChanges().forEach(element => {
										if (element.type == 'added') {
											let payment = element.doc.data();
											payment.$key = element.doc.id;
											if (payment.creation_date !== null) {
												payment.creation_date = payment.creation_date.toDate();
											}
											this.payments.push(payment);

											this.getTotal();
										}
									});

									if (this.open_payment_modal) {
										this.open_payment_modal = false;
										this.openPaymentModal();
									}
								}, err => {
									console.log(err);
								});
						});
				});
		};
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes.order_pos && !changes.order_pos.firstChange) {
			this.order_pos = changes.order_pos.currentValue;
			this.ngOnInit();
		};
	};

	/**
	 * It calculates the total amount of the order, the total amount of the payments, the total amount of
	 * the discounts, and the tip
	 */
	getTotal() {
		this.total = 0;
		this.total_payments = 0;
		this.gran_discount = 0;
		this.hidden_taxes = 0;
		this.taxes = 0;
		this.iva_discount = 0;
		this.pos.exempt = 0;

		if (this.order) {
			if (this.order.client && this.order.client.exempt) {
				this.pos.exempt = true;
			}

			this.order.items.forEach(element => {

				element.price = Number(element.price);
				if (element.is_promotion) {
					let promo = element;
					promo.quantity = 1;
					promo.total_taxes = 0;
					promo.hidden_taxes = 0;

					element.x_items.forEach(element_x => {

						if (element_x.is_variant) {
							if (element_x.parent.taxes_item) {
								element_x.total_taxes = 0;
								element_x.hidden_taxes = 0;
								element_x.parent.taxes_item.forEach(tax => {
									tax.rate = Number(tax.rate);

									if (!tax.included) {
										this.taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
										promo.total_taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
										if (tax.iva) {
											this.iva += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
										}
										promo.total_taxes = this.components.fixed(promo.total_taxes);
										this.taxes = this.components.fixed(this.taxes);
									} else {
										let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
										_tax = (element_x.price * element_x.quantity) - _tax;
										_tax = Number(_tax);
										promo.hidden_taxes += this.components.fixed(_tax);
										if (tax.iva) {
											this.iva += this.components.fixed(_tax);
										}
										this.hidden_taxes += this.components.fixed(_tax);
									}

								});

							} else {
								element_x.total_taxes = 0;
								element_x.hidden_taxes = 0;
							}
						} else {
							if (element_x.taxes_item) {
								element_x.total_taxes = 0;
								element_x.hidden_taxes = 0;
								element_x.taxes_item.forEach(tax => {
									tax.rate = Number(tax.rate);

									if (!tax.included) {
										this.taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
										element_x.total_taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
										promo.total_taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
										if (tax.iva) {
											this.iva += (element_x.price) * (Number(tax.rate) / 100);
										}
										promo.total_taxes = this.components.fixed(promo.total_taxes);
										this.taxes = this.components.fixed(this.taxes);
									} else {
										let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
										_tax = (element_x.price * element_x.quantity) - _tax;
										_tax = Number(_tax);
										this.hidden_taxes += this.components.fixed(_tax);
										promo.hidden_taxes += this.components.fixed(_tax);
										if (tax.iva) {
											this.iva += this.components.fixed(_tax);
										}
									}

								});
							} else {
								promo.total_taxes = 0;
								promo.hidden_taxes = 0;
							}
						}
					});

					element.y_items.forEach(element_y => {
						if (element_y.is_variant) {

							if (element_y.parent.taxes_item) {
								element_y.total_taxes = 0;
								element_y.hidden_taxes = 0;
								element_y.parent.taxes_item.forEach(tax => {
									tax.rate = Number(tax.rate);

									if (!tax.included) {
										this.taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
										promo.total_taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
										if (tax.iva) {
											this.iva += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
										}
										promo.total_taxes = this.components.fixed(promo.total_taxes);
										this.taxes = this.components.fixed(this.taxes);
									} else {
										let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
										_tax = (element_y.price * element_y.quantity) - _tax;
										_tax = Number(_tax);
										promo.hidden_taxes += this.components.fixed(_tax);
										if (tax.iva) {
											this.iva += this.components.fixed(_tax);
										}
										this.hidden_taxes += this.components.fixed(_tax);
									}

								});

							} else {
								element_y.total_taxes = 0;
								element_y.hidden_taxes = 0;
							}
						} else {
							if (element_y.taxes_item) {
								element_y.total_taxes = 0;
								element_y.taxes_item.forEach(tax => {

									tax.rate = Number(tax.rate);

									if (!tax.included) {
										this.taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
										element_y.total_taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
										promo.total_taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
										if (tax.iva) {
											this.iva += (element_y.price * element.quantity) * (Number(tax.rate) / 100);
										}

										promo.total_taxes = this.components.fixed(promo.total_taxes);

										this.taxes = this.components.fixed(this.taxes);
									} else {
										let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
										_tax = (element_y.price * element_y.quantity) - _tax;
										_tax = Number(_tax);
										this.hidden_taxes += this.components.fixed(_tax);
										promo.hidden_taxes += this.components.fixed(_tax);
										if (tax.iva) {
											this.iva += this.components.fixed(_tax);
										}
									}

								});
							} else {
								promo.total_taxes = 0;
								promo.hidden_taxes = 0;
							}
						}
					});

				} else {

					if (element.is_variant) {

						if (element.parent.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
							element.parent.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									this.taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price * element.quantity) * (Number(tax.rate) / 100);
									if (tax.iva) {
										this.iva += (element.price) * (Number(tax.rate) / 100);
									}
									element.total_taxes = this.components.fixed(element.total_taxes);
									this.taxes = this.components.fixed(this.taxes);
								} else {
									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);
									element.hidden_taxes += this.components.fixed(_tax);
									if (tax.iva) {
										this.iva += this.components.fixed(_tax);
									}
									this.hidden_taxes += this.components.fixed(_tax);
								}

							});

						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					} else {

						if (element.taxes_item) {
							element.total_taxes = 0;
							element.hidden_taxes = 0;

							element.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									this.taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
									element.total_taxes += (element.price * element.quantity) * (Number(tax.rate) / 100);
									if (tax.iva) {
										this.iva += (element.price) * (Number(tax.rate) / 100);
									}
									element.total_taxes = this.components.fixed(element.total_taxes);
									this.taxes = this.components.fixed(this.taxes);
								} else {

									let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element.price * element.quantity) - _tax;
									_tax = Number(_tax);

									element.hidden_taxes += this.components.fixed(_tax);
									if (tax.iva) {
										this.iva += this.components.fixed(_tax);
									}
									this.hidden_taxes += this.components.fixed(_tax);
								}

							});

						} else {
							if (element.miscellaneous && this.auth.account_data.fiscal) {

								let _tax = (element.price * element.quantity) / ((Number(13) * 0.01) + 1)

								_tax = (element.price * element.quantity) - _tax;
								_tax = Number(_tax);

								element.hidden_taxes = this.components.fixed(_tax);
								this.iva += this.components.fixed(_tax);
								this.hidden_taxes += this.components.fixed(_tax);

							} else {
								element.total_taxes = 0;
								element.hidden_taxes = 0;
							}
						}
					}
				}
				this.total += this.components.fixed(element.price * element.quantity);
			});

		} else {
			if (this.pos.client && this.pos.client.exempt) {
				this.pos.exempt = true;
			}
		}

		this.pos.items.forEach(element => {

			element.price = Number(element.price);

			if (element.is_promotion) {
				let promo = element;
				promo.quantity = 1;
				promo.total_taxes = 0;
				promo.hidden_taxes = 0;

				element.x_items.forEach(element_x => {
					element_x.real_price = Number(element_x.price);
					if (element_x.is_variant) {
						if (element_x.parent.taxes_item) {
							element_x.total_taxes = 0;
							element_x.hidden_taxes = 0;

							element_x.parent.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									this.taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
									promo.total_taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
									if (tax.iva) {
										this.iva += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
									}
									promo.total_taxes = this.components.fixed(promo.total_taxes);
									this.taxes = this.components.fixed(this.taxes);
								} else {
									let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_x.price * element_x.quantity) - _tax;
									_tax = Number(_tax);
									promo.hidden_taxes += this.components.fixed(_tax);
									if (tax.iva) {
										this.iva += this.components.fixed(_tax);
									}
									this.hidden_taxes += this.components.fixed(_tax);
								}

							});

						} else {
							element_x.total_taxes = 0;
							element_x.hidden_taxes = 0;
						}
					} else {
						if (element_x.taxes_item) {
							element_x.total_taxes = 0;
							element_x.hidden_taxes = 0;
							element_x.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);

								if (!tax.included) {
									this.taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100)
									element_x.total_taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_x.price * element_x.quantity) * (Number(tax.rate) / 100);
									if (tax.iva) {
										this.iva += (element_x.price) * (Number(tax.rate) / 100);
									}
									promo.total_taxes = this.components.fixed(promo.total_taxes);
									this.taxes = this.components.fixed(this.taxes);
								} else {
									let _tax = (element_x.price * element_x.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_x.price * element_x.quantity) - _tax;
									_tax = Number(_tax);
									this.hidden_taxes += this.components.fixed(_tax);
									promo.hidden_taxes += this.components.fixed(_tax);
									if (tax.iva) {
										this.iva += this.components.fixed(_tax);
									}
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					}
				});

				element.y_items.forEach(element_y => {
					element_y.real_price = Number(element_y.price);
					if (element_y.is_variant) {

						if (element_y.parent.taxes_item) {
							element_y.total_taxes = 0;
							element_y.hidden_taxes = 0;
							element_y.parent.taxes_item.forEach(tax => {
								tax.rate = Number(tax.rate);
								if (!tax.included) {
									this.taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
									promo.total_taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
									if (tax.iva) {
										this.iva += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
									}
									promo.total_taxes = this.components.fixed(promo.total_taxes);
									this.taxes = this.components.fixed(this.taxes);
								} else {
									let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_y.price * element_y.quantity) - _tax;
									_tax = Number(_tax);
									promo.hidden_taxes += this.components.fixed(_tax);
									if (tax.iva) {
										this.iva += this.components.fixed(_tax);
									}
									this.hidden_taxes += this.components.fixed(_tax);
								}

							});

						} else {
							element_y.total_taxes = 0;
							element_y.hidden_taxes = 0;
						}
					} else {

						if (element_y.taxes_item) {
							element_y.total_taxes = 0;
							element_y.taxes_item.forEach(tax => {

								tax.rate = Number(tax.rate);

								if (!tax.included) {
									this.taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100)
									element_y.total_taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
									promo.total_taxes += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
									if (tax.iva) {
										this.iva += (element_y.price * element_y.quantity) * (Number(tax.rate) / 100);
									}

									promo.total_taxes = this.components.fixed(promo.total_taxes);

									this.taxes = this.components.fixed(this.taxes);
								} else {
									let _tax = (element_y.price * element_y.quantity) / ((Number(tax.rate) * 0.01) + 1)
									_tax = (element_y.price * element_y.quantity) - _tax;
									_tax = Number(_tax);
									this.hidden_taxes += this.components.fixed(_tax);
									promo.hidden_taxes += this.components.fixed(_tax);
									if (tax.iva) {
										this.iva += this.components.fixed(_tax);
									}
								}

							});
						} else {
							promo.total_taxes = 0;
							promo.hidden_taxes = 0;
						}
					}
				});

			} else {
				element.real_price = Number(element.price);

				if (element.is_variant) {

					if (element.parent.taxes_item) {
						element.total_taxes = 0;
						element.hidden_taxes = 0;
						element.parent.taxes_item.forEach(tax => {
							tax.rate = Number(tax.rate);

							if (!tax.included) {
								this.taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
								element.total_taxes += (element.price * element.quantity) * (Number(tax.rate) / 100);
								if (tax.iva) {
									this.iva += (element.price * element.quantity) * (Number(tax.rate) / 100);
								}
								element.total_taxes = this.components.fixed(element.total_taxes);
								this.taxes = this.components.fixed(this.taxes);
							} else {
								let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
								_tax = (element.price * element.quantity) - _tax;
								_tax = Number(_tax);
								element.hidden_taxes += this.components.fixed(_tax);
								if (tax.iva) {
									this.iva += this.components.fixed(_tax);
								}
								this.hidden_taxes += this.components.fixed(_tax);
							}

						});

					} else {
						element.total_taxes = 0;
						element.hidden_taxes = 0;
					}
				} else {
					if (element.taxes_item) {
						element.total_taxes = 0;
						element.hidden_taxes = 0;

						element.taxes_item.forEach(tax => {
							tax.rate = Number(tax.rate);

							if (!tax.included) {
								this.taxes += (element.price * element.quantity) * (Number(tax.rate) / 100)
								element.total_taxes += (element.price * element.quantity) * (Number(tax.rate) / 100);
								if (tax.iva) {
									this.iva += (element.price * element.quantity) * (Number(tax.rate) / 100);
								}
								element.total_taxes = this.components.fixed(element.total_taxes);
								this.taxes = this.components.fixed(this.taxes);
							} else {
								let _tax = (element.price * element.quantity) / ((Number(tax.rate) * 0.01) + 1)
								_tax = (element.price * element.quantity) - _tax;
								_tax = Number(_tax);
								element.hidden_taxes += this.components.fixed(_tax);
								if (tax.iva) {
									this.iva += this.components.fixed(_tax);
								}
								this.hidden_taxes += this.components.fixed(_tax);
							}
						});
					} else {
						// && this.auth.account_data.fiscal.type == 'dte'
						if (element.miscellaneous && this.auth.account_data.fiscal) {

							let _tax = (element.price * element.quantity) / ((Number(13) * 0.01) + 1)
							_tax = (element.price * element.quantity) - _tax;
							_tax = Number(_tax);
							if (!element.hidden_taxes) element.hidden_taxes = 0;
							if (!element.total_taxes) element.total_taxes = 0;
							element.hidden_taxes += this.components.fixed(_tax);
							this.iva += this.components.fixed(_tax);
							this.hidden_taxes += this.components.fixed(_tax);
						} else {
							element.total_taxes = 0;
							element.hidden_taxes = 0;
						}
					}
				}
			}

			this.total += this.components.fixed(element.price * element.quantity);

		});

		let total_without_modifications = this.components.fixed(this.total);

		this.payments.forEach(element => {
			this.total_payments += element.total;
		});

		this.pos.discounts.forEach(pos_discount => {
			if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
				if (pos_discount.type == 'percentage') {

					// Para sacar el total de el descuentos sin los impuestos
					let discount = Math.ceil(((this.total - this.hidden_taxes) * pos_discount.amount / 100) * 100) / 100;

					// Para sacar los impuestos del descuentp
					let _tax_discount = discount * ((Number(13) * 0.01) + 1)
					_tax_discount = _tax_discount - discount;

					this.iva_discount += Math.ceil(_tax_discount * 100) / 100;

					if (this.pos.exempt) {
						pos_discount.iva_discount = 0;
					}

					pos_discount.total_discount = discount;

					// Para sacar el total menos el descuenot						
					let total = Math.ceil((this.total - this.hidden_taxes - discount) * 100) / 100;
					let hidden_taxes = Math.ceil(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
					total = total + hidden_taxes;
					let gran_discount = discount;

					if (gran_discount + total + hidden_taxes > total_without_modifications + 0.01) {
						let total = Math.floor((this.total - this.hidden_taxes - discount) * 100) / 100;
						let hidden_taxes = Math.floor(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
						total = total + hidden_taxes;
						let gran_discount = discount;
						this.total = total;
						this.gran_discount += gran_discount;
						this.hidden_taxes = hidden_taxes;
					} else {
						this.total = total;
						this.gran_discount += gran_discount;
						this.hidden_taxes = hidden_taxes;
					}

					pos_discount.total_discount = discount;

				} else if (pos_discount.type == 'fixed') {

					let discount = pos_discount.amount;

					let _tax_discount = discount / ((Number(13) * 0.01) + 1)
					_tax_discount = discount - _tax_discount;
					discount = this.components.fixed(discount - _tax_discount);

					this.iva_discount += Math.floor(_tax_discount * 100) / 100;
					pos_discount.iva_discount = Math.floor(_tax_discount * 100) / 100;;
					if (this.order.exempt) {
						pos_discount.iva_discount = 0;
					}
					pos_discount.total_discount = this.components.fixed(discount - _tax_discount);


					// Para sacar el total menos el descuenot						
					let total = Math.ceil((this.total - this.hidden_taxes - discount) * 100) / 100;
					let hidden_taxes = Math.ceil(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
					total = total + hidden_taxes;
					let gran_discount = discount;

					if (gran_discount + total + hidden_taxes > total_without_modifications + 0.01) {
						let total = Math.floor((this.total - this.hidden_taxes - discount) * 100) / 100;
						let hidden_taxes = Math.floor(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
						total = total + hidden_taxes;
						let gran_discount = discount;
						this.total = total;
						this.gran_discount += gran_discount;
						this.hidden_taxes = hidden_taxes;
					} else {
						this.total = this.components.fixed(total);
						this.gran_discount += this.components.fixed(gran_discount);
						this.hidden_taxes = this.components.fixed(hidden_taxes);
					}

					pos_discount.total_discount = discount;
				}

				this.gran_discount = this.components.fixed(this.gran_discount);
			} else {
				if (pos_discount.type == 'percentage') {
					let discount = Number(Number(this.total * (Number(pos_discount.amount) / 100)).toFixed(2));
					pos_discount.total_discount = discount;
					pos_discount.iva_discount = 0;
					this.iva_discount = 0;
					this.total = this.total - discount;
					this.gran_discount += discount;
				} else if (pos_discount.type == 'fixed') {
					pos_discount.total_discount = pos_discount.amount;
					pos_discount.iva_discount = 0;
					this.iva_discount = 0;
					this.total = this.total - pos_discount.amount;
					this.gran_discount += pos_discount.amount;
				}

				this.gran_discount = this.components.fixed(this.gran_discount);

				if (this.total < 0) {
					this.total = 0;
				}

			}

			if (this.total < 0) {
				this.total = 0;
			}
		});

		this.total = this.components.fixed(this.total);

		if (this.order) {
			if (this.order.discounts) {

				this.order.discounts.forEach(element => {

					if (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte') {
						if (element.type == 'percentage') {

							// Para sacar el total de el descuentos sin los impuestos
							let discount = Math.ceil(((this.total - this.hidden_taxes) * element.amount / 100) * 100) / 100;

							// Para sacar los impuestos del descuentp
							let _tax_discount = discount * ((Number(13) * 0.01) + 1)
							_tax_discount = _tax_discount - discount;

							this.iva_discount += Math.ceil(_tax_discount * 100) / 100;
							element.iva_discount = Math.ceil(_tax_discount * 100) / 100;

							if (this.pos.exempt) {
								element.iva_discount = 0;
							}
							element.total_discount = discount;

							// Para sacar el total menos el descuenot						
							let total = Math.ceil((this.total - this.hidden_taxes - discount) * 100) / 100;
							let hidden_taxes = Math.ceil(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
							total = total + hidden_taxes;
							let gran_discount = discount;

							if (gran_discount + total + hidden_taxes > total_without_modifications + 0.01) {
								let total = Math.floor((this.total - this.hidden_taxes - discount) * 100) / 100;
								let hidden_taxes = Math.floor(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
								total = total + hidden_taxes;
								let gran_discount = discount;
								this.total = total;
								this.gran_discount += gran_discount;
								this.hidden_taxes = hidden_taxes;
							} else {
								this.total = total;
								this.gran_discount += gran_discount;
								this.hidden_taxes = hidden_taxes;
							}

							element.total_discount = discount;

						} else if (element.type == 'fixed') {

							let discount = element.amount;

							// Para sacar los impuestos del descuentp
							let _tax_discount = discount / ((Number(13) * 0.01) + 1)
							_tax_discount = discount - _tax_discount;
							discount = this.components.fixed(discount - _tax_discount);

							this.iva_discount += Math.floor(_tax_discount * 100) / 100;
							element.iva_discount = Math.floor(_tax_discount * 100) / 100;;
							if (this.order.exempt) {
								element.iva_discount = 0;
							}
							element.total_discount = this.components.fixed(discount - _tax_discount);


							// Para sacar el total menos el descuenot						
							let total = Math.ceil((this.total - this.hidden_taxes - discount) * 100) / 100;
							let hidden_taxes = Math.ceil(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
							total = total + hidden_taxes;
							let gran_discount = discount;

							if (gran_discount + total + hidden_taxes > total_without_modifications + 0.01) {
								let total = Math.floor((this.total - this.hidden_taxes - discount) * 100) / 100;
								let hidden_taxes = Math.floor(((total * ((Number(13) * 0.01) + 1)) - total) * 100) / 100;
								total = total + hidden_taxes;
								let gran_discount = discount;
								this.total = total;
								this.gran_discount += gran_discount;
								this.hidden_taxes = hidden_taxes;
							} else {
								this.total = this.components.fixed(total);
								this.gran_discount += this.components.fixed(gran_discount);
								this.hidden_taxes = this.components.fixed(hidden_taxes);
							}

							element.total_discount = discount;
						}

						this.gran_discount = this.components.fixed(this.gran_discount);
					} else {
						if (element.type == 'percentage') {
							let discount = Number(Number(this.total * (Number(element.amount) / 100)).toFixed(2));
							element.total_discount = discount;
							element.iva_discount = 0;
							this.iva_discount = 0;
							this.total = this.total - discount;
							this.gran_discount += discount;
						} else if (element.type == 'fixed') {
							element.total_discount = element.amount;
							element.iva_discount = 0;
							this.iva_discount = 0;
							this.total = this.total - element.amount;
							this.gran_discount += element.amount;
						}

						this.gran_discount = this.components.fixed(this.gran_discount);

						if (this.total < 0) {
							this.total = 0;
						}

					}

					if (this.total < 0) {
						this.total = 0;
					}
				});
			}

			if (this.order.type == 'tables') {

				if (this.branch.active_tip && !this.order.tip_removed) {

					if (this.branch.include_taxes_tip) {
						if (this.branch.include_discounts_tip) {
							this.tip = (((this.total + this.taxes) * Number(this.branch.tip)) / 100);
						} else {
							this.tip = (((this.total + this.taxes + this.gran_discount) * Number(this.branch.tip)) / 100);
						}
					} else {
						if (this.branch.include_discounts_tip) {
							this.tip = (((this.total - (this.hidden_taxes)) * Number(this.branch.tip)) / 100);
						} else {
							this.tip = ((((this.total + this.gran_discount) - (this.hidden_taxes)) * Number(this.branch.tip)) / 100);
						}
					}
					this.tip = Math.round(this.tip * 100) / 100;
					this.tip = this.components.fixed(this.tip);
				} else {
					this.tip = 0;
				}
			} else {
				this.tip = 0;
			}

		} else {

			if (this.branch.active_tip && this.pos.type == 'tables') {
				if (this.branch.include_taxes_tip) {
					if (this.branch.include_discounts_tip) {
						this.tip = ((this.total * Number(this.branch.tip)) / 100);
					} else {
						this.tip = (((this.total + this.gran_discount) * Number(this.branch.tip)) / 100);
					}
				} else {
					if (this.branch.include_discounts_tip) {
						this.tip = (((this.total - (this.taxes + this.hidden_taxes)) * Number(this.branch.tip)) / 100);
					} else {
						this.tip = ((((this.total + this.gran_discount) - (this.taxes + this.hidden_taxes)) * Number(this.branch.tip)) / 100);
					}
				}
				this.tip = Math.round(this.tip * 100) / 100
				this.tip = this.components.fixed(this.tip);
			} else {
				this.tip = 0;
			}
		}

		let total = this.total + this.tip - this.hidden_taxes;

		if (this.order && this.order.client) {
			if (total > 100 && this.order.client.large_contributor) {
				this.iva_rete = this.components.fixed((total) * 0.01);
				this.total = this.total - this.iva_rete;
			}
		} else {
			if (this.pos.client) {
				if (total > 100 && this.pos.client.large_contributor) {
					this.iva_rete = this.components.fixed((total) * 0.01);
					this.total = this.total - this.iva_rete;
				}
			}
		}

		if (this.order) {
			this.order.discounts.forEach(order_discount => {
				let total_items = this.pos.items.length;

				if (this.order.items) {
					total_items += this.order.items.length;
				}
				this.pos.items.forEach(product => {

					if (order_discount.type == 'percentage') {
						let discount = Number(Number(product.price * (Number(order_discount.amount) / 100)).toFixed(2));
						product.discount = discount;
						product.real_price = product.price - product.discount;
					} else if (order_discount.type == 'fixed') {
						let discount_percent = this.gran_discount / (this.total + this.gran_discount);
						discount_percent = discount_percent * 100;
						let discount = Number(Number(product.price * (Number(discount_percent) / 100)).toFixed(2));
						product.discount = discount;
						product.real_price = product.price - product.discount;
					}
				});

				this.order.items.forEach(product => {

					if (order_discount.type == 'percentage') {
						let discount = Number(Number(product.price * (Number(order_discount.amount) / 100)).toFixed(2));
						product.discount = discount;
						product.real_price = product.price - product.discount;
					} else if (order_discount.type == 'fixed') {
						let discount_percent = this.gran_discount / (this.total + this.gran_discount);
						discount_percent = discount_percent * 100;
						let discount = Number(Number(product.price * (Number(discount_percent) / 100)).toFixed(2));
						product.discount = discount;
						product.real_price = product.price - product.discount;
					}
				});
			})
		}

		this.pos.discounts.forEach(pos_discount => {
			let total_items = this.pos.items.length;
			if (this.order.items) {
				total_items += this.order.items.length;
			}
			this.pos.items.forEach(product => {
				if (pos_discount.type == 'percentage') {
					let discount = Number(Number(product.price * (Number(pos_discount.amount) / 100)).toFixed(2));
					product.discount = discount;
					product.real_price = product.price - product.discount;
				} else if (pos_discount.type == 'fixed') {
					let discount_percent = this.gran_discount / (this.total + this.gran_discount);
					discount_percent = discount_percent * 100;
					let discount = Number(Number(product.price * (Number(discount_percent) / 100)).toFixed(2));
					product.discount = discount;
					product.real_price = product.price - product.discount;
				}
			});

			if (this.order.items) {
				this.order.items.forEach(product => {
					if (pos_discount.type == 'percentage') {
						let discount = Number(Number(product.price * (Number(pos_discount.amount) / 100)).toFixed(2));
						product.discount = discount;
						product.real_price = product.price - product.discount;
					} else if (pos_discount.type == 'fixed') {
						let discount_percent = this.gran_discount / (this.total + this.gran_discount);
						discount_percent = discount_percent * 100;
						let discount = Number(Number(product.price * (Number(discount_percent) / 100)).toFixed(2));
						product.discount = discount;
						product.real_price = product.price - product.discount;
					}
				});


			}

		});
	}

	/**
	 * If the order exists, add the items to the order, otherwise create a new order
	 */
	saveOrder(print = false) {
		this.components.showLoader('Guardando orden...').then(() => {

			if (this.pos.items.length > 0 || (this.order && this.pos.discounts.length > 0)) {
				if (this.order) {
					this.api.addItemsToOrder(this.order).then(data => {
						this.components.dismissLoader();
					}, err => {
						this.components.dismissLoader();
						console.log(err);
					})
				} else {
					this.api.addOrder().then(() => {
						this.clearAll();
						if (this.components.isApp()) this.modalController.dismiss();
						this.components.dismissLoader();
					}, err => {
						console.log(err);
					})
				}
			} else {
				this.components.dismissLoader();
				this.components.showToast('Debes de agregar items para poder continuar', 'error')

			}
		})
	}

	/**
	 * It clears the order, payments, and cancels the order
	 */
	clearAll() {
		this.order = false;
		this.payments = [];
		this.pos.clearOrder(undefined);
		// this.ngOnInit();
	}

	/**
	 * The function is called payNow and it's an async function. It has a variable called gran_total which
	 * is equal to the total plus the tip. It has a variable called gran_subtotal which is equal to the
	 * total. It has a variable called gran_tip which is equal to the tip. It has a variable called
	 * gran_discount which is equal to the gran_discount. It has a variable called params which is equal
	 * to an empty object
	 */
	async payNow(ev) {

		console.log(this.order.items);
		console.log(this.pos.items);

		if (this.order.items || this.pos.items.length > 0) {
			this.components.buttonLoad(ev);
			if (this.order) {
				if (this.pos.items.length > 0) {
					this.api.addItemsToOrder(this.order).then(async data => {
						await this.getTotal();
						this.openPaymentModal();
						this.components.dismissButtonLoad(ev);
					}, err => {
						console.log(err);
					})
				} else {
					if (this.pos.discounts.length > 0) {
						this.api.addDiscountsToOrder(this.order).then(async data => {
							await this.getTotal();
							this.openPaymentModal();
							this.components.dismissButtonLoad(ev);
						})
					} else {
						this.openPaymentModal();
						this.components.dismissButtonLoad(ev);
					}
				}

			} else {
				this.api.addOrder().then(respone => {
					this.open_payment_modal = true;
					this.order_pos = respone;
					this.order_pos.creation_date = new Date();
					this.pos.clearOrder(this.order);
					this.ngOnInit();
					this.components.dismissButtonLoad(ev);
				})
			}
		} else {
			this.components.showToast('Debes de agregar items para poder continuar', 'error')
		}
	}

	/**
	 * It opens a modal that contains a component that allows the user to select a payment method
	 */
	async openPaymentModal() {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;

			let params = {
				order_key: this.order.$key,
				gran_total: this.total + this.tip + this.taxes,
				gran_subtotal: this.total,
				gran_discount: this.gran_discount,
				gran_taxes: this.taxes,
				gran_hidden_taxes: this.hidden_taxes,
				discounts: this.order.discounts,
				gran_tip: this.tip,
				gran_iva: this.iva,
				branch: this.branch,
				order: this.order,
				iva_rete: this.iva_rete
			}

			const modal_payment = await this.modalController.create({
				component: ModalBaseComponent,
				cssClass: 'pay-modal',
				showBackdrop: true,
				componentProps: {
					rootPage: PaymentMethodComponent,
					params: params
				},
			});

			let check_dte = true;
			let no_client = false;

			if (this.auth.account_data.fiscal) {
				if (this.auth.account_data.fiscal.type == 'dte') {
					if (this.pos.client) {
						if (this.pos.client.type == 'regular' && (!this.pos.client.name || !this.pos.client.email || !this.pos.client.document_type)) {
							check_dte = false;
						} else if ((this.pos.client.type == 'legal' || this.pos.client.type == 'natural') && (!this.pos.client.name || !this.pos.client.phone || !this.pos.client.email || !this.pos.client.nit || !this.pos.client.nrc || !this.pos.client.activity || !this.pos.client.address || !this.pos.client.depto || !this.pos.client.munic)) {
							check_dte = false;
						}
					} else {

						if (this.order.tax_receipt.type == 'ccf') {
							check_dte = false;
							no_client = true;
						}
					}
				}
			}

			if (check_dte) {
				await modal_payment.present().then(data => {
					this.components.isModalPresent = false;
					this.components.dismissLoader();
				})
			} else {
				this.components.dismissLoader();
				this.components.isModalPresent = false;

				if (no_client) {
					this.components.showToast('Debes de seleccionar un cliente para el tipo de comprobante de CCF');
					this.addClient(true);
				} else {
					let new_class = '';
					if (this.pos.client.type != 'regular') {
						new_class = 'two-columns';
					}
					this.modalController.create({
						component: EditClientComponent,
						cssClass: 'auto-height ' + new_class,
						animated: true,
						backdropDismiss: true,
						componentProps: {
							client: this.pos.client,
							dte_valid: true
						}
					}).then(async modal => {
						await modal.present();
						await modal.present().then(() => {
							this.components.isModalPresent = false;
							this.components.dismissLoader();
						})
						await modal.onDidDismiss().then(async data => {
							if (data.data) {
								this.pos.client = data.data;
								this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
									client: data.data
								})
							}

						})
					});
				}
			}

			await modal_payment.onDidDismiss().then(data => {

				if (data.data) {
					if (data.data.completed) {
						if (this.auth.license.membership.type == 'resto') {
							this.order = '';
							this.total = 0;
							this.total_payments = 0;
							this.payments = [];
							this.client = false;
							this.tip = 0;
							this.taxes = 0;
							this.hidden_taxes = 0;
							this.gran_discount = '';
						} else {
							this.order = '';
							this.total = 0;
							this.taxes = 0;
							this.total_payments = 0;
							this.payments = [];
							this.client = false;
							this.tip = 0;
							this.hidden_taxes = 0;
							this.gran_discount = '';
						}
						if (this.components.isApp()) this.modalController.dismiss();
						this.router.navigate(['/pos']);
						this.successPaymentModal(data.data.ticket_key);
					}
				}

			});
		}
	}

	async changeDelivery() {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;

			const modal = await this.modalController.create({
				component: DeliveriesComponent,
				showBackdrop: true
			});

			await modal.present().then(() => { this.components.isModalPresent = false })

			await modal.onDidDismiss().then(data => {
				if (data.data) {
					let delivery = data.data;

					if (this.order) {
						this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
							delivery: delivery
						}).then(() => {
							this.order.delivery = delivery;
							this.pos.addDelivery(delivery);
							this.components.showToast('Delivery agregado a tu orden');
						}, err => {
							console.log(err);
						})
					} else {
						this.pos.addDelivery(delivery);
					}
				}
			});
		}
	}

	/**
	 * This function creates a modal that displays the success payment component
	 * @param ticket_key - The ticket key of the ticket that was just purchased.
	 */
	async successPaymentModal(ticket_key) {
		const modal = await this.modalController.create({
			component: SuccessPaymentComponent,
			cssClass: 'full-modal',
			backdropDismiss: false,
			animated: false,
			componentProps: {
				ticket_key: ticket_key
			}
		});

		await modal.present().then(() => {

		});

		await modal.onDidDismiss().then((data) => {
		})
	}

	/**
	 * It opens a modal, and when the modal is dismissed, it sets the isModalPresent variable to false
	 * @param item - The item that is being edited.
	 */
	async openEditModal(element, already_in_order = true) {
		let item = JSON.parse(JSON.stringify(element));
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;
			const modal = await this.modalController.create({
				component: ModalBaseComponent,
				cssClass: 'edit-item-modal',
				componentProps: {
					rootPage: EditItemComponent,
					params: {
						item: item,
						order: this.order,
						already_in_order: already_in_order
					}
				},
			});
			await modal.present().then(() => { this.components.isModalPresent = false })

			await modal.onDidDismiss().then(data => {
				if (data.data == 'returned') {
					this.router.navigate([`pos`]);
					this.clearAll();
				}
			})

		}
	}

	/**
	 * It opens a modal, and when the modal is dismissed, it adds the client to the order
	 */
	async addClient(is_ccf = false) {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;

			let modal = await this.modalController.create({
				component: ClientsComponent,
				showBackdrop: true,
				cssClass: 'clients-modal',
				componentProps: {
					order: this.order,
					is_ccf: is_ccf
				},
			});

			await modal.present().then(data => { this.components.isModalPresent = false })

			await modal.onDidDismiss().then(data => {
				if (data.data) {
					let client = data.data;
					let exempt = false;
					if (client.exempt) {
						exempt = true;
					}

					if (this.order) {
						this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
							client: client,
							exempt: exempt,
						}).then(() => {
							this.order.client = client;
							this.order.exempt = exempt;
							this.pos.client = client;
							this.pos.exempt = exempt;
							this.pos.updateOrderTicket(this.order.$key);
							this.components.showToast('Cliente agregado a tu orden');
						}, err => {
							console.log(err);
						})
					} else {
						this.pos.exempt = exempt;
						this.pos.addClient(client);
					}
				}
			});
		}
	}

	/**
	 * It removes the client from the POS, and if there's an order, it removes the client from the order
	 */
	removeClient(client) {
		this.components.showAlert('Eliminar cliente', `El cliente ${client.name} será eliminado del ticket.`,
			[
				{
					text: 'Cancelar',
					role: 'cancel'
				},
				{
					text: 'Eliminar',
					role: 'destructive',
					handler: () => {
						this.item_slides.closeOpened();
						if (this.order) {

							let data = {
								client: firebase.firestore.FieldValue.delete(),
								exempt: false,
							};

							if ((this.order.tax_receipt && this.order.tax_receipt.type == 'ccf') && (this.auth.account_data.fiscal && this.auth.account_data.fiscal.type == 'dte')) {
								data['tax_receipt'] = {
									type: 'invoice'
								}
								this.order.type = 'invoice';
								this.pos.type = 'invoice';
							}

							this.order.client = false;
							this.order.exempt = false;
							this.pos.client = false;
							this.pos.exempt = false;

							this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, data).then(() => {
								this.pos.updateOrderTicket(this.order.$key);
								this.components.showToast('Cliente eliminado correctamente.')
							}, err => {
								console.log(err);
							})
						} else {
							this.pos.removeClient();
						}
					}
				},
			]
		)
	}

	/**
	 * It changes the kds_type of an item in the pos.items array
	 * @param value - The value of the selected option.
	 * @param index - The index of the item in the order.
	 */
	changeKdsType(value, index) {
		this.item_slides.closeOpened();
		this.pos.items[index].kds_type = value;
		this.components.showToast('Se cambio el tipo de item.');
	}

	/**
	 * It opens a modal to edit a discount
	 * @param discount - The discount object that is being edited.
	 */
	async editDiscount(discount) {
		if (!discount.promotion) {
			const alert = await this.alertController.create({
				header: 'Eliminar descuento',
				message: `Estas seguro que deseas eliminar el descuento ${discount.name}?, esta acción no se puede revertir.`,
				buttons: [
					{
						text: 'Cancelar',
						role: 'cancel'
					},
					{
						text: 'Remover descuento',
						role: 'destructive',
						handler: () => {
							if (!discount.order_key) {
								this.pos.removeDiscount(discount);
								this.components.showToast('Descuento eliminado correctamente.');
							} else {

								this.api.deleteDocument(`accounts/${this.auth.account}/ticket_discounts`, discount.$key).then(() => {
									this.components.showToast('Descuento eliminado correctamente.');
									const found = this.order.discounts.findIndex(element => element.$key == discount.$key);
									this.order.discounts.splice(found, 1);
									this.getTotal();
								}, err => {
									console.log(err);
								})
							}
						}
					}
				]
			});

			await alert.present();
		} else {
			const alert = await this.alertController.create({
				header: 'Eliminar promocion',
				message: `Estas seguro que deseas eliminar el descuento ${discount.name}?, esta acción no se puede revertir.`,
				buttons: [
					{
						text: 'Cancelar',
						role: 'cancel'
					},
					{
						text: 'Remover propina',
						role: 'destructive',
						handler: () => {
							this.api.deleteDocument(`accounts/${this.auth.account}/ticket_discounts`, discount.$key).then(() => {
								this.modalController.dismiss();
								this.components.showToast('Descuento eliminado correctamente.');
							}, err => {
								console.log(err);
							})
						}
					}
				]
			});

			await alert.present();
		}
	}

	/**
	 * It creates a modal, and when the modal is dismissed, it navigates to the POS page and clears the
	 * order
	 */
	async ticketOptions() {

		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;
			const modal = await this.modalController.create({
				component: ModalBaseComponent,
				cssClass: 'ticket-options-modal',
				showBackdrop: true,
				backdropDismiss: true,
				componentProps: {
					rootPage: TicketOptionsComponent,
					params: {
						order: this.order,
						payments: this.payments,
						total: this.total + this.tip + this.taxes
					}
				}
			});
			await modal.present().then(() => { this.components.isModalPresent = false })

			await modal.onDidDismiss().then(data => {
				if (data.data == 'returned') {
					this.router.navigate([`pos`]);
					this.clearAll();
				}
			})
		}
	}

	/**
	 * Print the order, and then print the subtotal, total, and tip.
	 */
	async printOrder() {
		if (!this.auth.license.membership.features.tickets.print) {
			this.components.showAlert('Actualiza tu licencia', 'Al actualizar tu licencia podrás imprimir ordenes.', ['Aceptar']);
		} else {
			let total = this.components.fixed(this.total + this.tip + this.taxes);
			let subtotal = this.components.fixed(this.total);

			let tip = this.components.fixed(this.tip);
			let discount = this.components.fixed(this.gran_discount + this.iva_discount);
			let exempt = 0;

			if (this.pos.exempt) {
				exempt = this.hidden_taxes + this.taxes
			}

			let order = JSON.parse(JSON.stringify(this.order));

			let creation_date = this.order.creation_date;

			if (creation_date instanceof Date) {
				order.creation_date = creation_date;
			} else {
				order.creation_date = creation_date.toDate();
			}



			order.taxes = this.hidden_taxes + this.taxes;
			if (this.platform.is('cordova')) {
				this.printer.printOrder(order, subtotal, total, tip, discount, false, exempt);
			} else {
				this.printer.pdfOrder(order, subtotal, total, tip, discount, false, exempt);
			}

		}
	}

	/**
	 * It creates a modal that contains the TransferTicketsComponent, and passes the order to the
	 * component
	 */
	async goToChangeTablePage() {
		const modal = await this.modalController.create({
			component: TransferTicketsComponent,
			cssClass: 'full-modal',
			canDismiss: true,
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				orders: [this.order]
			}
		});

		await modal.present();

		modal.onDidDismiss().then(data => {
			if (data.data) {
				this.order.table = this.pos.table;
				this.order.table_area = this.pos.table_area;
			}
		})
	}

	/**
	 * It creates a modal with the NumberComponent component, and passes the order, is_update, and table
	 * props to the component
	 */
	async updatePersons() {
		const modal = await this.modalController.create({
			component: NumberComponent,
			cssClass: 'auto-height number-modal',
			canDismiss: true,
			showBackdrop: true,
			backdropDismiss: true,
			componentProps: {
				order: this.order,
				is_update: true,
				table: {
					text: this.pos.table
				}
			}
		});
		await modal.present();
	}

	cancelOrder() {
		this.pos.cancelOrder(undefined).then(() => {
			this.order = false;
			this.total = 0;
			this.total_payments = 0;
			this.payments = [];
			this.client = false;
			this.tip = 0;
			this.taxes = 0;
			this.iva = 0;
			this.gran_discount;
			this.hidden_taxes = 0;
			this.open_payment_modal = false;
			this.iva_discount = 0;
			this.iva_rete = 0;
		});

		if (this.components.isApp()) {
			this.modalController.dismiss();
		}
	}
}
