import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { AngularFirestore } from 'angularfire2/firestore';
import EscPosEncoder from 'esc-pos-encoder-ionic';


import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { Platform } from '@ionic/angular';
import * as Socket from '@vendus/sockets-for-cordova';
import { AuthService } from '../auth/auth.service';
import { ComponentsService } from '../components/components.service';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { AngularFireAuth } from 'angularfire2/auth';
import { HttpClient } from '@angular/common/http';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const encoder = new EscPosEncoder();

interface navigator {
	serail: {
		exitApp: () => any; // Or whatever is the type of the exitApp function
	}
}
@Injectable({
	providedIn: 'root'
})
export class PrintService {

	printers = [];
	peripheral: any = false;

	constructor(
		public btSerial: BluetoothSerial,
		public db: AngularFirestore,
		public auth: AuthService,
		private ble: BLE,
		public components: ComponentsService,
		private platform: Platform,
		private angularAuth: AngularFireAuth,
		private http: HttpClient
	) { }

	print(print_info, type: any = false) {
		this.components.showLoader('Imprimiendo...').then(() => {
			let length = 0;
			print_info.forEach(item => {
				length += item.length;
			});

			// UNE TODOS LOS ARRAYS METIDOS EN PRINT INFO
			// Create a new array with total length and merge all source arrays.
			let resultByte = new Uint8Array(length);
			let offset = 0;
			print_info.forEach(item => {
				resultByte.set(item, offset);
				offset += item.length;
			});

			if (this.auth.peripheral !== false) {
				this.ble.write(this.auth.peripheral, '49535343-FE7D-4AE5-8FA9-9FAFD205E455', '49535343-ACA3-481C-91EC-D85E28A60318', resultByte.buffer).then(data => {
					console.log(data);
				}, err => {
					this.auth.peripheral = false;
					this.printers.forEach(printer => {
						if (printer.payment_receipt) {

							var socket = new Socket();
							socket.open(printer.ip, printer.port, data => {
								socket.write(resultByte, () => {
									socket.shutdownWrite((suc) => {
										console.log(suc);
									}, (err) => {
										console.log(err);
									});
								}, err => {
									console.log(err);
								});
							}, errorMessage => {
								console.log(printer);
								console.log(errorMessage);
							});
						}
					});
				})
			} else {
				this.printers.forEach(printer => {
					if (type == 'commands' && printer.commands || type == 'closing' && printer.closing || type == 'receipt' && printer.payment_receipt) {
						if (this.platform.is('cordova')) {
							var socket = new Socket();
							console.log('a imprimir');
							socket.open(printer.ip, printer.port, data => {
								socket.write(resultByte, () => {
									socket.shutdownWrite((suc) => {
										console.log(suc);
									}, (err) => {
										console.log(err);
									});
								}, err => {
									console.log(err);
								});
							}, errorMessage => {
								console.log(printer);
								console.log(errorMessage);
							});
						} else {
							console.log('aqui deberia de imprimir');
						}
					}
				});
			}
		})

		setTimeout(() => {
			this.components.dismissLoader();
		}, 400);
	}

	searchBluetoothPrinter() {
		//This will return a list of bluetooth devices
		return this.btSerial.list();
	}
	connectToBluetoothPrinter(macAddress) {
		//This will connect to bluetooth printer via the mac address provided
		return this.btSerial.connect(macAddress)
	}

	disconnectBluetoothPrinter() {
		//This will disconnect the current bluetooth connection
		return this.btSerial.disconnect();
	}

	//macAddress->the device's mac address 
	//data_string-> string to be printer
	sendToBluetoothPrinter(macAddress, data_string) {
		console.log(1);

		//1. Try connecting to bluetooth printer
		this.connectToBluetoothPrinter(macAddress)

			.subscribe(_ => {
				console.log(2);

				//2. Connected successfully
				this.btSerial.write(data_string)
					.then(_ => {
						//3. Print successful
						//If you want to tell user print is successful,
						//handle it here
						//4. IMPORTANT! Disconnect bluetooth after printing
						this.disconnectBluetoothPrinter()
					}, err => {
						//If there is an error printing to bluetooth printer
						//handle it here
					})
			}, err => {
				console.log(err);

				//If there is an error connecting to bluetooth printer
				//handle it here
			})
	}

	async setPrinters() {

		this.angularAuth.authState.subscribe(async (firebaseUser) => {
			if (firebaseUser !== null) {

				const account = await Preferences.get({ key: 'account' });
				const branch = await Preferences.get({ key: 'branch' });

				if (account.value !== null) {
					this.printers = [];

					this.db.collection(`accounts/${account.value}/printers`).ref
						.where('branch_key', '==', branch.value)
						.onSnapshot(snapshots => {
							snapshots.docChanges().forEach(element => {
								if (element.type == 'added') {
									let printer = element.doc.data();
									printer.$key = element.doc.id;
									this.printers.push(printer);
								} else if (element.type == 'modified') {
									let printer = element.doc.data();
									printer.$key = element.doc.id;
									this.printers[element.oldIndex] = printer;
								} else if (element.type == 'removed') {
									const found = this.printers.findIndex(_element => _element.$key == element.doc.id);
									this.printers.splice(found, 1);
								}
							});
						}, err => {
							console.log(err);
						});
				} else {
					// console.log('no puede acceder');
				}
			}
		})

	}

	printItems(order, items) {
		let current_printers = [];

		this.printers.forEach(printer => {
			let printer_items = [];

			items.forEach(item => {
				if (item.miscellaneous) {
					printer_items.push(item);
				} else {
					let found_item;

					if (item.is_variant) {
						found_item = printer.items.find(_item => _item == item.parent.$key);
					} else {
						found_item = printer.items.find(_item => _item == item.item_key);
					}

					if (found_item !== undefined) {
						printer_items.push(item);
					}
				}
			});

			current_printers.push({
				ip: printer.ip,
				port: printer.port,
				printer_key: printer.$key,
				order: order,
				items: printer_items
			})

		});


		current_printers.forEach(printer => {
			var socket = new Socket();

			if (printer.items.length > 0) {
				const encoder = new EscPosEncoder();

				const result = encoder.initialize();
				let tipo = printer.order.type;

				if (printer.order.type == 'takeout') {
					tipo = 'LLEVAR'
				} else if (printer.order.type == 'tables') {
					tipo = `MESA #${order.table}`
				} else if (printer.order.type == 'delivery') {
					tipo = `DELIVERY`
				}

				let print_info = [];
				let header_data = result
					.newline()
					.newline()
					.newline()
					.newline()
					.newline()
					.align('center')
					.size('normal')
					.bold(true)
					.line(tipo)
					.line(`Orden #${printer.order.order_number}`)
					.bold(false)
					.align('left')
					.line(`Atendio: ${printer.order.user.name} ${printer.order.user.lastname}`)
					.line(`FECHA RESOLUCION: ${new Date().getHours()}:${new Date().getMinutes()} ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`)
					.newline()
					.line('------------------------------------------------')
					.encode()
				print_info.push(header_data)

				if (printer.order.type == 'delivery') {
					let delivery_data = result.newline()
						.line(`${printer.order.delivery.name} Pedido #${printer.order.delivery.delivery_number}`)
						.encode()
					print_info.push(delivery_data)
				}

				let rest_items = [];
				let drinks_items = [];
				let entree_items = [];
				let main_items = [];
				let dessert_items = [];

				printer.items.forEach(element => {
					if (element.kds_type == 'drinks') {
						drinks_items.push(element)
					} else if (element.kds_type == 'entree') {
						entree_items.push(element)
					} else if (element.kds_type == 'main') {
						main_items.push(element)
					} else if (element.kds_type == 'dessert') {
						dessert_items.push(element)
					} else {
						rest_items.push(element)
					}
				});

				if (drinks_items.length > 0) {
					let header_type = result
						.newline()
						.align('center')
						.size('normal')
						.line('------------------------------------------------')
						.bold(true)
						.line('BEBIDAS')
						.bold(false)
						.encode()
					print_info.push(header_type);

					drinks_items.forEach(item => {

						if (item.is_variant) {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.parent.name} (${item.name})`)
								.encode();
							print_info.push(item_data)
						} else {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.name}`)
								.encode();
							print_info.push(item_data)
						}

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {

								if (Array.isArray(modifier.selected)) {
									modifier.selected.forEach(element => {
										let modifier_data = result
											.bold(false)
											.line(`- ${element.name}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {
									let modifier_data = result
										.bold(false)
										.line(`- ${modifier.selected.name}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.bold(false)
								.line(`Notas: ${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}

						let item_end = result
							.line('------------------------------------------------')
							.encode();
						print_info.push(item_end)
					});

				}

				if (entree_items.length > 0) {
					let header_type = result
						.newline()
						.align('center')
						.size('normal')
						.line('------------------------------------------------')
						.bold(true)
						.line('ENTRADAS')
						.bold(false)
						.encode()
					print_info.push(header_type);

					entree_items.forEach(item => {

						if (item.is_variant) {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.parent.name} (${item.name})`)
								.encode();
							print_info.push(item_data)
						} else {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.name}`)
								.encode();
							print_info.push(item_data)
						}

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {

								if (Array.isArray(modifier.selected)) {
									modifier.selected.forEach(element => {
										let modifier_data = result
											.bold(false)
											.line(`- ${element.name}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {
									let modifier_data = result
										.bold(false)
										.line(`- ${modifier.selected.name}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.bold(false)
								.line(`Notas: ${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}

						let item_end = result
							.line('------------------------------------------------')
							.encode();
						print_info.push(item_end)
					});

				}

				if (main_items.length > 0) {
					let header_type = result
						.newline()
						.align('center')
						.size('normal')
						.line('------------------------------------------------')
						.bold(true)
						.line('FUERTE')
						.bold(false)
						.encode()
					print_info.push(header_type);

					main_items.forEach(item => {

						if (item.is_variant) {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.parent.name} (${item.name})`)
								.encode();
							print_info.push(item_data)
						} else {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.name}`)
								.encode();
							print_info.push(item_data)
						}

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {

								if (Array.isArray(modifier.selected)) {
									modifier.selected.forEach(element => {
										let modifier_data = result
											.bold(false)
											.line(`- ${element.name}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {
									let modifier_data = result
										.bold(false)
										.line(`- ${modifier.selected.name}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.bold(false)
								.line(`Notas: ${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}

						let item_end = result
							.line('------------------------------------------------')
							.encode();
						print_info.push(item_end)
					});

				}

				if (dessert_items.length > 0) {
					let header_type = result
						.newline()
						.align('center')
						.size('normal')
						.line('------------------------------------------------')
						.bold(true)
						.line('POSTRE')
						.bold(false)
						.encode()
					print_info.push(header_type);

					dessert_items.forEach(item => {

						if (item.is_variant) {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.parent.name} (${item.name})`)
								.encode();
							print_info.push(item_data)
						} else {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.name}`)
								.encode();
							print_info.push(item_data)
						}

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {

								if (Array.isArray(modifier.selected)) {
									modifier.selected.forEach(element => {
										let modifier_data = result
											.bold(false)
											.line(`- ${element.name}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {
									let modifier_data = result
										.bold(false)
										.line(`- ${modifier.selected.name}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.bold(false)
								.line(`Notas: ${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}

						let item_end = result
							.line('------------------------------------------------')
							.encode();
						print_info.push(item_end)
					});

				}

				if (rest_items.length > 0) {
					let header_type = result
						.newline()
						.align('center')
						.size('normal')
						.line('------------------------------------------------')
						.bold(true)
						.line('OTROS')
						.bold(false)
						.encode()
					print_info.push(header_type);

					rest_items.forEach(item => {

						if (item.is_variant) {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.parent.name} (${item.name})`)
								.encode();
							print_info.push(item_data)
						} else {
							let item_data = result
								.bold(true)
								.line(`(${item.quantity}) ${item.name}`)
								.encode();
							print_info.push(item_data)
						}

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {

								if (Array.isArray(modifier.selected)) {
									modifier.selected.forEach(element => {
										let modifier_data = result
											.bold(false)
											.line(`- ${element.name}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {

									let modifier_data = result
										.bold(false)
										.line(`- ${modifier.selected.name}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.bold(false)
								.line(`Notas: ${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}

						let item_end = result
							.line('------------------------------------------------')
							.encode();
						print_info.push(item_end)
					});

				}

				if (order.client) {
					let client_name = result
						.align('center')
						.newline()
						.line('Cliente: ' + order.client.name)
						.encode();
					print_info.push(client_name)

					if (order.client.phone) {
						let client_name = result
							.align('center')
							.line('Telefono: ' + order.client.phone)
							.encode();
						print_info.push(client_name)
					}
				}

				if (order.notes) {
					let footer_data = result
						.align('center')
						.newline()
						.newline()
						.line(order.notes ? 'Notas:' : '')
						.newline()
						.line(order.notes ? order.notes : '')
						.newline()
						.newline()
						.newline()
						.encode();
					print_info.push(footer_data)
				}

				// rest_items.forEach(item => {

				// 	if (item.is_variant) {
				// 		let item_data = result
				// 			.bold(true)
				// 			.line(`(${item.quantity}) ${item.parent.name} (${item.name})`)
				// 			.encode();
				// 		print_info.push(item_data)
				// 	} else {
				// 		let item_data = result
				// 			.bold(true)
				// 			.line(`(${item.quantity}) ${item.name}`)
				// 			.encode();
				// 		print_info.push(item_data)
				// 	}

				// 	if (item.have_modifiers) {

				// 		item.modifiers.forEach(modifier => {

				// 			if (Array.isArray(modifier.selected)) {
				// 				modifier.selected.forEach(element => {
				// 					let modifier_data = result
				// 						.bold(false)
				// 						.line(`- ${element.name}`)
				// 						.encode();
				// 					print_info.push(modifier_data)
				// 				});
				// 			} else {
				// 				let modifier_data = result
				// 					.bold(false)
				// 					.line(`- ${modifier.selected.name}`)
				// 					.encode();
				// 				print_info.push(modifier_data)
				// 			}
				// 		});
				// 	}

				// 	if (item.notes) {
				// 		let notes_data = result
				// 			.bold(false)
				// 			.line(`Notas: ${item.notes}`)
				// 			.encode();
				// 		print_info.push(notes_data)
				// 	}

				// 	let item_end = result
				// 		.line('------------------------------------------------')
				// 		.encode();
				// 	print_info.push(item_end)
				// });

				let footer_data = result
					.align('center')
					.newline()
					.newline()
					.newline()
					.newline()
					.newline()
					.newline()
					.newline()
					.cut()
					.encode();
				print_info.push(footer_data)

				let length = 0;
				print_info.forEach(item => {
					length += item.length;
				});

				// UNE TODOS LOS ARRAYS METIDOS EN PRINT INFO
				// Create a new array with total length and merge all source arrays.
				let resultByte = new Uint8Array(length);
				let offset = 0;
				print_info.forEach(item => {
					resultByte.set(item, offset);
					offset += item.length;
				});

				if (this.platform.is('cordova')) {
					var socket = new Socket();

					socket.open(printer.ip, printer.port, data => {
						socket.write(resultByte, () => {
							socket.shutdownWrite((suc) => {
								console.log(suc);
							}, (err) => {
								console.log(err);
							});
						}, err => {
							console.log(err);
						});
					}, errorMessage => {
						console.log(printer);
						console.log(errorMessage);
					});
				} else {
					console.log('aqui deberia de imprimir');
				}

			}
		});


		// if (!this.platform.is('cordova')) {

		// 	let tipo = order.type;

		// 	if (order.type == 'takeout') {
		// 		tipo = 'LLEVAR'
		// 	} else if (order.type == 'tables') {
		// 		tipo = `MESA #${order.table}`
		// 	} else if (order.type == 'delivery') {
		// 		tipo = `DELIVERY`
		// 	}

		// 	const documentDefinition = {
		// 		content: [
		// 			{ text: tipo, style: 'center' },
		// 			{ text: `Orden #${order.order_number}`, style: 'left' },
		// 			{ text: `Atendio: ${order.user.name} ${order.user.lastname}`, style: 'left' },
		// 			{ text: `FECHA RESOLUCION: ${new Date().getHours()}:${new Date().getMinutes()} ${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`, style: 'left' },
		// 			{ text: `------------------------------------------`, style: 'center' },
		// 		],
		// 		styles: {
		// 			center: {
		// 				fontSize: 12,
		// 				alignment: 'center'
		// 			},
		// 			left: {
		// 				fontSize: 12,
		// 				alignment: 'left'
		// 			},
		// 			right: {
		// 				fontSize: 12,
		// 				alignment: 'right'
		// 			},
		// 			small: {
		// 				alignment: 'center',
		// 				fontSize: 8,
		// 			},
		// 		},
		// 		pageSize: {
		// 			width: 305,
		// 			height: 'auto',
		// 		},
		// 		pageOrientation: 'landscape'
		// 	};

		// 	let pdf_items = [];

		// 	items.forEach(item => {
		// 		pdf_items.push(item);
		// 	});

		// 	let rest_items = [];
		// 	let drinks_items = [];
		// 	let entree_items = [];
		// 	let main_items = [];
		// 	let dessert_items = [];

		// 	pdf_items.forEach(element => {
		// 		if (element.kds_type == 'drinks') {
		// 			drinks_items.push(element)
		// 		} else if (element.kds_type == 'entree') {
		// 			entree_items.push(element)
		// 		} else if (element.kds_type == 'main') {
		// 			main_items.push(element)
		// 		} else if (element.kds_type == 'dessert') {
		// 			dessert_items.push(element)
		// 		} else {
		// 			rest_items.push(element)
		// 		}
		// 	});

		// 	if (drinks_items.length > 0) {

		// 		documentDefinition.content.push(
		// 			{ text: `------------------------------------------`, style: 'center' },
		// 			{ text: `BEBIDAS`, style: 'center' },
		// 		)

		// 		drinks_items.forEach(item => {
		// 			if (item.is_variant) {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.parent.name} (${item.name})`, style: 'center' },
		// 				)
		// 			} else {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.name}`, style: 'center' },
		// 				)
		// 			}

		// 			if (item.have_modifiers) {
		// 				item.modifiers.forEach(modifier => {
		// 					if (Array.isArray(modifier.selected)) {
		// 						modifier.selected.forEach(element => {
		// 							documentDefinition.content.push(
		// 								{ text: `- ${element.name}`, style: 'center' },
		// 							)
		// 						});
		// 					} else {
		// 						documentDefinition.content.push(
		// 							{ text: `- ${modifier.selected.name}`, style: 'center' },
		// 						)
		// 					}
		// 				});
		// 			}

		// 			if (item.notes) {
		// 				documentDefinition.content.push(
		// 					{ text: `Notas: ${item.notes}`, style: 'center' },
		// 					{ text: `------------------------------------------`, style: 'center' },
		// 				)
		// 			}

		// 		})
		// 	}

		// 	if (entree_items.length > 0) {

		// 		documentDefinition.content.push(
		// 			{ text: `------------------------------------------`, style: 'center' },
		// 			{ text: `ENTRADAS`, style: 'center' },
		// 		)

		// 		entree_items.forEach(item => {
		// 			if (item.is_variant) {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.parent.name} (${item.name})`, style: 'center' },
		// 				)
		// 			} else {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.name}`, style: 'center' },
		// 				)
		// 			}

		// 			if (item.have_modifiers) {
		// 				item.modifiers.forEach(modifier => {
		// 					if (Array.isArray(modifier.selected)) {
		// 						modifier.selected.forEach(element => {
		// 							documentDefinition.content.push(
		// 								{ text: `- ${element.name}`, style: 'center' },
		// 							)
		// 						});
		// 					} else {
		// 						documentDefinition.content.push(
		// 							{ text: `- ${modifier.selected.name}`, style: 'center' },
		// 						)
		// 					}
		// 				});
		// 			}

		// 			if (item.notes) {
		// 				documentDefinition.content.push(
		// 					{ text: `Notas: ${item.notes}`, style: 'center' },
		// 					{ text: `------------------------------------------`, style: 'center' },
		// 				)
		// 			}

		// 		})
		// 	}

		// 	if (main_items.length > 0) {

		// 		documentDefinition.content.push(
		// 			{ text: `------------------------------------------`, style: 'center' },
		// 			{ text: `FUERTE`, style: 'center' },
		// 		)

		// 		main_items.forEach(item => {
		// 			if (item.is_variant) {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.parent.name} (${item.name})`, style: 'center' },
		// 				)
		// 			} else {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.name}`, style: 'center' },
		// 				)
		// 			}

		// 			if (item.have_modifiers) {
		// 				item.modifiers.forEach(modifier => {
		// 					if (Array.isArray(modifier.selected)) {
		// 						modifier.selected.forEach(element => {
		// 							documentDefinition.content.push(
		// 								{ text: `- ${element.name}`, style: 'center' },
		// 							)
		// 						});
		// 					} else {
		// 						documentDefinition.content.push(
		// 							{ text: `- ${modifier.selected.name}`, style: 'center' },
		// 						)
		// 					}
		// 				});
		// 			}

		// 			if (item.notes) {
		// 				documentDefinition.content.push(
		// 					{ text: `Notas: ${item.notes}`, style: 'center' },
		// 					{ text: `------------------------------------------`, style: 'center' },
		// 				)
		// 			}

		// 		})
		// 	}

		// 	if (dessert_items.length > 0) {

		// 		documentDefinition.content.push(
		// 			{ text: `------------------------------------------`, style: 'center' },
		// 			{ text: `FUERTE`, style: 'center' },
		// 		)

		// 		dessert_items.forEach(item => {
		// 			if (item.is_variant) {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.parent.name} (${item.name})`, style: 'center' },
		// 				)
		// 			} else {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.name}`, style: 'center' },
		// 				)
		// 			}

		// 			if (item.have_modifiers) {
		// 				item.modifiers.forEach(modifier => {
		// 					if (Array.isArray(modifier.selected)) {
		// 						modifier.selected.forEach(element => {
		// 							documentDefinition.content.push(
		// 								{ text: `- ${element.name}`, style: 'center' },
		// 							)
		// 						});
		// 					} else {
		// 						documentDefinition.content.push(
		// 							{ text: `- ${modifier.selected.name}`, style: 'center' },
		// 						)
		// 					}
		// 				});
		// 			}

		// 			if (item.notes) {
		// 				documentDefinition.content.push(
		// 					{ text: `Notas: ${item.notes}`, style: 'center' },
		// 					{ text: `------------------------------------------`, style: 'center' },
		// 				)
		// 			}

		// 		})
		// 	}

		// 	if (rest_items.length > 0) {

		// 		documentDefinition.content.push(
		// 			{ text: `------------------------------------------`, style: 'center' },
		// 		)

		// 		rest_items.forEach(item => {
		// 			if (item.is_variant) {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.parent.name} (${item.name})`, style: 'center' },
		// 				)
		// 			} else {
		// 				documentDefinition.content.push(
		// 					{ text: `(${item.quantity}) ${item.name}`, style: 'center' },
		// 				)
		// 			}

		// 			if (item.have_modifiers) {
		// 				item.modifiers.forEach(modifier => {
		// 					if (Array.isArray(modifier.selected)) {
		// 						modifier.selected.forEach(element => {
		// 							documentDefinition.content.push(
		// 								{ text: `- ${element.name}`, style: 'center' },
		// 							)
		// 						});
		// 					} else {
		// 						documentDefinition.content.push(
		// 							{ text: `- ${modifier.selected.name}`, style: 'center' },
		// 						)
		// 					}
		// 				});
		// 			}

		// 			if (item.notes) {
		// 				documentDefinition.content.push(
		// 					{ text: `Notas: ${item.notes}`, style: 'center' },
		// 					{ text: `------------------------------------------`, style: 'center' },
		// 				)
		// 			}

		// 		})
		// 	}

		// 	pdfMake.createPdf(documentDefinition).print();

		// }
	}

	printOrder(order, subtotal, total, tip, discount, order_return = false, exempt = 0) {
		if (this.printers.length > 0 || this.auth.peripheral !== false) {

			const encoder = new EscPosEncoder();
			const result = encoder.initialize();
			let tipo = order.type;

			// order.creation_date = order.creation_date.toDate();
			console.log(order);

			if (order.type == 'takeout') {
				tipo = 'LLEVAR'
			} else if (order.type == 'tables') {
				tipo = `MESA #${order.table}`
			} else if (order.type == 'delivery') {
				tipo = `DELIVERY`
			}

			let print_info = [];

			if (order_return) {
				let header = result
					.align('center')
					// .qrcode(order.order_number)
					.newline()
					.line('------------------------------------------')
					.line('ANULACION ANULACION ANULACION ANULACION')
					.line('------------------------------------------')
					.newline()
					.newline()
					.encode();
				print_info.push(header)
			}

			let header_data = result
				.align('center')
				.size('normal')
				.bold(true)
				.line(this.auth.account_data.name)
				.line(`Telefono: ${this.auth.account_data.phone}`)
				.line(`FECHA RESOLUCION: ${new Date(order.creation_date).getDate()}/${new Date(order.creation_date).getMonth() + 1}/${new Date(order.creation_date).getFullYear()} | ${new Date(order.creation_date).getHours()}:${new Date(order.creation_date).getMinutes()}`)
				.line('------------------------------------------')
				.line(`Atendio: ${order.user.name} ${order.user.lastname}`)
				.bold(true)
				.line(`${tipo ? tipo : ''}`)
				.line(`ORDEN No: ${order.order_number}`)
				.bold(false)
				.line('------------------------------------------')
				.align('left')
				.encode()
			print_info.push(header_data)

			if (order.type == 'delivery') {
				let delivery_data = result
					.align('center')
					.size('normal')
					.line(`${order.delivery.name}`)
					.line(`# Pedido ${order.delivery.delivery_number}`)
					.bold(false)
					.align('left')
					.encode()
				print_info.push(delivery_data)
			}

			if (order_return) {
				let header_name = result
					.align('center')
					.line(`>>PRE CUENTA<<`)
					.newline()
					.encode()
				print_info.push(header_name)
			}

			if (order.items) {
				order.items.forEach(item => {

					let item_total;
					let cantidad;
					let nombre;
					let total;
					let precio_unit;

					let name = item.name;
					if (item.is_variant) {
						name = `${item.parent.name} (${item.name})`
					}
					if (item.is_promotion) {
						name = `${item.promotion.name}`
					}

					item_total = item.price * item.quantity + item.total_taxes;

					cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
					nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
					precio_unit = (item.price.toFixed(2).length <= 9) ? 9 - item.price.toFixed(2).length : 0;


					if (name.length > 20) {
						name = name.substring(0, 17) + '...'
					}

					if (this.auth.peripheral !== false) {
						cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
						nombre = (name.substring(0, 15).length <= 13) ? 13 - name.substring(0, 15).length : 0;
						total = (item_total.toFixed(2).length <= 5) ? 5 - item_total.toFixed(2).length : 0;
						precio_unit = (item.price.toFixed(2).length <= 5) ? 5 - item.price.toFixed(2).length : 0;

						if (name.length > 10) {
							name = name.substring(0, 10) + '...'
						}
					}

					let item_price = item.price;
					let item_quantity = item.quantity;

					let item_data = result
						.bold(true)
						.align('left')
						.size('normal')
						.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(item_price)} x${item_quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
						.bold(false)
						.encode();
					print_info.push(item_data)

					if (item.have_modifiers) {
						item.modifiers.forEach(modifier => {
							if (Array.isArray(modifier.selected)) {
								modifier.selected.forEach(element => {
									let modifier_data = result
										.size('small')
										.align('left')
										.bold(false)
										.line(`- ${element.name}`)
										.encode();
									print_info.push(modifier_data)
								});
							} else {
								let modifier_data = result
									.size('small')
									.align('left')
									.bold(false)
									.line(`- ${(modifier.selected.name).toUpperCase()}`)
									.encode();
								print_info.push(modifier_data)
							}
						});
					}

					if (item.notes) {
						let notes_data = result
							.size('small')
							.align('left')
							.bold(false)
							.line(`${item.notes}`)
							.encode();
						print_info.push(notes_data)
					}

					let item_end = result
						.align('center')
						.newline()
						.encode();
					print_info.push(item_end)
				});
			} else {
				let item_total;
				let cantidad;
				let nombre;
				let total;
				let precio_unit;

				let name = order.item.name;
				if (order.item.is_variant) {
					name = `${order.item.parent.name} (${order.item.name})`
				}
				if (order.item.is_promotion) {
					name = `${order.item.promotion.name}`
				}

				item_total = order.item.price * order.item.quantity;

				cantidad = (order.item.quantity.toString().length <= 4) ? 4 - order.item.quantity.toString().length : 0;
				nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
				precio_unit = (order.item.price.toFixed(2).length <= 9) ? 9 - order.item.price.toFixed(2).length : 0;


				if (name.length > 20) {
					name = name.substring(0, 17) + '...'
				}

				if (this.auth.peripheral !== false) {
					cantidad = (order.item.quantity.toString().length <= 4) ? 4 - order.item.quantity.toString().length : 0;
					nombre = (name.substring(0, 15).length <= 13) ? 13 - name.substring(0, 15).length : 0;
					total = (item_total.toFixed(2).length <= 5) ? 5 - item_total.toFixed(2).length : 0;
					precio_unit = (order.item.price.toFixed(2).length <= 5) ? 5 - order.item.price.toFixed(2).length : 0;

					if (name.length > 10) {
						name = name.substring(0, 10) + '...'
					}
				}

				let item_price = order.item.price;
				let item_quantity = order.item.quantity;

				if (exempt > 0) {
					item_total = item_total - order.item.hidden_taxes - order.item.total_taxes
					item_price = (item_total) / item_quantity;
				}

				let item_data = result
					.bold(true)
					.align('left')
					.size('normal')
					.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(item_price)} x${item_quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
					.bold(false)
					.encode();
				print_info.push(item_data)

				if (order.item.have_modifiers) {
					order.item.modifiers.forEach(modifier => {
						if (Array.isArray(modifier.selected)) {
							modifier.selected.forEach(element => {
								let modifier_data = result
									.size('small')
									.align('left')
									.bold(false)
									.line(`- ${element.name}`)
									.encode();
								print_info.push(modifier_data)
							});
						} else {
							let modifier_data = result
								.size('small')
								.align('left')
								.bold(false)
								.line(`- ${(modifier.selected.name).toUpperCase()}`)
								.encode();
							print_info.push(modifier_data)
						}
					});
				}

				if (order.item.notes) {
					let notes_data = result
						.size('small')
						.align('left')
						.bold(false)
						.line(`${order.item.notes}`)
						.encode();
					print_info.push(notes_data)
				}

				let item_end = result
					.align('center')
					.newline()
					.encode();
				print_info.push(item_end)
			}

			if (order_return) {
				// order.return_date = order.return_date.toDate();

				let payment_header = result
					.align('left')
					.bold(true)
					.size('normal')
					.line(' '.repeat(28) + 'SUBTOTAL: ' + this.formatToCurrency(subtotal))
					.align('center')
					.size(`normal`)
					.bold(true)
					.newline()
					.size('big')
					.line(`TOTAL A PAGAR: ${this.formatToCurrency(total)}`)
					.size('normal')
					.bold(false)
					.encode();
				print_info.push(payment_header)

				let footer_data = result
					.newline()
					.align('center')
					.line('------------------------------------------')
					.align('left')
					.line(`Fecha de anulacion: ${new Date(order.return_date).getDate()}/${new Date(order.return_date).getMonth() + 1}/${new Date(order.return_date).getFullYear()} | ${new Date(order.return_date).getHours()}:${new Date(order.return_date).getMinutes()}`)
					.line(`Realizada por: ${order.user.name} ${order.user.lastname}`)
					.line(`Motivo: ${order.note}`)
					.line(`Notas: ${order.message}`)
					.newline()
					.newline()
					.newline()
					.align('center')
					.line('__________________________')
					.line('Firma')
					.newline()
					.newline()
					.newline()
					.line('------------------------------------------')
					.line('ANULACION ANULACION ANULACION ANULACION')
					.line('------------------------------------------')
					.newline()
					.newline()
					.newline()
					.newline()
					.newline()
					.cut()
					.encode();
				print_info.push(footer_data)
			} else {

				let rep_subtotal = 28;
				let rep_tip = 29;
				let rep_exempt = 29;
				let rep_disc = 26;
				let rep_grav = 28;

				if (this.auth.peripheral !== false) {
					rep_subtotal = 14;
					rep_tip = 15;
					rep_exempt = 14;
					rep_disc = 11;
					rep_grav = 15;
				}

				if (!order.iva_rete) {
					order.iva_rete = 0;
				}

				let payment_header = result
					.align('left')
					.bold(true)
					.size('normal')
					.line(' '.repeat(rep_subtotal) + 'SUBTOTAL: ' + this.formatToCurrency(subtotal + order.iva_rete))
					.encode();
				print_info.push(payment_header)

				if (tip > 0) {
					let tip_section = result
						.align('left')
						.bold(true)
						.size('normal')
						.line(' '.repeat(rep_tip) + 'Propina: ' + this.formatToCurrency(tip))
						.encode();
					print_info.push(tip_section)
				}

				if (discount > 0) {
					let discount_section = result
						.align('left')
						.bold(true)
						.size('normal')
						.line(' '.repeat(rep_disc) + 'Descuento: -' + this.formatToCurrency(discount))
						.encode();
					print_info.push(discount_section)
				}

				if (order.client) {
					if (order.client.exempt) {
						let exempt_section = result
							.align('left')
							.bold(true)
							.size('normal')
							.line(' '.repeat(rep_tip) + 'Exento: -' + this.formatToCurrency(exempt))
							.encode();
						print_info.push(exempt_section)
					}
				}

				let payment = result
					.newline()
					.align('center')
					.size('big')
					.line(`TOTAL A PAGAR: ${this.formatToCurrency(total - exempt)}`)
					.size('normal')
					.bold(false)
					.encode();
				print_info.push(payment)

				if (order.client) {
					let client_name = result
						.align('center')
						.newline()
						.line('Cliente: ' + order.client.name)
						.encode();
					print_info.push(client_name)

					if (order.client.phone) {
						let client_name = result
							.align('center')
							.line('Telefono: ' + order.client.phone)
							.encode();
						print_info.push(client_name)
					}
				}

				let footer_data = result
					.align('center')
					.newline()
					.newline()
					.line(order.notes ? 'Notas' : '')
					.newline()
					.line(order.notes ? order.notes : '')
					.newline()
					.newline()
					.line('Esta es una pre-cuenta')
					.line('Exija su tiquete de pago')
					.newline()
					.newline()
					.newline()
					.newline()
					.cut()
					.encode();
				print_info.push(footer_data)
			}

			this.print(print_info, 'receipt');
		}
	}

	pdfOrder(order, subtotal, total, tip, discount, order_return = false, exempt = 0) {
		let iva_rete = 0;

		let tipo = order.type;

		// order.creation_date = order.creation_date.toDate();

		if (order.type == 'takeout') {
			tipo = 'LLEVAR'
		} else if (order.type == 'tables') {
			tipo = `MESA #${order.table}`
		} else if (order.type == 'delivery') {
			tipo = `DELIVERY`
		}

		const documentDefinition = {
			styles: {
				center: {
					fontSize: 10,
					alignment: 'center'
				},
				left: {
					fontSize: 10,
					alignment: 'left'
				},
				right: {
					fontSize: 10,
					alignment: 'right'
				},
				small: {
					alignment: 'center',
					fontSize: 8,
				},
			},
			pageSize: {
				width: 305,
				height: 'auto',
			},
			content: [
			],
		};

		if (order_return) {
			documentDefinition.content.push(
				{ text: `ANULACION ANULACION ANULACION`, style: 'center' },
				{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5 },
			)
		}

		documentDefinition.content.push(
			{ text: `${this.auth.account_data.name}`, style: 'center' },
			{ text: `TEL: ${this.auth.account_data.phone}`, style: 'center' },
			{ text: `Fecha: ${new Date(order.creation_date).getDate()}/${new Date(order.creation_date).getMonth() + 1}/${new Date(order.creation_date).getFullYear()} | ${new Date(order.creation_date).getHours()}:${new Date(order.creation_date).getMinutes()}`, style: 'center' },
			{ text: `Atendio: ${order.user.name} ${order.user.lastname}`, style: 'center' },
			{ text: `${tipo}`, style: 'center' },
			{ text: `Orden No: ${order.order_number}`, style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 3 },
			{ text: ` `, style: 'center' },
		)

		if (order.type == 'delivery') {

			documentDefinition.content.push(
				{ text: `${order.delivery.name}`, style: 'center' },
				{ text: `# Pedido ${order.delivery.order_number}`, style: 'center' },
			)
		}

		if (!order_return) {
			documentDefinition.content.push(
				{ text: `>>PRE CUENTA<<`, style: 'center', bold: true },
				{ text: ` `, style: 'center' },
				{ text: ` `, style: 'center' },
			)
		}

		if (order.items) {
			order.items.forEach(item => {

				let item_total;
				let cantidad;
				let nombre;
				let precio_unit;

				let name = item.name;
				if (item.is_variant) {
					name = `${item.parent.name} (${item.name})`
				}
				if (item.is_promotion) {
					name = `${item.promotion.name}`
				}

				item_total = item.price * item.quantity + item.total_taxes;

				cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
				nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
				precio_unit = (item.price.toFixed(2).length <= 9) ? 9 - item.price.toFixed(2).length : 0;

				let item_quantity = item.quantity;

				documentDefinition.content.push(
					{
						columns: [
							{
								width: 'auto',
								maxHeight: 15,
								text: `${item_quantity} x `,
								marginRight: 5,
								style: 'left',
								marginBottom: 6
							},
							{
								width: 100,
								text: name,
								style: 'left',
								marginBottom: 6
							},
							{
								maxHeight: 15,
								text: this.formatToCurrency(item_total),
								style: 'right',
								marginBottom: 6
							}
						]
					}
				)

				if (item.have_modifiers) {
					item.modifiers.forEach(modifier => {
						if (modifier.multiple) {
							modifier.selected.forEach(mod_element => {
								documentDefinition.content.push(
									{ text: `- ${(mod_element.name)}`, style: 'left', fontSize: 10, marginBottom: 6, color: '#7f828a' },
								)
							});
						} else {
							documentDefinition.content.push(
								{ text: `- ${(modifier.selected.name)}`, marginBottom: 6, style: 'left', fontSize: 10, color: '#7f828a' },
							)

						}
					});
				}

				if (item.notes != false) {
					documentDefinition.content.push(
						{ text: item.notes, style: 'left', marginBottom: 8 },
					)
				}
			});
		} else {
			let item_total;
			let cantidad;
			let nombre;
			let total;
			let precio_unit;

			let name = order.item.name;
			if (order.item.is_variant) {
				name = `${order.item.parent.name} (${order.item.name})`
			}
			if (order.item.is_promotion) {
				name = `${order.item.promotion.name}`
			}

			item_total = order.item.price * order.item.quantity;

			cantidad = (order.item.quantity.toString().length <= 4) ? 4 - order.item.quantity.toString().length : 0;
			nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
			precio_unit = (order.item.price.toFixed(2).length <= 9) ? 9 - order.item.price.toFixed(2).length : 0;


			let item_price = order.item.price;
			let item_quantity = order.item.quantity;

			if (exempt > 0) {
				item_total = item_total - order.item.hidden_taxes - order.item.total_taxes
				item_price = (item_total) / item_quantity;
			}

			documentDefinition.content.push(
				{
					columns: [
						{
							width: 'auto',
							maxHeight: 15,
							text: `${item_quantity} x `,
							marginRight: 5,
							style: 'left',
							marginBottom: 6
						},
						{
							width: 100,
							text: name,
							style: 'left',
							marginBottom: 6
						},
						{
							maxHeight: 15,
							text: this.formatToCurrency(item_total),
							style: 'right',
							marginBottom: 6
						}
					]
				}
			)

			if (order.item.have_modifiers) {
				order.item.modifiers.forEach(modifier => {
					if (Array.isArray(modifier.selected)) {
						modifier.selected.forEach(element => {
							documentDefinition.content.push(
								{ text: `- ${element.name}`, style: 'left' },
							)
						});
					} else {
						documentDefinition.content.push(
							{ text: `- ${(modifier.selected.name).toUpperCase()}`, style: 'left' },
						)
					}
				});
			}

			if (order.item.notes) {
				documentDefinition.content.push(
					{ text: `${order.item.notes}`, style: 'left' },
				)
			}
		}

		if (order_return) {
			documentDefinition.content.push(
				{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 6 },
				{
					columns: [
						{ text: 'Subtotal:', width: 100, style: 'left', marginBottom: 6 },
						{ text: `${this.formatToCurrency(subtotal)}`, style: 'right', marginBottom: 6 }
					],
				}
			)
			documentDefinition.content.push(
				{
					columns: [
						{
							width: 100,
							text: 'TOTAL A PAGAR:',
							style: 'left',
							fontSize: 13,
							bold: true,
							marginBottom: 6
						},
						{
							text: `${this.formatToCurrency(total)}`,
							style: 'right',
							fontSize: 13,
							bold: true,
							marginBottom: 6
						}
					]
				}
			)

			documentDefinition.content.push(
				{ text: ` `, style: 'center' },
				{ text: ` `, style: 'center' },
				// { text: ' '.repeat(28) + 'SUBTOTAL: ' + this.formatToCurrency(subtotal), style: 'center' },
				// { text: `TOTAL A PAGAR: ${this.formatToCurrency(total)}`, style: 'center' },
				{ text: `Fecha de anulacion: ${new Date(order.return_date).getDate()}/${new Date(order.return_date).getMonth() + 1}/${new Date(order.return_date).getFullYear()} | ${new Date(order.return_date).getHours()}:${new Date(order.return_date).getMinutes()}`, style: 'center' },
				{ text: `Realizada por: ${order.user.name} ${order.user.lastname}`, style: 'center' },
				{ text: `Motivo: ${order.note}`, style: 'center' },
				{ text: `Notas: ${order.message}`, style: 'center' },
				{ text: `—`.repeat(100), style: 'center', maxHeight: 15 },
				{ text: `ANULACION ANULACION ANULACION ANULACION`, style: 'center' },
				{ text: `—`.repeat(100), style: 'center', maxHeight: 15 },
				{ text: ` `, style: 'center' },
				{ text: ` `, style: 'center' },
				{ text: `__________________________`, style: 'center' },
				{ text: `Firma`, style: 'center' },
				{ text: ` `, style: 'center' },
				{ text: ` `, style: 'center' },
			)

		} else {

			if (!order.iva_rete) {
				order.iva_rete = 0;
			}

			documentDefinition.content.push(
				{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 6 },
				{
					columns: [
						{ text: 'Subtotal:', width: 100, style: 'left', marginBottom: 6 },
						{ text: `${this.formatToCurrency(subtotal + order.iva_rete)}`, style: 'right', marginBottom: 6 }
					],
				}
			)

			if (tip > 0) {
				documentDefinition.content.push(
					{
						columns: [
							{ width: 100, text: 'Propina:', style: 'left', marginBottom: 6 },
							{ text: `${this.formatToCurrency(tip)}`, style: 'right', marginBottom: 6 }
						],
					}
				)
			}
			if (discount > 0) {
				documentDefinition.content.push(
					{
						columns: [
							{ width: 100, text: 'Descuento:', style: 'left', marginBottom: 6 },
							{ text: `-${this.formatToCurrency(discount)}`, style: 'right', marginBottom: 6 }
						],
					},
				)
			}

			if (order.client) {
				if (order.client.exempt) {
					documentDefinition.content.push(
						{
							columns: [
								{ text: 'Exento:', width: 100, style: 'left', marginBottom: 6 },
								{ text: `-${this.formatToCurrency(exempt)}`, style: 'right', marginBottom: 6 }
							],
						}
					)
				}

				if (order.client.large_contributor && total > 100) {

					documentDefinition.content.push(
						{
							columns: [
								{ text: 'Iva retenido:', width: 100, style: 'left', marginBottom: 6 },
								{ text: `-${this.formatToCurrency(order.iva_rete)}`, style: 'right', marginBottom: 6 }
							],
						}
					)
				}

			}

			documentDefinition.content.push(
				{
					columns: [
						{
							width: 100,
							text: 'Total:',
							style: 'left',
							fontSize: 13,
							bold: true,
							marginBottom: 6
						},
						{
							text: `${this.formatToCurrency(total - exempt)}`,
							style: 'right',
							fontSize: 13,
							bold: true,
							marginBottom: 6
						}
					]
				},
				{ text: `—`.repeat(100), style: 'center', maxHeight: 15 },
				{ text: ` `, style: 'center' },
			)

			if (order.client) {
				documentDefinition.content.push({ text: `Cliente: ${order.client.name}`, style: 'center' });
				if (order.client.phone) {
					documentDefinition.content.push({ text: `Telefono: ${order.client.phone}`, style: 'center' });
				}
			}

			documentDefinition.content.push(
				{ text: ` `, style: 'center' },
				{ text: order.notes ? 'Notas' : '', style: 'center' },
				{ text: order.notes ? order.notes : '', style: 'center' },
				{ text: ` `, style: 'center' },
				{ text: ` `, style: 'center' },
				{ text: `Esta es una pre-cuenta`, style: 'center' },
				{ text: `Exija su tiquete de pago`, style: 'center' },
				{ text: ` `, style: 'center' },
			)
		}

		pdfMake.createPdf(documentDefinition).print();
	}

	formatToCurrency(amount) {
		return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
	}

	printTicket(ticket) {

		if (this.printers.length > 0 || this.auth.peripheral !== false)
			this.db.collection(`accounts`).ref
				.doc(this.auth.account)
				.get()
				.then(response => {

					let account = response.data();

					let encoder = new EscPosEncoder();

					let result = encoder.initialize();

					let print_info = [];

					let header_data = result
						.align('center')
						.size('normal')
						.bold(true)
						.line(account.name)
						.line(`${ticket.address}`)
						.line(`Telefono: ${ticket.phone}`)
						.align('left')
						.encode()
					print_info.push(header_data)


					if (ticket.fiscal) {
						if (ticket.fiscal_type == 'dte') {
							let type = 'Credito Fiscal';

							if (ticket.fiscal.type == 'invoice') {
								type = 'Factura';
							}
							let header_fiscal = result
								.align('center')
								.size('normal')
								.bold(true)
								.line(ticket.fiscal.business_name)
								.line(`Codigo de generación: ${ticket.fiscal.nit}`)
								.line(`${ticket.fiscal.dte.codigoGeneracion}`)
								.line(`Numero de control: ${ticket.fiscal.nit}`)
								.line(`${ticket.fiscal.dte.json.identificacion.numeroControl}`)
								.line(`Sello de recepcion: ${ticket.fiscal.nit}`)
								.line(`${ticket.fiscal.dte.selloRecibido}`)
								.line(`Tipo de documento:`)
								.line(`${type}`)
								.qrcode(`https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${ticket.fiscal.dte.codigoGeneracion}&fechaEmi=${ticket.fiscal.dte.json.identificacion.fecEmi}`, 1, 4, 'l')
								.encode()
							print_info.push(header_fiscal)

						} else {
							let header_fiscal = result
								.align('center')
								.size('normal')
								.bold(true)
								.line(ticket.fiscal.business_name)
								.line(`NIT: ${ticket.fiscal.nit}`)
								.line(`NRC: ${ticket.fiscal.nrc}`)
								.encode()
							print_info.push(header_fiscal)

							if (ticket.fiscal.type == 'ticket') {
								let fiscal = result
									.bold(true)
									.line(`FECHA RESOLUCION: ${this.components.convertToDate(ticket.fiscal.ticket.resolution_date.toDate())}`)
									.align('center')
									.line(account.fiscal ? `Giro. ${account.fiscal.activity ? account.fiscal.activity.value : account.industry}` : ``)
									.line(`Res No. ${ticket.fiscal.ticket.res}`)
									.line(`Del ${ticket.fiscal.ticket.serie}|${ticket.fiscal.ticket.range_from} al ${ticket.fiscal.ticket.serie}|${ticket.fiscal.ticket.range_to}`)
									.line(`Tiquete No. ${ticket.fiscal.ticket.current_ticket_number}`)
									.line(`Caja. ${ticket.fiscal.ticket.device_number}`)
									.align('center')
									.encode();
								print_info.push(fiscal)
							} else if (ticket.fiscal.type == 'ccf') {
								let fiscal = result
									.bold(true)
									.align('center')
									.line(`CCF #${ticket.fiscal.ccf.number}`)
									.align('center')
									.encode();
								print_info.push(fiscal)
							} else if (ticket.fiscal.type == 'invoice') {
								let fiscal = result
									.bold(true)
									.align('center')
									.line(`Factura #${ticket.fiscal.invoice.number}`)
									.align('center')
									.encode();
								print_info.push(fiscal)
							}
						}
					}

					let type = ticket.type;
					if (ticket.type == 'takeout') {
						type = 'Llevar'
					} else if (ticket.type == 'delivery') {
						type = 'Llevar'
					} else if (ticket.type == 'tables') {
						type = `Mesas ${ticket.table_area} ${ticket.table}`
					}

					let subheader_data = result
						.align('center')
						.line('------------------------------------------')
						.size('normal')
						.bold(true)
						.line(`FECHA: ${this.components.convertToDate(ticket.completed_date)} ${this.components.convertToHours(ticket.completed_date)} `)
						.line(`Atendio: ${ticket.user.name} ${ticket.user.lastname}`)
						.bold(true)
						.line(`ORDEN No: ${ticket.order_number}`)
						.line(`${type}`)
						.bold(false)
						.line('------------------------------------------')
						.newline()
						.align('left')
						.encode()
					print_info.push(subheader_data)

					let item_data = result
						.bold(true)
						.align('left')
						.size('normal')
						.bold(false)
						.encode();
					print_info.push(item_data)

					ticket.items.forEach(item => {
						let item_total;
						let cantidad;
						let nombre;
						let total;
						let precio_unit;


						let name = item.name;
						if (item.is_variant) {
							name = `${item.parent.name} (${item.name})`
						}
						if (item.is_promotion) {
							name = `${item.promotion.name}`
						}


						item_total = item.price * item.quantity;

						cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
						nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
						total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
						precio_unit = (item.price.toFixed(2).length <= 9) ? 9 - item.price.toFixed(2).length : 0;

						if (name.length > 20) {
							name = name.substring(0, 17) + '...'
						}

						if (this.auth.peripheral !== false) {
							cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
							nombre = (name.substring(0, 15).length <= 13) ? 13 - name.substring(0, 15).length : 0;
							total = (item_total.toFixed(2).length <= 5) ? 5 - item_total.toFixed(2).length : 0;
							precio_unit = (item.price.toFixed(2).length <= 5) ? 5 - item.price.toFixed(2).length : 0;

							if (name.length > 10) {
								name = name.substring(0, 10) + '...'
							}
						}

						let item_price = item.price;
						let item_quantity = item.quantity;

						if (ticket.exempt > 0) {
							item_total = item_total - item.hidden_taxes - item.total_taxes
							item_price = (item_total) / item_quantity;
						}

						let item_data = result
							.bold(true)
							.align('left')
							.size('normal')
							.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(item_price)} x${item_quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
							.bold(false)
							.encode();
						print_info.push(item_data)

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {

								if (modifier.multiple) {
									modifier.selected.forEach(mod_element => {
										let modifier_data = result
											.size('small')
											.align('left')
											.bold(false)
											.line(`- ${(mod_element.name).toUpperCase()}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {

									let modifier_data = result
										.size('small')
										.align('left')
										.bold(false)
										.line(`- ${(modifier.selected.name).toUpperCase()}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.size('small')
								.align('left')
								.bold(false)
								.line(`${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}
						let item_end = result
							.align('center')
							.newline()
							.encode();
						print_info.push(item_end)
					});

					let rep_subtotal = 28;
					let rep_tip = 29;
					let rep_exempt = 29;
					let rep_disc = 26;
					let rep_grav = 28;

					if (this.auth.peripheral !== false) {
						rep_subtotal = 13;
						rep_tip = 14;
						rep_exempt = 12;
						rep_disc = 11;
						rep_grav = 13;
					}

					let total_grav = ticket.subtotal + ticket.hidden_taxes + ticket.taxes - ticket.exempt;
					let total_exempt = 0;

					if (ticket.exempt > 0) {
						total_grav = 0;
						total_exempt = ticket.subtotal + ticket.hidden_taxes + ticket.taxes - ticket.exempt;
					}

					let payment_header = result
						.line('------------------------------------------')
						.align('left')
						.bold(true)
						.size('normal')
						.line(`${' '.repeat(rep_subtotal)} SUBTOTAL: ${this.formatToCurrency(ticket.subtotal + ticket.hidden_taxes + ticket.taxes + ticket.discount + ticket.iva_discount - ticket.exempt)}`)
						.line(`${' '.repeat(rep_disc)} Descuentos:-${this.formatToCurrency(ticket.discount + ticket.iva_discount)}`)
						.line(`${' '.repeat(rep_grav)} Gravadas: ${this.formatToCurrency(total_grav)}`)
						.line(`${' '.repeat(rep_grav)}  Exentas: ${this.formatToCurrency(total_exempt)}`)
						.encode();
					print_info.push(payment_header)

					if (this.auth.license.membership.type == 'resto') {
						let tip = result
							.line(`${' '.repeat(rep_tip)} Propina: ${this.formatToCurrency(ticket.tip)}`)
							.encode();
						print_info.push(tip)
					}

					let payment_total = result
						.align('center')
						.size(`normal`)
						.bold(true)
						.newline()
						.size('big')
						.line(`TOTAL: ${this.formatToCurrency(ticket.total)}`)
						.size('small')
						.line('------------------------------------------')
						.line(`Pagos realizados`)
						.size('normal')
						.bold(false)
						.encode();
					print_info.push(payment_total)

					let rep_cash = 31;
					let rep_card = 30;
					let rep_giftcard = 31;
					if (this.auth.peripheral !== false) {
						rep_cash = 18;
						rep_card = 17;
						rep_giftcard = 18;
					}

					ticket.payments.forEach(element => {
						if (element.method == 'cash') {
							let payment_data = result
								.align('left')
								.line('Efectivo' + ' '.repeat(rep_cash) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						} else if (element.method == 'card') {
							let payment_data = result
								.align('left')
								.line('T.Credito' + ' '.repeat(rep_card) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						} else if (element.method == 'giftcard') {
							let payment_data = result
								.align('left')
								.line('Giftcard' + ' '.repeat(rep_card) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						} else if (element.method == 'check') {
							let payment_data = result
								.align('left')
								.line('Cheque' + ' '.repeat(rep_card) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						} else if (element.method == 'transfer') {
							let payment_data = result
								.align('left')
								.line('Transferencia' + ' '.repeat(rep_card) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						} else if (element.method == 'link') {
							let payment_data = result
								.align('left')
								.line('Link de pago' + ' '.repeat(rep_card) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						} else if (element.method == 'other') {
							let payment_data = result
								.align('left')
								.line('Otros Metodos' + ' '.repeat(rep_card) + this.formatToCurrency(element.total))
								.encode();
							print_info.push(payment_data)
						}
					});

					let rep_change = 30;
					let rep_no = 26;

					if (this.auth.peripheral !== false) {
						rep_change = 18;

						rep_no = 14;
					}

					let payment_footer = result
						.bold(true)
						.align('center')
						.size('big')
						.newline()
						.line(`TOTAL PAGADO: ${this.formatToCurrency(ticket.total)}`)
						.size('normal')
						.line('------------------------------------------')

						// .line(`${' '.repeat(27)} Impuestos: ${this.formatToCurrency(ticket.hidden_taxes + ticket.taxes)}`)
						.bold(false)
						.encode();
					print_info.push(payment_footer)

					if (ticket.client) {
						let client_name = result
							.align('center')
							.newline()
							.line('Cliente: ' + ticket.client.name)
							.encode();
						print_info.push(client_name)

						if (ticket.client.phone) {
							let client_name = result
								.align('center')
								.line('Telefono: ' + ticket.client.phone)
								.encode();
							print_info.push(client_name)
						}
					}

					let footer_data = result
						.newline()
						.align('center')
						.line('******************************************')
						.line('TODOS LOS MONTOS EN USD')
						.line('******************************************')
						.align('left')
						.size('small')
						.align('center')
						.line('Dejanos tu feedback y comentarios, todo lo que tengas para decir es util y nos ayuda a servirte mejor')
						.newline()
						.newline()
						.newline()
						.line('Powered by QUANTO')
						.line('www.quantopos.com')
						.newline()
						.newline()
						.newline()
						.newline()
						.cut()
						.encode();
					print_info.push(footer_data)


					let length = 0;
					print_info.forEach(item => {
						length += item.length;
					});

					// UNE TODOS LOS ARRAYS METIDOS EN PRINT INFO
					// Create a new array with total length and merge all source arrays.
					let resultByte = new Uint8Array(length);
					let offset = 0;
					print_info.forEach(item => {
						resultByte.set(item, offset);
						offset += item.length;
					});

					if (this.auth.peripheral !== false) {
						this.ble.write(this.auth.peripheral, '49535343-FE7D-4AE5-8FA9-9FAFD205E455', '49535343-ACA3-481C-91EC-D85E28A60318', resultByte.buffer).then(data => {

						}, err => {
							this.auth.peripheral = false;
							this.printers.forEach(printer => {
								if (printer.payment_receipt) {
									var socket = new Socket();
									socket.open(printer.ip, printer.port, data => {
										socket.write(resultByte, () => {
											socket.shutdownWrite((suc) => {

											}, (err) => {
												console.log(err);
											});
										}, err => {
											console.log(err);
										});
									}, errorMessage => {
										console.log(printer);
										console.log(errorMessage);
									});
								}
							});
						})
					} else {
						this.printers.forEach(printer => {
							if (printer.payment_receipt) {
								var socket = new Socket();
								socket.open(printer.ip, printer.port, data => {
									socket.write(resultByte, () => {
										socket.shutdownWrite((suc) => {

										}, (err) => {
											console.log(err);
										});
									}, err => {
										console.log(err);
									});
								}, errorMessage => {
									console.log(printer);
									console.log(errorMessage);
								});
							}
						});
					}


					// this.print(print_info, 'receipt');

				});

	}

	async loadImageAsBase64(imagePath: string): Promise<string> {
		try {
			const imageBlob = await this.http.get(imagePath, { responseType: 'blob' }).toPromise();
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => {
					if (reader.result && typeof reader.result === 'string') {
						const base64Image = reader.result.split(',')[1];
						resolve(base64Image);
					} else {
						reject('No se pudo cargar la imagen');
					}
				};
				reader.readAsDataURL(imageBlob);
			});
		} catch (error) {
			console.error('Error al cargar la imagen', error);
			throw error;
		}
	}

	pdfTicket(ticket) {
		this.db.collection(`accounts`).ref
			.doc(this.auth.account)
			.get()
			.then(async response => {
				let account = response.data();
				// let base64Image;
				// if (account.logo) {
				// 	base64Image = await this.loadImageAsBase64(account.logo.url);
				// }

				const documentDefinition: any = {
					styles: {
						center: {
							fontSize: 10,
							alignment: 'center'
						},
						left: {
							fontSize: 10,
							alignment: 'left'
						},
						right: {
							fontSize: 10,
							alignment: 'right'
						},
						small: {
							alignment: 'center',
							fontSize: 8,
						},
					},
					pageSize: {
						width: 305,
						height: 'auto',
					},
					content: [
						{ text: `  `, style: 'center', marginBottom: 3 },
						{ text: account.name.toUpperCase(), style: 'center', bold: true, marginBottom: 3 },
						{ text: ticket.address.toUpperCase(), style: 'center', marginBottom: 3 },
						{ text: `TEL: ${ticket.phone}`, style: 'center', marginBottom: 3 },
						{ text: ticket.fiscal.business_name, style: 'center', marginBottom: 3 },

					]
				};


				if (ticket.fiscal_type == 'dte') {
					let type = 'Credito Fiscal';

					if (ticket.fiscal.type == 'invoice') {
						type = 'Factura';
					}

					documentDefinition.content.push(
						{ text: `   `, style: 'center' },
						{ text: `Codigo de generación:`, style: 'center' },
						{ text: `${ticket.fiscal.dte.codigoGeneracion}`, style: 'center' },
						{ text: `   `, style: 'center' },
						{ text: `Numero de control:`, style: 'center' },
						{ text: `${ticket.fiscal.dte.json.identificacion.numeroControl}`, style: 'center' },
						{ text: `   `, style: 'center' },
						{ text: `Sello de recepcion:`, style: 'center' },
						{ text: `${ticket.fiscal.dte.selloRecibido}`, style: 'center' },
						{ text: `   `, style: 'center' },
						{ text: `Tipo de documento:`, style: 'center' },
						{ text: `${type}`, style: 'center' },
						{ text: `   `, style: 'center' },
						{ qr: `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${ticket.fiscal.dte.codigoGeneracion}&fechaEmi=${ticket.fiscal.dte.json.identificacion.fecEmi}`, fit: '100', eccLevel: 'M', style: 'center' },
					)

				} else {
					if (ticket.fiscal.type == 'ticket') {
						documentDefinition.content.push(
							{ text: `NIT: ${ticket.fiscal.nit}`, style: 'center', marginBottom: 3 },
							{ text: `NRC: ${ticket.fiscal.nrc}`, style: 'center', marginBottom: 3 },
							{ text: `FECHA RESOLUCION: ${this.components.convertToDate(ticket.fiscal.ticket.resolution_date.toDate())}`, style: 'center', marginBottom: 3 },
							{ text: `Giro. ${account.industry}`, style: 'center', marginBottom: 3 },
							{ text: `Res No. ${ticket.fiscal.ticket.res}`, style: 'center', marginBottom: 3 },
							{ text: `Del ${ticket.fiscal.ticket.serie}|${ticket.fiscal.ticket.range_from} al ${ticket.fiscal.ticket.serie}}`, style: 'center', marginBottom: 3 },
							{ text: `Tiquete No. ${ticket.fiscal.ticket.current_ticket_number}`, style: 'center', marginBottom: 3 },
							{ text: `Caja. ${ticket.fiscal.ticket.device_number}`, style: 'center', marginBottom: 3 },
						)
					} else if (ticket.fiscal.type == 'ccf') {
						documentDefinition.content.push(
							{ text: `NIT: ${ticket.fiscal.nit}`, style: 'center', marginBottom: 3 },
							{ text: `NRC: ${ticket.fiscal.nrc}`, style: 'center', marginBottom: 3 },
							{ text: `CCF #${ticket.fiscal.ccf.number}`, style: 'center', marginBottom: 3 },
						)
					} else if (ticket.fiscal.type == 'invoice') {
						documentDefinition.content.push(
							{ text: `NIT: ${ticket.fiscal.nit}`, style: 'center', marginBottom: 3 },
							{ text: `NRC: ${ticket.fiscal.nrc}`, style: 'center', marginBottom: 3 },
							{ text: `Factura #${ticket.fiscal.invoice.number}`, style: 'center', marginBottom: 3 },
						)
					}
				}

				let type = ticket.type;
				if (ticket.type == 'takeout') {
					type = 'Llevar'
				} else if (ticket.type == 'delivery') {
					type = 'Llevar'
				} else if (ticket.type == 'tables') {
					type = `Mesas ${ticket.table_area} ${ticket.table}`
				}

				documentDefinition.content.push(
					{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 3 },
					{ text: `Fecha: ${this.components.convertToDate(ticket.completed_date)} ${this.components.convertToHours(ticket.completed_date)} `, style: 'center', marginBottom: 3 },
					{ text: `Atendio: ${ticket.user.name} ${ticket.user.lastname}`, style: 'center', marginBottom: 3 },
					{ text: `Orden No: ${ticket.order_number}`, style: 'center', marginBottom: 3 },
					{ text: `${type}`, style: 'center', marginBottom: 3 },
					{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 3 },
				)

				ticket.items.forEach(item => {
					let item_total;
					let cantidad;
					let nombre;
					let total;
					let precio_unit;

					let name = item.name;
					if (item.is_variant) {
						name = `${item.parent.name} (${item.name})`
					}
					if (item.is_promotion) {
						name = `${item.promotion.name}`
					}

					item_total = item.price * item.quantity;

					cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
					nombre = (name.substring(0, 15).length <= 13) ? 13 - name.substring(0, 15).length : 0;
					total = (item_total.toFixed(2).length <= 5) ? 5 - item_total.toFixed(2).length : 0;
					precio_unit = (item.price.toFixed(2).length <= 5) ? 5 - item.price.toFixed(2).length : 0;

					let item_price = item.price;
					let item_quantity = item.quantity;

					if (ticket.exempt > 0) {
						item_total = item_total - item.hidden_taxes - item.total_taxes
						item_price = (item_total) / item_quantity;
					}

					documentDefinition.content.push(
						{
							columns: [
								{
									width: 'auto',
									maxHeight: 15,
									text: `${item_quantity} x `,
									marginRight: 5,
									style: 'left',
									marginBottom: 6
								},
								{
									width: 100,
									text: name,
									style: 'left',
									marginBottom: 6
								},
								{
									maxHeight: 15,
									text: this.formatToCurrency(item_total),
									style: 'right',
									marginBottom: 6
								}
							]
						}
					)

					if (item.have_modifiers) {
						item.modifiers.forEach(modifier => {
							if (modifier.multiple) {
								modifier.selected.forEach(mod_element => {
									documentDefinition.content.push(
										{ text: `- ${(mod_element.name)}`, style: 'left', color: '#7f828a' },
									)
								});
							} else {
								documentDefinition.content.push(
									{ text: `- ${(modifier.selected.name)}`, marginBottom: 6, style: 'left', color: '#7f828a' },
								)

							}
						});
					}

					if (item.notes != false) {
						documentDefinition.content.push(
							{ text: item.notes, style: 'left', marginBottom: 8 },
						)
					}
				})

				let rep_subtotal = 28;
				let rep_tip = 29;
				let rep_exempt = 29;
				let rep_disc = 26;
				let rep_grav = 28;

				if (ticket.iva_rete == undefined) {
					ticket.iva_rete = 0;
				}

				let total_grav = ticket.subtotal + ticket.hidden_taxes + ticket.taxes - ticket.exempt + ticket.iva_rete;
				let total_exempt = 0;

				if (ticket.exempt > 0) {
					total_grav = 0;
					total_exempt = ticket.subtotal + ticket.hidden_taxes + ticket.taxes - ticket.exempt;
				}

				documentDefinition.content.push(
					{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 6 },
					{
						columns: [
							{ text: 'Subtotal:', width: 100, style: 'left', marginBottom: 6 },
							{ text: `${this.formatToCurrency(ticket.subtotal + ticket.hidden_taxes + ticket.taxes + ticket.discount + ticket.iva_discount - ticket.exempt + ticket.iva_rete)}`, style: 'right', marginBottom: 6 }
						],
					}
				)

				if (ticket.discount > 0) {
					documentDefinition.content.push(
						{
							columns: [
								{ width: 100, text: 'Descuentos:', style: 'left', marginBottom: 6 },
								{ text: `-${this.formatToCurrency(ticket.discount + ticket.iva_discount)}`, style: 'right', marginBottom: 6 }
							],
						},
					)
				}

				documentDefinition.content.push(
					{
						columns: [
							{ width: 100, text: 'Exentas:', style: 'left', marginBottom: 6 },
							{ text: `${this.formatToCurrency(total_exempt)}`, style: 'right', marginBottom: 6 }
						],
					}
				)

				if (ticket.tip > 0 && this.auth.license.membership.type == 'resto') {
					documentDefinition.content.push(
						{
							columns: [
								{
									width: 100,
									text: 'Propina:',
									style: 'left',
									marginBottom: 6
								},
								{
									text: `${this.formatToCurrency(ticket.tip)}`,
									style: 'right',
									marginBottom: 6
								}
							],
						}
					)
				}

				if (ticket.client) {
					if (ticket.client.large_contributor && ticket.subtotal > 100) {

						documentDefinition.content.push(
							{
								columns: [
									{ text: 'Iva retenido:', width: 100, style: 'left', marginBottom: 6 },
									{ text: `-${this.formatToCurrency(ticket.iva_rete)}`, style: 'right', marginBottom: 6 }
								],
							}
						)
					}
				}



				documentDefinition.content.push(
					{
						columns: [
							{
								width: 100,
								text: 'Total:',
								style: 'left',
								fontSize: 13,
								bold: true,
								marginBottom: 6
							},
							{
								text: `${this.formatToCurrency(ticket.total)}`,
								style: 'right',
								fontSize: 13,
								bold: true,
								marginBottom: 6
							}
						]
					},
					{ text: `—`.repeat(100), style: 'center', maxHeight: 15 },
					{ text: ` `, style: 'center' },
					{ text: ticket.notes ? 'Notas' : '', style: 'center' },
					{ text: ticket.notes ? ticket.notes : '', style: 'center' },
					{ text: ` `, style: 'center' },
					{ text: `Pagos realizados`, style: 'center' },
					{ text: ` `, style: 'center' },
				)

				let rep_cash = 31;
				let rep_card = 30;
				let rep_giftcard = 31;

				ticket.payments.forEach(element => {

					if (element.method == 'cash') {
						documentDefinition.content.push(
							{ text: 'Efectivo' + ' '.repeat(rep_cash) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					} else if (element.method == 'card') {
						documentDefinition.content.push(
							{ text: 'T.Credito' + ' '.repeat(rep_card) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					} else if (element.method == 'giftcard') {
						documentDefinition.content.push(
							{ text: 'Giftcard' + ' '.repeat(rep_card) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					} else if (element.method == 'check') {
						documentDefinition.content.push(
							{ text: 'Cheque' + ' '.repeat(rep_card) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					} else if (element.method == 'transfer') {
						documentDefinition.content.push(
							{ text: 'Transferencia' + ' '.repeat(rep_card) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					} else if (element.method == 'link') {
						documentDefinition.content.push(
							{ text: 'Link' + ' '.repeat(rep_card) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					} else if (element.method == 'other') {
						documentDefinition.content.push(
							{ text: 'Otros Metodos' + ' '.repeat(rep_card) + this.formatToCurrency(element.total), style: 'center', marginTop: 5 },
						)
					}

				});

				let rep_change = 30;
				let rep_no = 26;

				documentDefinition.content.push(
					{ text: `  `, style: 'center' },
					{ text: `  `, style: 'center' },
					{ text: `TOTAL PAGADO: ${this.formatToCurrency(ticket.total)}`, style: 'center', bold: true, marginTop: 4, marginBottom: 6 },
					{ text: `  `, style: 'center' },
				)

				if (ticket.client) {
					documentDefinition.content.push({ text: `Cliente: ${ticket.client.name}`, style: 'center' });
					if (ticket.client.phone) {
						documentDefinition.content.push({ text: `Telefono: ${ticket.client.phone}`, style: 'center' });
					}
				}

				if (ticket.notes) {
					documentDefinition.content.push(
						{ text: `  `, style: 'center' },
						{ text: `Notas`, style: 'center' }
					);
					documentDefinition.content.push({ text: `${ticket.notes}`, style: 'center' });
				}

				documentDefinition.content.push(
					{ text: `  `, style: 'center' },
					{ text: `******************************************`, style: 'center' },
					{ text: `TODOS LOS MONTOS EN USD`, style: 'center' },
					{ text: `******************************************`, style: 'center', marginTop: 4 },
					{ text: `  `, style: 'center' },
					{ text: `  `, style: 'center' },
					{ text: `Powered by QUANTO`, style: 'small', marginBottom: 4 },
					{ text: `www.quantopos.com`, style: 'small' },
				)

				// `order_${ticket.order_number}.pdf`
				pdfMake.createPdf(documentDefinition).print();
			})
	}

	printRefundItem(refund) {

		if (this.printers.length > 0 || this.auth.peripheral !== false) {
			this.db.collection(`accounts`).ref
				.doc(this.auth.account)
				.get()
				.then(response => {
					let account = response.data();

					const encoder = new EscPosEncoder();

					const result = encoder.initialize();

					let print_info = [];

					let header_data = result
						.align('center')
						.size('normal')
						.bold(true)
						.line(`DEVOLUCION, DEVOLUCION, DEVOLUCION, DEVOLUCION`)
						.line('------------------------------------------')
						.newline()
						.newline()
						.newline()
						.line(`${account.address}`)
						.line(`Telefono: ${account.phone}`)
						.align('left')
						.encode()
					print_info.push(header_data)

					if (refund.fiscal) {
						let header_fiscal = result
							.align('center')
							.size('normal')
							.bold(true)
							.line(refund.fiscal.business_name)
							.line(`NIT: ${account.fiscal.nit}`)
							.line(`NRC: ${account.fiscal.nrc}`)
							.encode()
						print_info.push(header_fiscal)


						let fiscal = result
							.bold(true)
							.line(`FECHA RESOLUCION: ${this.components.convertToDate(refund.fiscal.ticket.resolution_date.toDate())}`)
							.align('center')
							.line(account.fiscal ? `Giro. ${account.fiscal.activity ? account.fiscal.activity.value : account.industry}` : ``)
							.line(`Res No. ${refund.fiscal.ticket.res}`)
							.line(`Del ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_from} al ${refund.fiscal.ticket.serie}}`)
							.line(`Tiquete No. ${refund.fiscal.ticket.current_ticket_number}`)
							.line(`Caja. ${refund.fiscal.ticket.device_number}`)
							.align('center')
							.encode();
						print_info.push(fiscal)
					}

					let subheader_data = result
						.align('center')
						.line('------------------------------------------')
						.size('normal')
						.bold(true)
						.line(`FECHA: ${this.components.convertToDate(refund.creation_date)} ${this.components.convertToHours(refund.creation_date)} `)
						.line(`Atendio: ${refund.user.name} ${refund.user.lastname}`)
						.bold(true)
						.bold(false)
						.line('------------------------------------------')
						.newline()
						.align('left')
						.encode()
					print_info.push(subheader_data)

					let name = refund.item.name;
					if (refund.item.is_variant) {
						name = `${refund.item.parent.name} (${refund.item.name})`
					}

					let item_total = refund.price * refund.quantity;

					const cantidad = (refund.quantity.toString().length <= 4) ? 4 - refund.quantity.toString().length : 0;
					const nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
					const total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
					const precio_unit = (refund.price.toFixed(2).length <= 9) ? 9 - refund.price.toFixed(2).length : 0;

					if (name.length > 20) {
						name = name.substring(0, 17) + '...'
					}

					let item_data = result
						.bold(true)
						.align('left')
						.size('normal')
						.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(refund.price)} x${refund.quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
						.bold(false)
						.encode();
					print_info.push(item_data)

					if (refund.item.have_modifiers) {
						refund.item.modifiers.forEach(modifier => {
							if (modifier.multiple) {
								modifier.selected.forEach(mod_element => {
									let modifier_data = result
										.size('small')
										.align('left')
										.bold(false)
										.line(`- ${(mod_element.name).toUpperCase()}`)
										.encode();
									print_info.push(modifier_data)
								});
							} else {
								let modifier_data = result
									.size('small')
									.align('left')
									.bold(false)
									.line(`- ${(modifier.name).toUpperCase()}`)
									.encode();
								print_info.push(modifier_data)
							}
						});
					}

					let payment_header = result
						.line('------------------------------------------')
						.align('left')
						.bold(true)
						.size('normal')
						.line(`${' '.repeat(28)} SUBTOTAL: ${this.formatToCurrency(refund.price * refund.quantity)}`)
						.align('center')
						.size(`normal`)
						.bold(true)
						.newline()
						.size('big')
						.size('small')
						.line('------------------------------------------')
						.size('normal')
						.bold(false)
						.encode();
					print_info.push(payment_header)

					let return_data = result
						.bold(true)
						.align('center')
						.size('big')
						.newline()
						.line(`TOTAL DEVUELTO: ${this.formatToCurrency(refund.price * refund.quantity)}`)
						.size('small')
						.line(`Motivo: ${refund.note}`)
						.line(`Notas: ${refund.message}`)
						.line('------------------------------------------')
						.bold(false)
						.align('center')
						.size('normal')
						.bold(true)
						.newline()
						.line('DUI:______________________________________')
						.newline()
						.line('Firma_____________________________________')
						.align('left')
						.align('center')
						.size('normal')
						.bold(true)
						.newline()
						.newline()
						.newline()
						.line('------------------------------------------')
						.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
						.line('------------------------------------------')
						.align('left')
						.newline()
						.newline()
						.newline()
						.newline()
						.newline()
						.newline()
						.cut()
						.encode();
					print_info.push(return_data)

					this.print(print_info, 'receipt');
				})
		}
	}

	pdfRefundItem(refund) {
		this.db.collection(`accounts`).ref
			.doc(this.auth.account)
			.get()
			.then(response => {
				const documentDefinition = {
					content: [
					],
					styles: {
						center: {
							fontSize: 10,
							alignment: 'center'
						},
						left: {
							fontSize: 10,
							alignment: 'left'
						},
						right: {
							fontSize: 10,
							alignment: 'right'
						},
						small: {
							alignment: 'center',
							fontSize: 8,
						},
					},
					pageSize: {
						width: 305,
						height: 'auto',

					},
				};

				let account = response.data();

				documentDefinition.content.push(
					{ text: `------------------------------------------`, style: 'center' },
					{ text: `Devolucion, Devolucion, Devolucion, Devolucion`, style: 'center' },
					{ text: `------------------------------------------`, style: 'center' },
					{ text: account.name, style: 'center' },
					{ text: `Telefono: ${account.phone}`, style: 'center' },
					{ text: ``, style: 'center' },
					{ text: ``, style: 'center' },
				)

				if (refund.fiscal) {
					documentDefinition.content.push(
						{ text: refund.fiscal.business_name, style: 'center' },
						{ text: `NIT: ${account.fiscal.nit}`, style: 'center' },
						{ text: `NRC: ${account.fiscal.nrc}`, style: 'center' },
						{ text: `FECHA RESOLUCION: ${this.components.convertToDate(refund.fiscal.ticket.resolution_date.toDate())}`, style: 'center' },
						{ text: account.fiscal ? `Giro. ${account.fiscal.activity ? account.fiscal.activity.value : account.industry}` : ``, style: 'center' },
						{ text: `Res No. ${refund.fiscal.ticket.res}`, style: 'center' },
						{ text: `Del ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_from} al ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_to}`, style: 'center' },
						{ text: `Tiquete No. ${refund.fiscal.ticket.current_ticket_number}`, style: 'center' },
						{ text: `Caja. ${refund.fiscal.ticket.device_number}`, style: 'center' },
						{ text: ' ', style: 'center' },
					)
				}

				documentDefinition.content.push(
					{ text: '------------------------------------------', style: 'center' },
					{ text: `FECHA: ${this.components.convertToDate(refund.creation_date)} ${this.components.convertToHours(refund.creation_date)} `, style: 'center' },
					{ text: `Atendio: ${refund.user.name} ${refund.user.lastname}`, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: ' ', style: 'center' },
				)


				let name = refund.item.name;
				if (refund.item.is_variant) {
					name = `${refund.item.parent.name} (${refund.item.name})`
				}

				let item_total = refund.price * refund.quantity;

				const cantidad = (refund.quantity.toString().length <= 4) ? 4 - refund.quantity.toString().length : 0;
				const nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
				const total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
				const precio_unit = (refund.price.toFixed(2).length <= 9) ? 9 - refund.price.toFixed(2).length : 0;

				if (name.length > 20) {
					name = name.substring(0, 17) + '...'
				}

				documentDefinition.content.push(
					{ text: `${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(refund.price)} x${refund.quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`, style: 'left' },
				)

				if (refund.item.have_modifiers) {
					refund.item.modifiers.forEach(modifier => {
						if (modifier.multiple) {
							modifier.selected.forEach(mod_element => {
								documentDefinition.content.push(
									{ text: `- ${(mod_element.name).toUpperCase()}`, style: 'left' },
								)
							});
						} else {
							documentDefinition.content.push(
								{ text: `- ${(modifier.name).toUpperCase()}`, style: 'left' },
							)
						}
					})
				}

				documentDefinition.content.push(
					{ text: ' ', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `${' '.repeat(28)} SUBTOTAL: ${this.formatToCurrency(refund.price * refund.quantity)}`, style: 'center' },
					{ text: `TOTAL DEVUELTO: ${this.formatToCurrency(refund.price * refund.quantity)}`, style: 'center' },
					{ text: `Motivo: ${refund.note}`, style: 'center' },
					{ text: `Notas: ${refund.message}`, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: 'DUI:______________________________________', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: 'Firma_____________________________________', style: 'center' },
					{ text: ' ', style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `Devolucion, Devolucion, Devolucion, Devolucion`, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
				)

				pdfMake.createPdf(documentDefinition).print();

			})

	}

	printRefundTicket(ticket) {

		if (this.printers.length > 0 || this.auth.peripheral !== false) {
			this.db.collection(`accounts`).ref
				.doc(this.auth.account)
				.get()
				.then(response => {
					let account = response.data();

					const encoder = new EscPosEncoder();

					const result = encoder.initialize();

					let print_info = [];

					let header_data = result
						.align('center')
						.size('normal')
						.bold(true)
						.line('------------------------------------------')
						.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
						.line('------------------------------------------')
						.newline()
						.newline()
						.newline()
						.line(account.name)
						.line(`${account.address}`)
						.line(`Telefono: ${account.phone}`)
						.align('left')
						.encode()
					print_info.push(header_data)

					if (ticket.refund.fiscal) {
						let header_fiscal = result
							.align('center')
							.size('normal')
							.bold(true)
							.line(ticket.refund.fiscal.business_name)
							.line(`NIT: ${account.fiscal.nit}`)
							.line(`NRC: ${account.fiscal.nrc}`)
							.encode()
						print_info.push(header_fiscal)


						let fiscal = result
							.bold(true)
							.line(`FECHA RESOLUCION: ${this.components.convertToDate(ticket.refund.fiscal.ticket.resolution_date.toDate())}`)
							.align('center')
							.line(account.fiscal ? `Giro. ${account.fiscal.activity ? account.fiscal.activity.value : account.industry}` : ``)
							.line(`Res No. ${ticket.refund.fiscal.ticket.res}`)
							.line(`Del ${ticket.refund.fiscal.ticket.serie}|${ticket.refund.fiscal.ticket.range_from} al ${ticket.refund.fiscal.ticket.serie}|${ticket.refund.fiscal.ticket.range_to}`)
							.line(`Tiquete No. ${ticket.refund.fiscal.ticket.current_ticket_number}`)
							.line(`Caja. ${ticket.refund.fiscal.ticket.device_number}`)
							.align('center')
							.encode();
						print_info.push(fiscal)
					}


					let subheader_data = result
						.align('center')
						.line('------------------------------------------')
						.size('normal')
						.bold(true)
						.line(`FECHA: ${this.components.convertToDate(ticket.refund.creation_date)} ${this.components.convertToHours(ticket.refund.creation_date)} `)
						.line(`Atendio: ${ticket.refund.user.name} ${ticket.refund.user.lastname}`)
						.bold(true)
						.bold(false)
						.line('------------------------------------------')
						.newline()
						.align('left')
						.encode()
					print_info.push(subheader_data)

					ticket.items.forEach(item => {
						let name = item.name;
						if (item.is_variant) {
							name = `${item.parent.name} (${item.name})`
						}

						if (item.is_promotion) {
							name = `${item.promotion.name}`
						}

						let item_total = item.price * item.quantity;

						const cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
						const nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
						const total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
						const precio_unit = (item.price.toFixed(2).length <= 9) ? 9 - item.price.toFixed(2).length : 0;

						if (name.length > 20) {
							name = name.substring(0, 17) + '...'
						}

						let item_data = result
							.bold(true)
							.align('left')
							.size('normal')
							.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(item.price)} x${item.quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
							.bold(false)
							.encode();
						print_info.push(item_data)

						if (item.have_modifiers) {

							item.modifiers.forEach(modifier => {
								if (modifier.multiple) {
									modifier.selected.forEach(mod_element => {
										let modifier_data = result
											.size('small')
											.align('left')
											.bold(false)
											.line(`- ${(mod_element.name).toUpperCase()}`)
											.encode();
										print_info.push(modifier_data)
									});
								} else {

									let modifier_data = result
										.size('small')
										.align('left')
										.bold(false)
										.line(`- ${(modifier.name).toUpperCase()}`)
										.encode();
									print_info.push(modifier_data)
								}
							});
						}

						if (item.notes) {
							let notes_data = result
								.size('small')
								.align('left')
								.bold(false)
								.line(`${item.notes}`)
								.encode();
							print_info.push(notes_data)
						}
						let item_end = result
							.align('center')
							.newline()
							.encode();
						print_info.push(item_end)
					});

					let payment_header = result
						.line('------------------------------------------')
						.align('left')
						.bold(true)
						.size('normal')
						.line(`${' '.repeat(28)} SUBTOTAL: ${this.formatToCurrency(ticket.refund.total)}`)
						.align('center')
						.size(`normal`)
						.bold(true)
						.newline()
						.size('big')
						.size('small')
						.line('------------------------------------------')
						.size('normal')
						.bold(false)
						.encode();
					print_info.push(payment_header)

					let return_data = result
						.bold(true)
						.align('center')
						.size('big')
						.newline()
						.line(`TOTAL DEVUELTO: ${this.formatToCurrency(ticket.refund.total)}`)
						.size('small')
						.line(`Motivo: ${ticket.refund.note}`)
						.line(`Notas: ${ticket.refund.message}`)
						.line('------------------------------------------')
						.bold(false)
						.align('center')
						.size('normal')
						.bold(true)
						.newline()
						.line('DUI:______________________________________')
						.newline()
						.line('Firma_____________________________________')
						.align('left')
						.align('center')
						.size('normal')
						.bold(true)
						.align('left')
						.newline()
						.encode();
					print_info.push(return_data)

					if (ticket.client) {
						let client_data = result
							.size('normal')
							.align('center')
							.line('------------------------------------------')
							.bold(true)
							.line(`Cliente: ${ticket.client.name}`)
							.line(`Documento: ${ticket.client.document}`)
							.size('small')
							.encode();
						print_info.push(client_data)
					}

					let footer_data2 = result
						.newline()
						.newline()
						.newline()
						.line('------------------------------------------')
						.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
						.line('------------------------------------------')
						.newline()
						.newline()
						.newline()
						.newline()
						.newline()
						.newline()
						.cut()
						.encode();

					print_info.push(footer_data2)

					this.print(print_info, 'receipt');
				})

		}
	}

	pdfRefundTicket(ticket) {

		let account = this.auth.account_data;

		const documentDefinition = {
			content: [],
			styles: {
				center: {
					fontSize: 12,
					alignment: 'center'
				},
				left: {
					fontSize: 12,
					alignment: 'left'
				},
				right: {
					fontSize: 12,
					alignment: 'right'
				},
				small: {
					alignment: 'center',
					fontSize: 8,
				},
			},
			pageSize: {
				width: 305,
				height: 'auto',

			},
		};

		documentDefinition.content.push(
			{ text: `DEVOLUCION, DEVOLUCION, DEVOLUCION, DEVOLUCION`, style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 },
			{ text: account.name, style: 'center' },
			{ text: `${ticket.branch.address}`, style: 'center' },
			{ text: `Telefono: ${ticket.branch.phone}`, style: 'center' },
			{ text: ` `, style: 'center' },
		)

		if (ticket.refund.fiscal) {
			if (ticket.refund.fiscal.dte !== undefined) {
				console.log(ticket.refund);

				documentDefinition.content.push(
					{ text: ticket.refund.fiscal.business_name, style: 'center' },
					{ text: `NIT: ${account.fiscal.nit}`, style: 'center' },
					{ text: `NRC: ${account.fiscal.nrc}`, style: 'center' },
					{ text: `FECHA RESOLUCION: ${this.components.convertToDate(ticket.refund.creation_date)}`, style: 'center' },
					{ text: account.fiscal ? `Giro. ${account.fiscal.activity ? account.fiscal.activity.value : account.industry}` : ``, style: 'center' },
					{ text: `Codgio de generacion. ${ticket.refund.fiscal.dte.codigoGeneracion}`, style: 'center' },
					{ text: ' ', style: 'center' },
				)
			} else {
				documentDefinition.content.push(
					{ text: ticket.refund.fiscal.business_name, style: 'center' },
					{ text: `NIT: ${account.fiscal.nit}`, style: 'center' },
					{ text: `NRC: ${account.fiscal.nrc}`, style: 'center' },
					{ text: `FECHA RESOLUCION: ${this.components.convertToDate(ticket.refund.fiscal.ticket.resolution_date.toDate())}`, style: 'center' },
					{ text: account.fiscal ? `Giro. ${account.fiscal.activity ? account.fiscal.activity.value : account.industry}` : ``, style: 'center' },
					{ text: `Res No. ${ticket.refund.fiscal.ticket.res}`, style: 'center' },
					{ text: `Del ${ticket.refund.fiscal.ticket.serie}|${ticket.refund.fiscal.ticket.range_from} al ${ticket.refund.fiscal.ticket.serie}|${ticket.refund.fiscal.ticket.range_to}`, style: 'center' },
					{ text: `Tiquete No. ${ticket.refund.fiscal.ticket.current_ticket_number}`, style: 'center' },
					{ text: `Caja. ${ticket.refund.fiscal.ticket.device_number}`, style: 'center' },
					{ text: ' ', style: 'center' },
				)
			}
		}

		documentDefinition.content.push(
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 },
			{ text: `FECHA: ${this.components.convertToDate(ticket.refund.creation_date)} ${this.components.convertToHours(ticket.refund.creation_date)} `, style: 'center' },
			{ text: `Atendio: ${ticket.refund.user.name} ${ticket.refund.user.lastname}`, style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 },
			{ text: ' ', style: 'center' },
		)

		ticket.items.forEach(item => {
			let name = item.name;
			if (item.is_variant) {
				name = `${item.parent.name} (${item.name})`
			}

			if (item.is_promotion) {
				name = `${item.promotion.name}`
			}

			let item_total = item.price * item.quantity;
			let item_quantity = item.quantity;

			documentDefinition.content.push(
				{
					columns: [
						{
							width: 'auto',
							maxHeight: 15,
							text: `${item_quantity} x `,
							marginRight: 5,
							style: 'left',
							marginBottom: 6
						},
						{
							width: 100,
							text: name,
							style: 'left',
							marginBottom: 6
						},
						{
							maxHeight: 15,
							text: this.formatToCurrency(item_total),
							style: 'right',
							marginBottom: 6
						}
					]
				}
			)

			if (item.have_modifiers) {
				item.modifiers.forEach(modifier => {
					if (modifier.multiple) {
						modifier.selected.forEach(mod_element => {
							documentDefinition.content.push(
								{ text: `- ${(mod_element.name).toUpperCase()}`, style: 'left' },
							)
						});
					} else {
						documentDefinition.content.push(
							{ text: `- ${(modifier.name).toUpperCase()}`, style: 'left' },
						)
					}
				})
			}

		})

		documentDefinition.content.push(
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginBottom: 6 },
			{
				columns: [
					{ text: 'Subtotal:', width: 100, style: 'left', marginBottom: 6 },
					{ text: `${this.formatToCurrency(ticket.refund.total)}`, style: 'right', marginBottom: 6 }
				],
			}
		)
		documentDefinition.content.push(
			{
				columns: [
					{
						width: 100,
						text: 'TOTAL DEVUELTO:',
						style: 'left',
						fontSize: 12,
						bold: true,
						marginBottom: 6
					},
					{
						text: `${this.formatToCurrency(ticket.refund.total)}`,
						style: 'right',
						fontSize: 12,
						bold: true,
						marginBottom: 6
					}
				]
			}
		)

		documentDefinition.content.push(
			{ text: ' ', style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 },
			{ text: `Motivo: ${ticket.refund.note}`, style: 'center' },
			{ text: `Notas: ${ticket.refund.message}`, style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 },
			{ text: ' ', style: 'center' },
			{ text: 'DUI:____________________', style: 'center' },
			{ text: ' ', style: 'center' },
			{ text: ' ', style: 'center' },
			{ text: 'Firma___________________', style: 'center' },
			{ text: ' ', style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 },
			{ text: `Devolucion, Devolucion, Devolucion, Devolucion`, style: 'center' },
			{ text: `—`.repeat(100), style: 'center', maxHeight: 15, marginTop: 5, marginBottom: 5 }
		)

		pdfMake.createPdf(documentDefinition).print();
	}

	closing(type, closing, categories, users, types) {
		const encoder = new EscPosEncoder();

		const result = encoder.initialize();

		let print_info = [];

		this.db.collection(`accounts/${this.auth.account}/branches`).ref
			.doc(closing.branch_key)
			.get()
			.then(response => {
				let branch = response.data();

				if (this.auth.account_data.fiscal && !closing.is_dte) {
					let header_fiscal = result
						.align('center')
						.size('normal')
						.bold(true)
						.line(this.auth.account_data.name)
						.line(this.auth.account_data.fiscal.business_name)
						.line(closing.branch.name)
						.line(closing.branch.address)
						.line(`Telefono: ${this.auth.account_data.phone}`)
						.align('center')
						.size('normal')
						.bold(true)
						.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
						.line(`NRC: ${this.auth.account_data.fiscal.nrc}`)
						.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
						.align('center')
						.encode()
					print_info.push(header_fiscal)
				} else {
					let header_fiscal = result
						.align('center')
						.size('normal')
						.bold(true)
						.line(this.auth.account_data.name)
						.line(closing.branch.name)
						.line(closing.branch.address)
						.line(`Telefono: ${this.auth.account_data.phone}`)
						.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
						.line(`NRC: ${this.auth.account_data.fiscal.nrc}`)
						.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
						.line(`Res No. ${branch['fiscal'].res}`)
						.line(`Del ${branch.fiscal.serie}|${branch.fiscal.range_from} al ${branch.fiscal.serie}|${branch.fiscal.range_to}`)
						.align('center')
						.size('normal')
						.bold(true)
						.align('center')
						.encode()
					print_info.push(header_fiscal)
				}

				let first_ticket = closing.tickets[0];
				let last_ticket = closing.tickets[closing.tickets.length - 1];
				if (first_ticket == undefined) {
					first_ticket = '-';
					last_ticket = '-'
				}
				let first_invoice = closing.invoices[0];
				let last_invoice = closing.invoices[closing.invoices.length - 1];
				if (first_invoice == undefined) {
					first_invoice = '-';
					last_invoice = '-'
				}
				let first_ccf = closing.ccfs[0];
				let last_ccf = closing.ccfs[closing.ccfs.length - 1];
				if (first_ccf == undefined) {
					first_ccf = '-';
					last_ccf = '-'
				}

				let name = `>>Total ${type}<<`;
				if (closing.big) {
					name = '>>GRAN TOTAL Z<<';
				}

				if (closing.total_transfer == undefined) {
					closing.total_transfer = 0;
				}
				if (closing.total_link == undefined) {
					closing.total_link = 0;
				}
				if (closing.total_check == undefined) {
					closing.total_check = 0;
				}
				if (closing.total_other == undefined) {
					closing.total_other = 0;
				}


				let header_data = result
					.align('center')
					.line('------------------------------------------')
					.bold(true)
					.line(`${name}`)
					.bold(false)
					.line('------------------------------------------')
					.newline()
					.align('left')
					.line(`Fecha inicio : ${this.components.convertToDate(closing.from)} ${this.components.convertToHours(closing.from)}`)
					.line(`Fecha final : ${this.components.convertToDate(closing.to)} ${this.components.convertToHours(closing.to)}`)

					.line(`Ventas en tarjeta: ${this.formatToCurrency(closing.total_card)}`)
					.line(`Ventas en efectivo: ${this.formatToCurrency(closing.total_cash)}`)
					.line(`Consumo de giftcard: ${this.formatToCurrency(closing.total_giftcard)}`)

					.line(`Ventas con transferencia: ${this.formatToCurrency(closing.total_transfer)}`)
					.line(`Ventas con link: ${this.formatToCurrency(closing.total_link)}`)
					.line(`Ventas con check: ${this.formatToCurrency(closing.total_check)}`)
					.line(`Ventas con otros Metodos: ${this.formatToCurrency(closing.total_other)}`)

					.line(`Propina: ${this.formatToCurrency(closing.tip)}`)

					.line(`Ventas gravadas: ${this.formatToCurrency(closing.sales)}`)
					.line(`Ventas Exentas: ${this.formatToCurrency(closing.exempt)}`)
					.line(`Devoluciones: ${this.formatToCurrency(closing.refunds)}`)
					.line(`Creditos: ${this.formatToCurrency(closing.credit)}`)
					.line(`Descuentos: ${this.formatToCurrency(closing.discounts)}`)
					.line(`Ventas de giftcards: ${this.formatToCurrency(closing.giftcards)}`)

					.line(`Ventas Totales: ${this.formatToCurrency(closing.total_cash + closing.total_card + closing.total_transfer + closing.total_giftcard + closing.total_link + closing.total_check + closing.total_other + closing.credit - closing.discounts - closing.refunds)}`)
					.encode()
				print_info.push(header_data)

				types.forEach(type => {
					let types_info = result
						.line(`${type.name}: ${this.formatToCurrency(type.sales)}`)
						.encode
					print_info.push(types_info)
				});

				if (!closing.is_dte) {
					let tickets_data = result
						.align('center')
						.line('------------------------------------------')
						.bold(true)
						.line(`>>Tiquetes<<`)
						.bold(false)
						.line('------------------------------------------')
						.newline()
						.align('left')
						.line(`Ventas gravadas: ${this.formatToCurrency(closing.tickets_sales)}`)
						.line(`Ventas Exentas: ${this.formatToCurrency(closing.tickets_exempt)}`)
						.line(`Devoluciones: ${this.formatToCurrency(closing.tickets_refunds)}`)
						.line(`Propina: ${this.formatToCurrency(closing.tickets_tip)}`)
						.line(`Total tiquetes: ${closing.total_tickets}`)
						.line(`tiquete inicial: ${first_ticket}`)
						.line(`tiquete final: ${last_ticket}`)
						.encode()
					print_info.push(tickets_data)
				}

				let body_data = result
					.align('center')
					.line('------------------------------------------')
					.bold(true)
					.line(`>>Facturas<<`)
					.bold(false)
					.line('------------------------------------------')
					.newline()
					.align('left')
					.line(`Ventas gravadas: ${this.formatToCurrency(closing.invoices_sales)}`)
					.line(`Ventas Exentas: ${this.formatToCurrency(closing.invoices_exempt)}`)
					.line(`Devoluciones: ${this.formatToCurrency(closing.invoices_refunds)}`)
					.line(`Propina: ${this.formatToCurrency(closing.invoices_tip)}`)
					.line(`Total Facturas: ${closing.total_invoices}`)
					.line(`Factura inicial: ${first_invoice}`)
					.line(`Factura final: ${last_invoice}`)
					.align('center')
					.line('------------------------------------------')
					.bold(true)
					.line(`>>CCF<<`)
					.bold(false)
					.line('------------------------------------------')
					.newline()
					.align('left')
					.line(`Ventas gravadas: ${this.formatToCurrency(closing.ccfs_sales)}`)
					.line(`Ventas Exentas: ${this.formatToCurrency(closing.ccfs_exempt)}`)
					.line(`Devoluciones: ${this.formatToCurrency(closing.ccfs_refunds)}`)
					.line(`Propina: ${this.formatToCurrency(closing.ccfs_tip)}`)
					.line(`Total CCF: ${closing.total_ccfs}`)
					.line(`CCF inicial: ${first_ccf}`)
					.line(`CCF final: ${last_ccf}`)
					.align('center')
					.line('------------------------------------------')
					.bold(true)
					.encode()
				print_info.push(body_data)


				if (closing.gateways.length > 0) {
					let gateway_info = result
						.align('center')
						.line('------------------------------------------')
						.bold(true)
						.line(`>>Pasarelas de pago<<`)
						.bold(false)
						.line('------------------------------------------')
						.encode
					print_info.push(gateway_info)
				}

				closing.gateways.forEach(gateway => {
					let gateway_info = result
						.align('left')
						.line(` ${gateway.gateway} (${gateway.quantity}) ${this.formatToCurrency(gateway.total)}`)
						.encode
					print_info.push(gateway_info)
				})


				if (categories.length > 0) {
					let gateway_info = result
						.align('center')
						.line('------------------------------------------')
						.bold(true)
						.line(`>>Ventas por categoria<<`)
						.bold(false)
						.line('------------------------------------------')
						.encode
					print_info.push(gateway_info)
				}
				categories.forEach(category => {
					let categories_info = result
						.align('left')
						.line(` ${category.name} (${category.quantity}) ${this.formatToCurrency(category.amount)}`)
						.encode
					print_info.push(categories_info)
				})

				if (categories.length > 0) {
					let gateway_info = result
						.align('center')
						.line('------------------------------------------')
						.bold(true)
						.line(`>>Ventas por empleado<<`)
						.bold(false)
						.line('------------------------------------------')
						.encode
					print_info.push(gateway_info)
				}
				users.forEach(user => {
					let users_info = result
						.align('left')
						.line(` ${user.user} | Propina: ${this.formatToCurrency(user.tip)}  Ventas:${this.formatToCurrency(user.sales)}`)
						.encode
					print_info.push(users_info)
				})

				let footer_data = result
					.align('center')
					.newline()
					.newline()
					.newline()
					.newline()
					.newline()
					.cut()
					.encode();
				print_info.push(footer_data)

				this.print(print_info, 'closing');
			})
	}

	pdfClosing(type, closing, categories, users, types) {
		this.db.collection(`accounts/${this.auth.account}/branches`).ref
			.doc(closing.branch_key)
			.get()
			.then(response => {

				const documentDefinition = {
					content: [],
					styles: {
						center: {
							fontSize: 12,
							alignment: 'center'
						},
						left: {
							fontSize: 12,
							alignment: 'left'
						},
						right: {
							fontSize: 12,
							alignment: 'right'
						},
						small: {
							alignment: 'center',
							fontSize: 8,
						},
					},
					pageSize: {
						width: 305,
						height: 'auto',

					},
				};

				let branch = response.data();

				if (this.auth.account_data.fiscal && !closing.is_dte) {
					documentDefinition.content.push(
						{ text: this.auth.account_data.name, style: 'center' },
						{ text: closing.branch.name, style: 'center' },
						{ text: closing.branch.address, style: 'center' },
						{ text: `Telefono: ${this.auth.account_data.phone}`, style: 'center' },
						{ text: `NIT: ${this.auth.account_data.fiscal.nit}`, style: 'center' },
						{ text: `NRC: ${this.auth.account_data.fiscal.nrc}`, style: 'center' },
						{ text: this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``, style: 'center' },
						{ text: `Res No. ${branch['fiscal'].res}`, style: 'center' },
						{ text: `Del ${branch.fiscal.serie}|${branch.fiscal.range_from} al ${branch.fiscal.serie}|${branch.fiscal.range_to}`, style: 'center' },
					)
				} else {
					documentDefinition.content.push(
						{ text: this.auth.account_data.name, style: 'center' },
						{ text: closing.branch.name, style: 'center' },
						{ text: closing.branch.address, style: 'center' },
						{ text: `Telefono: ${this.auth.account_data.phone}`, style: 'center' },
						{ text: `NIT: ${this.auth.account_data.fiscal.nit}`, style: 'center' },
						{ text: `NRC: ${this.auth.account_data.fiscal.nrc}`, style: 'center' },
						{ text: this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``, style: 'center' },
					)
				}

				let first_ticket = closing.tickets[0];
				let last_ticket = closing.tickets[closing.tickets.length - 1];
				if (first_ticket == undefined) {
					first_ticket = '-';
					last_ticket = '-'
				}
				let first_invoice = closing.invoices[0];
				let last_invoice = closing.invoices[closing.invoices.length - 1];
				if (first_invoice == undefined) {
					first_invoice = '-';
					last_invoice = '-'
				}
				let first_ccf = closing.ccfs[0];
				let last_ccf = closing.ccfs[closing.ccfs.length - 1];
				if (first_ccf == undefined) {
					first_ccf = '-';
					last_ccf = '-'
				}

				let name = `>>Total ${type}<<`;
				if (closing.big) {
					name = '>>GRAN TOTAL Z<<';
				}

				if (closing.total_transfer == undefined) {
					closing.total_transfer = 0;
				}
				if (closing.total_link == undefined) {
					closing.total_link = 0;
				}
				if (closing.total_check == undefined) {
					closing.total_check = 0;
				}
				if (closing.total_other == undefined) {
					closing.total_other = 0;
				}

				documentDefinition.content.push(
					{ text: '------------------------------------------', style: 'center' },
					{ text: name, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `Fecha inicio : ${this.components.convertToDate(closing.from)} ${this.components.convertToHours(closing.from)}`, style: 'left' },
					{ text: `Fecha final : ${this.components.convertToDate(closing.to)} ${this.components.convertToHours(closing.to)}`, style: 'left' },
					{ text: `Ventas en tarjeta: ${this.formatToCurrency(closing.total_card)}`, style: 'left' },
					{ text: `Ventas en efectivo: ${this.formatToCurrency(closing.total_cash)}`, style: 'left' },
					{ text: `Ventas con giftcard: ${this.formatToCurrency(closing.total_giftcard)}`, style: 'left' },
					{ text: `Ventas con transferencia: ${this.formatToCurrency(closing.total_transfer)}`, style: 'left' },
					{ text: `Ventas con link: ${this.formatToCurrency(closing.total_link)}`, style: 'left' },
					{ text: `Ventas con cheque: ${this.formatToCurrency(closing.total_check)}`, style: 'left' },
					{ text: `Ventas con otros Metodos: ${this.formatToCurrency(closing.total_other)}`, style: 'left' },
					{ text: `Propina: ${this.formatToCurrency(closing.tip)}`, style: 'left' },
					{ text: `Ventas gravadas: ${this.formatToCurrency(closing.sales)}`, style: 'left' },
					{ text: `Ventas Exentas: ${this.formatToCurrency(closing.exempt)}`, style: 'left' },
					{ text: `Devoluciones: ${this.formatToCurrency(closing.refunds)}`, style: 'left' },
					{ text: `Creditos: ${this.formatToCurrency(closing.credit)}`, style: 'left' },
					{ text: `Descuentos: ${this.formatToCurrency(closing.discounts)}`, style: 'left' },
					{ text: `Ventas de giftcards: ${this.formatToCurrency(closing.giftcards)}`, style: 'left' },
					{ text: `Ventas Totales: ${this.formatToCurrency(closing.total_cash + closing.total_card + closing.credit - closing.discounts - closing.refunds)}`, style: 'left' },
				)

				types.forEach(type => {
					documentDefinition.content.push(
						{ text: `${type.name}: ${this.formatToCurrency(type.sales)}`, style: 'left' },
					)
				})

				documentDefinition.content.push(
					{ text: '------------------------------------------', style: 'center' },
					{ text: `>>Tiquetes<<`, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `Ventas gravadas: ${this.formatToCurrency(closing.tickets_sales)}`, style: 'left' },
					{ text: `Ventas Exentas: ${this.formatToCurrency(closing.tickets_exempt)}`, style: 'left' },
					{ text: `Devoluciones: ${this.formatToCurrency(closing.tickets_refunds)}`, style: 'left' },
					{ text: `Propina: ${this.formatToCurrency(closing.tickets_tip)}`, style: 'left' },
					{ text: `Total tiquetes: ${closing.total_tickets}`, style: 'left' },
					{ text: `tiquete inicial: ${first_ticket}`, style: 'left' },
					{ text: `tiquete final: ${last_ticket}`, style: 'left' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `>>Facturas<<`, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `Ventas gravadas: ${this.formatToCurrency(closing.invoices_sales)}`, style: 'left' },
					{ text: `Ventas Exentas: ${this.formatToCurrency(closing.invoices_exempt)}`, style: 'left' },
					{ text: `Devoluciones: ${this.formatToCurrency(closing.invoices_refunds)}`, style: 'left' },
					{ text: `Propina: ${this.formatToCurrency(closing.invoices_tip)}`, style: 'left' },
					{ text: `Total Facturas: ${closing.total_invoices}`, style: 'left' },
					{ text: `Factura inicial: ${first_invoice}`, style: 'left' },
					{ text: `Factura final: ${last_invoice}`, style: 'left' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `>>CCF<<`, style: 'center' },
					{ text: '------------------------------------------', style: 'center' },
					{ text: `Ventas gravadas: ${this.formatToCurrency(closing.ccfs_sales)}`, style: 'left' },
					{ text: `Ventas Exentas: ${this.formatToCurrency(closing.ccfs_exempt)}`, style: 'left' },
					{ text: `Devoluciones: ${this.formatToCurrency(closing.ccfs_refunds)}`, style: 'left' },
					{ text: `Propina: ${this.formatToCurrency(closing.ccfs_tip)}`, style: 'left' },
					{ text: `Total CCF: ${closing.total_ccfs}`, style: 'left' },
					{ text: `CCF inicial: ${first_ccf}`, style: 'left' },
					{ text: `CCF final: ${last_ccf}`, style: 'left' },

				)

				if (closing.gateways.length > 0) {
					documentDefinition.content.push(
						{ text: '------------------------------------------', style: 'center' },
						{ text: `>>Pasarelas de pago<<`, style: 'center' },
						{ text: '------------------------------------------', style: 'center' },
						{ text: ' ', style: 'left' },
					)

					closing.gateways.forEach(gateway => {
						documentDefinition.content.push(
							{ text: ` ${gateway.gateway} (${gateway.quantity}) ${this.formatToCurrency(gateway.total)}`, style: 'left' },
						)
					})
				}

				if (categories.length > 0) {
					documentDefinition.content.push(
						{ text: '------------------------------------------', style: 'center' },
						{ text: `>>Ventas por categoria<<`, style: 'center' },
						{ text: '------------------------------------------', style: 'center' },
					)

					categories.forEach(category => {
						documentDefinition.content.push(
							{ text: ` ${category.name} (${category.quantity}) ${this.formatToCurrency(category.amount)}`, style: 'center' },
						)
					})

					documentDefinition.content.push(
						{ text: '------------------------------------------', style: 'center' },
						{ text: `>>Ventas por empleado<<`, style: 'center' },
						{ text: '------------------------------------------', style: 'center' },
					)

					users.forEach(user => {
						documentDefinition.content.push(
							{ text: ` ${user.user} | Propina: ${this.formatToCurrency(user.tip)}  Ventas:${this.formatToCurrency(user.sales)}`, style: 'left' },
						)
					})



				}

				documentDefinition.content.push(
					{ text: ` `, style: 'left' },
					{ text: ` `, style: 'left' },
					{ text: ` `, style: 'left' },
					{ text: ` `, style: 'left' },
					{ text: ` `, style: 'left' },
				)

				pdfMake.createPdf(documentDefinition).print();

			})

	}

	printAudit(start_date, end_date) {
		this.db.collection(`accounts/${this.auth.account}/branches`).ref
			.doc(this.auth.branch)
			.get()
			.then(response => {
				let branch = response.data();
				const encoder = new EscPosEncoder();

				const result = encoder.initialize();

				let print_info = [];

				let all_info = [];

				this.db.collection(`accounts/${this.auth.account}/tickets`).ref
					.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
					.where('branch_key', '==', this.auth.branch)
					.where('completed_date', '>=', start_date)
					.where('completed_date', '<=', end_date)
					.get()
					.then(ticket_snapshots => {

						let total_tickets = 0;

						ticket_snapshots.forEach(element => {
							let ticket = element.data();

							ticket.$key = element.id;
							ticket.creation_date = ticket.creation_date.toDate();
							ticket.completed_date = ticket.completed_date.toDate();
							ticket.info_type = 'ticket';

							this.db.collection(`accounts/${this.auth.account}/ticket_items`).ref
								.where('ticket_key', '==', ticket.$key)
								.get()
								.then(snapshots => {
									ticket.items = [];
									snapshots.forEach(element => {
										let item = element.data();
										item.$key = element.id;
										ticket.items.push(item)
									});

									this.db.collection(`accounts/${this.auth.account}/ticket_items`).ref
										.where('ticket_key', '==', ticket.$key)
										.get()
										.then(snapshots => {
											ticket.payments = [];
											snapshots.forEach(element => {
												let payment = element.data();
												payment.$key = element.id;
												ticket.payments.push(payment)
											});
											total_tickets++;
											all_info.push(ticket)

											if (total_tickets == ticket_snapshots.size) {
												this.db.collection(`accounts/${this.auth.account}/closings`).ref
													.where('creation_date', '>=', start_date)
													.where('creation_date', '<=', end_date)
													.get()
													.then(closing_snapshots => {
														let total_closings = 0;

														closing_snapshots.forEach(element => {
															let closing = element.data();
															closing.info_type = 'closing';
															closing.creation_date = closing.creation_date.toDate();
															closing.from = closing.from.toDate();
															closing.to = closing.to.toDate();
															closing.items = [];
															closing.closing_tickets = [];

															this.db.collection(`accounts/${this.auth.account}/tickets`).ref
																.where('fiscal.type', 'in', ['ticket', 'ccf', 'invoice'])
																.where('branch_key', '==', this.auth.branch)
																.where('completed_date', '>=', closing.from)
																.where('completed_date', '<=', closing.to)
																.get()
																.then(snapshots => {

																	snapshots.forEach(element => {
																		let ticket_clo = element.data();
																		ticket_clo.$key = element.id;

																		this.db.collection(`accounts/${this.auth.account}/ticket_items`).ref
																			.where('ticket_key', '==', ticket_clo.$key)
																			.get()
																			.then(snapshots => {
																				snapshots.forEach(element => {
																					let item = element.data();
																					item.$key = element.id;
																					closing.items.push(item);
																				});
																			})
																		closing.closing_tickets.push(ticket_clo);
																	});

																	let dataCat = [];
																	closing.categories = [];

																	closing.items.forEach(item => {
																		let category = '';

																		if (item.parent) {
																			if (item.parent.category) {
																				category = item.parent.category.name;
																			}
																		} else {
																			if (item.category) {
																				category = item.category.name;
																			} else {
																				category = 'Otros'
																			}
																		}

																		if (!dataCat.hasOwnProperty(category)) {
																			dataCat[category] = {
																				name: category,
																				quantity: 0,
																				amount: 0
																			}
																		}
																		dataCat[category].quantity += Number(item.quantity);
																		dataCat[category].amount += Number(item.price) * Number(item.quantity);
																	})

																	for (const property in dataCat) {
																		closing.categories.push(dataCat[property]);
																	}

																	let dataUs = [];
																	closing.users = [];

																	closing.closing_tickets.forEach(ticket => {

																		let user_key = ticket.user.$key;

																		if (!dataUs.hasOwnProperty(user_key)) {
																			dataUs[user_key] = {
																				user: `${ticket.user.name} ${ticket.user.lastname}`,
																				sales: 0,
																				tip: 0
																			}
																		}

																		dataUs[user_key].sales += Number(ticket.subtotal);
																		dataUs[user_key].tip += Number(ticket.tip);
																	})

																	for (const property in dataUs) {
																		closing.users.push(dataUs[property]);
																	}

																	all_info.push(closing)
																	total_closings++;

																	if (total_closings == closing_snapshots.size) {
																		total_closings++;

																		this.db.collection(`accounts/${this.auth.account}/ticket_refunds`).ref
																			.where('creation_date', '>=', start_date)
																			.where('creation_date', '<=', end_date)
																			.where('branch_key', '==', this.auth.branch)
																			.get()
																			.then(ticket_snapshots => {

																				let total_ticket_refunds = 0;

																				ticket_snapshots.forEach(element => {
																					let refund = element.data();
																					refund.$key = element.id;
																					refund.creation_date = refund.creation_date.toDate();
																					refund.info_type = 'refund';
																					total_ticket_refunds += refund.total;
																					all_info.push(refund)
																				});

																				this.db.collection(`accounts/${this.auth.account}/items_refunds`).ref
																					.where('creation_date', '>=', start_date)
																					.where('creation_date', '<=', end_date)
																					.where('branch_key', '==', this.auth.branch)
																					.get()
																					.then(ticket_snapshots => {

																						ticket_snapshots.forEach(element => {
																							let refund = element.data();
																							refund.$key = element.id;
																							refund.creation_date = refund.creation_date.toDate();
																							refund.info_type = 'refund_item';
																							all_info.push(refund)
																							total_ticket_refunds += refund.quantity * refund.price;
																						});


																						all_info.sort(function (a, b) {
																							var orderA = a.creation_date, orderB = b.creation_date;
																							return orderA - orderB;
																						});



																						all_info.forEach(element => {

																							// ssss
																							if (element.info_type == 'ticket') {
																								let ticket = element;
																								let header_data = result
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.line(this.auth.account_data.name)
																									.line(this.auth.account_data.fiscal.business_name)
																									.line(`${this.auth.account_data.address}`)
																									.line(`Telefono: ${this.auth.account_data.phone}`)
																									.line(`FECHA RESOLUCION: ${this.components.convertToDate(ticket.fiscal.ticket.resolution_date.toDate())}`)
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
																									.line(`NRC: ${this.auth.account_data.fiscal.nrc}`)
																									.bold(true)
																									.align('center')
																									.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
																									.line(`Res No. ${element.fiscal.ticket.res}`)
																									.line(`Del ${element.fiscal.ticket.serie}|${element.fiscal.ticket.range_from} al ${element.fiscal.ticket.serie}|${element.fiscal.ticket.range_to}`)
																									.line(`Tiquete No. ${element.fiscal.ticket.current_ticket_number}`)
																									.line(`Caja. ${element.fiscal.ticket.device_number}`)
																									.align('center')
																									.encode()
																								print_info.push(header_data)

																								let type = ticket.type;
																								if (ticket.type == 'takeout') {
																									type = 'Llevar'
																								} else if (ticket.type == 'delivery') {
																									type = 'Mesas'
																								} else if (ticket.type == 'tables') {
																									type = 'Llevar'
																								}

																								let subheader_data = result
																									.line('------------------------------------------')
																									.size('normal')
																									.bold(true)
																									.line(`FECHA: ${this.components.convertToDate(ticket.completed_date)} ${this.components.convertToHours(ticket.completed_date)} `)
																									.line(`Atendio: ${ticket.user.name} ${ticket.user.lastname}`)
																									.bold(true)
																									.line(`ORDEN No: ${ticket.order_number}`)
																									.line(`${type}`)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.encode()
																								print_info.push(subheader_data)

																								ticket.items.forEach(item => {
																									let name = item.name;
																									if (item.is_variant) {
																										name = `${item.parent.name} (${item.name})`
																									}

																									let item_total = item.price * item.quantity;

																									const cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
																									const nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
																									const total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
																									const precio_unit = (item.price.toFixed(2).length <= 9) ? 9 - item.price.toFixed(2).length : 0;

																									if (name.length > 20) {
																										name = name.substring(0, 17) + '...'
																									}

																									let item_data = result
																										.bold(true)
																										.align('left')
																										.size('normal')
																										.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(item.price)} x${item.quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
																										.bold(false)
																										.encode();
																									print_info.push(item_data)

																									if (item.have_modifiers) {

																										item.modifiers.forEach(modifier => {
																											if (modifier.multiple) {
																												modifier.selected.forEach(mod_element => {
																													let modifier_data = result
																														.size('small')
																														.align('left')
																														.bold(false)
																														.line(`- ${(mod_element.name).toUpperCase()}`)
																														.encode();
																													print_info.push(modifier_data)
																												});
																											} else {
																												console.log(modifier);

																												let modifier_data = result
																													.size('small')
																													.align('left')
																													.bold(false)
																													.line(`- ${(modifier.name).toUpperCase()}`)
																													.encode();
																												print_info.push(modifier_data)
																											}
																										});
																									}

																									if (item.notes) {
																										let notes_data = result
																											.size('small')
																											.align('left')
																											.bold(false)
																											.line(`${item.notes}`)
																											.encode();
																										print_info.push(notes_data)
																									}
																									let item_end = result
																										.align('center')
																										.newline()
																										.encode();
																									print_info.push(item_end)
																								});

																								let payment_header = result
																									.line('------------------------------------------')
																									.align('left')
																									.bold(true)
																									.size('normal')
																									.line(' '.repeat(28) + 'SUBTOTAL: ' + this.formatToCurrency(ticket.subtotal))
																									.line(' '.repeat(30) + 'Propina: ' + this.formatToCurrency(ticket.tip))
																									.align('center')
																									.size(`normal`)
																									.bold(true)
																									.newline()
																									.size('big')
																									.line(`TOTAL: ${this.formatToCurrency(ticket.total)}`)
																									.size('small')
																									.line('------------------------------------------')
																									.line(`Pagos realizados`)
																									.size('normal')
																									.bold(false)
																									.encode();
																								print_info.push(payment_header)

																								ticket.payments.forEach(element => {
																									if (element.method == 'cash') {
																										let payment_data = result
																											.align('left')
																											.line('CASH' + ' '.repeat(35) + this.formatToCurrency(element.amount))
																											.encode();
																										print_info.push(payment_data)
																									} else if (element.method == 'card') {
																										let payment_data = result
																											.align('left')
																											.line('T. Credito' + ' '.repeat(29) + this.formatToCurrency(element.amount))
																											.encode();
																										print_info.push(payment_data)
																									}
																								});

																								let payment_footer = result
																									.bold(true)
																									.align('center')
																									.size('big')
																									.newline()
																									.line(`TOTAL PAGADO: ${this.formatToCurrency(ticket.total)}`)
																									.size('normal')
																									.line('------------------------------------------')
																									.line(`${' '.repeat(30)} Cambio: ${this.formatToCurrency(0)}`)
																									.line(`${' '.repeat(29)} Exentas: ${this.formatToCurrency(ticket.exempt)}`)
																									.line(`${' '.repeat(28)} Gravadas: ${this.formatToCurrency(ticket.subtotal)}`)
																									.line(`${' '.repeat(26)} No sujetas: ${this.formatToCurrency(0)}`)
																									.line(`${' '.repeat(27)} Impuestos: ${this.formatToCurrency(ticket.taxes)}`)
																									.bold(false)
																									.encode();
																								print_info.push(payment_footer)

																								if (ticket.client) {
																									let client_data = result
																										.size('normal')
																										.align('center')
																										.line('------------------------------------------')
																										.bold(true)
																										.line(`Cliente: ${ticket.client.name}`)
																										.line(`Documento: ${ticket.client.document}`)
																										.size('small')
																										.encode();
																									print_info.push(client_data)
																								}

																								let end = result
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.line('------------------------------------------')
																									.encode();
																								print_info.push(end)

																							} else if (element.info_type == 'closing') {
																								let closing = element;

																								let type = closing.type;

																								let header_fiscal = result
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.line(this.auth.account_data.name)
																									.line(this.auth.account_data.fiscal.business_name)
																									.line(this.auth.account_data.address)
																									.line(`Telefono: ${this.auth.account_data.phone}`)
																									// .line(`FECHA RESOLUCION: ${this.components.convertToDate(closing.fiscal.ticket.resolution_date.toDate())}`)
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
																									.line(`NRC: ${this.auth.account_data.phone}`)
																									.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
																									.line(`Res No. ${branch['fiscal'].res}`)
																									.line(`Del ${branch.fiscal.serie}|${branch.fiscal.range_from} al ${branch.fiscal.serie}|${branch.fiscal.range_to}`)
																									// .line(`Caja. ${closing.fiscal.ticket.device_number}`)
																									// .line(`Tiquete No. ${closing.fiscal.ticket.current_ticket_number} `)
																									.align('center')
																									.encode()
																								print_info.push(header_fiscal)

																								let first_ticket = closing.tickets[0];
																								let last_ticket = closing.tickets[closing.tickets.length - 1];
																								if (first_ticket == undefined) {
																									first_ticket = '-';
																									last_ticket = '-'
																								}
																								let first_invoice = closing.invoices[0];
																								let last_invoice = closing.invoices[closing.invoices.length - 1];
																								if (first_invoice == undefined) {
																									first_invoice = '-';
																									last_invoice = '-'
																								}
																								let first_ccf = closing.ccfs[0];
																								let last_ccf = closing.ccfs[closing.ccfs.length - 1];
																								if (first_ccf == undefined) {
																									first_ccf = '-';
																									last_ccf = '-'
																								}

																								let name = `>>Total ${type}<<`;
																								if (closing.big) {
																									name = '>>GRAN TOTAL Z<<';
																								}

																								let header_data = result
																									.align('center')
																									.line('------------------------------------------')
																									.bold(true)
																									.line(` ${name} `)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									// .line(`Fecha inicio : 17/1/2023 06:00`)
																									.line(`Fecha inicio : ${this.components.convertToDate(closing.from)} ${this.components.convertToHours(closing.from)}`)
																									.line(`Fecha final : ${this.components.convertToDate(closing.to)} ${this.components.convertToHours(closing.to)}`)
																									.line(`Ventas en tarjeta: ${this.formatToCurrency(closing.total_card)}`)
																									.line(`Ventas en efectivo: ${this.formatToCurrency(closing.total_cash)}`)
																									.align('center')
																									.line('------------------------------------------')
																									.bold(true)
																									.line(`>>Tiquetes<<`)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.line(`Ventas gravadas: ${this.formatToCurrency(closing.tickets_sales)}`)
																									.line(`Ventas Exentas: ${this.formatToCurrency(closing.tickets_exempt)}`)
																									.line(`Devoluciones: ${this.formatToCurrency(closing.tickets_refunds)}`)
																									.line(`Propina: ${this.formatToCurrency(closing.tickets_tip)}`)
																									.line(`Total tiquetes: ${closing.total_tickets}`)
																									.line(`tiquete inicial: ${first_ticket}`)
																									.line(`tiquete final: ${last_ticket}`)
																									.align('center')
																									.line('------------------------------------------')
																									.bold(true)
																									.line(`>>Facturas<<`)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.line(`Ventas gravadas: ${this.formatToCurrency(closing.invoices_sales)}`)
																									.line(`Ventas Exentas: ${this.formatToCurrency(closing.invoices_exempt)}`)
																									.line(`Devoluciones: ${this.formatToCurrency(closing.invoices_refunds)}`)
																									.line(`Propina: ${this.formatToCurrency(closing.invoices_tip)}`)
																									.line(`Total Facturas: ${closing.total_invoices}`)
																									.line(`Factura inicial: ${first_invoice}`)
																									.line(`Factura final: ${last_invoice}`)
																									.align('center')
																									.line('------------------------------------------')
																									.bold(true)
																									.line(`>>CCF<<`)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.line(`Ventas gravadas: ${this.formatToCurrency(closing.ccfs_sales)}`)
																									.line(`Ventas Exentas: ${this.formatToCurrency(closing.ccfs_exempt)}`)
																									.line(`Devoluciones: ${this.formatToCurrency(closing.ccfs_refunds)}`)
																									.line(`Propina: ${this.formatToCurrency(closing.ccfs_tip)}`)
																									.line(`Total CCF: ${closing.total_ccfs}`)
																									.line(`CCF inicial: ${first_ccf}`)
																									.line(`CCF final: ${last_ccf}`)
																									.align('center')
																									.line('------------------------------------------')
																									.bold(true)
																									.line(`>>TOTAL<<`)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.line(`Ventas gravadas: ${this.formatToCurrency(closing.sales)}`)
																									.line(`Ventas Exentas: ${this.formatToCurrency(closing.exempt)}`)
																									// .line(`Devoluciones: ${this.formatToCurrency(closing.refunds)}`)
																									.line(`Propina: ${this.formatToCurrency(closing.tip)}`)
																									.encode()
																								print_info.push(header_data)
																								if (closing.categories.length > 0) {
																									let gateway_info = result
																										.align('center')
																										.line('------------------------------------------')
																										.bold(true)
																										.line(`>>Ventas por categoria<<`)
																										.bold(false)
																										.line('------------------------------------------')
																										.encode
																									print_info.push(gateway_info)
																								}
																								closing.categories.forEach(category => {
																									let categories_info = result
																										.align('left')
																										.line(` ${category.name} (${category.quantity}) ${this.formatToCurrency(category.amount)}`)
																										.encode
																									print_info.push(categories_info)
																								})

																								if (closing.categories.length > 0) {
																									let gateway_info = result
																										.align('center')
																										.line('------------------------------------------')
																										.bold(true)
																										.line(`>>Ventas por empleado<<`)
																										.bold(false)
																										.line('------------------------------------------')
																										.encode
																									print_info.push(gateway_info)
																								}

																								closing.users.forEach(user => {
																									let users_info = result
																										.align('left')
																										.line(` ${user.user} | Propina: ${this.formatToCurrency(user.tip)}  Ventas:${this.formatToCurrency(user.sales)}`)
																										.encode
																									print_info.push(users_info)
																								})

																								let end = result
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.line('------------------------------------------')
																									.encode();
																								print_info.push(end)

																							} else if (element.info_type == 'refund_item') {
																								let refund = element;

																								let type = closing.type;

																								let header_data = result
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.line('------------------------------------------')
																									.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
																									.line('------------------------------------------')
																									.newline()
																									.newline()
																									.newline()
																									.line(this.auth.account_data.name)
																									.line(`${this.auth.account_data.address}`)
																									.line(`Telefono: ${this.auth.account_data.phone}`)
																									.align('left')
																									.encode()
																								print_info.push(header_data)

																								if (refund.fiscal) {
																									let header_fiscal = result
																										.align('center')
																										.size('normal')
																										.bold(true)
																										.line(refund.fiscal.business_name)
																										.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
																										.line(`NRC: ${this.auth.account_data.fiscal.nrc}`)
																										.encode()
																									print_info.push(header_fiscal)


																									let fiscal = result
																										.bold(true)
																										.line(`FECHA RESOLUCION: ${this.components.convertToDate(refund.fiscal.ticket.resolution_date.toDate())}`)
																										.align('center')
																										.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
																										.line(`Res No. ${refund.fiscal.ticket.res}`)
																										.line(`Del ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_from} al ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_to}`)
																										.line(`Tiquete No. ${refund.fiscal.ticket.current_ticket_number}`)
																										.line(`Caja. ${refund.fiscal.ticket.device_number}`)
																										.align('center')
																										.encode();
																									print_info.push(fiscal)
																								}

																								let subheader_data = result
																									.align('center')
																									.line('------------------------------------------')
																									.size('normal')
																									.bold(true)
																									.line(`FECHA: ${this.components.convertToDate(refund.creation_date)} ${this.components.convertToHours(refund.creation_date)} `)
																									.line(`Atendio: ${refund.user.name} ${refund.user.lastname}`)
																									.bold(true)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.encode()
																								print_info.push(subheader_data)

																								console.log(refund);

																								let name = refund.item.name;
																								if (refund.item.is_variant) {
																									name = `${refund.item.parent.name} (${refund.item.name})`
																								}

																								let item_total = refund.price * refund.quantity;

																								const cantidad = (refund.quantity.toString().length <= 4) ? 4 - refund.quantity.toString().length : 0;
																								const nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
																								const total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
																								const precio_unit = (refund.price.toFixed(2).length <= 9) ? 9 - refund.price.toFixed(2).length : 0;

																								if (name.length > 20) {
																									name = name.substring(0, 17) + '...'
																								}

																								let item_data = result
																									.bold(true)
																									.align('left')
																									.size('normal')
																									.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(refund.price)} x${refund.quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
																									.bold(false)
																									.encode();
																								print_info.push(item_data)

																								if (refund.item.have_modifiers) {
																									refund.item.modifiers.forEach(modifier => {
																										if (modifier.multiple) {
																											modifier.selected.forEach(mod_element => {
																												let modifier_data = result
																													.size('small')
																													.align('left')
																													.bold(false)
																													.line(`- ${(mod_element.name).toUpperCase()}`)
																													.encode();
																												print_info.push(modifier_data)
																											});
																										} else {
																											let modifier_data = result
																												.size('small')
																												.align('left')
																												.bold(false)
																												.line(`- ${(modifier.name).toUpperCase()}`)
																												.encode();
																											print_info.push(modifier_data)
																										}
																									});
																								}

																								let payment_header = result
																									.line('------------------------------------------')
																									.align('left')
																									.bold(true)
																									.size('normal')
																									.line(`${' '.repeat(28)} SUBTOTAL: ${this.formatToCurrency(refund.price * refund.quantity)}`)
																									.align('center')
																									.size(`normal`)
																									.bold(true)
																									.newline()
																									.size('big')
																									.size('small')
																									.line('------------------------------------------')
																									.size('normal')
																									.bold(false)
																									.encode();
																								print_info.push(payment_header)

																								let return_data = result
																									.bold(true)
																									.align('center')
																									.size('big')
																									.newline()
																									.line(`TOTAL DEVUELTO: ${this.formatToCurrency(refund.price * refund.quantity)}`)
																									.size('small')
																									.line('------------------------------------------')
																									.bold(false)
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.newline()
																									.line('DUI:______________________________________')
																									.newline()
																									.line('Firma_____________________________________')
																									.align('left')
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.newline()
																									.newline()
																									.newline()
																									.line('------------------------------------------')
																									.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
																									.line('------------------------------------------')
																									.align('left')
																									.newline()
																									.encode();
																								print_info.push(return_data)

																								let end = result
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.line('------------------------------------------')
																									.encode();
																								print_info.push(end)


																							} else if (element.info_type == 'refund') {
																								let refund = element;

																								let type = closing.type;

																								let header_data = result
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.line('------------------------------------------')
																									.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
																									.line('------------------------------------------')
																									.newline()
																									.newline()
																									.newline()
																									.line(this.auth.account_data.name)
																									.line(`${this.auth.account_data.address}`)
																									.line(`Telefono: ${this.auth.account_data.phone}`)
																									.align('left')
																									.encode()
																								print_info.push(header_data)

																								if (refund.fiscal) {
																									let header_fiscal = result
																										.align('center')
																										.size('normal')
																										.bold(true)
																										.line(refund.fiscal.business_name)
																										.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
																										.line(`NRC: ${this.auth.account_data.fiscal.nrc}`)
																										.encode()
																									print_info.push(header_fiscal)


																									let fiscal = result
																										.bold(true)
																										.line(`FECHA RESOLUCION: ${this.components.convertToDate(refund.fiscal.ticket.resolution_date.toDate())}`)
																										.align('center')
																										.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
																										.line(`Res No. ${refund.fiscal.ticket.res}`)
																										.line(`Del ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_from} al ${refund.fiscal.ticket.serie}|${refund.fiscal.ticket.range_to}`)
																										.line(`Tiquete No. ${refund.fiscal.ticket.current_ticket_number}`)
																										.line(`Caja. ${refund.fiscal.ticket.device_number}`)
																										.align('center')
																										.encode();
																									print_info.push(fiscal)
																								}


																								let subheader_data = result
																									.align('center')
																									.line('------------------------------------------')
																									.size('normal')
																									.bold(true)
																									.line(`FECHA: ${this.components.convertToDate(refund.creation_date)} ${this.components.convertToHours(refund.creation_date)} `)
																									.line(`Atendio: ${refund.user.name} ${refund.user.lastname}`)
																									.bold(true)
																									.bold(false)
																									.line('------------------------------------------')
																									.newline()
																									.align('left')
																									.encode()
																								print_info.push(subheader_data)

																								ticket.items.forEach(item => {
																									let name = item.name;
																									if (item.is_variant) {
																										name = `${item.parent.name} (${item.name})`
																									}

																									let item_total = item.price * item.quantity;

																									const cantidad = (item.quantity.toString().length <= 4) ? 4 - item.quantity.toString().length : 0;
																									const nombre = (name.substring(0, 27).length <= 20) ? 20 - name.substring(0, 27).length : 0;
																									const total = (item_total.toFixed(2).length <= 9) ? 9 - item_total.toFixed(2).length : 0;
																									const precio_unit = (item.price.toFixed(2).length <= 9) ? 9 - item.price.toFixed(2).length : 0;

																									if (name.length > 20) {
																										name = name.substring(0, 17) + '...'
																									}

																									let item_data = result
																										.bold(true)
																										.align('left')
																										.size('normal')
																										.line(`${name.toUpperCase()} ${' '.repeat(nombre)}${' '.repeat(precio_unit)} ${this.formatToCurrency(item.price)} x${item.quantity} ${' '.repeat(total)}${this.formatToCurrency(item_total)}`)
																										.bold(false)
																										.encode();
																									print_info.push(item_data)

																									if (item.have_modifiers) {

																										item.modifiers.forEach(modifier => {
																											if (modifier.multiple) {
																												modifier.selected.forEach(mod_element => {
																													let modifier_data = result
																														.size('small')
																														.align('left')
																														.bold(false)
																														.line(`- ${(mod_element.name).toUpperCase()}`)
																														.encode();
																													print_info.push(modifier_data)
																												});
																											} else {
																												console.log(modifier);

																												let modifier_data = result
																													.size('small')
																													.align('left')
																													.bold(false)
																													.line(`- ${(modifier.name).toUpperCase()}`)
																													.encode();
																												print_info.push(modifier_data)
																											}
																										});
																									}

																									if (item.notes) {
																										let notes_data = result
																											.size('small')
																											.align('left')
																											.bold(false)
																											.line(`${item.notes}`)
																											.encode();
																										print_info.push(notes_data)
																									}
																									let item_end = result
																										.align('center')
																										.newline()
																										.encode();
																									print_info.push(item_end)
																								});

																								let payment_header = result
																									.line('------------------------------------------')
																									.align('left')
																									.bold(true)
																									.size('normal')
																									.line(`${' '.repeat(28)} SUBTOTAL: ${this.formatToCurrency(refund.total)}`)
																									.align('center')
																									.size(`normal`)
																									.bold(true)
																									.newline()
																									.size('big')
																									.size('small')
																									.line('------------------------------------------')
																									.size('normal')
																									.bold(false)
																									.encode();
																								print_info.push(payment_header)

																								let return_data = result
																									.bold(true)
																									.align('center')
																									.size('big')
																									.newline()
																									.line(`TOTAL DEVUELTO: ${this.formatToCurrency(refund.total)}`)
																									.size('small')
																									.line('------------------------------------------')
																									.bold(false)
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.newline()
																									.line('DUI:______________________________________')
																									.newline()
																									.line('Firma_____________________________________')
																									.align('left')
																									.align('center')
																									.size('normal')
																									.bold(true)
																									.align('left')
																									.newline()
																									.encode();
																								print_info.push(return_data)

																								if (ticket.client) {
																									let client_data = result
																										.size('normal')
																										.align('center')
																										.line('------------------------------------------')
																										.bold(true)
																										.line(`Cliente: ${ticket.client.name}`)
																										.line(`Documento: ${ticket.client.document}`)
																										.size('small')
																										.encode();
																									print_info.push(client_data)
																								}


																								let footer_data2 = result
																									.newline()
																									.newline()
																									.newline()
																									.line('------------------------------------------')
																									.line(`Devolucion, Devolucion, Devolucion, Devolucion`)
																									.line('------------------------------------------')
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.encode();

																								print_info.push(footer_data2)

																								let end = result
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.newline()
																									.line('------------------------------------------')
																									.encode();
																								print_info.push(end)
																							}
																						});

																						let footer_data = result
																							.align('center')
																							.newline()
																							.newline()
																							.newline()
																							.newline()
																							.newline()
																							.align('center')
																							.size('normal')
																							.bold(true)
																							.line(this.auth.account_data.name)
																							.line(this.auth.account_data.fiscal.business_name)
																							.line(`${this.auth.account_data.address}`)
																							.line(`Telefono: ${this.auth.account_data.phone}`)
																							.line(`FECHA RESOLUCION: ${this.components.convertToDate(ticket.fiscal.ticket.resolution_date.toDate())}`)
																							.align('center')
																							.size('normal')
																							.bold(true)
																							.line(`NIT: ${this.auth.account_data.fiscal.nit}`)
																							.line(`NRC: ${this.auth.account_data.fiscal.nrc}`)
																							.bold(true)
																							.align('center')
																							.line(this.auth.account_data.fiscal ? `Giro. ${this.auth.account_data.fiscal.activity.value}` : ``)
																							.align('center')
																							.newline()
																							.newline()
																							.newline()
																							.newline()
																							.newline()
																							.cut()
																							.encode();
																						print_info.push(footer_data)

																						this.print(print_info, 'closing');

																					})

																			})
																	}
																}, err => {
																	console.log(err);
																});

															// all_info.push(closing);

														});

													})
											}
										})
								})
						});


					})

			})
	}

}
