import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PosService } from 'src/app/services/pos/pos.service';
import { ComponentsService } from './../../../services/components/components.service';

@Component({
	selector: 'app-add-note',
	templateUrl: './add-note.component.html',
	styleUrls: ['./add-note.component.scss'],
})
export class AddNoteComponent implements OnInit {

	item: any;
	already_in_order: any;
	notes: any = '';

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		public api: ApiService,
		public components: ComponentsService,
		public auth: AuthService,
		public pos: PosService,
		public nav: IonNav
	) {
		this.item = this.navParams.get('item');
		this.already_in_order = this.navParams.get('already_in_order');
	}

	ngOnInit() { }

	addNote() {
		if (!this.already_in_order) {
			this.pos.updateItem(this.item, this.notes);
			this.components.showToast('Nota actualizada correctamente.', 'success');
			this.modalController.dismiss();
		} else {
			this.api.updateDocument(`accounts/${this.auth.account}/ticket_items`, this.item.$key, {
				notes: this.item.notes
			}).then(() => {
				this.components.showToast('Nota actualizada correctamente.', 'success');
				this.modalController.dismiss();
			}, err => {
				console.log(err);
			});
		}
	}
}
