import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';
import { AddCancelNoteComponent } from '../add-cancel-note/add-cancel-note.component';
import { RefundItemComponent } from '../refund-item/refund-item.component';
import { RefundTicketComponent } from '../refund-ticket/refund-ticket.component';
import { ReturnItemComponent } from '../return-item/return-item.component';
import { ReturnOrderComponent } from '../return-order/return-order.component';

@Component({
	selector: 'app-cancel-notes',
	templateUrl: './cancel-notes.component.html',
	styleUrls: ['./cancel-notes.component.scss'],
})
export class CancelNotesComponent implements OnInit {

	item: any;
	order: any;
	type: any;
	ticket: any;

	reasons: any = [
		"Item equivocado",
		"No se presento a recoger",
		"No habia nadie en domicilio",
		"Se tardo mucho tiempo",
		"No le gusto al cliente",
		"Cortesia de gerente",
		"Entrenando empleado",
		"Otros"
	]

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		public components: ComponentsService,
		private nav: IonNav
	) {
		let params = this.navParams.data;

		if (Object.keys(params).length > 0) {
			if (navParams.data.type == 'order') {
				this.type = JSON.parse(JSON.stringify(navParams.data.type));
				this.order = JSON.parse(JSON.stringify(navParams.data.order));
			} else if (navParams.data.type == 'refund_ticket') {
				this.type = JSON.parse(JSON.stringify(navParams.data.type));
				this.ticket = JSON.parse(JSON.stringify(navParams.data.ticket));
			} else if (navParams.data.type == 'refund_item') {
				this.type = JSON.parse(JSON.stringify(navParams.data.type));
				this.item = JSON.parse(JSON.stringify(navParams.data.item));
				this.ticket = JSON.parse(JSON.stringify(navParams.data.ticket));
			} else if (navParams.data.type == 'return_item') {
				this.item = JSON.parse(JSON.stringify(navParams.data.item));
				this.order = JSON.parse(JSON.stringify(navParams.data.order));
			} else {
				this.item = JSON.parse(JSON.stringify(navParams.data.pre_item));
			}
		}
	}

	ngOnInit() { }

	selectReason(reason) {

		if (this.type == 'order') {
			this.nav.push(ReturnOrderComponent, {
				level: 1,
				order: this.order,
				reason: reason
			});
		} else if (this.type == 'refund_ticket') {
			this.nav.push(RefundTicketComponent, {
				level: 1,
				ticket: this.ticket,
				reason: reason
			});
		} else if (this.type == 'refund_item') {
			this.nav.push(RefundItemComponent, {
				level: 1,
				item: this.item,
				ticket: this.ticket,
				reason: reason
			});
		} else if (this.type == 'return_item') {
			this.nav.push(ReturnItemComponent, {
				level: 1,
				item: this.item,
				order: this.order,
				reason: reason
			});
		}
		else {
			this.nav.push(AddCancelNoteComponent, {
				level: 1,
				pre_item: this.item,
				reason: reason
			});
		}
	}

}
