import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-delivery-data',
	templateUrl: './delivery-data.component.html',
	styleUrls: ['./delivery-data.component.scss'],
})
export class DeliveryDataComponent implements OnInit {

	deliveryForm: UntypedFormGroup;
	delivery: any;

	constructor(
		public modalController: ModalController,
		public formBuilder: UntypedFormBuilder,
		public pos: PosService,
		public navParams: NavParams,
		public components: ComponentsService
	) {
		this.delivery = this.navParams.get('delivery');

		this.deliveryForm = this.formBuilder.group({
			order_number: [''],
			delivery_number: ['', [Validators.required]]
		});
	}

	ngOnInit() { }

	setDelivery() {
		let delivery_data = this.deliveryForm.value;
		this.delivery.order_number = delivery_data.order_number;
		this.delivery.delivery_number = delivery_data.delivery_number;
		this.modalController.dismiss(this.delivery);
		this.pos.addDelivery(this.delivery);
		this.pos.changeType('delivery');
	}
}
