import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from 'src/app/services/pos/pos.service';

@Component({
	selector: 'app-number',
	templateUrl: './number.component.html',
	styleUrls: ['./number.component.scss'],
})
export class NumberComponent implements OnInit {

	wrong: any = false;
	table: any;
	mens: any = 0;
	childrens: any = 0;
	womens: any = 0;
	is_update: any;
	order: any;

	constructor(
		public router: Router,
		public auth: AuthService,
		public components: ComponentsService,
		public navParams: NavParams,
		public modalController: ModalController,
		public api: ApiService,
		public pos: PosService
	) {
		this.table = navParams.get('table');
		this.is_update = navParams.get('is_update');
		this.order = navParams.get('order');

		if (this.is_update) {
			this.womens = this.pos.womens;
			this.mens = this.pos.mens;
			this.childrens = this.pos.childrens;
		}
	}

	ngOnInit() { }

	logOut() {
		this.auth.logOut();
	}

	goToTable() {
		let number = this.womens + this.childrens + this.mens;

		if (this.is_update) {
			if (this.order) {

				this.components.showLoader().then(() => {
					this.api.updateDocument(`accounts/${this.auth.account}/orders`, this.order.$key, {
						mens: this.mens,
						womens: this.womens,
						childrens: this.childrens,
					}).then(data => {
						this.pos.womens = this.womens;
						this.pos.mens = this.mens;
						this.pos.childrens = this.childrens;
						this.components.dismissLoader();
						this.modalController.dismiss();
					})
				})
			} else {
				this.pos.womens = this.womens;
				this.pos.mens = this.mens;
				this.pos.childrens = this.childrens;
				this.modalController.dismiss();
			}
		} else {
			if (number > 0) {
				this.pos.type = 'tables';
				this.pos.womens = this.womens;
				this.pos.mens = this.mens;
				this.pos.childrens = this.childrens;
				this.pos.table = this.table.text;
				this.modalController.dismiss();
				this.router.navigate(['/pos']);
			} else {
				this.components.showToast('Debes de agrega al menos una persona en la mesa', 'error');
			}
		}
	}

	changeQuantity(adding_type, type) {
		if (adding_type == 'add') {
			if (type == 'mens') {
				this.mens++;
			} else if (type == 'womens') {
				this.womens++;
			} else if (type == 'childrens') {
				this.childrens++;
			}

		}
		else if (adding_type == 'minus') {
			if (type == 'mens') {
				this.mens--;
			} else if (type == 'womens') {
				this.womens--;
			} else if (type == 'childrens') {
				this.childrens--;
			}

		}
	};

}
