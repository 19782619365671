import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { isEqual } from 'lodash';

@Component({
	selector: 'app-separate-items',
	templateUrl: './separate-items.component.html',
	styleUrls: ['./separate-items.component.scss'],
})
export class SeparateItemsComponent implements OnInit {

	order_key: any;
	items: any = [];
	loading: any = true;
	selected_items: any = [];
	total: any = 0;
	total_items: any = 0;
	orders: any = [];

	constructor(
		public modalController: ModalController,
		public navParams: NavParams,
		public api: ApiService,
		public auth: AuthService,
		public components: ComponentsService,
		public router: Router
	) {
	}

	ngOnInit() {
		this.order_key = this.navParams.get('order_key');

		this.api.getRef(`accounts/${this.auth.account}/ticket_items`).ref
			.where('order_key', '==', this.order_key)
			.onSnapshot(snapshots => {
				snapshots.docChanges().forEach(element => {
					if (element.type == 'added') {
						let item = element.doc.data();
						item.$key = element.doc.id;
						item.checked = false;
						if (item.quantity > 1) {
							item.items_to_paid = 1;
						}
						this.items.push(item);
					}
				});

				this.loading = false;

			}, err => {
				console.log(err);
			})
	}

	decreaseQuantity(item) {
		if (item.items_to_paid > 1) {
			item.items_to_paid = Number(item.items_to_paid) - 1;
		}
		this.getTotal();
	}

	addQuantity(item) {
		if (item.items_to_paid < item.quantity) {
			item.items_to_paid = Number(item.items_to_paid) + 1;
		}
		this.getTotal();
	}

	updateItems(item) {
		if (item.checked) {
			this.selected_items.push(item);
		} else {
			const found = this.selected_items.findIndex(_element => _element.$key == item.$key);
			this.selected_items.splice(found, 1);
		}

		this.getTotal();

	}

	selectAll(ev) {
		this.items.forEach(element => {
			element.checked = ev.detail.checked;
		});
	}

	getTotal() {
		this.total = 0;
		this.total_items = 0;
		this.selected_items.forEach(element => {
			if (element.quantity > 1) {
				this.total_items += element.items_to_paid;
				this.total += element.price * element.items_to_paid;
			} else {
				this.total_items += element.quantity;
				this.total += element.price;
			}
		});
	}

	splitItems() {
		let items_totales = 0;

		this.items.forEach(element => {
			items_totales += element.quantity;
		});

		if (items_totales - this.total_items <= 0) {
			this.components.dismissLoader();
			this.components.showToast('No puedes dejar la cuenta sin items.', 'error', 5000);
		} else {
			if (this.total_items > 0) {
				let items_total = 0;

				this.selected_items.forEach(element => {
					items_total += element.quantity * element.price;
				});

				if (this.navParams.get('total') - items_total < 0) {
					this.components.dismissLoader();
					this.components.showToast('No puedes separar items ya que hay pagos realizados y el total no puede ser menor de 0', 'error', 5000);
				} else {
					let items = [];

					this.selected_items.forEach(element => {

						if (element.quantity == 1) {

							// Aqui Se pasa el item de un solo
							const found = this.items.findIndex(_element => _element.$key == element.$key);
							let item = JSON.parse(JSON.stringify(this.items[found]));
							items.push(item);
							this.items.splice(found, 1);
						} else {
							if (element.items_to_paid < element.quantity) {
								// Aqui es por que solo se pasa parcial
								const found = this.items.findIndex(_element => _element.$key == element.$key);
								this.items[found].quantity = this.items[found].quantity - element.items_to_paid;
								let new_item = JSON.parse(JSON.stringify(this.items[found]));
								new_item.new = true;
								new_item.quantity = element.items_to_paid
								items.push(new_item);
							} else {
								// Aqui se pasa todo
								const found = this.items.findIndex(_element => _element.$key == element.$key);
								let item = JSON.parse(JSON.stringify(this.items[found]));
								items.push(item);
								this.items.splice(found, 1);
							}
						}
					});

					let ticket = {
						items: items,
						total: 0
					}

					items.forEach(element => {
						ticket.total += element.price * element.quantity;
					});

					this.selected_items = [];
					this.total_items = 0;

					this.items.forEach(element => {
						element.checked = false;
						element.items_to_paid = 1;
					});

					this.orders.push(ticket)

				}
			}
		}
	}

	undoOrder(order, index) {
		order.items.forEach(item => {
			const found = this.items.findIndex(element => element.item_key == item.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers));
			item.checked = false;
			if (found < 0) {
				this.items.push(item);
			} else {
				this.items[found].quantity += item.quantity;
			}
		});

		this.orders.splice(index, 1);
	}

	undoItem(item, order_index, item_index) {
		const found = this.items.findIndex(element => element.item_key == item.item_key && item.notes == element.notes && item.price == element.price && item.have_promotion == element.have_promotion && item.have_modifiers == element.have_modifiers && isEqual(item.modifiers, element.modifiers));
		item.checked = false;

		if (found < 0) {
			this.items.push(item);
		} else {
			this.items[found].quantity += item.quantity;
		}

		this.orders[order_index].items.splice(item_index, 1);

		if (this.orders[order_index].items.length == 0) {
			this.orders.splice(order_index, 1);
		}
	}

	splitAccount() {
		this.components.showLoader('Separando items...').then(() => {
			if (this.orders.length > 0) {
				this.api.splitItems(this.navParams.get('order'), this.order_key, this.items, this.orders).then(data => {
					this.components.dismissLoader();
					this.modalController.dismiss(data);
				}, err => {
					console.log(err);
				})
			} else {
				this.components.dismissLoader();
				this.components.showToast('No puedes separar items ya que hay pagos realizados y el total no puede ser menor de 0', 'error', 5000);
			}
		})
	}

}
