import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { PosService } from './../../../services/pos/pos.service';
import { SelectPaymentMethodComponent } from './../../giftcards/select-payment-method/select-payment-method.component';
import { ClientsComponent } from './../../pos/clients/clients.component';
import { DeliveriesComponent } from '../../pos/deliveries/deliveries.component';

@Component({
	selector: 'app-select-type',
	templateUrl: './select-type.component.html',
	styleUrls: ['./select-type.component.scss'],
})
export class SelectTypeComponent implements OnInit {
	constructor(
		public auth: AuthService,
		public modalController: ModalController,
		public router: Router,
		public components: ComponentsService,
		public pos: PosService
	) {
	}

	ngOnInit() {
	}

	selectType(type) {
		if (type == 'delivery') {
			this.addDelivery('delivery');
		} else {
			this.pos.changeType(type);
		}
	}

	async addClient(type = '') {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;

			const modal = await this.modalController.create({
				component: ClientsComponent,
				cssClass: 'clients-modal',
				showBackdrop: true,
				componentProps: {
					type: type
				},
			});

			await modal.present().then(() => { this.components.isModalPresent = false })

			await modal.onDidDismiss().then(data => {
				if (data.data) {
					let client = data.data;
					if (type != 'giftcard') {
						this.pos.addClient(client);
						this.pos.changeType(type);
					} else {
						// this.components.showToast('Cliente agregado correctamente.');
						this.selectPaymentMethod(client);
					}
				}
			});

		}
	}

	async addDelivery(type = '') {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;

			const modal = await this.modalController.create({
				component: DeliveriesComponent,
				showBackdrop: true,
				componentProps: {
					type: type
				},
			});

			await modal.present().then(() => { this.components.isModalPresent = false })
		}
	}

	async selectPaymentMethod(client) {
		if (!this.components.isModalPresent) {
			this.components.isModalPresent = true;

			if (!this.components.isApp()) {
				const modal = await this.modalController.create({
					component: SelectPaymentMethodComponent,
					showBackdrop: true,
					cssClass: 'select-method-modal',
					componentProps: {
						client: client
					},
				});

				await modal.present().then(() => { this.components.isModalPresent = false })

			} else {

				const modal = await this.modalController.create({
					component: SelectPaymentMethodComponent,
					showBackdrop: true,
					initialBreakpoint: 1,
					cssClass: 'select-method-modal',
					breakpoints: [0, 1],
					componentProps: {
						client: client
					},
				});

				await modal.present().then(() => { this.components.isModalPresent = false })
			}
		}
	}
}
