import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ComponentsService } from 'src/app/services/components/components.service';
import { tipo_documento, departamentos, municipios, actividad_economica } from "../../../../assets/dte";
import { SelectPopoverComponent } from '../../shared/select-popover/select-popover.component';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';

@Component({
	selector: 'app-add-client',
	templateUrl: './add-client.component.html',
	styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit {
	clientForm: UntypedFormGroup;
	type: any;
	documents_types: any = tipo_documento;
	activity: any = actividad_economica;
	deptos: any = departamentos;
	municipios: any = municipios;

	readonly nit_legal_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly nit_natural_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly nrc_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly document_mask: MaskitoOptions = {
		mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/],
	};

	readonly maskPredicate: MaskitoElementPredicateAsync = async (el) => (el as HTMLIonInputElement).getInputElement();

	constructor(
		public modalController: ModalController,
		public popoverController: PopoverController,
		public formBuilder: UntypedFormBuilder,
		private api: ApiService,
		public auth: AuthService,
		public components: ComponentsService,
		public navParams: NavParams
	) {
		this.type = this.navParams.get('type');
		if (this.type == 'regular') {
			this.clientForm = this.formBuilder.group({
				name: ['', [Validators.required]],
				email: ['', [Validators.email]],
				phone: [''],
				document_type: [''],
				document: [''],
				type: ['regular'],
				address: [''],
				address_2: [''],
				city: [''],
				postal_code: [''],
				legal_person: [false],
				credit: [false],
				limit_credit: [0],
				depto: [''],
				munic: [''],
				exempt: [false]
			});

			this.clientForm.get('credit').valueChanges.subscribe((credit) => {
				console.log(credit);

				if (credit) {
					this.clientForm.controls['limit_credit'].setValidators([
						Validators.required,
					]);
				} else {
					this.clientForm.controls['limit_credit'].clearValidators();
				}
				this.clientForm.controls['limit_credit'].updateValueAndValidity();
			});
		} else if (this.type == 'natural' || this.type == 'legal') {
			this.clientForm = this.formBuilder.group({
				name: ['', [Validators.required]],
				email: [''],
				phone: [''],
				address: [''],
				address_2: [''],
				city: [''],
				nit: [''],
				nrc: [''],
				postal_code: [''],
				credit: [false],
				limit_credit: [0],
				legal_person: [true],
				large_contributor: [false],
				type: [this.type],
				activity: [''],
				depto: [''],
				munic: [''],
				exempt: [false]
			});

			this.clientForm.get('credit').valueChanges.subscribe((credit) => {
				if (credit) {
					this.clientForm.controls['limit_credit'].setValidators([
						Validators.required,
					]);
				} else {
					this.clientForm.controls['limit_credit'].clearValidators();
				}
				this.clientForm.controls['limit_credit'].updateValueAndValidity();
			});
		}
	}

	ngOnInit() { }

	addClient() {
		this.components.showLoader('Agregando cliente...').then(() => {
			let client = this.clientForm.value;
			let valid = true;

			if (client.legal_person) {
				client.nit = client.nit.replace(/-/g, "");
				client.nrc = client.nrc.replace(/-/g, "");
			} else {
				if (client.document_type.value == 'NIT') {
					client.document = client.document.replace(/-/g, "");
				}
			}

			if (client.credit) {
				if (client.limit_credit <= 0) {
					valid = false;
					this.components.showToast('El limite de crédito debe de ser mayor a 0 ', 'error');
					this.components.dismissLoader();
				}
			}

			console.log(client);

			if (valid) {
				client.spent = 0;
				client.orders = 0;
				client.creation_date = new Date();

				this.api.addDocument(`accounts/${this.auth.account}/clients`, client).then((data) => {
					client.$key = data.id;
					this.modalController.dismiss(client);
					this.components.dismissLoader();
					this.components.showToast('Cliente agregado.');
				});

			}
		});
	}

	changePersonType(e) {
		console.log(e.detail.checked);
	}

	async selectDocType(ev) {
		let docs_types = JSON.parse(JSON.stringify(this.documents_types));

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			size: 'cover',
			showBackdrop: false,
			componentProps: {
				list: docs_types
			}
		});

		popover.onDidDismiss().then((data) => {
			let element = data.data;
			if (element && (element.value != this.clientForm.value.document_type.value)) {
				this.clientForm.controls.document_type.setValue(element);
			}
		});

		await popover.present();
	}

	async selectAct(ev) {
		let activity = JSON.parse(JSON.stringify(this.activity));

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			size: 'cover',
			showBackdrop: false,
			componentProps: {
				list: activity
			}
		});

		popover.onDidDismiss().then((data) => {
			if (data.data) {
				this.clientForm.controls.activity.setValue(data.data);
			}
		});

		await popover.present();
	}

	async selectDepto(ev) {
		let deptos = JSON.parse(JSON.stringify(this.deptos))

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			showBackdrop: false,
			componentProps: {
				list: deptos
			}
		});

		popover.onDidDismiss().then((data) => {
			let element = data.data;
			if (element && (element.value != this.clientForm.value.depto.value)) {
				this.clientForm.controls.depto.setValue(element);
				this.clientForm.controls.munic.setValue('');
			}
		});

		await popover.present();
	}

	async selectMunicipios(ev) {
		let municipios = JSON.parse(JSON.stringify(this.municipios));
		let municipios_filtrados = [];

		municipios.forEach(munic => {
			if (this.clientForm.value.depto.code == munic.depto) {
				municipios_filtrados.push(munic);
			}
		});

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			event: ev,
			showBackdrop: false,
			componentProps: {
				list: municipios_filtrados
			}
		});

		popover.onDidDismiss().then((data) => {
			let element = data.data;
			if (element && (element.value != this.clientForm.value.munic.value)) {
				this.clientForm.controls.munic.setValue(element);
			}
		});

		await popover.present();
	}

	getMask() {
		let document_type = this.clientForm.value.document_type.value;

		if (document_type == 'DUI') {
			return this.document_mask;
		} else if (document_type == 'NIT') {
			return this.nit_legal_mask;
		} else if (document_type == 'NRC') {
			return this.nrc_mask;
		} else {
			return null;
		}
	}
}
